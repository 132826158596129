import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ModuleBody } from "@wfp/ui";

import TableExtended from "components/TableExtended";

import { getLevelAgeMappings } from "data-handler/ducks/ageGroups";
import { displayStaffLevelsReport } from "components/SchoolReport/Absences";

import { orderRowsByLevels } from "helpers/utils";

import {
  studentKinds,
  studentLevelTotals,
  allLevels,
  staffLevelTotals,
} from "SCConstants";

import styles from "./school-report.module.scss";

const Enrolments = ({
  report: {
    aggregates: { enrolment_rows, enrolment_totals_rows },
  },
}) => {
  enrolment_rows = orderRowsByLevels(enrolment_rows);
  const levelMapping = useSelector(getLevelAgeMappings);

  return (
    <ModuleBody className={styles.reportModule}>
      <h5 className={styles.sectionTitle}>
        <FormattedMessage
          id="enrolments.number_of_students_enrolled_title"
          defaultMessage="1. Numbers of students enrolled by level and gender at the end of
            the month (source: figures from the register)"
        />
      </h5>
      <TableExtended>
        <thead className={styles.reportTHead}>
          <tr>
            <td>
              <span>
                <FormattedMessage
                  id="enrolments.level"
                  defaultMessage="Level"
                />
              </span>
            </td>
            {studentKinds.map((kind) => (
              <td key={kind.value}>
                <span>{kind.labelTrans}</span>
              </td>
            ))}
            <td>
              <span>
                <FormattedMessage
                  id="enrolments.total"
                  defaultMessage="Total"
                />
              </span>
            </td>
          </tr>
        </thead>
        <tbody>
          {/* Per-level aggregates */}
          {enrolment_rows.map((row, i) => {
            const customName = levelMapping.find(
              (item) => item.level === row.level
            )?.custom_name;
            const defaultName = allLevels.find(
              (item) => item.value === row.level
            )?.label;
            const level =
              customName && customName !== "" ? customName : defaultName;
            return (
              <tr key={i}>
                <td className={styles.headerData}>
                  <span>{level}</span>
                </td>
                {studentKinds.map((kind) => (
                  <td className={styles.headerData} key={kind.value}>
                    <span>{row[kind.value]}</span>
                  </td>
                ))}
                <td className={styles.headerData}>
                  <span>{row.total}</span>
                </td>
              </tr>
            );
          })}

          {/* Totals */}
          {enrolment_totals_rows
            .filter(function (enrolment_totals_row) {
              if (
                !enrolment_rows.some((e) => e.level === "staff") &&
                enrolment_totals_row.name === "staff"
              ) {
                return false;
              } else {
                return true;
              }
            })
            .map((row, i) => (
              <tr key={i}>
                <td className={styles.headerData}>
                  <span className={styles.boldText}>
                    {
                      studentLevelTotals.find((total) => total.key === row.name)
                        ?.title
                    }
                  </span>
                  {displayStaffLevelsReport(enrolment_rows) && (
                    <span className={styles.boldText}>
                      {
                        staffLevelTotals?.find(
                          (staffTotal) => staffTotal.key === row.name
                        )?.title
                      }
                    </span>
                  )}
                </td>
                {studentKinds.map((kind) => (
                  <td className={styles.headerData} key={kind.value}>
                    <span className={styles.boldText}>{row[kind.value]}</span>
                  </td>
                ))}
                <td className={styles.headerData}>
                  <span className={styles.boldText}>{row.total}</span>
                </td>
              </tr>
            ))}
        </tbody>
      </TableExtended>
    </ModuleBody>
  );
};

export default Enrolments;
