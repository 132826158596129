import React from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Module, ModuleBody, ModuleHeader, User, Tag } from "@wfp/ui";

import styles from "./school-report.module.scss";

// The following FormattedMessages are expressed individually
// in order for react-intl's tooling to detect them
// when parsing the codebase searching for translatables.
const transitionsToTags = {
  update: (
    <Tag type="info">
      <FormattedMessage id="ReportTransition.update" defaultMessage="Updated" />
    </Tag>
  ),
  close: (
    <Tag type="info">
      <FormattedMessage id="ReportTransition.close" defaultMessage="Closed" />
    </Tag>
  ),
  submit: (
    <Tag type="info">
      <FormattedMessage
        id="ReportTransition.submit"
        defaultMessage="Submitted"
      />
    </Tag>
  ),
  approve: (
    <Tag type="success">
      <FormattedMessage
        id="ReportTransition.approve"
        defaultMessage="Approved"
      />
    </Tag>
  ),
  reject: (
    <Tag type="error">
      <FormattedMessage
        id="ReportTransition.reject"
        defaultMessage="Rejected"
      />
    </Tag>
  ),
  amend: (
    <Tag type="info">
      <FormattedMessage id="ReportTransition.amend" defaultMessage="Amended" />
    </Tag>
  ),
  reopen: (
    <Tag type="info">
      <FormattedMessage
        id="ReportTransition.reopen"
        defaultMessage="Reopened"
      />
    </Tag>
  ),
  reopen_approved: (
    <Tag type="info">
      <FormattedMessage
        id="ReportTransition.reopenApproved"
        defaultMessage="Reopened"
      />
    </Tag>
  ),
  reopen_validated: (
    <Tag type="info">
      <FormattedMessage
        id="ReportTransition.reopenValidated"
        defaultMessage="Reopened"
      />
    </Tag>
  ),
};

const COMMENT_SUBTITLE = (
  <FormattedMessage
    id="ReportCommentSubtitle.subtitle"
    defaultMessage="Comments: "
  />
);

const ReportTransition = ({ transition }) => {
  return transitionsToTags[transition];
};

const LogEntry = ({ log_entry }) => {
  return (
    <Module>
      <ModuleHeader className={styles.logEntryHeader}>
        <div className={styles.logEntryHeader}>
          <div>{moment(log_entry.timestamp).format("DD.MM.YYYY")}</div>
          <div className={styles.verticalSeparator} />
          <ReportTransition transition={log_entry.transition} />
          <div className={styles.verticalSeparator} />
          <User name={log_entry.user.full_name} />
          {(log_entry.transition === "approve" ||
            log_entry.transition === "reject") &&
            log_entry.user.designation && (
              <span>&nbsp;({log_entry.user.designation})</span>
            )}
        </div>
      </ModuleHeader>
      {log_entry.comments && (
        <ModuleBody className={styles.logEntryComments}>
          <strong>{COMMENT_SUBTITLE}</strong>
          {log_entry.comments}
        </ModuleBody>
      )}
    </Module>
  );
};

export default LogEntry;
