import React from "react";
import { Route, Link } from "react-router-dom";

const TabLink = ({ index, selected, children, to, exact, handleTabClick }) => (
  <Route
    path={to}
    exact={exact}
    children={(props) => {
      const location = props.location.pathname.split("/");
      // school/:id/... Route has 4 sections
      // We want the final part to distingush between the tabs
      let indexNumber = 3;
      // Where as /countryAdmin/ has 3 sections apart from the country Profile page.
      // This only has 2.
      if (location[1] === "countryAdmin") {
        indexNumber = 2;
      }

      // We need to make sure the "clicked" tab and the selected tab within the website are the same.
      // Although the split is not the same for each route.
      // For example, /countryAdmin/ route for the countryProfile does not have a suffix.
      // This checks that if the ending in undefined but the prefix is "countryAdmin" then move to the country profile page.
      const section = props.location.pathname.split("/")[indexNumber]
        ? props.location.pathname.split("/")[indexNumber]
        : props.location.pathname.split("/")[indexNumber - 1]
        ? props.location.pathname.split("/")[indexNumber - 1]
        : undefined;

      const navSection = to.split("/")[indexNumber]
        ? to.split("/")[indexNumber].split("?")[0]
        : to.split("/")[indexNumber - 1].split("?")[0]
        ? to.split("/")[indexNumber - 1].split("?")[0]
        : undefined;
      const match = section === navSection;
      if (match && !selected) {
        handleTabClick(index, children);
      }
      return (
        <div
          className={
            match
              ? "wfp--tabs__nav-item wfp--tabs__nav-item--selected"
              : "wfp--tabs__nav-item"
          }
        >
          <Link className="wfp--tabs__nav-link" to={to}>
            {children}
          </Link>
        </div>
      );
    }}
  />
);

export default TabLink;
