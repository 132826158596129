import axios from "axios";
import moment from "moment";

import {
  REFRESH_TOKEN,
  REFRESH_LAST_ACTION_PERFORMED_AT,
  getLastActionPerformedAt,
  getRefreshToken,
  getToken,
  setOnlineRequiredError,
  getIsOfflineMode,
} from "data-handler/ducks/auth";

// list to store the failed 401 requests
const failedRequestArray = [];

export const refreshAccessToken = (refreshToken) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_CIAM_REFRESH_TOKEN_URL}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { refresh_token: refreshToken },
    })
      .then((response) => {
        return resolve({
          token: response.data.token,
          refreshToken: response.data.refresh_token,
        });
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

export default {
  setupInterceptors: (store) => {
    axios.interceptors.request.use(
      (config) => {
        const state = store.getState();
        const token = getToken(state);

        if (token) {
          config.headers["authorization"] = "Bearer " + token;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      async (response) => {
        // Update the last performed action attribute of the auth store
        store.dispatch({
          type: REFRESH_LAST_ACTION_PERFORMED_AT,
          data: moment().toString(),
        });

        // Remove the 401 failed requests associated to the
        // successful response's url (if existing)
        if (failedRequestArray.length !== 0) {
          failedRequestArray.forEach((x, i) => {
            if (response.config.url === x.url) {
              failedRequestArray.splice(i, 1);
            }
          });
        }

        return response;
      },
      async (error) => {
        const state = store.getState();
        const isOfflineMode = getIsOfflineMode(state);

        if (isOfflineMode) {
          return Promise.reject(error);
        }

        const originalRequest = error.config;

        const lastActionPerformedAt = getLastActionPerformedAt(state);
        const timeSinceLastAction = lastActionPerformedAt
          ? moment().diff(moment(lastActionPerformedAt), "minutes", true)
          : undefined;

        // catches if the session ended, refreshes the access token and retries the
        // failed 401 requests in queue, but only if the user hasn't been inactive
        // for more than 30 minutes
        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          (timeSinceLastAction < 30 || !timeSinceLastAction)
        ) {
          // Add the request to the failed list if there isn't already an entry with the same url
          if (
            !failedRequestArray.find((item) => item.url === originalRequest.url)
          ) {
            failedRequestArray.push(originalRequest);
          }

          originalRequest._retry = true;
          try {
            // Get the old refresh token that is used to refresh the current access token
            const oldRefreshToken = getRefreshToken(state);

            // If it is the first failed request, refresh the token for it
            if (failedRequestArray.length === 1) {
              const refreshTokenResponse = await refreshAccessToken(
                oldRefreshToken
              );
              // save the updated access and refresh tokens
              store.dispatch({
                type: REFRESH_TOKEN,
                data: refreshTokenResponse,
              });

              // Retry the request with the new access token, and after the request is
              // successful, retry the previously failed requests (401) from the failed list
              // with the new access token
              return axios(originalRequest).then((res) => {
                if (res.status === 200) {
                  if (failedRequestArray.length !== 0) {
                    failedRequestArray.forEach((req) => {
                      try {
                        return axios(req);
                      } catch (error) {
                        return Promise.reject(error);
                      }
                    });
                  }
                }
              });
            }
          } catch (error) {
            if (
              (error.response?.status === 400 &&
                error.response?.data?.error ===
                  "Could not retrieve user token") ||
              error.response?.status === 403
            ) {
              store.dispatch(setOnlineRequiredError());
            }
            return Promise.reject(error);
          }
        } else if (
          error.response &&
          error.response.status === 401 &&
          timeSinceLastAction >= 30
        ) {
          // if the user has been inactive for more than 30 minutes, require login again
          store.dispatch(setOnlineRequiredError());
        }
        return Promise.reject(error);
      }
    );
  },
};
