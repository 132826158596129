import { RootState } from "data-handler/rootReducer";

export const SET_SELECTED_COUNTRY =
  "schoolconnect/countryProfile/SET_SELECTED_COUNTRY";

// TODO: We need to find a way to inform devs to update this type
// whenever we update the serializers.
/*
 * Currency values should be same as currency serializer.
 */
export type Currency = {
  id: number;
  code: string;
  code_number: number;
  name: string;
  decimals: number;
};

type Country = {
  id: number;
  iso2: string;
  iso3: string;
  name: string;
  adm0_id: string;
  currency: Currency;
};

type CountryProfile = {
  fetching: boolean;
  error: any;
  errorResponse: any;
  selectedCountry: Country | null;
};

const initialState: CountryProfile = {
  fetching: false,
  error: null,
  errorResponse: null,
  selectedCountry: null,
};

export default function reducer(
  state = initialState,
  action: CountryProfile & { type: string }
) {
  switch (action.type) {
    case SET_SELECTED_COUNTRY:
      return { ...state, selectedCountry: action.selectedCountry };
    default:
      return state;
  }
}

export const setSelectedCountry = (selectedCountry: string) => ({
  type: SET_SELECTED_COUNTRY,
  selectedCountry,
});

export const getSelectedCountry = (state: RootState) =>
  state.countryProfile.selectedCountry;
