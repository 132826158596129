import React from "react";
import { FormattedMessage } from "react-intl";

import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { errorToast, successToast } from "data-handler/ducks/toast";

export const COUNTRY_ADMIN_LEVELS_REQUEST =
  "schoolconnect/adminLevels/COUNTRY_ADMIN_LEVELS_REQUEST";
export const COUNTRY_ADMIN_LEVELS_SUCCESS =
  "schoolconnect/adminLevels/COUNTRY_ADMIN_LEVELS_SUCCESS";
export const COUNTRY_ADMIN_LEVELS_FAILURE =
  "schoolconnect/adminLevels/COUNTRY_ADMIN_LEVELS_FAILURE";

export const REGISTRATION_SCHOOLS_REQUEST =
  "schoolconnect/adminLevels/REGISTRATION_SCHOOLS_REQUEST";
export const REGISTRATION_SCHOOLS_SUCCESS =
  "schoolconnect/adminLevels/REGISTRATION_SCHOOLS_SUCCESS";
export const REGISTRATION_SCHOOLS_FAILURE =
  "schoolconnect/adminLevels/REGISTRATION_SCHOOLS_FAILURE";

export const SCHOOL_ADMINS_REQUEST =
  "schoolconnect/adminLevels/SCHOOL_ADMINS_REQUEST";
export const SCHOOL_ADMINS_SUCCESS =
  "schoolconnect/adminLevels/SCHOOL_ADMINS_SUCCESS";
export const SCHOOL_ADMINS_FAILURE =
  "schoolconnect/adminLevels/SCHOOL_ADMINS_FAILURE";

export const COUNTRY_ADMIN_LEVELS_UPDATE_REQUEST =
  "schoolconnect/adminLevels/COUNTRY_ADMIN_LEVELS_UPDATE_REQUEST";
export const COUNTRY_ADMIN_LEVELS_UPDATE_SUCCESS =
  "schoolconnect/adminLevels/COUNTRY_ADMIN_LEVELS_UPDATE_SUCCESS";
export const COUNTRY_ADMIN_LEVELS_UPDATE_FAILURE =
  "schoolconnect/adminLevels/COUNTRY_ADMIN_LEVELS_UPDATE_FAILURE";

const initialState = {
  error: null,
  errorResponse: null,
  fetching: false,
  countryAdminLevels: [],
  schoolAdmins: {},
  registrationSchools: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COUNTRY_ADMIN_LEVELS_REQUEST:
      return { ...state, fetching: true, error: null };
    case COUNTRY_ADMIN_LEVELS_SUCCESS: {
      const { countryAdminLevels } = action;
      return {
        ...state,
        countryAdminLevels: sortByField(
          countryAdminLevels,
          "level",
          "ascending"
        ),
        fetching: false,
        error: null,
      };
    }
    case COUNTRY_ADMIN_LEVELS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case REGISTRATION_SCHOOLS_REQUEST:
      return { ...state, fetching: true, error: null };
    case REGISTRATION_SCHOOLS_SUCCESS:
      const { registrationSchools } = action;
      return {
        ...state,
        registrationSchools: registrationSchools,
        fetching: false,
        error: null,
      };
    case REGISTRATION_SCHOOLS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case SCHOOL_ADMINS_REQUEST:
      return { ...state, fetching: true, error: null };
    case SCHOOL_ADMINS_SUCCESS:
      const { data, level } = action;
      return {
        ...state,
        schoolAdmins: { ...state.schoolAdmins, [level]: data },
        fetching: false,
        error: null,
      };
    case SCHOOL_ADMINS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case COUNTRY_ADMIN_LEVELS_UPDATE_REQUEST:
      return { ...state, fetching: true, error: null };
    case COUNTRY_ADMIN_LEVELS_UPDATE_SUCCESS:
      return { ...state, fetching: false, error: null };
    case COUNTRY_ADMIN_LEVELS_UPDATE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}

/**
 * For sorting by a given field
 *
 * @param {Object} a
 * @param {Object} b
 */
function sortByField(list, field, order) {
  if (order === "ascending") {
    return list.sort((a, b) =>
      a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0
    );
  } else if (order === "descending") {
    return list.sort((a, b) =>
      a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0
    );
  }

  return list;
}

// Action creators
export const requestCountryAdminLevels = (countryId) => ({
  type: COUNTRY_ADMIN_LEVELS_REQUEST,
  countryId,
});

export const requestRegistrationSchools = (adminFourId) => ({
  type: REGISTRATION_SCHOOLS_REQUEST,
  adminFourId,
});

export const requestSchoolAdmins = (
  level,
  countryId,
  parentId,
  adminOneId
) => ({
  type: SCHOOL_ADMINS_REQUEST,
  level,
  countryId,
  parentId,
  adminOneId,
});

export const updateCountryAdminLevels = (data) => ({
  type: COUNTRY_ADMIN_LEVELS_UPDATE_REQUEST,
  data,
});

// Sagas
export function* requestCountryAdminLevelsSagaWatcher() {
  yield takeEvery(
    COUNTRY_ADMIN_LEVELS_REQUEST,
    requestCountryAdminLevelsSagaWorker
  );
}

export function* requestRegistrationSchoolsSagaWatcher() {
  yield takeEvery(
    REGISTRATION_SCHOOLS_REQUEST,
    requestRegistrationSchoolsSagaWorker
  );
}

export function* requestSchoolAdminsSagaWatcher() {
  yield takeEvery(SCHOOL_ADMINS_REQUEST, requestSchoolAdminsSagaWorker);
}

export function* requestUpdateCountryAdminLevelsSagaWatcher() {
  yield takeEvery(
    COUNTRY_ADMIN_LEVELS_UPDATE_REQUEST,
    requestUpdateCountryAdminLevelsSagaWorker
  );
}
function fetchCountryAdminLevels(action) {
  const { countryId } = action;
  const url = `${process.env.REACT_APP_API_URL}/country-adm-levels/`;
  const params = {
    country: countryId,
    limit: 99999,
  };
  return axios({
    method: "GET",
    url: url,
    params,
  });
}

function* requestCountryAdminLevelsSagaWorker(action) {
  try {
    const response = yield call(fetchCountryAdminLevels, action);
    const data = response.data.results;

    yield put({
      type: COUNTRY_ADMIN_LEVELS_SUCCESS,
      countryAdminLevels: data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: COUNTRY_ADMIN_LEVELS_FAILURE, error });
  }
}

function fetchRegistrationSchools(action) {
  const { adminFourId } = action;
  const url = `${process.env.REACT_APP_API_URL}/schools/registration_list/`;
  const params = {
    adm_4: adminFourId,
    limit: 99999,
  };
  return axios({
    method: "GET",
    url: url,
    params,
  });
}

function* requestRegistrationSchoolsSagaWorker(action) {
  try {
    const response = yield call(fetchRegistrationSchools, action);
    const data = response.data.results;

    yield put({
      type: REGISTRATION_SCHOOLS_SUCCESS,
      registrationSchools: data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: REGISTRATION_SCHOOLS_FAILURE, error });
  }
}

function fetchSchoolAdmins(action) {
  const { level, countryId, parentId, adminOneId } = action;
  const url =
    `${process.env.REACT_APP_API_URL}/school-adm/` +
    `?limit=99999` +
    (level ? `&adm_country_level=${level}` : "") +
    (countryId ? `&country=${countryId}` : "") +
    (adminOneId ? `&adm_1=${adminOneId}` : "") +
    (parentId ? `&parent=${parentId}` : "");

  return axios({
    method: "GET",
    url: url,
  });
}

function* requestSchoolAdminsSagaWorker(action) {
  try {
    const response = yield call(fetchSchoolAdmins, action);
    const data = response.data.results;
    const { level, countryId } = action;

    yield put({
      type: SCHOOL_ADMINS_SUCCESS,
      level,
      data,
      countryId,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: SCHOOL_ADMINS_FAILURE, error });
  }
}

function postCountryAdminLevels(action) {
  const { data } = action;
  const url = `${process.env.REACT_APP_API_URL}/country-adm-levels/manage-admin-levels/`;

  return axios(url, {
    method: "POST",
    data: data,
  });
}

function* requestUpdateCountryAdminLevelsSagaWorker(action) {
  try {
    yield call(postCountryAdminLevels, action);
    yield put({ type: COUNTRY_ADMIN_LEVELS_UPDATE_SUCCESS });
    yield put(successToast());
    yield put(requestCountryAdminLevels(action.data?.country));
  } catch (error) {
    const errorMessage = error?.response?.data?.errors?.message;
    const label =
      errorMessage === "clashing_school_admins" ? (
        <FormattedMessage
          id="toast.clashingSchoolAdmins"
          defaultMessage="Unable to delete the selected admin levels as there are schools with data for them"
        />
      ) : undefined;
    // dispatch a failure action to the store with the error
    yield put({ type: COUNTRY_ADMIN_LEVELS_UPDATE_FAILURE, error });
    // Check for message
    yield put(errorToast(label));
  }
}

export const getCountryAdminLevels = (state) =>
  state.adminLevels.countryAdminLevels;

export const getRegistrationSchools = (state) =>
  state.adminLevels.registrationSchools?.map((item) => ({
    name: item.name,
    id: item.id,
  }));

export const getSchoolAdmins = (state) => state.adminLevels.schoolAdmins;

export const getAdminLevelsIsFetching = (state) => state.adminLevels.fetching;

export const getAdminLevelsError = (state) => state.adminLevels.error;
