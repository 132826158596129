import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { ModuleBody } from "@wfp/ui";

import TableExtended from "components/TableExtended";

import styles from "./school-report.module.scss";
import { getFilteredReportSignatures } from "data-handler/ducks/reportSignatures";
import { GetRoleLabel } from "helpers/roles";

const Signatures = ({
  report: {
    month,
    year,
    submitted_at,
    submitter_signature,
    submitter_name,
    start_day,
    end_day,
  },
}) => {
  const localSignaturesStorekeeper = useSelector(
    getFilteredReportSignatures({
      reportMonth: month,
      reportYear: year,
      reportStartDay: start_day,
      reportEndDay: end_day,
      userRole: "store_keeper",
    })
  );

  const localSignaturesPrincipals = useSelector(
    getFilteredReportSignatures({
      reportMonth: month,
      reportYear: year,
      reportStartDay: start_day,
      reportEndDay: end_day,
      userRole: "principal",
    })
  );

  const localSignaturesSchoolGroupAdmins = useSelector(
    getFilteredReportSignatures({
      reportMonth: month,
      reportYear: year,
      reportStartDay: start_day,
      reportEndDay: end_day,
      userRole: "school_group_administrator",
    })
  );

  return (
    <ModuleBody className={styles.reportModule}>
      <h5 className={styles.sectionTitle}>
        <FormattedMessage
          id="signatures.signaturesHeader"
          defaultMessage="7. Signatures"
        />
      </h5>
      <TableExtended>
        <tbody>
          <tr>
            <td>
              <FormattedMessage
                id="signatures.last_and_first_name_of_store_keeper"
                defaultMessage="Last Name and First Name of the {store_keeper}"
                values={{
                  store_keeper: GetRoleLabel({ user_role: "store_keeper" }),
                }}
              />
            </td>
            <td>
              <FormattedMessage
                id="signatures.signature"
                defaultMessage="Signature"
              />
            </td>
            <td>
              <FormattedMessage id="signatures.date" defaultMessage="Date" />
            </td>
          </tr>
          {localSignaturesStorekeeper.length === 0 && (
            <tr>
              <td />
              <td />
              <td />
            </tr>
          )}
          {localSignaturesStorekeeper.map((signature, i) => (
            <LocalSignatureRow signature={signature} key={i} />
          ))}
          <tr>
            <td>
              <FormattedMessage
                id="signatures.last_and_first_name_of_principal_title"
                defaultMessage="Last Name and First Name of {principal}"
                values={{
                  principal: GetRoleLabel({ user_role: "principal" }),
                }}
              />
            </td>
            <td>
              <FormattedMessage
                id="signatures.signature"
                defaultMessage="Signature"
              />
            </td>
            <td>
              <FormattedMessage id="signatures.date" defaultMessage="Date" />
            </td>
          </tr>

          {submitter_name && submitter_signature && submitted_at ? (
            <tr>
              <td>
                <span className={styles.boldText}>
                  {submitter_name ? submitter_name : ""}
                </span>
              </td>
              <td>
                {submitter_signature && (
                  <img
                    src={submitter_signature}
                    alt="Submitter signature"
                    className={styles.signatureImg}
                  />
                )}
              </td>
              <td>
                {submitted_at && moment(submitted_at).format("DD-MM-YYYY")}
              </td>
            </tr>
          ) : (
            [
              ...localSignaturesPrincipals,
              ...localSignaturesSchoolGroupAdmins,
            ].map((signature, i) => (
              <LocalSignatureRow signature={signature} key={i} />
            ))
          )}
        </tbody>
      </TableExtended>
    </ModuleBody>
  );
};

export default Signatures;

const LocalSignatureRow = ({
  signature: { userName, datetime, signature },
}) => {
  return (
    <tr>
      <td>
        <span className={styles.boldText}>{userName}</span>
      </td>
      <td>
        <img
          src={signature}
          alt="Storekeeper signature"
          className={styles.signatureImg}
        />
      </td>
      <td>{moment(datetime).format("DD-MM-YYYY")}</td>
    </tr>
  );
};
