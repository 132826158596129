import React, {
  ReactElement,
  BaseSyntheticEvent,
  useCallback,
  useMemo,
} from "react";
import classNames from "classnames";
import { Input } from "@wfp/ui";

import { Control } from "react-hook-form";
import { limitFractions } from "helpers/numbers";

type MeasureUnit = {
  id: number;
  name: string;
  symbol: string;
  decimals: number;
};

type QuantityInputProps = {
  /**
   * React-Hook-Form control
   */
  control: Control;

  /**
   * setValue function from useFormCall
   */
  setValue: (name: string, value: unknown, config?: Object) => void;

  /**
   * name given by react hook form controller.
   */
  name: string;

  /**
   * Specify an optional className to be applied to the wrapper node
   */
  className?: string;

  /**
   * Specify an optional className to be applied to the form-item node
   */
  formItemClassName?: string;

  /**
   * Specify if the control should be disabled, or not
   */
  disabled?: boolean;

  /**
   * Specify whether you want the underlying label to be visually hidden
   */
  // hideLabel: boolean;

  /**
   * Specify a custom `id` for the input
   */
  id?: string;

  /**
   * Generic `label` that will be used as the textual representation of what
   * this field is for
   */
  labelText: ReactElement;

  /**
   * The new value is available in 'imaginaryTarget.value'
   * i.e. to get the value: evt.imaginaryTarget.value
   */
  onChange: (event: BaseSyntheticEvent) => void;

  /**
   * Specify the value of the input, if undefined or null the value is empty
   */
  value: string | number;

  /**
   * Specify whether the control is currently invalid.
   * Either a boolean in combination with `invalidText` or an `object`( eg. { message: "Message" }) can be passed.
   */
  invalid: { message: string } | boolean;

  /**
   * Provide the text that is displayed when the control is in an invalid state
   */
  invalidText: string | ReactElement;

  /**
   * Provide additional component that is used alongside the input for customization
   */
  additional?: ReactElement;

  /**
   * Provide text that is used alongside the control label for additional help
   */
  helperText?: ReactElement;

  /**
   * `true` to use the light version.
   */
  light?: boolean;

  /**
   * `true` to allow empty string.
   */
  allowEmpty?: boolean;

  /**
   * Regex string for the input
   */
  // pattern: string;

  /**
   * measureUnit for current quantity
   */
  measureUnit: MeasureUnit;

  onBlur: (event: BaseSyntheticEvent) => void;
};

export default (props: QuantityInputProps) => {
  const {
    control,
    name,
    additional,
    className,
    disabled = false,
    formItemClassName,
    id,
    onChange = () => {},
    labelText,
    helperText,
    allowEmpty,
    invalid,
    invalidText,
    value,
    setValue,
    measureUnit,
    ...other
  } = props;

  const measureUnitDecimals =
    measureUnit?.decimals === undefined ? 3 : measureUnit?.decimals;

  const measureUnitLabel = measureUnit?.name ? `(${measureUnit.name})` : null;

  // If decimals is 0 multiplier will be 1, if it is 2 multiplier will be 100;
  let decimalMultiplier = useMemo(() => {
    let value = 1;
    for (let i = 0; i < measureUnitDecimals; i++) {
      value *= 10;
    }
    return value;
  }, [measureUnitDecimals]);

  const normalizeInput = useCallback(
    (input: string): string => {
      const n: number = Number(input);
      const flooredN: number =
        Math.floor(n * decimalMultiplier) / decimalMultiplier;
      if (n !== flooredN) {
        return flooredN.toString();
      } else {
        return limitFractions(input, measureUnitDecimals);
      }
    },
    [decimalMultiplier, measureUnitDecimals]
  );

  const handleChange = useCallback(
    (evt: BaseSyntheticEvent) => {
      if (!disabled) {
        const value = evt.target.value;
        const normalizedValue = normalizeInput(value);
        if (value !== normalizedValue) {
          setValue(name, normalizedValue);
          control.reRender();
          evt.target.value = normalizedValue;
        }
        onChange(evt);
      }
    },
    [control, disabled, name, normalizeInput, onChange, setValue]
  );

  const inputClassNames = classNames(className, {
    [`wfp--input--invalid`]: invalid,
  });

  const newProps = {
    disabled,
    id,
    onChange: handleChange,
    value: value,
  };
  // TODO instead of adding all additional values into props, we need to pick the attributes from props
  // and add exact attributes to Input and input elements seperately.
  return (
    <Input
      {...props}
      labelText={
        <>
          {labelText} {measureUnitLabel}
        </>
      }
    >
      {() => {
        return (
          <div className="wfp--number">
            <input
              type="number"
              className={inputClassNames}
              step="any"
              {...other}
              {...newProps}
            />
          </div>
        );
      }}
    </Input>
  );
};
