import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Button, TextInput, InlineLoading } from "@wfp/ui";

import {
  requestLogin,
  getCurrentUser,
  getLoginState,
} from "data-handler/ducks/auth";

import styles from "./login.module.scss";

import LoginWrapper from "components/Login/LoginWrapper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ErrorList from "components/ErrorList";
import loginBackground from "images/login-background.jpg";
import { Link } from "@wfp/ui";
import { getIsActive } from "../../data-handler/ducks/auth";

/**
 * As it currently stands, this component is only used in the E2E testing environment.
 */
const OnlineLogin = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => getCurrentUser(state));
  const params = useParams();
  const { fetching, errorResponse } = useSelector((state) =>
    getLoginState(state)
  );

  const history = useHistory();
  const isActive = useSelector(getIsActive);

  const { clearErrors, handleSubmit, setError, errors, register } = useForm({
    defaultValues: {
      email: params.username,
    },
  });

  useEffect(() => {
    clearErrors();
    if (errorResponse)
      Object.keys(errorResponse.data.errors).forEach((key) => {
        if (key !== "status_code")
          setError(key, {
            type: "manual",
            message: errorResponse.data.errors[key][0],
          });
      });
  }, [errorResponse, setError, clearErrors]);

  useEffect(() => {
    if (currentUser) {
      history.push("");
    }
  }, [currentUser, history]);

  const onSubmit = async (values) => {
    dispatch(requestLogin(values));
  };

  return (
    <LoginWrapper
      showBackLink={false}
      rightSide={
        <img
          alt="School connect users looking at tablet devices"
          className={styles.loginContentImage}
          src={loginBackground}
        />
      }
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          clearErrors();
        }}
      >
        <ErrorList errors={errors} />

        <TextInput
          autocorrect="off"
          autoCapitalize="off"
          labelText={
            <FormattedMessage
              id="user.emailFieldLabel"
              defaultMessage="Email"
            />
          }
          inputRef={register({
            required: "Please enter an email",
          })}
          name="email"
          id="email"
          invalid={errors.email}
          invalidText={errors.email && errors.email.message}
        />

        <TextInput
          autocorrect="off"
          autoCapitalize="off"
          labelText={
            <FormattedMessage
              id="user.passwordFieldLabel"
              defaultMessage="Password"
            />
          }
          inputRef={register}
          invalid={errors.password}
          invalidText={errors.password && errors.password.message}
          type="password"
          name="password"
          id="password"
        />
        <div className={styles.submitWrapper}>
          <div className={styles.button}>
            <Button type="submit">
              <FormattedMessage
                id="user.signInButtonLabel"
                defaultMessage="Sign in"
              />
            </Button>
            {fetching && <InlineLoading />}
          </div>
          <Link
            className={styles.password}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_CIAM_RECOVER_PASSWORD_URL}.do?callback=${process.env.REACT_APP_CIAM_REDIRECT_URL}`,
                "_self"
              )
            }
          >
            <FormattedMessage
              id="user.requestNewPasswordButtonLabel"
              defaultMessage="Request new password"
            />
          </Link>
          <br />
        </div>
        <div className={styles.corporateButton}>
          <div className={styles.button}>
            <Button
              type="button"
              onClick={() => history.push("/corporateLogin")}
            >
              <FormattedMessage
                id="user.signInCorporateButtonLabel"
                defaultMessage="Sign in online"
              />
            </Button>
          </div>
        </div>
        {isActive === false && (
          <>
            <br />
            <FormattedMessage
              id="user.signInInactiveUserMessage"
              defaultMessage="Dear User, your registration is pending approval.
                    You will be able to log in once your request has been approved.
                    For more information, please contact your WFP focal point."
            />
          </>
        )}
      </form>
    </LoginWrapper>
  );
};

export default OnlineLogin;
