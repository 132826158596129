import React, { PureComponent } from "react";
import moment from "moment";

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ComposedChart,
  Line,
  Tooltip,
  Legend,
} from "recharts";

import { connect } from "react-redux";
//import ResponsiveContainer from './ResponsiveContainer';

import "./_historyChart.scss";
import Area from "recharts/lib/cartesian/Area";

import { colors } from "@wfp/ui";

import { ChartTooltipContent } from "components/ChartTooltip";

import withSizes from "react-sizes";
import { mapSizesToProps } from "helpers/mapSizesToProps";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

const legendEntryTranslatedLabels = {
  maleValue: (
    <FormattedMessage
      id="AttendanceHistoryChart.boysAttendance"
      defaultMessage="Boys attendance"
    />
  ),
  femaleValue: (
    <FormattedMessage
      id="AttendanceHistoryChart.girlsAttendance"
      defaultMessage="Girls attendance"
    />
  ),
  enrolment: (
    <FormattedMessage
      id="AttendanceHistoryChart.enrolment"
      defaultMessage="Total enrolment"
    />
  ),
  value: (
    <FormattedMessage
      id="AttendanceHistoryChart.value"
      defaultMessage="Total attendance"
    />
  ),
};

const AttendanceHistoryChartLegendEntry = (value, entry) => {
  const { color } = entry;

  return (
    <span style={{ color }}>{legendEntryTranslatedLabels[value] || value}</span>
  );
};

class HistoryChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
    };
  }

  updateSize = (e) => {
    this.setState({
      responsiveWidth: e.width,
      responsiveHeight: e.height,
    });
  };

  monthTickFormatter = (tick) => {
    const date = new Date(tick);

    return date.getMonth() + 1;
  };

  render() {
    const { data } = this.props;

    if (!data) return null;

    const gradientOffset = () => {
      const dataMax = Math.max(
        ...data.map((i) => Math.max(i.value || 0, i.enrolment || 0))
      );
      const dataMin = Math.min(
        ...data.map((i) => Math.max(i.value || 0, i.enrolment || 0))
      );

      if (dataMax <= 0) {
        return 0;
      } else if (dataMin >= 0) {
        return 1;
      } else {
        return dataMax / (dataMax - dataMin);
      }
    };

    const off = gradientOffset();

    const { isMobile } = this.props;
    return (
      <ResponsiveContainer
        width="100%"
        aspect={isMobile ? 4.0 / 1.4 : 4.0 / 1.2}
        onChange={this.updateSize}
      >
        <ComposedChart
          responsiveWidth={900}
          responsiveHeight={600}
          data={data}
          padding={0}
          margin={{
            top: isMobile ? 20 : 30,
            right: isMobile ? 10 : 30,
            bottom: isMobile ? 0 : 10,
            left: isMobile ? 10 : 0,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset={off}
                stopColor={colors["interactive-01"].hex}
                stopOpacity={1}
              />
              <stop
                offset={off}
                stopColor={colors["support-01"].hex}
                stopOpacity={1}
              />
            </linearGradient>
            <linearGradient id="gradientUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={colors["interactive-01"].hex}
                stopOpacity={0.3}
              />
              <stop
                offset="95%"
                stopColor={colors["interactive-01"].hex}
                stopOpacity={0.1}
              />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="formatDate"
            domain={["dataMin", "dataMax"]}
            type="number"
            tickCount={8}
            tick={{ fontSize: 12 }}
            tickFormatter={(timeStr) => moment.unix(timeStr).format("DD.MM.")}
          />
          <YAxis
            dataKey="enrolment"
            tick={{ fontSize: 12 }}
            tickFormatter={(value) => value}
            orientation="left"
          />
          <Tooltip
            animationDuration={20}
            position={(e) => {
              return { x: 400, y: 0 };
            }}
            content={
              <ChartTooltipContent
                labels={{
                  enrolment: (
                    <FormattedMessage
                      id="AttendanceHistoryChart.Enrolment"
                      defaultMessage="Enrolment"
                    />
                  ),
                  maleValue: (
                    <FormattedMessage
                      id="AttendanceHistoryChart.boys"
                      defaultMessage="Boys"
                    />
                  ),
                  femaleValue: (
                    <FormattedMessage
                      id="AttendanceHistoryChart.girls"
                      defaultMessage="Girls"
                    />
                  ),
                  value: (
                    <FormattedMessage
                      id="AttendanceHistoryChart.attendance"
                      defaultMessage="Attendance"
                    />
                  ),
                }}
              />
            }
          />

          <Area
            animationDuration={150}
            connectNulls
            dataKey="enrolment"
            dot={false}
            fill={colors["interactive-02"].hex}
            fillOpacity={0.1}
            stroke={colors["interactive-02"].hex}
            strokeWidth={2}
            title={
              <FormattedMessage
                id="AttendanceHistoryChart.enrolmentAreaTitle"
                defaultMessage="Enrolment"
              />
            }
            type="stepBefore"
          />

          <Area
            animationDuration={150}
            connectNulls
            dataKey="value"
            dot={false}
            stroke={colors["interactive-01"].hex}
            strokeWidth={2}
            fill="url(#gradientUv)"
            title={
              <FormattedMessage
                id="AttendanceHistoryChart.attendanceAreaTitle"
                defaultMessage="Total attendance"
              />
            }
            type="monotone"
          />

          <Line
            animationDuration={150}
            connectNulls
            dataKey="femaleValue"
            dot={false}
            stroke={colors["female"].hex}
            strokeWidth={1}
            title={
              <FormattedMessage
                id="AttendanceHistoryChart.girlsLineTitle"
                defaultMessage="Girls"
              />
            }
            type="monotone"
          />

          <Line
            animationDuration={150}
            connectNulls
            dataKey="maleValue"
            dot={false}
            stroke={colors["male"].hex}
            strokeWidth={1}
            title={
              <FormattedMessage
                id="AttendanceHistoryChart.boysLineTitle"
                defaultMessage="Boys"
              />
            }
            type="monotone"
          />

          <Legend height={36} formatter={AttendanceHistoryChartLegendEntry} />
          <ReferenceLine y={0} stroke="#000" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stores: state.stores,
  };
};

export default compose(
  withSizes(mapSizesToProps),
  connect(mapStateToProps)
)(HistoryChart);
