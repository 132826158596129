import React, { useEffect, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  Module,
  ModuleBody,
  ModuleHeader,
  ModuleFooter,
  Wrapper,
  InlineLoading,
} from "@wfp/ui";

import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  requestCurrentSchoolsImport,
  requestSchoolsImportRecords,
  getSchoolsImports,
  getSchoolsImportRecords,
} from "data-handler/ducks/schoolsImports";

import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import { statusOptions, messageLevelOptions } from "./constants";

import "./_content.scss";

const SchoolsImportDetailedPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // eslint-disable-next-line no-use-before-define
  const { schoolsImportId } = useParams(schoolsImportId);

  const currentSchoolsImport = useSelector(getSchoolsImports).results[
    schoolsImportId
  ];

  const schoolsImportRecords = useSelector(getSchoolsImportRecords);

  const { count = 0, ids = [] } = schoolsImportRecords;

  const data = ids.map((id) => schoolsImportRecords.results[id]);

  useEffect(() => {
    if (!currentSchoolsImport) {
      dispatch(requestCurrentSchoolsImport(schoolsImportId, history));
    } else {
      dispatch(
        requestSchoolsImportRecords(pageSize, pageNum, "", schoolsImportId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolsImportId, currentSchoolsImport, dispatch]);

  const fields = [
    {
      id: "csvFilename",
      label: (
        <FormattedMessage
          id="countryAdmin.csvFilename"
          defaultMessage="CSV filename"
        />
      ),
      value: currentSchoolsImport?.csv_filename,
    },
    {
      id: "createdBy",
      label: (
        <FormattedMessage
          id="countryAdmin.createdBy"
          defaultMessage="Created by"
        />
      ),
      value: currentSchoolsImport?.created_by?.email,
    },
    {
      id: "status",
      label: (
        <FormattedMessage id="countryAdmin.status" defaultMessage="Status" />
      ),
      value: statusOptions.find(
        (item) => item.value === currentSchoolsImport?.status
      )?.label,
    },
    {
      id: "numCreated",
      label: (
        <FormattedMessage id="countryAdmin.created" defaultMessage="Created" />
      ),
      value: currentSchoolsImport?.num_created,
    },
    {
      id: "numUpdated",
      label: (
        <FormattedMessage id="countryAdmin.updated" defaultMessage="Updated" />
      ),
      value: currentSchoolsImport?.num_updated,
    },
    {
      id: "numFailed",
      label: (
        <FormattedMessage id="countryAdmin.failed" defaultMessage="Failed" />
      ),
      value: currentSchoolsImport?.num_failed,
    },
    {
      id: "total",
      label: (
        <FormattedMessage id="countryAdmin.total" defaultMessage="Total" />
      ),
      value: currentSchoolsImport?.total,
    },
  ];

  const statusCell = ({ row }) => {
    const status = row["original"]["status"];
    return statusOptions.find((item) => item.value === status).label;
  };

  const messageLevelCell = ({ row }) => {
    const messageLevel = row["original"]["message_level"];
    return messageLevelOptions.find((item) => item.value === messageLevel)
      .label;
  };

  const columns = useMemo(() => {
    return [
      {
        Header: intl.formatMessage({
          id: "countryAdmin.recordNumber",
          defaultMessage: "Record number",
        }),
        accessor: "record_num",
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.status",
          defaultMessage: "Status",
        }),
        accessor: "status",
        Cell: statusCell,
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.message",
          defaultMessage: "Message",
        }),
        accessor: "message",
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.messageLevel",
          defaultMessage: "Message level",
        }),
        accessor: "message_level",
        Cell: messageLevelCell,
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.schoolName",
          defaultMessage: "School's name",
        }),
        accessor: "school.name",
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.schoolCountry",
          defaultMessage: "School's country",
        }),
        accessor: "school.country",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPage = (size, idx, ordering) => {
    setPageSize(size);
    setPageNum(idx);
    dispatch(requestSchoolsImportRecords(size, idx, ordering, schoolsImportId));
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
  };

  const downloadFile = () => {
    if (
      currentSchoolsImport?.id &&
      currentSchoolsImport?.csv_file &&
      currentSchoolsImport?.csv_filename
    ) {
      const element = document.createElement("a");

      element.href = currentSchoolsImport.csv_file;
      element.download = currentSchoolsImport.csv_filename;

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  if (!currentSchoolsImport) {
    return (
      <div className="inlineLoading">
        <InlineLoading />
      </div>
    );
  }

  return (
    <div className="schools-import-page">
      <Wrapper
        pageWidth="sm"
        spacing="md"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleHeader className="schools-import-page-row">
            <Link
              exact="true"
              to={"/countryAdmin/schools/schoolsImports"}
              style={{ paddingRight: "10px" }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </Link>
            {currentSchoolsImport?.csv_filename}
          </ModuleHeader>
          <ModuleBody>
            <div className="schools-import-page">
              {fields.map((field) => (
                <div key={field.id} className="schools-import-page-row">
                  <label className="wfp--label">{field.label}</label>
                  <span className="field-value">{field.value}</span>
                </div>
              ))}
              {!data.find(
                (item) => item.school_import !== parseInt(schoolsImportId)
              ) &&
                currentSchoolsImport?.total > 0 &&
                data.length > 0 && <Table {...tableProps} showSort />}
            </div>
          </ModuleBody>
          <ModuleFooter>
            <Button kind="secondary" onClick={downloadFile}>
              <FormattedMessage
                id="countryAdmin.downloadCsvFile"
                defaultMessage="Download CSV file"
              />
            </Button>
          </ModuleFooter>
        </Module>
      </Wrapper>
    </div>
  );
};

export default SchoolsImportDetailedPage;
