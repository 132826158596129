import React, { useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Module, ModuleBody, ModuleHeader, Wrapper, Button } from "@wfp/ui";

import "./_content.scss";
import {
  boolIconCell,
  valueLinkCell,
} from "components/CountryAdmin/CountryAdminContent/Table/Utils";
import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";
import {
  getCountryAdminPendingUsersResponse,
  requestCountryAdminPendingUsers,
  setCountryAdminUserStatus,
} from "data-handler/ducks/users";
import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import { CountryAdminLabel } from "SCConstants";
import { RoleFilterOptions } from "../index";
import { GetRoleLabel } from "helpers/roles";

const NewUsers = () => {
  const dispatch = useDispatch();
  const response = useSelector(getCountryAdminPendingUsersResponse);

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filtersState, setFilters] = useState([]);

  const { count = 0, ids = [] } = response;

  const data = ids.map((id) => response.results[id]);

  function dispatchStatusAction(selectedStatusAction, selectedUserID) {
    // Dispatch the Action and user id to Accept / Reject a user.
    dispatch(setCountryAdminUserStatus(selectedStatusAction, selectedUserID));
  }

  const setApproveButton = useCallback(
    ({ row }) => (
      <Button
        className={"is-approve-button"}
        onClick={() => dispatchStatusAction("approve", row["original"]["id"])}
      >
        {CountryAdminLabel.find((e) => e.name === "countryAdmin.approve").label}
      </Button>
    ),
    // Disabled due to issue with calling disaptch function on ever render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setRejectButton = useCallback(
    ({ row }) => (
      <Button
        className={"is-approve-button"}
        onClick={() => dispatchStatusAction("reject", row["original"]["id"])}
      >
        {CountryAdminLabel.find((e) => e.name === "countryAdmin.reject").label}
      </Button>
    ),
    // Disabled due to issue with calling disaptch function on ever render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filterProps = {
    fields: [
      CountryAdminLabel.find((e) => e.name === "email"),
      CountryAdminLabel.find((e) => e.name === "other_names"),
      CountryAdminLabel.find((e) => e.name === "last_name"),
      {
        name: "school_name",
        label: CountryAdminLabel.find((e) => e.name === "countryAdmin.school")
          .label,
      },
      {
        name: "user_role",
        label: CountryAdminLabel.find((e) => e.name === "countryAdmin.role")
          .label,
        type: "select",
        options: RoleFilterOptions(),
      },
    ],
    onSubmit: (e) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
  };

  const setSchool = (school) => {
    return school
      ? school.name
      : CountryAdminLabel.find((e) => e.name === "countryAdmin.noSchool").label;
  };

  const columns = useMemo(() => {
    return [
      {
        Header: CountryAdminLabel.find((e) => e.name === "email").label,
        accessor: "email",
        Cell: ({ cell: { row, value } }) =>
          valueLinkCell(
            value,
            `/countryAdmin/users/newUsers/${row["original"]["id"]}/`
          ),
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "other_names").label,
        accessor: "other_names",
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "last_name").label,
        accessor: "last_name",
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin.school")
          .label,
        accessor: "school.name",
        Cell: ({ cell: { row } }) => setSchool(row["original"]["school"]),
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin.role")
          .label,
        accessor: "user_role",
        Cell: ({ cell: { row } }) => GetRoleLabel(row["original"]),
        disableSortBy: true,
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin.admin")
          .label,
        accessor: "is_admin",
        Cell: boolIconCell,
        disableSortBy: true,
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryadmin.WFP")
          .label,
        accessor: "is_wfp",
        Cell: boolIconCell,
        disableSortBy: true,
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin").label,
        accessor: "is_country_admin",
        Cell: boolIconCell,
        disableSortBy: true,
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin.active")
          .label,
        accessor: "is_active",
        Cell: boolIconCell,
      },
      { Header: "", accessor: "approve", Cell: setApproveButton },
      { Header: "", accessor: "reject", Cell: setRejectButton },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setApproveButton, setRejectButton]);

  const loadPage = (size, idx, ordering, filters = filtersState) => {
    setPageSize(size);
    setPageNum(idx);
    dispatch(requestCountryAdminPendingUsers(size, idx, ordering, filters));
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
  };

  return (
    <Wrapper pageWidth="lg" spacing="md" background="lighter" className="table">
      <Module>
        <ModuleHeader>
          {
            CountryAdminLabel.find((e) => e.name === "countryAdmin.newUser")
              .label
          }
        </ModuleHeader>
        <ModuleBody>
          <Filtering {...filterProps} />
          <Table {...tableProps} showSort />
        </ModuleBody>
      </Module>
    </Wrapper>
  );
};

export default NewUsers;
