import React from "react";
import { Route, Switch } from "react-router-dom";
import RolesEdit from "./CountryAdminRolesEdit";
import RolesShow from "./CountryAdminRolesShow";

const CountryAdminRoles = () => {
  return (
    <div className="country-admin-main-content">
      <div className="schools-levels-table">
        <Switch>
          <Route path={`/countryAdmin/roles/edit`} component={RolesEdit} />
          <Route path={`/countryAdmin/roles/`} component={RolesShow} />
        </Switch>
      </div>
    </div>
  );
};

export default CountryAdminRoles;
