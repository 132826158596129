import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

export const COUNTRY_AUTHORITY_TYPES_REQUEST =
  "schoolconnect/authorityLevels/COUNTRY_AUTHORITY_TYPES_REQUEST";
export const COUNTRY_AUTHORITY_TYPES_SUCCESS =
  "schoolconnect/authorityLevels/COUNTRY_AUTHORITY_TYPES_SUCCESS";
export const COUNTRY_AUTHORITY_TYPES_FAILURE =
  "schoolconnect/authorityLevels/COUNTRY_AUTHORITY_TYPES_FAILURE";

export const EDUCATION_AUTHORITIES_REQUEST =
  "schoolconnect/authorityLevels/EDUCATION_AUTHORITIES_REQUEST";
export const EDUCATION_AUTHORITIES_SUCCESS =
  "schoolconnect/authorityLevels/EDUCATION_AUTHORITIES_SUCCESS";
export const EDUCATION_AUTHORITIES_FAILURE =
  "schoolconnect/authorityLevels/EDUCATION_AUTHORITIES_FAILURE";

const initialState = {
  error: null,
  errorResponse: null,
  fetching: false,
  countryAuthorityTypes: [],
  educationAuthorities: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COUNTRY_AUTHORITY_TYPES_REQUEST:
      return { ...state, fetching: true, error: null };
    case COUNTRY_AUTHORITY_TYPES_SUCCESS: {
      const { countryAuthorityTypes } = action;
      return {
        ...state,
        countryAuthorityTypes: sortByField(
          countryAuthorityTypes,
          "authority_type",
          "descending"
        ),
        fetching: false,
        error: null,
      };
    }
    case COUNTRY_AUTHORITY_TYPES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case EDUCATION_AUTHORITIES_REQUEST:
      return { ...state, fetching: true, error: null };
    case EDUCATION_AUTHORITIES_SUCCESS:
      const { educationAuthorities } = action;
      return {
        ...state,
        educationAuthorities: educationAuthorities,
        fetching: false,
        error: null,
      };
    case EDUCATION_AUTHORITIES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}

/**
 * For sorting by a given field
 *
 * @param {Object} a
 * @param {Object} b
 */
function sortByField(list, field, order) {
  if (order === "ascending") {
    return list.sort((a, b) =>
      a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0
    );
  } else if (order === "descending") {
    return list.sort((a, b) =>
      a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0
    );
  }

  return list;
}

// Action creators
export const requestCountryAuthorityTypes = (countryId) => ({
  type: COUNTRY_AUTHORITY_TYPES_REQUEST,
  countryId,
});

export const requestEducationAuthorities = (countryId) => ({
  type: EDUCATION_AUTHORITIES_REQUEST,
  countryId,
});

// Sagas
export function* requestCountryAuthorityTypesSagaWatcher() {
  yield takeEvery(
    COUNTRY_AUTHORITY_TYPES_REQUEST,
    requestCountryAuthorityTypesSagaWorker
  );
}

export function* requestEducationAuthoritiesSagaWatcher() {
  yield takeEvery(
    EDUCATION_AUTHORITIES_REQUEST,
    requestEducationAuthoritiesSagaWorker
  );
}

function fetchCountryAuthorityTypes(action) {
  const { countryId } = action;
  const url = `${process.env.REACT_APP_API_URL}/country-authority-types/`;
  const params = {
    country: countryId,
    limit: 99999,
  };
  return axios({
    method: "GET",
    url: url,
    params,
  });
}

function* requestCountryAuthorityTypesSagaWorker(action) {
  try {
    const response = yield call(fetchCountryAuthorityTypes, action);
    const data = response.data.results;

    yield put({
      type: COUNTRY_AUTHORITY_TYPES_SUCCESS,
      countryAuthorityTypes: data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: COUNTRY_AUTHORITY_TYPES_FAILURE, error });
  }
}

function fetchEducationAuthorities(action) {
  const { countryId } = action;
  const url = `${process.env.REACT_APP_API_URL}/education-authorities/`;
  const params = {
    country: countryId,
    limit: 99999,
  };
  return axios({
    method: "GET",
    url: url,
    params,
  });
}

function* requestEducationAuthoritiesSagaWorker(action) {
  try {
    const response = yield call(fetchEducationAuthorities, action);
    const data = response.data.results;

    yield put({
      type: EDUCATION_AUTHORITIES_SUCCESS,
      educationAuthorities: data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: EDUCATION_AUTHORITIES_FAILURE, error });
  }
}

export const getCountryAuthorityTypes = (state) =>
  state.authorityLevels.countryAuthorityTypes?.map((item) => ({
    name: item.name,
    id: item.id,
  }));

export const getEducationAuthoritiesByAuthorityType = (state) =>
  state.authorityLevels.educationAuthorities?.reduce((a, x) => {
    (a[x.country_authority_type] = a[x.country_authority_type] || []).push(x);
    return a;
  }, {});
