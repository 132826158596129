import React from "react";
import { Input } from "@wfp/ui";
import styles from "./styles.module.scss";
import ReactCodeInput from "react-code-input";

export default function CodeInput(props) {
  return (
    <Input {...props} value={props.value} formItemClassName={styles.codeInput}>
      {(props) => {
        return (
          <div className={styles.codeInputWrapper}>
            <ReactCodeInput
              {...props}
              ref={props.inputRef}
              value={props.value}
              fields={6}
              className={styles.codeInputElement}
            />
          </div>
        );
      }}
    </Input>
  );
}
