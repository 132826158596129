import React, { useState, useEffect, memo } from "react";
type OnlineOfflineProps = {
  onlineChildren?: React.ReactChild;
  offlineChildren?: React.ReactChild;
};
const OnlineOffline: React.ComponentType<OnlineOfflineProps> = ({
  onlineChildren,
  offlineChildren,
}) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return <div>{isOnline ? onlineChildren : offlineChildren}</div>;
};

export default memo(OnlineOffline);
