import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";

import {
  Button,
  Module,
  ModuleBody,
  ModuleHeader,
  Wrapper,
  ModuleFooter,
} from "@wfp/ui";

import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";

import { getSelectedCountry } from "data-handler/ducks/countryProfile";
import {
  requestCountryRoleMapping,
  getRoles,
  requestRoles,
} from "data-handler/ducks/roles";
import { CountryAdminLabel } from "SCConstants";
import { generateCustomRoleList } from "helpers/roles";
import { getLanguage } from "data-handler/ducks/language";
import { getCurrentUser } from "data-handler/ducks/auth";

const SchoolRolesMappingShow = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCountry = useSelector(getSelectedCountry);
  const language = useSelector(getLanguage);
  const { fetching, roles, countryRoles } = useSelector(getRoles);
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    let fetchedCountry =
      countryRoles?.length > 0 ? countryRoles[0]?.country : null;

    if (selectedCountry) {
      if (fetchedCountry !== selectedCountry.id && !fetching) {
        dispatch(requestCountryRoleMapping(selectedCountry.id));
        if (roles.length === 0) {
          dispatch(requestRoles());
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCountry]);

  const data = roles
    ? generateCustomRoleList(
        roles,
        countryRoles,
        language,
        currentUser?.is_admin
      )
    : [];

  const columns = useMemo(() => {
    return [
      {
        Header: CountryAdminLabel.find(
          (item) => item.name === "countryAdmin.roles"
        ).label,
        accessor: "role",
      },
      {
        Header: CountryAdminLabel.find(
          (item) => item.name === "countryAdmin.customName"
        ).label,
        accessor: "custom_name",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableProps = {
    columns,
    data,
    pageNum: 1,
    pageSize: 20,
    totalItems: 0,
    loadPage: () => {
      if (!fetching && selectedCountry) {
        dispatch(requestCountryRoleMapping(selectedCountry.id));
      }
    },
  };

  return (
    <Wrapper pageWidth="lg" spacing="md" background="lighter" className="table">
      <Module>
        <ModuleHeader>
          <FormattedMessage
            id="rolesShow.RolesTitle"
            defaultMessage="Roles Mapping"
          />
        </ModuleHeader>
        <ModuleBody>
          <Table {...tableProps} showPagination={false} />
        </ModuleBody>
        <ModuleFooter className="edit-button">
          <Button onClick={() => history.push("/countryAdmin/roles/edit")}>
            <FormattedMessage
              id="SchoolLevelsMappingShow.editButton"
              defaultMessage="Edit"
            />
          </Button>
        </ModuleFooter>
      </Module>
    </Wrapper>
  );
};

export default SchoolRolesMappingShow;
