export const SET_DELIVERIES = "schoolconnect/deliveries/SET_DELIVERIES";
export const CLEAR_DELIVERY_OPTIONS =
  "schoolconnect/deliveries/CLEAR_DELIVERY_OPTIONS";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  deliveryOptions: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DELIVERIES:
      return {
        ...state,
        fetching: false,
        deliveryOptions: {
          ...state.deliveryOptions,
          ...action.deliveries,
        },
        error: null,
      };
    case CLEAR_DELIVERY_OPTIONS:
      return {
        ...state,
        deliveryOptions: null,
      };
    default:
      return state;
  }
}

export const setDeliveries = (deliveries) => ({
  type: SET_DELIVERIES,
  deliveries,
});

export const getDeliveries = (state) => state.deliveries.deliveryOptions;

export const clearDeliveryOptions = (data) => ({
  type: CLEAR_DELIVERY_OPTIONS,
  data,
});
