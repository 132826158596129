import React from "react";
import { Route, Switch } from "react-router-dom";

import CountryAdminSchoolPage from "./CountryAdminSchoolPage";
import CountryAdminSchoolsTable from "./CountryAdminSchoolsTable";
import SchoolsImportDetailedPage from "./SchoolsImportDetailedPage";

import "./_content.scss";

const CountryAdminSchools = () => {
  const renderCountryAdminSchoolPageCreate = () => {
    return (
      <CountryAdminSchoolPage
        create={true}
        prevPath={"/countryAdmin/schools/newSchools"}
      />
    );
  };

  const renderCountryAdminAllSchoolsPageEdit = () => {
    return (
      <CountryAdminSchoolPage
        create={false}
        prevPath={"/countryAdmin/schools/allSchools"}
      />
    );
  };

  const renderCountryAdminNewSchoolsPageEdit = () => {
    return (
      <CountryAdminSchoolPage
        create={false}
        prevPath={"/countryAdmin/schools/newSchools"}
      />
    );
  };

  const renderSchoolImportDetailedPage = () => {
    return <SchoolsImportDetailedPage />;
  };

  return (
    <div className="admin-schools country-admin-main-content">
      <Switch>
        <Route
          path="/countryAdmin/schools/create"
          component={renderCountryAdminSchoolPageCreate}
        />
        <Route
          path="/countryAdmin/schools/allSchools/:schoolId"
          component={renderCountryAdminAllSchoolsPageEdit}
        />

        <Route
          path="/countryAdmin/schools/newSchools/:schoolId"
          component={renderCountryAdminNewSchoolsPageEdit}
        />

        <Route
          path="/countryAdmin/schools/schoolsImports/:schoolsImportId"
          component={renderSchoolImportDetailedPage}
        />
        <Route component={CountryAdminSchoolsTable} />
      </Switch>
    </div>
  );
};

export default CountryAdminSchools;
