/**
 * Selectors useful for aggregating report data
 */
import { createSelector } from "reselect";
import moment from "moment";
import {
  getAllPurchases,
  getAllStores,
  getSchoolYearByDate,
} from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";
import { getReportByYearMonthDay } from "data-handler/ducks/reports";
import { deliveryCategory, purchaseDetailCategory } from "SCConstants";
import { deliveriesInStockByCommodityByCategory } from "helpers/stock";

// TODO: shouldn't this return stores?
export const getReportedStores = createSelector([getAllStores], (stores) => {
  const reports = stores.filter((store) => store.model === "report");
  const output = {};

  reports.forEach((report) => {
    const date0 = moment(report.date).startOf("month");
    const date1 = moment(report.date).endOf("month");

    const relatedReports = stores.filter((store) =>
      moment(
        store.occurred_on || store.occurred_at || store.delivered_at
      ).isBetween(date0, date1, "days", "[]")
    );

    relatedReports.forEach((element) => {
      output[element.client_id] = report.client_id;
    });
  });

  return output;
});

export const getPurchasedStocksByDate = (
  state,
  date,
  commodityCategory,
  initialStock = false
) => {
  const schoolYear = getSchoolYearByDate(date)(state);

  // This variable is used to only include initial stock deliveries if
  // the given end date has the same month as the school year start date
  const firstMonthOfTheSchoolYear =
    moment(date).isSame(schoolYear?.starts_on, "month") && initialStock;

  const attendances = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? false
      : store.model === "attendance" &&
        store.consumption &&
        store.consumption.meal_provided === true &&
        moment(store.occurred_on).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  );

  const purchasesIncidents = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? false
      : store.model === "incident" &&
        store.type !== "delete" &&
        moment(store.occurred_at).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  );

  const purchases = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? // TODO: uncomment this once we allow carry over for purchased stock (replace false)
        // store.model === "purchasedetail" &&
        // store.type !== "delete" &&
        // store.is_initial_stock === true &&
        // moment(store.delivered_at).isSame(date, "day")
        false
      : store.model === "purchasedetail" &&
        store.type !== "delete" &&
        moment(store.purchased_at).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  );

  const accumulator = {};
  // Subtract consumption
  attendances.forEach((attendance) => {
    attendance.consumption.commodities.forEach(
      ({ commodity, quantity, category }) => {
        if (category === commodityCategory) {
          accumulator[commodity] = parseFloat(
            ((accumulator[commodity] || 0) - parseFloat(quantity)).toFixed(10)
          );
        }
      }
    );
  });

  purchases.forEach((stock) => {
    stock.commodities.forEach(({ commodity, quantity, category }) => {
      if (category === commodityCategory) {
        accumulator[commodity] = parseFloat(
          ((accumulator[commodity] || 0) + parseFloat(quantity)).toFixed(10)
        );
      }
    });
  });
  // Subtract incidents
  purchasesIncidents.forEach((incident) => {
    incident.commodities.forEach(({ commodity, quantity, category }) => {
      if (category === commodityCategory) {
        accumulator[commodity] = parseFloat(
          ((accumulator[commodity] || 0) - parseFloat(quantity)).toFixed(10)
        );
      }
    });
  });

  return accumulator;
};

/**
 * Returns a snapshot of the current school's stock on the given `date`
 *
 * in the shape of: { <commodity_id>: <commodity_quantity>, ... }
 */
export const getStocksByDate = (
  state,
  date,
  commodityCategory,
  initialStock = false
) => {
  const schoolYear = getSchoolYearByDate(date)(state);

  // This variable is used to only include initial stock deliveries if
  // the given end date has the same month as the school year start date
  const firstMonthOfTheSchoolYear =
    moment(date).isSame(schoolYear?.starts_on, "month") && initialStock;

  const deliveries = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? store.model === "delivery" &&
        store.type !== "delete" &&
        store.is_initial_stock === true &&
        moment(store.delivered_at).isSame(date, "day")
      : store.model === "delivery" &&
        store.type !== "delete" &&
        moment(store.delivered_at).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  );

  const attendances = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? false
      : store.model === "attendance" &&
        store.consumption &&
        store.consumption.meal_provided === true &&
        moment(store.occurred_on).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  );

  const incidents = getAllStores(state).filter((store) =>
    firstMonthOfTheSchoolYear
      ? false
      : store.model === "incident" &&
        store.type !== "delete" &&
        moment(store.occurred_at).isBetween(
          schoolYear?.starts_on,
          date,
          "days",
          initialStock ? "[)" : "[]"
        )
  );

  const accumulator = {};
  // Add deliveries
  deliveries.forEach((stock) => {
    stock.commodities.forEach(({ commodity, quantity, category }) => {
      if (category === commodityCategory) {
        accumulator[commodity] = parseFloat(
          ((accumulator[commodity] || 0) + parseFloat(quantity)).toFixed(10)
        );
      }
    });
  });
  // Subtract consumption
  attendances.forEach((attendance) => {
    attendance.consumption.commodities.forEach(
      ({ commodity, quantity, category }) => {
        if (category === commodityCategory) {
          accumulator[commodity] = parseFloat(
            ((accumulator[commodity] || 0) - parseFloat(quantity)).toFixed(10)
          );
        }
      }
    );
  });

  // Subtract incidents
  incidents.forEach((incident) => {
    incident.commodities.forEach(({ commodity, quantity, category }) => {
      if (category === commodityCategory) {
        accumulator[commodity] = parseFloat(
          ((accumulator[commodity] || 0) - parseFloat(quantity)).toFixed(10)
        );
      }
    });
  });

  return accumulator;
};

/**
 * Returns a snapshot of the stock deliveries within given dates
 */
export const getDeliveriesWithinDates = (
  state,
  date0,
  date1,
  includeDeleted = false,
  category = deliveryCategory
) => {
  const deliveriesWithinDates = getAllStores(state).filter(
    (store) =>
      store.model === "delivery" &&
      (!includeDeleted ? store.type !== "delete" : true) &&
      store.is_negative_stock_rebalance === false &&
      store.is_initial_stock === false &&
      moment(store.delivered_at).isBetween(date0, date1, "days", "[]")
  );

  const accumulator = deliveriesWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(
      ({ commodity, quantity, category: commCategory }) => {
        if (commCategory === category || category === "all") {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {});

  return accumulator;
};

/**
 * Returns a snapshot of the stock purchases within given dates
 */
export const getPurchasedCommoditiesWithinDates = (
  state,
  date0,
  date1,
  detailType
) => {
  const purchasesWithinDates = getAllStores(state).filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.category === purchaseDetailCategory &&
      store.type !== "delete" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  );
  var detail;

  const accumulator = purchasesWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(
      ({ commodity, quantity, total_paid, comments }) => {
        switch (detailType) {
          case "total_paid":
            detail = Number(total_paid);
            break;
          case "quantity":
            detail = Number(quantity);
            break;
          case "date":
            detail = String(moment(curr.date).format("DD-MM-YYYY") + ", ");
            break;
          case "comments":
            detail = comments;
            break;
          default:
            detail = "";
        }

        if (!detail) {
          return acc;
        }
        if (acc[commodity] === undefined) {
          acc[commodity] = detail;
        } else {
          acc[commodity] = acc[commodity] + detail;
        }
      }
    );
    return acc;
  }, {});

  return accumulator;
};

/**
 * Returns a snapshot of the stock purchases within given dates
 */
export const getOtherCostWithinDates = (state, date0, date1, detailType) => {
  const purchasesWithinDates = getAllStores(state).filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.type !== "delete" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  );

  var detail;

  const accumulator = purchasesWithinDates?.reduce((acc, curr) => {
    if (curr.other_costs) {
      curr.other_costs.forEach(({ other_cost, total_paid, comments }) => {
        switch (detailType) {
          case "total_paid":
            detail = Number(total_paid);
            break;
          case "date":
            detail = String(moment(curr.date).format("DD-MM-YYYY") + ", ");
            break;
          case "comments":
            detail = comments;
            break;
          default:
            detail = "";
        }
        if (!detail) {
          return acc;
        }
        if (acc[other_cost] === undefined) {
          acc[other_cost] = detail;
        } else {
          acc[other_cost] = acc[other_cost] + detail;
        }
      });
    }
    return acc;
  }, {});
  return accumulator;
};

export const getPurchaseDetails = (state, date0, date1, detailType) => {
  const purchasesWithinDates = getAllPurchases(state).filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.type !== "delete" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  );
  var detail;

  const commodityId = purchasesWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(({ commodity }) => {
      acc = commodity;
    });
    return acc;
  }, {});

  const accumulator = purchasesWithinDates.reduce((acc, curr) => {
    detailType === "date" ? (detail = String(curr.date)) : (detail = "N/A");
    acc[commodityId] = detail;
    return acc;
  }, {});
  return accumulator;
};

/**
 * Returns a snapshot of the stock consumption within given dates
 */
export const getConsumptionWithinDates = (
  stores,
  state,
  date0,
  date1,
  category
) => {
  const attendances = stores(state).filter(
    (store) =>
      store.model === "attendance" &&
      store.consumption &&
      store.consumption.meal_provided === true &&
      moment(store.occurred_on).isBetween(date0, date1, "days", "[]")
  );

  const accumulator = attendances.reduce((acc, curr) => {
    curr.consumption.commodities.forEach(
      ({ commodity, quantity, category: commodityCategory }) => {
        if (commodityCategory === category) {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {});

  return accumulator;
};

/**
 * Returns a snapshot of the stock consumption within given dates
 */
export const getIncidentsWithinDates = (
  stores,
  state,
  date0,
  date1,
  category,
  includeDeleted = false
) => {
  const incidents = stores(state).filter(
    (store) =>
      store.model === "incident" &&
      (!includeDeleted ? store.type !== "delete" : true) &&
      !store.is_initial_stock_incident &&
      moment(store.occurred_at).isBetween(date0, date1, "days", "[]")
  );

  const accumulator = incidents.reduce((acc, curr) => {
    curr.commodities.forEach(
      ({ commodity, quantity, category: commodityCategory }) => {
        if (commodityCategory === category) {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {});

  return accumulator;
};

/**
 * An incident is considered a loss in any case unless it's a return
 */
const isIncidentLoss = (incident) => !isIncidentReturn(incident);

/**
 * An incident is considered a return if "Food will be returned to WFP"
 * is one of its causes.
 */
const isIncidentReturn = (incident) => {
  // Reason 1 = Loss
  // Reason 2 = return
  // Defined in incidentEdit/index.js:79 "incidentTypes"
  return parseInt(incident.reason) === 2;
};

/**
 * Returns a snapshot of the stock losses within given dates
 */
export const getLossesWithinDates = (state, date0, date1, category) => {
  const lossesWithinDates = getAllStores(state).filter(
    (store) =>
      store.model === "incident" &&
      store.type !== "delete" &&
      isIncidentLoss(store) &&
      moment(store.occurred_at).isBetween(date0, date1, "days", "[]")
  );

  const accumulator = lossesWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(
      ({ commodity, quantity, category: commodityCategory }) => {
        if (commodityCategory === category) {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {});

  return accumulator;
};

/**
 * Returns a snapshot of the stock returns to WFP within given dates
 */
export const getReturnsWithinDates = (state, date0, date1, category) => {
  const returnsWithinDates = getAllStores(state).filter(
    (store) =>
      store.model === "incident" &&
      store.type !== "delete" &&
      isIncidentReturn(store) &&
      moment(store.occurred_at).isBetween(date0, date1, "days", "[]")
  );

  const accumulator = returnsWithinDates.reduce((acc, curr) => {
    curr.commodities.forEach(
      ({ commodity, quantity, category: commodityCategory }) => {
        if (commodityCategory === category) {
          acc[commodity] = (acc[commodity] || 0) + Number(quantity);
        }
      }
    );
    return acc;
  }, {});

  return accumulator;
};

/**
 * Returns a snapshot of the stock deliveries batch numbers within given dates
 */
export const getBatchNumbersWithinDates = (
  state,
  date0,
  date1,
  previousReportDates,
  commodities,
  category
) => {
  const allStores = getAllStores(state);

  // The end date of the previous report
  const previousReportDate1 = previousReportDates
    ? moment(
        `${previousReportDates.year}/${previousReportDates.month}/${previousReportDates.endDay}`
      )
    : undefined;

  const deliveries = getAllStores(state).filter(
    (store) =>
      store.model === "delivery" &&
      store.type !== "delete" &&
      moment(store.delivered_at).isBetween(date0, date1, "days", "[]")
  );

  const batch_nos = {};

  const allCommodities = commoditiesDuck.getList(state);

  allCommodities.forEach((commodity) => {
    batch_nos[commodity.id] = [];
  });

  deliveries.forEach((delivery) => {
    delivery.commodities.forEach(
      ({ commodity, batch_no, category: commCategory }) => {
        if (
          category === commCategory &&
          !batch_nos[commodity].includes(batch_no)
        ) {
          batch_nos[commodity].push(batch_no);
        }
      }
    );
  });

  // Add batch numbers from previous month for commodities that
  // were not fully consumed in that previous month
  commodities.forEach((commodity) => {
    deliveriesInStockByCommodityByCategory(
      allStores,
      commodity.id,
      category,
      undefined,
      previousReportDate1,
      previousReportDate1
    ).forEach((item) => {
      if (
        parseFloat(item.quantityAvailable) > 0 &&
        !batch_nos[commodity.id].includes(item.batch_no)
      ) {
        batch_nos[commodity.id].push(item.batch_no);
      }
    });
  });

  return batch_nos;
};

/**
 * Returns a snapshot of the stock deliveries batch numbers within given dates
 */
export const getOtherCostsWithinDates = (state, date0, date1) => {
  const deliveries = getAllStores(state).filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.type !== "delete" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  );

  const dates = {};

  const otherCosts = commoditiesDuck.getList(state);
  otherCosts.forEach((commodity) => {
    dates[commodity.id] = [];
  });

  deliveries.forEach((delivery) => {
    delivery.commodities.forEach(({ commodity, batch_no }) => {
      dates[commodity].push(batch_no);
    });
  });

  return dates;
};

/**
 * Returns true if the report specific to the given date is open, or if
 * there is no report but there is a school year ongoing for the given date
 *
 * Otherwise, false
 */
export const isReportOpenByDate = (date = undefined) => (state) => {
  if (!date) {
    date = moment().format("YYYY-MM-DD");
  }

  const splitDate = date?.split("-");
  const year = splitDate ? parseInt(splitDate[0]) : undefined;
  const month = splitDate ? parseInt(splitDate[1]) : undefined;
  const day = splitDate ? parseInt(splitDate[2]) : undefined;

  const report = getReportByYearMonthDay(state, year, month, day);

  const currentSchoolYear = getSchoolYearByDate(date)(state);

  return (!report && currentSchoolYear) || report?.state === "open"
    ? true
    : false;
};
