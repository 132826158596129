import React from "react";

import Wizard from "components/Wizard";

export default class TaheHomeRationsWizard extends Wizard {
  next = (values) => {
    const currentPage = Math.min(
      this.state.currentPage + 1,
      this.props.children.length
    );
    const formState = this.props.formState;
    const stateValue = {
      ...this.state,
      ...formState,
      [currentPage]: values,
      currentPage: currentPage,
    };
    this.setState(stateValue);
    this.props.setFormState(stateValue);
  };

  previous = () => {
    this.setState((state) => ({
      currentPage: Math.max(state.currentPage - 1, 0),
    }));
    // Manually reset the fields for the first page when coming back to it
    // otherwise the fields will not have the correct value
    if (this.state.currentPage === 1) {
      const reset = this.props.methods.reset;
      const formState = this.props.formState;
      if (formState?.hasOwnProperty(1)) {
        reset(formState[1]);
      }
    }
  };

  onSubmit = (values) => {
    const { children, onSubmit } = this.props;
    const state = this.state;
    const isLastPage = state.currentPage + 1 === React.Children.count(children);
    if (isLastPage) {
      const currentPage = Math.min(
        this.state.currentPage + 1,
        this.props.children.length
      );

      const stateValue = {
        ...this.state,
        [currentPage]: values,
      };
      this.setState(stateValue);
      return onSubmit(stateValue);
    }
  };
}
