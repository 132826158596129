import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { iconWfpLogoVerticalEn } from "@wfp/icons";
import { Icon } from "@wfp/ui";

import { getCurrentUser } from "data-handler/ducks/auth";

import RoleSelection from "./RoleSelection";
import SchoolStaffRegistrationForm from "./RoleRegistration/SchoolStaffRegistrationForm";
import ReportApproverRegistrationForm from "./RoleRegistration/ReportApproverRegistrationForm";
import ViewerRegistrationForm from "./RoleRegistration/ViewerRegistrationForm";

import styles from "./registration.module.scss";

import loginBackground from "images/login-background.jpg";
import { useLocation } from "react-router";
import CountryAdminRegistrationForm from "./RoleRegistration/CountryAdminRegistrationForm";
import { countriesDuck } from "../../data-handler/ducks/countries";
import { UserRegistrationEndpoints } from "SCConstants";
import TermsAndConditionsPage from "components/Registration/TermsAndConditionsPage";
import { getIsInternal } from "helpers/users";

const Registration = () => {
  const currentUser = useSelector((state) => getCurrentUser(state));
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const countries = useSelector(countriesDuck.getList);
  const countriesIsFetching = useSelector(countriesDuck.isFetching());
  const intl = useIntl();

  const [userData, setUserData] = useState();
  const [userRole, setUserRole] = useState();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [selectedEndpont, setSelectedEndpoint] = useState();
  const [authorityData, setAuthorityData] = useState([]);
  const [selectCountryOption, setselectCountryOption] = useState(
    "allCountries"
  );
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");

  useEffect(() => {
    if (countries.length === 0 && !countriesIsFetching) {
      dispatch(countriesDuck.fetchList());
    }
  }, [countries, countriesIsFetching, dispatch]);
  let registrationFormComponent;

  useEffect(() => {
    if (currentUser) {
      history.push("");
    }

    if (location.state && location.state.data) {
      setUserData(location.state.data);
    }
  }, [currentUser, history, location]);

  const handleRoleSubmit = (role) => {
    setUserRole(role);
  };
  const handleOnSubmit = (data) => {
    switch (userRole) {
      case "reportApprover":
        {
          data.country = countries.find(
            (country) => country.iso2 === data.country
          );

          // Extract the authority levels from data
          const {
            authorityLevelOne,
            authorityLevelTwo,
            authorityLevelThree,
            ...remainingData
          } = data;
          // Create the authorities list that will go into the request
          const levelsList = [
            authorityLevelOne,
            authorityLevelTwo,
            authorityLevelThree,
          ]
            .filter((item) => item !== "" && item !== undefined)
            .map((item) => parseInt(item));
          remainingData.authorities =
            levelsList.length > 0 ? levelsList : undefined;

          const user = { ...userData, ...remainingData };
          setAuthorityData([
            authorityLevelOne,
            authorityLevelTwo,
            authorityLevelThree,
          ]);
          setUserData(user);
          setShowTermsAndConditions(true);
          setSelectedEndpoint(UserRegistrationEndpoints.ReportApprover);
        }
        break;

      case "countryAdmin":
        {
          data.country = countries.find(
            (country) => country.iso2 === data.country
          );
          const user = { ...userData, ...data };
          setUserData(user);
          setShowTermsAndConditions(true);
          setSelectedEndpoint(UserRegistrationEndpoints.CountryAdmin);
        }
        break;
      // use same logic for schoolGroupAdmin and viewer but different endpoints
      case "schoolGroupAdmin":
      case "viewer":
        {
          // Internal users need to pass as a List of ids
          // External users need to just pass the id
          const isInternal = getIsInternal(userData.email);

          let postData = { ...userData, ...data };
          if (isInternal) {
            delete postData.country;
            if (selectCountryOption !== "allCountries") {
              postData.countries = data.country.map(
                (dataCountry) => dataCountry.value
              );
            } else {
              postData.is_all_countries = true;
            }
          } else {
            postData.country = data.country.value;
          }
          setUserData(postData);
          setShowTermsAndConditions(true);
          userRole === "viewer"
            ? setSelectedEndpoint(UserRegistrationEndpoints.Viewer)
            : setSelectedEndpoint(UserRegistrationEndpoints.SchoolGroupAdmin);
        }
        break;
      case "schoolStaff":
        {
          const { adminHighest, adminLowest, ...submissionData } = data;
          if (!submissionData.school) {
            setSubmitErrorMessage(
              intl.formatMessage({
                id: "Registration.selectCountryWithSchool",
                defaultMessage:
                  "You cannot register without a school. Please select a country which has all the necessary fields.",
              })
            );
          } else {
            setUserData({ ...userData, ...data });
            setShowTermsAndConditions(true);
            setSelectedEndpoint(UserRegistrationEndpoints.SchoolStaff);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleBackToRoleSelection = () => {
    setUserRole();
  };

  switch (userRole) {
    case "schoolStaff":
      registrationFormComponent = (
        <SchoolStaffRegistrationForm
          userData={userData}
          onBackToRoleSelection={handleBackToRoleSelection}
          onSubmit={handleOnSubmit}
          countries={countries}
          submitErrorMessage={submitErrorMessage}
          setSubmitErrorMessage={setSubmitErrorMessage}
        />
      );
      break;
    case "countryAdmin":
      registrationFormComponent = (
        <CountryAdminRegistrationForm
          userData={userData}
          onBackToRoleSelection={handleBackToRoleSelection}
          onSubmit={handleOnSubmit}
          countries={countries}
        />
      );
      break;
    case "reportApprover":
      registrationFormComponent = (
        <ReportApproverRegistrationForm
          userData={userData}
          onBackToRoleSelection={handleBackToRoleSelection}
          onSubmit={handleOnSubmit}
          countries={countries}
          authorityData={authorityData}
        />
      );
      break;
    // use same form for schoolGroupAdmin and viewer
    case "schoolGroupAdmin":
    case "viewer":
      registrationFormComponent = (
        <ViewerRegistrationForm
          userData={userData}
          userRole={userRole}
          onBackToRoleSelection={handleBackToRoleSelection}
          onSubmit={handleOnSubmit}
          countries={countries}
          setselectCountryOption={setselectCountryOption}
          selectCountryOption={selectCountryOption}
        />
      );
      break;
    default:
      registrationFormComponent = (
        <SchoolStaffRegistrationForm
          userData={userData}
          onBackToRoleSelection={handleBackToRoleSelection}
          countries={countries}
          onSubmit={handleOnSubmit}
        />
      );
  }
  if (showTermsAndConditions) {
    return (
      <TermsAndConditionsPage
        data={userData}
        endpoint={selectedEndpont}
        onBackToDataEntry={() => setShowTermsAndConditions(false)}
      />
    );
  } else {
    return (
      <div className={styles.register}>
        <div className={styles.registerForm}>
          <div className={styles.loginLogo}>
            <Icon icon={iconWfpLogoVerticalEn} />
          </div>
          {!userData ? (
            <p>
              <FormattedMessage
                id="User.RegisterUnavailable"
                defaultMessage="This page is not available"
              />
            </p>
          ) : !userRole ? (
            <RoleSelection
              userData={userData}
              onRoleSubmit={handleRoleSubmit}
            />
          ) : (
            <>
              {registrationFormComponent}
              <div className={styles.submitMessageWrapper}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="wfp--label">
                  <FormattedMessage
                    id="Registration.submitRegistrationMessage"
                    defaultMessage="Once submitted, your registration will need to be approved in order to give you access. Approval may take approximately 2-6 hours."
                  />
                </label>
              </div>
            </>
          )}
        </div>
        <div className={styles.registerContent}>
          <img src={loginBackground} alt="School connect users with tablet" />
        </div>
      </div>
    );
  }
};

export default Registration;
