import React, { useEffect, useState } from "react";
import { Select, SelectItem, TextInput, Button, Checkbox } from "@wfp/ui";
import { iconFilterAlt, iconRestartGlyph } from "@wfp/icons";

import "./Filtering.scss";
import { FormattedMessage } from "react-intl";

const Filtering = (props) => {
  const { showClear = true } = props;
  const { showSecondaryButton = false } = props;
  const [state, setState] = useState(props.defaults || {});
  useEffect(() => {
    setState(props.defaults ? props.defaults : {});
  }, [props.defaults]);

  const onChange = (e) => {
    e.persist();
    const value = e.target.value;
    const id = e.target.id;
    if (!value) setState(({ [id]: _, ...state }) => state);
    else setState({ ...state, [id]: value });
  };

  const submit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.onSubmit(state);
  };

  const secondarySubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.secondaryButton.secondaryButtonState.selectedAllState === false) {
      props.secondaryButton.onSubmit(state);
      props.secondaryButton.secondaryButtonState.setSelectedAll(true);
    } else {
      clear();
      props.secondaryButton.secondaryButtonState.setSelectedAll(false);
      props.secondaryButton.selectedSchoolsIds.current = {};
    }
  };
  const clear = () => {
    setState({});
    props.onSubmit({});
  };
  return (
    <div className="filtering">
      {props?.fields?.map(({ name, label, type = "text", options }) => {
        const filterProps = {
          onChange,
          id: name,
          key: name,
          labelText: label,
          value: state[name] || "",
        };
        switch (type) {
          case "select":
            return (
              <Select {...filterProps}>
                {options?.map(({ name, label }) => (
                  <SelectItem key={name} value={name} text={label} />
                ))}
              </Select>
            );
          case "checkbox":
            return (
              <Checkbox
                id={name}
                labelText={label}
                checked={state[name] || false}
                onChange={(e) => {
                  setState({ ...state, [name]: e.target.checked });
                }}
              />
            );
          default:
            return <TextInput {...filterProps} />;
        }
      })}
      <div className="filter-buttons">
        <Button icon={iconFilterAlt} onClick={submit} kind="secondary">
          {
            <FormattedMessage
              id="CountryAdmin.filter"
              defaultMessage="Filter"
            />
          }
        </Button>
        {showClear && (
          <Button icon={iconRestartGlyph} onClick={clear} kind="tertiary">
            {
              <FormattedMessage
                id="CountryAdmin.clear"
                defaultMessage="Clear"
              />
            }
          </Button>
        )}
        {showSecondaryButton && (
          <Button
            onClick={(e) => {
              secondarySubmit(e);
            }}
            kind="secondary"
          >
            {props.secondaryButton.secondaryButtonState.selectedAllState ===
            true
              ? props.secondaryButton?.secondaryName
              : props.secondaryButton?.name}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Filtering;
