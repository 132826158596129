import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

/** Prefix for all endpointDuck actions */
const PREFIX = "SC";

/** Default fetcher in case you don't want to provide one */
const DEFAULT_FETCHER = "DEFAULT";

/**
 * Create a standard duck for consuming REST API endpoints.
 *
 * Rationale:
 * Ducks for REST endpoints all look the same, so a factory can be used.
 * This speeds up development, reduces mistakes, and only needs to be tested once.
 *
 * The `fetcher` argument:
 * `fetchDetail` and `fetchList` have an optional `fetcher` argument.
 * This is useful when multiple components can fetch from the same endpoint,
 * and you want to show a "loading spinner" only on the one which is currently fetching.
 *
 * TODO: better documentation
 * TODO: more HTTP verbs
 * TODO: pagination of list GET
 * TODO: convert to typescript, express selector return types as generics
 */
export default function endpointDuckFactory({
  apiBaseUrl = process.env.REACT_APP_API_URL,
  endpoint,
}) {
  const ENDPOINT = endpoint.toUpperCase();

  // Action types
  const DETAIL_REQUEST = `${PREFIX}_${ENDPOINT}_DETAIL_REQUEST`;
  const DETAIL_SUCCESS = `${PREFIX}_${ENDPOINT}_DETAIL_SUCCESS`;
  const DETAIL_FAILURE = `${PREFIX}_${ENDPOINT}_DETAIL_FAILURE`;

  const LIST_REQUEST = `${PREFIX}_${ENDPOINT}_LIST_REQUEST`;
  const LIST_SUCCESS = `${PREFIX}_${ENDPOINT}_LIST_SUCCESS`;
  const LIST_FAILURE = `${PREFIX}_${ENDPOINT}_LIST_FAILURE`;

  const _reducerInitialState = {
    /** components which are currently fetching from this endpoint */
    currentFetchers: [],
    /** fetched resources, indexed by `id` */
    index: {},
    /** error response of the latest request */
    error: null,
  };

  const _reducer = (state = _reducerInitialState, action) => {
    switch (action.type) {
      //
      // DETAIL
      //
      case DETAIL_REQUEST:
        return {
          ...state,
          currentFetchers: [...(state.currentFetchers || []), action.fetcher],
        };
      case DETAIL_SUCCESS:
        return {
          ...state,
          index: { ...state.index, ...{ [action.result.id]: action.result } },
          currentFetchers: (state.currentFetchers || []).filter(
            (fetcher) => fetcher !== action.fetcher
          ),
        };
      case DETAIL_FAILURE:
        return {
          ...state,
          currentFetchers: (state.currentFetchers || []).filter(
            (fetcher) => fetcher !== action.fetcher
          ),
          error: action.error,
        };

      //
      // LIST
      //
      case LIST_REQUEST:
        return {
          ...state,
          currentFetchers: [...(state.currentFetchers || []), action.fetcher],
        };
      case LIST_SUCCESS:
        // Previous index plus new results
        const index = action.results.reduce((acc, elem) => {
          acc[elem.id] = elem;
          return acc;
        }, {});
        return {
          ...state,
          index,
          currentFetchers: (state.currentFetchers || []).filter(
            (fetcher) => fetcher !== action.fetcher
          ),
        };
      case LIST_FAILURE:
        return {
          ...state,
          currentFetchers: (state.currentFetchers || []).filter(
            (fetcher) => fetcher !== action.fetcher
          ),
          error: action.error,
        };
      default:
        return state;
    }
  };
  /** Avoid persisting currentFetchers */
  const persistedReducer = persistReducer(
    {
      key: endpoint,
      storage,
      blacklist: ["currentFetchers", "error"],
    },
    _reducer
  );

  //
  // LIST
  //

  /** Fetch list action creator */
  const fetchList = (fetcher = DEFAULT_FETCHER, params) => ({
    type: LIST_REQUEST,
    fetcher,
    params,
  });

  function* _listWatcher() {
    yield takeLatest(LIST_REQUEST, listWorker);
  }

  function* listWorker(action) {
    try {
      const response = yield call(requestList, action.params);
      yield put({
        type: LIST_SUCCESS,
        results: response.data.results,
        fetcher: action.fetcher,
      });
    } catch (error) {
      yield put({ type: LIST_FAILURE, fetcher: action.fetcher, error });
    }
  }

  function requestList(
    params = {
      compact: true,
      limit: 99999,
    }
  ) {
    return axios({
      method: "GET",
      url: `${apiBaseUrl}/${endpoint}/`,
      params,
    });
  }

  //
  // DETAIL
  //

  /** Fetch detail action creator */
  const fetchDetail = (id, fetcher = DEFAULT_FETCHER) => ({
    type: DETAIL_REQUEST,
    id,
    fetcher,
  });

  function* _detailWatcher() {
    yield takeLatest(DETAIL_REQUEST, detailWorker);
  }

  function* detailWorker(action) {
    try {
      const response = yield call(requestDetail, action.id);
      yield put({
        type: DETAIL_SUCCESS,
        result: response.data,
        fetcher: action.fetcher,
      });
    } catch (error) {
      yield put({ type: DETAIL_FAILURE, fetcher: action.fetcher, error });
    }
  }

  function requestDetail(id) {
    return axios({
      method: "GET",
      url: `${apiBaseUrl}/${endpoint}/${id}`,
    });
  }

  //
  // SELECTORS
  //

  const getById = (state, id) => state[endpoint].index[id];
  const getError = (state) => state[endpoint].error;
  const getIndex = (state) => state[endpoint].index;
  const getList = (state) => Object.values(state[endpoint].index);
  const isFetching = (fetcher = DEFAULT_FETCHER) => (state) =>
    state[endpoint].currentFetchers &&
    state[endpoint].currentFetchers.includes(fetcher);

  return {
    _detailWatcher,
    _listWatcher,
    _reducer: persistedReducer,
    _reducerInitialState,
    fetchDetail,
    fetchList,
    getById,
    getError,
    getIndex,
    getList,
    isFetching,
  };
}
