import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
//TODO: remove qs
import qs from "qs";
import { Button, TextInput, InlineLoading } from "@wfp/ui";

import infoIcon from "images/notebook.svg";

import Empty from "components/Empty";
import styles from "./login.module.scss";
import PageTitle from "../PageTitle";
import LoginWrapper from "components/Login/LoginWrapper";
import ErrorList from "components/ErrorList";

const RequestPassword = () => {
  const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    control,
    clearErrors,
    handleSubmit,
    setError,
    errors,
    register,
  } = useForm();

  const onSubmit = async (values) => {
    setLoading(true);
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/new-password/`,
        qs.stringify(values)
      )
      .then((response) => {
        // TODO: improve response
        if (response) {
          setError("user", {
            message: "Password reset e-mail has been sent.",
          });
          setSuccess(true);
        } else {
          setLoading(false);
          setError("user", {
            message: "Unknown email. Please verify",
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            //setError("Enter a valid email");

            setError("user", {
              message: error.response.data.user[0],
            });

            //setError(error.response.data.errors);
          } else if (error.response.status === 500) {
            setError("user", { message: "A server error occurred" });
            //setError(error.response.data.errors);f
          } else {
            setError("user", {
              message: "You are offline. Please try again when you are online.",
            });
          }
          setLoading(false);
        }
        return null;
      });
  };

  if (success)
    return (
      <Empty
        button={
          <>
            <NavLink to={`/login`}>
              <Button kind="secondary">Login again</Button>
            </NavLink>
          </>
        }
        title="New password has been sent!"
        kind="large"
        icon={
          <img
            alt="info illustratation notebook with pen"
            src={infoIcon}
            style={{ marginLeft: "0.5rem", marginBottom: "1rem" }}
          />
        }
      >
        Please check your emails or SMS
      </Empty>
    );

  return (
    <LoginWrapper
      rightSide={
        <div className={styles.loginContent}>
          <h2>Resetting your password will</h2>
          <p>
            Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
            ut labore et dolore magna aliquyam erat, sed diam voluptua.
          </p>
        </div>
      }
      showBackLink
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          clearErrors();
        }}
      >
        {success && (
          <Empty
            button={
              <>
                <NavLink to={`/login`}>
                  <Button kind="secondary">Login again</Button>
                </NavLink>
              </>
            }
            title="New password has been sent!"
            kind="large"
            icon={
              <img
                alt="info illustratation notebook with pen"
                src={infoIcon}
                style={{ marginLeft: "0.5rem", marginBottom: "1rem" }}
              />
            }
          >
            Please check your emails or SMS
          </Empty>
        )}

        {!success && (
          <>
            <PageTitle>Request new password</PageTitle>
            <ErrorList errors={errors} />

            <TextInput
              control={control}
              autocorrect="off"
              autoCapitalize="off"
              labelText="Email"
              id="user"
              name="user"
              defaultValue=""
              invalid={errors.user}
              invalidText={errors.user && errors.user.message}
              inputRef={register}
            />
            <div className={styles.submitWrapper}>
              <Button type="submit">Request password</Button>
              {loading && <InlineLoading />}
            </div>
          </>
        )}
      </form>
    </LoginWrapper>
  );
};

export default RequestPassword;
