import React, { useRef, useState } from "react";
import { Button } from "@wfp/ui";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignatureCanvas from "react-signature-canvas";
import { faSignature } from "@fortawesome/pro-solid-svg-icons";
import { useMeasure } from "react-use";
import { FormattedMessage } from "react-intl";

const CLEAR_SIGNATURE_LABEL = (
  <FormattedMessage
    id="SignatureInput.clearButtonLabel"
    defaultMessage="Clear signature"
  />
);

const REPLACE_SIGNATURE_LABEL = (
  <FormattedMessage
    id="SignatureInput.replaceButtonLabel"
    defaultMessage="Replace signature"
  />
);

export default function SignatureInput(props) {
  const { labelText, onChange, value } = props;
  const [newSignature, setNewSignature] = useState(value ? false : true);
  const [hasSignature, setHasSignature] = useState();
  const [containerRef, { width, height }] = useMeasure();
  let sigCanvas = useRef();

  return (
    <div className="wfp--form-item">
      <label className="wfp--label">{labelText}</label>
      <div className={styles.signatureInput} ref={containerRef}>
        {newSignature ? (
          <>
            {hasSignature && (
              <Button
                small
                onClick={() => {
                  onChange(sigCanvas.clear());
                  setHasSignature(false);
                }}
                kind="secondary"
                icon={<FontAwesomeIcon icon={faSignature} />}
              >
                {CLEAR_SIGNATURE_LABEL}
              </Button>
            )}
            <SignatureCanvas
              ref={(ref) => {
                sigCanvas = ref;
              }}
              onEnd={() => {
                onChange(sigCanvas.toDataURL());
                setHasSignature(true);
              }}
              canvasProps={{
                width: width,
                height: height,
                className: styles.sigCanvas,
              }}
            />
          </>
        ) : (
          <>
            <Button
              small
              onClick={() => setNewSignature(true)}
              icon={<FontAwesomeIcon icon={faSignature} />}
              kind="secondary"
            >
              {REPLACE_SIGNATURE_LABEL}
            </Button>
            <img src={value} alt="Signature" />
          </>
        )}
      </div>
    </div>
  );
}
