/**
 * Currently it's not worth the effort to check the types of migration functions.
 *
 * If at some point it's worth it, see this for reference:
 * https://gist.github.com/lafiosca/b7bbb569ae3fe5c1ce110bf71d7ee153
 */

import migration_1_clean_attendance from "./migration_1_clean_attendance";

const migrations = {
  1: migration_1_clean_attendance,
};

export default migrations;
