import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Select from "react-select";
import { Modal } from "@wfp/ui";

import { getAllSchoolProfilesNonPaginated } from "data-handler/ducks/schoolProfile";

const SetSchoolProfileModal = ({
  schoolProfileOpen,
  submitNewSchoolProfiles,
  setSchoolProfileModalOpen,
  setNewSchoolProfileId,
}) => {
  const allSchoolProfilesNonPaginated = useSelector(
    getAllSchoolProfilesNonPaginated
  );
  const intl = useIntl();

  return (
    <Modal
      open={schoolProfileOpen}
      modalHeading={intl.formatMessage({
        id: "schools.selectSchoolProfile",
        defaultMessage: "Select School Profile",
      })}
      primaryButtonText={intl.formatMessage({
        id: "Report.actionSubmit",
        defaultMessage: "Submit",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      onRequestSubmit={submitNewSchoolProfiles}
      onSecondarySubmit={() => setSchoolProfileModalOpen(false)}
      onRequestClose={() => setSchoolProfileModalOpen(false)}
      shouldSubmitOnEnter={true}
    >
      <Select
        id={"select-new-profile"}
        options={allSchoolProfilesNonPaginated?.map((profile) => ({
          label: profile.name,
          value: profile.id,
        }))}
        onChange={(profile) => setNewSchoolProfileId(profile.value)}
      />
    </Modal>
  );
};

export default SetSchoolProfileModal;
