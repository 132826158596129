import React from "react";

import styles from "./section.module.scss";

const Section = ({ children }) => {
  return <div className={styles.section}>{children}</div>;
};

Section.propTypes = {};

export default Section;
