import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { useState } from "react";
import axios from "axios";
import { errorToast } from "data-handler/ducks/toast";
import { downloadBlob } from "data-handler/ducks/utils";
import { toastFormattedMessages } from "SCConstants";

export default function () {
  const dispatch = useDispatch();

  const [status, setStatus] = useState("pending");
  const templateId = useRef();
  const intervalId = useRef();

  const initiateFileGeneration = (url, schoolsIds) => {
    let details = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (schoolsIds !== undefined) {
      details["data"] = schoolsIds;
    }
    setStatus("generating");
    axios(url, details)
      .then((response) => {
        const tempId = response?.data?.template_id;
        if (tempId) {
          templateId.current = tempId;
        } else {
          // throw an error if there is no template id (which is caught below)
          throw new Error();
        }
      })
      .catch((error) => {
        setStatus("pending");
        dispatch(
          errorToast(
            toastFormattedMessages.find(
              (e) => e.name === "toast.updateTemplateGenerationFailed"
            ).label
          )
        );
      });
  };

  const downloadFile = useCallback(() => {
    axios(
      `${process.env.REACT_APP_API_URL}/school-imports/download-update-template/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: templateId.current,
        responseType: "blob",
      }
    )
      .then((response) => {
        if (response?.data?.type === "text/csv") {
          const blob = response.data;
          downloadBlob(blob, "Schools_Update_Template.csv");
          setStatus("pending");
        }
      })
      .catch((error) => {
        setStatus("pending");
        dispatch(
          errorToast(
            toastFormattedMessages.find(
              (e) => e.name === "toast.downloadFailed"
            ).label
          )
        );
      });
  }, [dispatch]);

  useEffect(() => {
    if (status === "generating" && !intervalId.current) {
      const interval = setInterval(() => {
        downloadFile();
      }, 3000);
      // Set the new interval ID
      intervalId.current = interval;

      // Clear the interval on unmount
      return () => {
        clearInterval(intervalId.current);
        intervalId.current = undefined;
        templateId.current = undefined;
      };
    }
  }, [status, downloadFile]);

  return { status, initiateFileGeneration };
}
