import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ModuleBody } from "@wfp/ui";

import TableExtended from "components/TableExtended";
import MeasureUnitValue from "components/MeasureUnitValue";
import { displayStaffLevelsReport } from "components/SchoolReport/Absences";

import { getCurrentSchoolProfileWfpCommodities } from "data-handler/ducks/schools";
import { getLevelAgeMappings } from "data-handler/ducks/ageGroups";

import {
  studentKinds,
  studentLevelTotals,
  allLevels,
  staffLevelTotals,
} from "SCConstants";

import { checkEmptyOrUndefined, orderRowsByLevels } from "helpers/utils";

import styles from "./school-report.module.scss";

export const checkValuesAreZero = (values) => {
  if (checkEmptyOrUndefined(values)) {
    return true;
  }

  const checkValueIsNotZero = (value) => {
    const floatValue = parseFloat(value).toFixed(3);
    if (!(floatValue < 1 && floatValue >= 0 && floatValue % 1 === 0)) {
      return true;
    }
    return false;
  };

  let valuesAreZero = true;
  values.forEach((value) => {
    Object.keys(value.attendance_per_kind).forEach((key) => {
      if (checkValueIsNotZero(value.attendance_per_kind[key])) {
        valuesAreZero = false;
      }
    });
    Object.keys(value.consumption_per_commodity).forEach((key) => {
      if (checkValueIsNotZero(value.consumption_per_commodity[key])) {
        valuesAreZero = false;
      }
    });
  });

  return valuesAreZero;
};

const ThrData = ({
  report: {
    aggregates: {
      thr_data_per_level_rows,
      thr_data_per_level_totals_rows: totalsRow,
    },
  },
}) => {
  const wfpCommodities = useSelector(getCurrentSchoolProfileWfpCommodities);
  const levelMapping = useSelector(getLevelAgeMappings);

  if (
    checkValuesAreZero(thr_data_per_level_rows) ||
    checkValuesAreZero(totalsRow)
  ) {
    return null;
  }

  thr_data_per_level_rows = orderRowsByLevels(thr_data_per_level_rows);

  // Only show WFP commodities that were used
  let wfpCommoditiesUsed = [];
  thr_data_per_level_rows.forEach((row) => {
    const consumptionObject = row.consumption_per_commodity || {};
    Object.keys(consumptionObject).forEach((commodityId) => {
      const floatValue = parseFloat(consumptionObject[commodityId]);
      if (!(floatValue < 1 && floatValue >= 0 && floatValue % 1 === 0)) {
        wfpCommoditiesUsed.push(parseInt(commodityId));
      }
    });
  });
  const filteredWfpCommodities = wfpCommodities.filter((item) =>
    wfpCommoditiesUsed.includes(item.id)
  );

  return (
    <ModuleBody className={styles.reportModule}>
      <h5 className={styles.sectionTitle}>
        <FormattedMessage
          id="thrData.title"
          defaultMessage="5. Take-Home rations data per level"
        />
      </h5>
      <TableExtended>
        <thead className={styles.reportTHead}>
          <tr colSpan={filteredWfpCommodities.length + 4} rowSpan={2}>
            <td className={styles.headerData} rowSpan={2}>
              <FormattedMessage
                id="thrData.takeHomeRations"
                defaultMessage="Take-Home rations"
              />
            </td>

            <td className={styles.headerData} colSpan={3}>
              <span>
                <FormattedMessage
                  id="thrData.takeHomeRationsParticipants"
                  defaultMessage="Number of students"
                />
              </span>
            </td>
            <td
              className={styles.headerData}
              colSpan={filteredWfpCommodities.length}
            >
              <span>
                <FormattedMessage
                  id="thrData.quantityOfFoodDistributed"
                  defaultMessage="Quantity of food distributed"
                />
              </span>
            </td>
          </tr>
          <tr>
            <td
              className={`${styles.headerData} ${styles.numCell}`}
              rowSpan={2}
            >
              <FormattedMessage
                id="dailyAttendance.boys"
                defaultMessage="Boys"
              />
            </td>
            <td
              className={`${styles.headerData} ${styles.numCell}`}
              rowSpan={2}
            >
              <FormattedMessage
                id="dailyAttendance.girls"
                defaultMessage="Girls"
              />
            </td>
            <td
              className={`${styles.headerData} ${styles.numCell}`}
              rowSpan={2}
            >
              <FormattedMessage
                id="dailyAttendance.total"
                defaultMessage="Total"
              />
            </td>
            {filteredWfpCommodities.map((commodity) => (
              <td
                key={commodity.name}
                className={`${styles.headerData} ${styles.numCell}`}
                rowSpan={2}
              >
                {commodity.name}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* School day summary rows */}

          {thr_data_per_level_rows.map((row, i) => {
            const customName = levelMapping.find(
              (item) => item.level === row.level
            )?.custom_name;
            const defaultName = allLevels.find(
              (item) => item.value === row.level
            )?.label;
            const level =
              customName && customName !== "" ? customName : defaultName;
            return (
              <tr key={i}>
                <td className={styles.headerData}>
                  <span>{level}</span>
                </td>
                {studentKinds.map((kind) => (
                  <td
                    className={`${styles.headerData} ${styles.numCell}`}
                    key={kind.value}
                  >
                    <span>{row.attendance_per_kind[kind.value]}</span>
                  </td>
                ))}
                <td className={`${styles.headerData} ${styles.numCell}`}>
                  {row.attendance_per_kind.male +
                    row.attendance_per_kind.female}
                </td>
                {filteredWfpCommodities.map((commodity) => (
                  <td
                    key={commodity.id}
                    className={`${styles.headerData} ${styles.numCell}`}
                  >
                    <MeasureUnitValue measureUnit={commodity.measure_unit}>
                      {row.consumption_per_commodity[commodity.id]}
                    </MeasureUnitValue>
                  </td>
                ))}
              </tr>
            );
          })}

          {/* School day summary totals row */}
          {totalsRow
            .filter((thrDataRow) =>
              !displayStaffLevelsReport(thr_data_per_level_rows) &&
              thrDataRow.level === "staff"
                ? false
                : true
            )
            .map((row, i) => (
              <tr key={i}>
                <td className={styles.headerData}>
                  <span className={styles.boldText}>
                    {
                      studentLevelTotals.find(
                        (total) => total.key === row.level
                      )?.title
                    }
                  </span>
                  {displayStaffLevelsReport(thr_data_per_level_rows) && (
                    <span className={styles.boldText}>
                      {
                        staffLevelTotals?.find(
                          (staffTotal) => staffTotal.key === row.level
                        )?.title
                      }
                    </span>
                  )}
                </td>
                {studentKinds.map((kind) => (
                  <td
                    className={`${styles.headerData} ${styles.numCell}`}
                    key={kind.value}
                  >
                    <span>{row.attendance_per_kind[kind.value]}</span>
                  </td>
                ))}
                <td className={`${styles.headerData} ${styles.numCell}`}>
                  {row.attendance_per_kind.male +
                    row.attendance_per_kind.female}
                </td>
                {filteredWfpCommodities.map((commodity) => (
                  <td
                    key={commodity.id}
                    className={`${styles.headerData} ${styles.numCell}`}
                  >
                    <MeasureUnitValue measureUnit={commodity.measure_unit}>
                      {row.consumption_per_commodity[commodity.id] || 0}
                    </MeasureUnitValue>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </TableExtended>
    </ModuleBody>
  );
};

export default ThrData;
