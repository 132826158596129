import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Button } from "@wfp/ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import DeleteModal from "components/DeleteModal";
import { isReportOpenByDate } from "helpers/report";
import { Store } from "data-handler/ducks/stores";

type DeleteModalButtonProps = {
  currentStoreData: Store;
  section?: string | undefined;
  title: React.ReactNode;
  disabled?: boolean;
  customRedirectPath: string;
};

const DeleteModalButton: React.ComponentType<DeleteModalButtonProps> = ({
  currentStoreData,
  section,
  title,
  disabled,
  customRedirectPath,
}) => {
  const [deleteModalState, setDeleteModalState] = useState(false);
  const getDate =
    (currentStoreData as any)?.occurred_on ||
    (currentStoreData as any)?.delivered_at ||
    (currentStoreData as any)?.occurred_at;

  const isReportOpen = useSelector(isReportOpenByDate(getDate));

  return (
    <>
      <Button
        kind="danger"
        icon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={() => setDeleteModalState(true)}
        disabled={disabled ? true : isReportOpen ? false : true}
      >
        {title ? (
          title
        ) : (
          <FormattedMessage
            id="IncidentShow.deleteIncident"
            defaultMessage="Delete"
          />
        )}
      </Button>
      <DeleteModal
        state={deleteModalState}
        setState={setDeleteModalState}
        currentStoreData={currentStoreData}
        section={section}
        customRedirectPath={customRedirectPath as any}
      />
    </>
  );
};

export default DeleteModalButton;
