import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import { getFilteredAttendanceTotal } from "helpers/attendance";
import SidebarItem from "components/SidebarItem";
import { AttendanceItemProps } from "./types";

type StudentAttendanceItemParams = {
  item: string;
  schoolId: string;
};

const StudentAttendanceItem: React.ComponentType<AttendanceItemProps> = ({
  currentStoreData,
  store,
  studentStore,
  thrStore,
  currentSchoolEnabledStudentAttendance,
  ...extraProps
}) => {
  const params: StudentAttendanceItemParams = useParams();

  const selected =
    (currentStoreData && currentStoreData.client_id === store.client_id) ||
    (currentStoreData === undefined && store.client_id === params.item);
  let subTitle: React.ReactElement = (
    <>
      {getFilteredAttendanceTotal({
        attendance: store,
        kinds: ["male"],
      })}{" "}
      <FormattedMessage id="app.boys" defaultMessage="boys" />,{" "}
      {getFilteredAttendanceTotal({
        attendance: store,
        kinds: ["female"],
      })}{" "}
      <FormattedMessage id="app.girls" defaultMessage="girls" />
    </>
  );

  const title = (
    <>
      {getFilteredAttendanceTotal({
        attendance: store,
      })}{" "}
      <FormattedMessage id="app.students" defaultMessage="students" />
    </>
  );

  return (
    <SidebarItem
      {...extraProps}
      store={store}
      selected={selected}
      subTitle={subTitle}
      id={store.client_id}
      title={title}
      to={`/school/${params.schoolId}/attendance/${store.client_id}`}
    />
  );
};

export default StudentAttendanceItem;
