import React from "react";
import { FormattedMessage } from "react-intl";

export const textInputFields = [
  {
    id: "email",
    name: "email",
    labelText: (
      <FormattedMessage id="countryAdmin.email" defaultMessage="Email" />
    ),
    required: true,
    disabled: false,
  },
  {
    id: "last_name",
    name: "last_name",
    labelText: (
      <FormattedMessage id="countryAdmin.lastName" defaultMessage="Last name" />
    ),
    required: true,
    disabled: false,
  },
  {
    id: "other_names",
    name: "other_names",
    labelText: (
      <FormattedMessage
        id="countryAdmin.firstName"
        defaultMessage="First name"
      />
    ),
    required: true,
    disabled: false,
  },
];

export const selectFields = [
  {
    id: "user_role",
    name: "user_role",
    labelText: (
      <FormattedMessage id="countryAdmin.role" defaultMessage="Role" />
    ),
    roles: [
      "global_administrator",
      "school_group_administrator",
      "country_administrator",
      "principal",
      "teacher",
      "store_keeper",
      "approver",
      "wfp_viewer",
      "external_viewer",
      null,
    ],
  },
  {
    id: "country",
    name: "country",
    labelText: (
      <FormattedMessage id="CountryAdmin.country" defaultMessage="Country" />
    ),
    roles: [
      "country_administrator",
      "school_group_administrator",
      "principal",
      "teacher",
      "store_keeper",
      "approver",
      "external_viewer",
      "wfp_viewer",
    ],
  },
  {
    id: "school",
    name: "school",
    labelText: (
      <FormattedMessage id="countryAdmin.school" defaultMessage="School" />
    ),
    roles: ["principal", "teacher", "store_keeper"],
  },
  {
    id: "designation",
    name: "designation",
    labelText: (
      <FormattedMessage
        id="countryAdmin.designation"
        defaultMessage="Designation"
      />
    ),
    roles: ["approver"],
  },
  {
    id: "authorities",
    name: "authorities",
    labelText: (
      <FormattedMessage
        id="CountryAdmin.schoolAuthorities"
        defaultMessage="Authorities"
      />
    ),
    roles: ["approver"],
  },
];

export const approverDesignations = [
  {
    value: 1,
    label: (
      <FormattedMessage
        id="User.RegistrationGovernment"
        defaultMessage="Government"
      />
    ),
  },
  {
    value: 2,
    label: (
      <FormattedMessage
        id="User.RegistrationCooperatingPartner"
        defaultMessage="Cooperating/Delivery Partner"
      />
    ),
  },
];

export const isActiveOptions = [
  {
    value: true,
    label: (
      <FormattedMessage id="countryAdmin.yesMessage" defaultMessage="Yes" />
    ),
  },
  {
    value: false,
    label: <FormattedMessage id="countryAdmin.noMessage" defaultMessage="No" />,
  },
];

export const schoolStaffRoles = ["principal", "teacher", "store_keeper"];
