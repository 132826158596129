import { getLevelAgeMappings } from "data-handler/ducks/ageGroups";
import React from "react";
import { useSelector } from "react-redux";
import { allLevels } from "SCConstants";

export default function LevelTitle({ level }) {
  const levelMapping = useSelector(getLevelAgeMappings);
  if (!level.level) {
    // TODO: why would this ever happen, fix this in parent components
    return null;
  }

  const studentLevel = allLevels.find((ld) => ld.value === level.level);
  const customName = levelMapping.find((item) => item.level === level.level)
    ?.custom_name;
  const defaultName =
    (studentLevel && studentLevel.label) || level.level?.custom_name;
  const label = customName && customName !== "" ? customName : defaultName;
  return <>{label}</>;
}
