import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  NavLink,
} from "react-router-dom";
import { useSelector } from "react-redux";

import {
  SecondaryNavigation,
  SecondaryNavigationTitle,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbHome,
  Tabs,
  Tab,
} from "@wfp/ui";

import { useIntl } from "react-intl";
import { CountryAdminLabel } from "SCConstants";

import AllUsers from "./AllUsers";
import NewUsers from "./NewUsers";
import { generateCustomRoleList } from "helpers/roles";
import { getCurrentUser } from "data-handler/ducks/auth";
import { getRoles } from "data-handler/ducks/roles";
import { getLanguage } from "data-handler/ducks/language";

const UsersTable = () => {
  const history = useHistory();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      label: CountryAdminLabel.find(
        (item) => item.name === "countryAdmin.allUser"
      ).label,
      href: `/countryAdmin/users/allUsers`,
    },
    {
      label: CountryAdminLabel.find(
        (item) => item.name === "countryAdmin.newUser"
      ).label,
      href: `/countryAdmin/users/newUsers`,
    },
  ];

  const setInitTabAndRedirect = () => {
    const index = tabs.findIndex((tab) => tab.href === location.pathname);
    if (index < 0) {
      setTabIndex(0);
      history.replace(tabs[0].href);
    } else {
      setTabIndex(index);
    }
  };
  useEffect(setInitTabAndRedirect, [tabs, location.pathname, history]);

  const loadTab = (index) => {
    const tab = tabs[index];
    history.push(tab ? tab.href : `countryAdmin/users/`);
    setTabIndex(tab ? index : 0);
  };

  const tabsKey = tabs.reduce((acc, cur) => acc + cur.label, "");
  return (
    <div className="users-table">
      <SecondaryNavigation pageWidth="lg">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/countryAdmin/">
              <BreadcrumbHome />
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink to={location.pathname}>Users</NavLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <SecondaryNavigationTitle>Users</SecondaryNavigationTitle>
        <Tabs key={tabsKey} onSelectionChange={loadTab} selected={tabIndex}>
          {tabs.map((tab) => (
            <Tab key={tab.label.props.defaultMessage} label={tab.label} />
          ))}
        </Tabs>
      </SecondaryNavigation>
      <Switch>
        <Route path={`/countryAdmin/users/allUsers`} component={AllUsers} />
        <Route path={`/countryAdmin/users/newUsers`} component={NewUsers} />
      </Switch>
    </div>
  );
};

export default UsersTable;

/**
 * This function creates the filter options for the User Tables
 * @returns list
 */
export const RoleFilterOptions = () => {
  const intl = useIntl();
  const currentUser = useSelector(getCurrentUser);
  const { roles, countryRoles } = useSelector(getRoles);
  const language = useSelector(getLanguage);
  return [
    {
      name: "",
      label: intl.formatMessage({
        id: "countryAdmin.AllSchoolRolesTitle",
        defaultMessage: "All roles",
      }),
    },
    ...generateCustomRoleList(
      roles,
      countryRoles,
      language,
      currentUser.is_admin
    ).map((item) => {
      return {
        name: item.type,
        label: item[`custom_name`] ? item[`custom_name`] : item.role,
      };
    }),
  ];
};
