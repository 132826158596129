import endpointDuckFactory from "./_endpointDuckFactory";

const commoditiesDuck = endpointDuckFactory({ endpoint: "commodities" });

export default commoditiesDuck;

/* These are all the commodities. Please use the School Commodities for commodities that are specific to that school. */
export const getWfpCommodities = (state) =>
  commoditiesDuck.getList(state).filter((commodity) => commodity.is_wfp);

export const getOtherCommodities = (state) =>
  commoditiesDuck.getList(state).filter((commodity) => !commodity.is_wfp);
