import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { notificationStyle } from "@wfp/ui";

import {
  TOAST_SUCCESS,
  TOAST_ERROR,
  TOAST_WARNING,
  TOAST_REGULAR,
  CLEAR_TOAST,
  getCurrentToast,
} from "data-handler/ducks/toast";
const Toast = () => {
  const dispatch = useDispatch();

  const currentToast = useSelector(getCurrentToast);

  const options = {
    ...currentToast.options,
  };

  useEffect(() => {
    const { toastType, message } = currentToast;
    if (message) {
      switch (toastType) {
        case TOAST_SUCCESS:
          toast.success(message, options);
          break;
        case TOAST_ERROR:
          toast.error(message, options);
          break;
        case TOAST_WARNING:
          toast.warn(message, options);
          break;
        case TOAST_REGULAR:
          toast(message, options);
          break;
        default:
          toast.error("Invalid Toast", options);
      }
    }
    if (toastType) dispatch({ type: CLEAR_TOAST });
  }, [dispatch, options, currentToast]);

  return (
    <ToastContainer
      {...notificationStyle}
      hideProgressBar
      position="top-right"
    />
  );
};

export default Toast;
