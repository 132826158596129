import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import queryString from "query-string";
import withSizes from "react-sizes";
import classNames from "classnames";

import { getCurrentUser } from "data-handler/ducks/auth";

import { mapSizesToProps } from "helpers/mapSizesToProps";

import UserModal from "components/UserModal";
import SchoolSelect from "components/SchoolSelect";
import UpdateLocalPassword from "components/Login/UpdateLocalPassword";
import SupportModal from "components/SupportModal";

import MainNav from "../CountryAdminHeader/MainNav";
import MobileNav from "../CountryAdminHeader/MobileNav";

import CountryAdminProfile from "./CountryAdminProfile";
import CountryAdminUsers from "./CountryAdminUsers";
import CountryAdminSchoolProfiles from "./CountryAdminSchoolProfiles";
import CountryAdminSchools from "./CountryAdminSchools";
import CountryAdminDistributionReport from "./CountryAdminDistributionReport";
import CountryAdminVendors from "./CountryAdminVendors";

import { getOnlineRequiredError } from "data-handler/ducks/auth";
import {
  clearLevels,
  requestLevelGroupMappings,
} from "data-handler/ducks/ageGroups";
import { getSelectedCountry } from "data-handler/ducks/countryProfile";
import {
  requestCountryRoleMapping,
  getRoles,
  requestRoles,
} from "data-handler/ducks/roles";
import OnlineRequiredModal from "components/OnlineRequiredModal/OnlineRequiredModal";

import "./_content.scss";

/**
 * - Renders Nav, according to isMobile
 * - Renders Pages, according to routing
 * - Loads SC primitives, on first access
 *
 * This component must be accessible _only_ to authenticated users.
 */
const Content = ({ isMobile }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = queryString.parse(location.search);

  const currentUser = useSelector(getCurrentUser);
  const onlineRequiredError = useSelector(getOnlineRequiredError);
  const selectedCountry = useSelector(getSelectedCountry);
  const { roles } = useSelector(getRoles);

  const routeMatch = useRouteMatch("/countryAdmin/");

  useEffect(() => {
    if (currentUser?.user_role === "global_administrator" && !selectedCountry) {
      dispatch(clearLevels());
    }
    const countryId =
      currentUser?.user_role === "country_administrator"
        ? currentUser?.country?.id
        : currentUser?.user_role === "global_administrator" && selectedCountry
        ? selectedCountry.id
        : undefined;

    if (countryId) {
      dispatch(requestLevelGroupMappings(countryId));
      dispatch(requestCountryRoleMapping(countryId));
      if (roles.length === 0) {
        dispatch(requestRoles());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedCountry, roles]);

  if (currentUser && onlineRequiredError) {
    return <OnlineRequiredModal />;
  }

  return (
    <div
      className={classNames("content", {
        content__active: urlParams.nav,
      })}
    >
      {/* Modals */}
      {urlParams.select === "school" && <SchoolSelect />}
      <Route path="/countryAdmin/" component={SupportModal} />
      <Route path="/countryAdmin/" component={UpdateLocalPassword} />
      <Route path="/countryAdmin/" component={UserModal} />

      {/* Nav */}
      <Switch>
        {/* TODO: Change "routeMatch" to match future routes
            Potentially can be removed?
            Previous Path for navigiation - :school/:schoolId/:page?/:item?/:details? 
        */}
        <Route
          path="/countryAdmin/:page?"
          render={(props) =>
            isMobile ? (
              <MobileNav {...props} />
            ) : (
              <MainNav match={routeMatch} {...props} />
            )
          }
        />
      </Switch>

      {/* Pages */}
      <div className="country-admin-inner-content">
        <Switch>
          <Route path="/countryAdmin/users/" component={CountryAdminUsers} />
          <Route
            path="/countryAdmin/schoolProfiles/"
            component={CountryAdminSchoolProfiles}
          />
          <Route
            path="/countryAdmin/schools/"
            component={CountryAdminSchools}
          />
          <Route
            path="/countryAdmin/distributionReport"
            component={CountryAdminDistributionReport}
          />
          <Route path="/countryAdmin/vendors" component={CountryAdminVendors} />
          <Route component={CountryAdminProfile} />
        </Switch>
      </div>
    </div>
  );
};

export default withSizes(mapSizesToProps)(Content);
