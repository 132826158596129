import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ModuleBody } from "@wfp/ui";

import commoditiesDuck from "data-handler/ducks/commodities";
import { getCurrencyForCurrentSchool } from "data-handler/ducks/currency";
import MeasureUnitValue from "components/MeasureUnitValue";

import TableExtended from "components/TableExtended";

import {
  missingCommodity,
  Report,
  ReportCommodity,
  ReportFoodExpenditure,
} from "data-handler/ducks/reports";

import styles from "./school-report.module.scss";

type PurchasedStockMovementsExpendituresProps = {
  report: Report;
};

// Food expenditure rows has 2 different kind of rows inside. One is food expenditure rows and second is other costs related to them.
// Here are 2 examples of those rows.
// There were a code somewhere that distinguishes them by checking if quantity value is N/A. I will use the same logic here.
//  If other edge cases show up we should update this function until we add some king of type property to distinguish them.
// - {"date":"2024-05-13T07:38:54Z","item":49,"comments":"A ton of aroma","quantity":"1000.000","total_paid":"1000.000","measure_unit":{}}
// - {"date":"2024-05-13T07:38:54Z","item":"Other Costs (Transportation cost)","comments":"","quantity":"N/A","total_paid":"100.000","measure_unit":null}]
const isOtherCostsRow = (row: ReportFoodExpenditure) => row.quantity === "N/A";

const PurchasedStockMovementsExpenditures: React.ComponentType<PurchasedStockMovementsExpendituresProps> = ({
  report,
}) => {
  const commoditiesIndex = useSelector(commoditiesDuck.getIndex);
  const defaultCurrencyName = useSelector(getCurrencyForCurrentSchool).name;
  const {
    aggregates: {
      food_expenditure_rows,
      food_expenditure_totals_row,
      commodities,
    },
  } = report;

  const checkValuesAreZero = (values: any[]) => {
    let valuesAreZero = true;
    values.forEach((value) => {
      const floatValue: any = parseFloat(value).toFixed(3);
      if (!(floatValue < 1 && floatValue >= 0 && floatValue % 1 === 0)) {
        valuesAreZero = false;
      }
    });
    return valuesAreZero;
  };

  const commodityMapping = useMemo(
    () => {
      // Create commodityId -> Commodity mapping
      const commodityMapping: { [commodityId: number]: ReportCommodity } = {};
      if (commodities !== undefined) {
        for (let commodity of commodities) {
          commodityMapping[commodity.id] = commodity;
        }
      }
      return commodityMapping;
    },
    // Calculate this only once per report on initialization
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report.id]
  );

  const getCommodity = (id: number) => {
    const defaultCommodity = {
      ...missingCommodity,
      name: `Unidentified(${id})`,
    };
    return commodityMapping[id] || defaultCommodity;
  };

  const filteredStockMovementRows = food_expenditure_rows?.filter(
    (item) =>
      !checkValuesAreZero([
        item.quantity,
        item.date,
        item.total_paid,
        item.comments,
      ])
  );

  const totalPaid = filteredStockMovementRows?.map((i) => i.total_paid);
  const totalPaidSum = totalPaid?.reduce(
    (a: string, b: string) => (parseInt(a) + parseInt(b)).toString(),
    "0"
  );

  return (
    <ModuleBody className={styles.reportModule}>
      <h5 className={styles.sectionTitle}>
        <FormattedMessage
          id="stockMovement.expenditures"
          defaultMessage="6. Food expenditures"
        />
      </h5>

      <TableExtended>
        <thead className={styles.reportTHead}>
          <tr>
            <td className={styles.headerData} rowSpan={2}>
              <FormattedMessage id="stockMovement.number" defaultMessage="No" />
            </td>
            <td className={styles.headerData} rowSpan={2}>
              <FormattedMessage
                id="stockMovement.items"
                defaultMessage="Items"
              />
            </td>
            <td className={styles.headerData}>
              <FormattedMessage
                id="stockMovement.quantity"
                defaultMessage="Quantity"
              />
            </td>
            <td className={styles.headerData}>
              <FormattedMessage id="stockMovement.unit" defaultMessage="Unit" />
            </td>
            <td className={styles.headerData}>
              <FormattedMessage
                id="stockMovement.totalPaid"
                defaultMessage="Total paid"
              />{" "}
              ({defaultCurrencyName})
            </td>
            <td className={styles.headerData}>
              <FormattedMessage id="stockMovement.date" defaultMessage="Date" />
            </td>
            <td className={styles.headerData}>
              <FormattedMessage
                id="stockMovement.comments"
                defaultMessage="Comments"
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {/* Stock movements per-commodity */}
          {filteredStockMovementRows?.map((row, i) => {
            return (
              <tr key={i}>
                <td className={styles.headerData}>{i + 1}</td>
                <td
                  style={{ textAlign: "left" }}
                  className={`${styles.headerData} ${styles.numCell}`}
                >
                  {isOtherCostsRow(row)
                    ? row.item
                    : commoditiesIndex[row.item]?.name}
                </td>
                <td className={`${styles.headerData} ${styles.numCell}`}>
                  {row.quantity}
                </td>
                <td className={`${styles.headerData} ${styles.numCell}`}>
                  {isOtherCostsRow(row)
                    ? "N/A"
                    : getCommodity(row.item).measure_unit.name}
                </td>
                <td className={`${styles.headerData} ${styles.numCell}`}>
                  {row.total_paid}
                </td>
                <td className={`${styles.headerData} ${styles.numCell}`}>
                  {row.date}
                </td>
                <td className={`${styles.headerData} ${styles.numCell}`}>
                  {row.comments !== "N/A" || row.comments.includes("undefined")
                    ? row.comments
                    : "N/A"}
                </td>
              </tr>
            );
          })}

          {/* Stock movement totals */}
          <tr className={styles.boldText}>
            <td className={styles.headerData}>
              {
                // Row number
                filteredStockMovementRows?.length + 1
              }
            </td>
            <td className={styles.headerData}>
              <FormattedMessage id="Common.Total" defaultMessage="Total" />
            </td>
            <td className={`${styles.headerData} ${styles.numCell}`}>
              {food_expenditure_totals_row.measure_unit &&
              food_expenditure_totals_row?.quantity ? (
                <MeasureUnitValue
                  measureUnit={food_expenditure_totals_row.measure_unit}
                >
                  {food_expenditure_totals_row?.quantity}
                </MeasureUnitValue>
              ) : (
                food_expenditure_totals_row?.quantity
              )}
            </td>
            <td className={`${styles.headerData} ${styles.numCell}`}></td>
            <td className={`${styles.headerData} ${styles.numCell}`}>
              {totalPaidSum}
            </td>
            <td className={`${styles.headerData} ${styles.numCell}`}></td>
            <td className={`${styles.headerData} ${styles.numCell}`}></td>
          </tr>
        </tbody>
      </TableExtended>
    </ModuleBody>
  );
};

export default PurchasedStockMovementsExpenditures;
