import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { Blockquote } from "@wfp/ui";

import reportsDuck from "data-handler/ducks/reports";
import { getFilteredReportSignatures } from "data-handler/ducks/reportSignatures";
import { REPORT_FETCHER } from "./index";

const ActionFailed = ({ error, error_details }) => (
  <Blockquote
    kind="error"
    title={
      <FormattedMessage
        id="useReportAction.actionFailed"
        defaultMessage="Action failed"
      />
    }
  >
    {error}
    <pre>{error_details}</pre>
  </Blockquote>
);

export default function ({ report, action, useFormArguments }) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const period = moment(`${report.year}/${report.month}/1`).format("MMMM YYYY");

  const closeModal = () => {
    history.push(`/school/${params.schoolId}/report/${params.item}`);
  };

  const [actionFeedback, setActionFeedback] = useState(null);
  const [isPerforming, setIsPerforming] = useState(false);

  const localSignatures = useSelector(
    getFilteredReportSignatures({
      reportMonth: report.month,
      reportYear: report.year,
      reportStartDay: report.start_day,
      reportEndDay: report.end_day,
    })
  );

  const form = useForm(useFormArguments);

  /**
   * Perform the appropriate API call for this hook's `action`.
   *
   * Sets `isPerforming` to true when requesting, to false after response.
   * Sets `actionFeedback` to null when requesting, to a JSX node after response.
   */
  const _performAction = (values) => {
    setIsPerforming(true);
    setActionFeedback(null);

    // Add the staff signature object if the the action is submit
    // and if there is a signature
    if (
      action === "submit" &&
      localSignatures.length > 0 &&
      localSignatures[0].signature
    ) {
      values.staff_signature = {
        user: localSignatures[0].userId,
        signature: localSignatures[0].signature,
      };
    }

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/reports/${report.id}/perform/${action}/`,
        values
      )
      .then((response) => {
        const { id } = response.data;
        // Re-fetch the updated report from the BE (TODO: use this response)
        dispatch(reportsDuck.fetchDetail(id, REPORT_FETCHER));

        closeModal();
        toast.success(
          <FormattedMessage
            id="useReportAction.actionSucceededBody"
            defaultMessage="The report's status has been updated"
          />
        );
      })
      .catch((error) => {
        const errorObject = error?.response?.data?.errors;

        const message = Object.keys(errorObject)
          ?.filter((item) => item !== "status_code")
          ?.map((key) => {
            const individualMessage = errorObject[key];
            return `${key}: ${individualMessage}`;
          })
          .join("\n");
        setActionFeedback(<ActionFailed error={""} error_details={message} />);
      })
      .finally(() => {
        setIsPerforming(false);
      });
  };

  /**
   * Uses react-hook-form handleSubmit, then performs the action and closes the modal
   */
  const performAction = form.handleSubmit(_performAction); // handleSubmit returns a function

  return {
    actionFeedback,
    closeModal,
    form,
    isPerforming,
    performAction,
    period,
  };
}
