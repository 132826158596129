import moment from "moment";

import {
  getEnrolmentUpdatesByDateRange,
  getOngoingSchoolYearByDate,
  getSchoolYearLevelsBySchoolYearId,
} from "data-handler/ducks/stores";
import {
  staffLevels,
  prePrimaryLevels,
  primaryLevels,
  studentKinds,
  studentLevels,
} from "SCConstants";

/**
 * Returns the total amount of newcomers and dropouts which match the given criteria
 */
export const getFilteredEnrolmentUpdate = ({
  enrolmentUpdate,
  kinds = studentKinds.map((kind) => kind.value),
  levels = studentLevels.map((level) => level.value),
}) => {
  if (!enrolmentUpdate || !enrolmentUpdate.levels)
    return { newcomers: 0, dropouts: 0 };
  let newcomers = 0;
  let dropouts = 0;
  enrolmentUpdate.levels.forEach((level) => {
    if (levels.includes(level.level)) {
      kinds.forEach((kind) => {
        newcomers += level[`${kind}_newcomers`];
        dropouts += level[`${kind}_dropouts`];
      });
    }
  });
  return { newcomers, dropouts };
};

/**
 * Returns a redux selector of the total amount of enrolled students on the given date
 * which match the given criteria.
 *
 * Accounts for:
 * - initial enrolment of the ongoing SchoolYear on the given `date`
 * - EnrolmentUpdates from SchoolYear start date up to the given `date`.
 */
export const getFilteredEnrolmentTotalOnDate = ({
  date,
  kinds = studentKinds.map((kind) => kind.value),
  levels = studentLevels.map((level) => level.value),
}) => (state) => {
  const date_ = moment(date);

  const schoolYear = getOngoingSchoolYearByDate(date_)(state);
  // No school year ongoing -> no enrolment
  if (!schoolYear) return 0;

  let total = 0;

  const enrolmentUpdates = getEnrolmentUpdatesByDateRange(
    schoolYear.starts_on,
    date
  )(state);

  // Account for SchoolYear initial enrolment
  schoolYear.levels.forEach((level) => {
    if (levels.includes(level.level)) {
      kinds.forEach((kind) => {
        total += level[`initial_${kind}`];
      });
    }
  });

  // Account for EnrolmentUpdate newcomers and dropouts
  enrolmentUpdates.forEach((enrolmentUpdate) => {
    const { newcomers, dropouts } = getFilteredEnrolmentUpdate({
      enrolmentUpdate,
      kinds,
      levels,
    });
    total += newcomers - dropouts;
  });

  return total;
};

/**
 * Returns a selector of aggregated enrolment data on the given `date`.
 * Useful for reports or an enrolment table.
 *
 * The selector returns { rows, totals_rows }, where:
 * - rows = [{ level, male, female, total }, ...]
 * - totals_rows = [{ name:"pre"|"primary"|"total", male, female, total }]
 */
export const getEnrolmentAggregateOnDate = (date) => (state) => {
  // TODO: use reselect
  const ongoingSchoolYear = getOngoingSchoolYearByDate(date)(state);
  // If there is no ongoing schoolyear schoolyear levels are just an empty list.
  const ongoingSchoolYearLevels = ongoingSchoolYear
    ? getSchoolYearLevelsBySchoolYearId(ongoingSchoolYear.object_id)(state)
    : [];

  // TODO: there is a selector that calculates this.
  const displayStaffLevelEnrollments = ongoingSchoolYearLevels.find(
    (level) => level?.level === "staff"
  )
    ? true
    : false;
  const level_total_rows = [
    { name: "pre", levels: prePrimaryLevels },
    { name: "primary", levels: primaryLevels },
    { name: "total", levels: studentLevels },
  ];
  if (displayStaffLevelEnrollments) {
    level_total_rows.push({ name: "staff", levels: staffLevels });
  }
  if (ongoingSchoolYear === null) {
    return {
      rows: [],
      totals_rows: level_total_rows,
    };
  }

  const rows = ongoingSchoolYearLevels.map((level) => {
    const row = {
      level: level.level,
      total: getFilteredEnrolmentTotalOnDate({
        date: date,
        levels: [level.level],
      })(state),
      custom_name: level.hasOwnProperty(`custom_name`)
        ? level[`custom_name`]
        : undefined,
    };
    studentKinds.forEach((kind) => {
      row[kind.value] = getFilteredEnrolmentTotalOnDate({
        date: date,
        kinds: [kind.value],
        levels: [level.level],
      })(state);
    });

    return row;
  });

  const totals_rows = level_total_rows.map((totalType) => {
    const row = {
      name: totalType.name,
      total: getFilteredEnrolmentTotalOnDate({
        date: date,
        levels: totalType.levels.map((level) => level.value),
      })(state),
    };

    studentKinds.forEach((kind) => {
      row[kind.value] = getFilteredEnrolmentTotalOnDate({
        date: date,
        kinds: [kind.value],
        levels: totalType.levels.map((level) => level.value),
      })(state);
    });

    return row;
  });
  return { rows, totals_rows };
};
