import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  SecondaryNavigation,
  SecondaryNavigationTitle,
  Tab,
  Tabs,
} from "@wfp/ui";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Button } from "@wfp/ui";

import CountrySelectModal from "./CountrySelectModal";
import CountryAdminLevels from "./CountryAdminLevels";
import CountryAdminRoles from "components/CountryAdmin/CountryAdminContent/CountryAdminProfile/CountryAdminRoles";
import CountryAdminSchoolClassNames from "./CountryAdminSchoolClassNames";

import { getCurrentUser } from "data-handler/ducks/auth";
import {
  getSelectedCountry,
  setSelectedCountry,
} from "data-handler/ducks/countryProfile";

import "./_content.scss";

const CountryAdminProfile = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser, selectedCountry } = props;

  const [countrySelectModalOpen, setCountrySelectModalOpen] = useState(
    currentUser?.user_role === "global_administrator" && !selectedCountry
      ? true
      : false
  );

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    // Pre-populate the selected country if the user is a country administrator
    if (currentUser?.user_role === "country_administrator") {
      dispatch(setSelectedCountry(currentUser.country));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const tabs = [
    {
      label: (
        <FormattedMessage
          id="countryProfile.adminLevels"
          defaultMessage="Admin levels"
        />
      ),
      href: ["/countryAdmin/adminLevels"],
    },
    {
      label: (
        <FormattedMessage
          id="countryProfile.classNameMapping"
          defaultMessage="School classes"
        />
      ),
      href: [
        "/countryAdmin/schoolClassNames",
        "/countryAdmin/schoolClassNames/edit",
      ],
    },
    {
      label: (
        <FormattedMessage
          id="countryProfile.rolesMapping"
          defaultMessage="Roles"
        />
      ),
      href: ["/countryAdmin/roles", "/countryAdmin/roles/edit"],
    },
  ];

  const setInitTabAndRedirect = () => {
    const index = tabs.findIndex((tab) => tab.href.includes(location.pathname));
    if (index < 0) {
      setTabIndex(0);
      history.replace(tabs[0].href[0]);
    } else {
      setTabIndex(index);
    }
  };
  useEffect(setInitTabAndRedirect, [tabs, location.pathname, history]);

  const loadTab = (index) => {
    const tab = tabs[index];
    history.push(tab ? tab.href[0] : `countryAdmin/`);
    setTabIndex(tab ? index : 0);
  };

  const tabsKey = tabs.reduce((acc, cur) => acc + cur.label, "");

  const submitSelectedCountry = (country) => {
    dispatch(setSelectedCountry(country));
  };

  return (
    <div className="country-admin-main-content country-profile-page">
      <SecondaryNavigation pageWidth="lg">
        <SecondaryNavigationTitle>
          <FormattedMessage
            id="countryAdmin.countryProfile"
            defaultMessage="Country profile"
          />
          <Button
            className="wfp--btn--secondary"
            disabled={currentUser?.user_role === "country_administrator"}
            onClick={() => {
              setCountrySelectModalOpen(true);
            }}
          >
            {selectedCountry ? (
              selectedCountry.name
            ) : (
              <FormattedMessage
                id="CountryAdmin.noCountrySelected"
                defaultMessage="No country selected"
              />
            )}
          </Button>
        </SecondaryNavigationTitle>
        <Tabs key={tabsKey} onSelectionChange={loadTab} selected={tabIndex}>
          {tabs.map((tab) => (
            <Tab key={tab.label.props.defaultMessage} label={tab.label} />
          ))}
        </Tabs>
      </SecondaryNavigation>
      <CountrySelectModal
        countrySelectModalOpen={countrySelectModalOpen}
        setCountrySelectModalOpen={setCountrySelectModalOpen}
        country={selectedCountry}
        submitSelectedCountry={submitSelectedCountry}
      />
      <Switch>
        <Route
          path={`/countryAdmin/schoolClassNames`}
          component={CountryAdminSchoolClassNames}
        />
        <Route
          path={"/countryAdmin/adminLevels"}
          component={CountryAdminLevels}
        />
        <Route path={"/countryAdmin/roles"} component={CountryAdminRoles} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state),
    selectedCountry: getSelectedCountry(state),
  };
};

export default connect(mapStateToProps)(CountryAdminProfile);
