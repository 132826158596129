import { call, put, takeEvery } from "redux-saga/effects";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import axios from "axios";
import { getIsAdmin, getIsCountryAdmin } from "helpers/users";

// TODO: these consts DON'T need to be exposed, other modules must use action creators!
export const REQUEST = "schoolconnect/auth/REQUEST_LOGIN";
export const SUCCESS = "schoolconnect/auth/SUCCESS_LOGIN";
export const FAILURE = "schoolconnect/auth/FAILURE_LOGIN";
export const LOGIN_LOCAL = "LOGIN_LOCAL";
export const LOGOUT_LOCAL = "LOGOUT_LOCAL";
export const LOGOUT = "LOGOUT";
export const LOGIN_CIAM = "LOGIN_CIAM";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_LAST_ACTION_PERFORMED_AT =
  "REFRESH_LAST_ACTION_PERFORMED_AT";
export const REQUIRE_ONLINE_LOGIN = "REQUIRE_ONLINE_LOGIN";
export const VIEW_LAYOUT = "VIEW_LAYOUT";

const OFFLINE_LOGIN = "OFFLINE_LOGIN";
const ONLINE_LOGIN = "ONLINE_LOGIN";
const initialState = {
  currentUser: undefined,
  error: null,
  errorResponse: null,
  fetching: false,
  token: undefined,
  refreshToken: undefined,
  lastActionPerformedAt: undefined,
  is_active: undefined,
  isCIAM: false,
  onlineRequiredError: false,
  viewLayout: "auto",
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, fetching: true, error: null };
    case SUCCESS:
      return {
        ...state,
        currentUser: action.data.user,
        fetching: false,
        loginMode: ONLINE_LOGIN,
        token: action.data.token,
        onlineRequiredError: false,
      };
    case FAILURE:
      return {
        ...state,
        currentUser: undefined,
        error: action.error,
        errorResponse: action.error.response,
        fetching: false,
        token: undefined,
        refreshToken: undefined,
        lastActionPerformedAt: undefined,
        onlineRequiredError: false,
      };
    case LOGIN_LOCAL:
      return {
        ...state,
        currentUser: action.data,
        loginMode: OFFLINE_LOGIN,
        onlineRequiredError: false,
        token: "123", //TODO: set var, action.data.localData.token,
        refreshToken: undefined,
        lastActionPerformedAt: undefined,
      };
    case LOGOUT_LOCAL:
      let newAuth = state;
      delete newAuth.user;
      return newAuth;
    case LOGOUT:
      if (state.isCIAM) {
        // Log this user out of CIAM, not just out of School Connect
        window.open(
          `${process.env.REACT_APP_CIAM_LOGOUT_URL}?id_token_hint=${state.token}&post_logout_redirect_uri=${process.env.REACT_APP_CIAM_REDIRECT_URL}`,
          "_self"
        );
      }
      return initialState;
    case LOGIN_CIAM:
      const currentUser =
        action.data && action.data.user ? action.data.user : undefined;
      const token =
        action.data && action.data.user ? action.data.user.token : undefined;
      const refresh_token =
        action.data && action.data.user
          ? action.data.user.refresh_token
          : undefined;
      // TODO: move role into backend in a nicer way
      let role;
      if (action.data.user && action.data.user.school) {
        const school = action.data.user.school;
        role = school.is_principal
          ? "principal_at" //TODO: better role naming and handling in backend
          : school.is_teacher
          ? "teacher_at"
          : school.is_store_keeper
          ? "store_keeper_at"
          : undefined;
      }
      const is_active = action.data.is_active;
      return {
        ...state,
        currentUser: { ...currentUser, role, isCIAM: true },
        fetching: false,
        is_active: is_active,
        isCIAM: true,
        loginMode: ONLINE_LOGIN,
        onlineRequiredError: false,
        token: token,
        refreshToken: refresh_token,
        lastActionPerformedAt: undefined,
      };
    case REFRESH_LAST_ACTION_PERFORMED_AT:
      return {
        ...state,
        lastActionPerformedAt: action.data,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          token: action.data.token,
          refreshToken: action.data.refreshToken,
        },
        token: action.data.token,
        refreshToken: action.data.refreshToken,
        lastActionPerformedAt: undefined,
      };
    case REQUIRE_ONLINE_LOGIN:
      return {
        ...state,
        onlineRequiredError: action.data,
        lastActionPerformedAt: undefined,
      };
    case VIEW_LAYOUT:
      return {
        ...state,
        viewLayout: action.data,
      };
    default:
      return state;
  }
}

const persistedReducer = persistReducer(
  {
    key: "auth",
    storage,
    blacklist: ["error", "errorResponse", "fetching"],
  },
  reducer
);

export default persistedReducer;

//
// ACTION CREATORS
//

export const requestLogin = (data) => ({ type: REQUEST, data });

export const setViewLayout = (data) => ({ type: VIEW_LAYOUT, data });

export const setOnlineRequiredError = () => ({
  type: REQUIRE_ONLINE_LOGIN,
  data: { requireOnlineLogin: true },
});
export const unsetOnlineRequiredError = () => ({
  type: REQUIRE_ONLINE_LOGIN,
  data: { requireOnlineLogin: false },
});

//
// SELECTORS
//

export const getAuthErrorResponse = (state) => state.auth.errorResponse;
export const getToken = (state) => state.auth?.token;
export const getRefreshToken = (state) => state.auth?.refreshToken;
export const getLastActionPerformedAt = (state) =>
  state.auth?.lastActionPerformedAt;
export const getCurrentUser = (state) => state.auth.currentUser;
export const getIsActive = (state) => state.auth.is_active;
export const getIsWFPUser = (state) => state.auth.currentUser?.is_wfp;

export const getLoggedInOffline = (state) =>
  state.auth.loginMode === OFFLINE_LOGIN;
export const getOnlineRequiredError = (state) => state.auth.onlineRequiredError;
export const getLoginState = (state) => ({
  fetching: state.auth.fetching,
  error: state.auth.error,
  errorResponse: state.auth.errorResponse,
});
export const getUserPermissionGroups = (state) =>
  state.auth.currentUser?.groups;
export const getCurrentUserCountryId = (state) => {
  return state.auth.currentUser.country?.id;
};

export const getRawDataExtractionPermission = (state) => {
  return state.auth.currentUser.has_raw_data_extraction_permission;
};

export const getIsViewer = (state) =>
  getCurrentUser(state)?.is_external_viewer ||
  getCurrentUser(state)?.is_wfp_viewer;

export const getIsApprover = (state) => state.auth.currentUser?.is_approver;

export const getIsSchoolStaff = (state) =>
  state.auth.currentUser?.school?.is_teacher ||
  state.auth.currentUser?.role === "teacher" ||
  state.auth.currentUser?.role?.type === "teacher" ||
  state.auth.currentUser?.role_type === "teacher" ||
  state.auth.currentUser?.school?.is_store_keeper ||
  state.auth.currentUser?.role === "store_keeper" ||
  state.auth.currentUser?.role?.type === "store_keeper" ||
  state.auth.currentUser?.role_type === "store_keeper" ||
  state.auth.currentUser?.school?.is_principal ||
  state.auth.currentUser?.role === "principal" ||
  state.auth.currentUser?.role?.type === "principal" ||
  state.auth.currentUser?.role_type === "principal";

export const getIsSchoolAdmin = (state) =>
  state.auth.currentUser?.school?.is_principal;

export const getRoleList = (state) => [
  { is_viewer: getIsViewer(state) },
  { is_admin: getIsAdmin(state.auth.currentUser) },
  { is_countryAdmin: getIsCountryAdmin(state.auth.currentUser) },
  { is_wfp: getIsWFPUser(state) },
  { is_approver: getIsApprover(state) },
  { is_school_staff: getIsSchoolStaff(state) },
  { is_school_admin: getIsSchoolAdmin(state) },
];

export const getIsOfflineMode = (state) =>
  state.auth.loginMode === OFFLINE_LOGIN;

export const getIsOnlineMode = (state) => state.auth.loginMode === ONLINE_LOGIN;

export const getViewLayout = (state) => state.auth.viewLayout;
//
// SAGA
//

export function* loginSagaWatcher() {
  yield takeEvery(REQUEST, loginSagaWorker);
}

function POSTLogin({ data }) {
  const url = `${process.env.REACT_APP_API_URL}/auth/login/`;
  return axios({
    method: "POST",
    url: url,
    data: data,
  });
}

function* loginSagaWorker(data) {
  let response;
  try {
    response = yield call(POSTLogin, data);
    yield put({ type: SUCCESS, data: response.data });
  } catch (error) {
    yield put({ type: FAILURE, error });
  }
}
