import React from "react";
import { Blockquote, List, ListItem } from "@wfp/ui";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

/**
 * Recursively traverses an error tree.
 * Tree leaves are {message: any} objects.
 * Tree nodes may be arrays or objects.
 * e.g. error = {commodities.0.commodity: {message: "required"}}
 */
const ErrorListItem = ({ error }) => {
  if (Array.isArray(error)) {
    return (
      <>
        {error.map((error) => (
          <ErrorListItem error={error} key={error} />
        ))}
      </>
    );
  } else if (
    typeof error === "object" &&
    !error.message // Avoid iterating over leaf
  ) {
    return (
      <>
        {Object.values(error).map((f) => (
          <ErrorListItem error={f} key={error} />
        ))}
      </>
    );
  } else if (error) {
    return <ListItem>{error.message}</ListItem>;
  } else {
    return null;
  }
};

export default function ErrorList({ errors }) {
  if (Object.keys(errors).length > 0)
    return (
      <Blockquote kind="warning" className={styles.error}>
        <p>
          <FormattedMessage
            id="errorGeneric.error_occured_please_check"
            defaultMessage="An error occured. Please check the following errors"
          />
          :
        </p>
        {errors._error && errors._error.message}
        <List kind="unordered">
          {Object.values(errors).map((field) => (
            <ErrorListItem error={field} key={field} />
          ))}
        </List>
      </Blockquote>
    );
  return null;
}
