import React from "react";
import "./_table-extended.scss";
import styles from "./styles.module.scss";
import classNames from "classnames";

export function TableMobileTitle({ children }) {
  return <div className={styles.tableMobileTitle}>{children}</div>;
}

export default function TableExtended({
  className = "",
  children,
  noBorder = false,
  mobileList = undefined,
}) {
  const classes = classNames("wfp--table wfp--table--extended", className, {
    "wfp--table--no-border": noBorder,
    "wfp--table--mobile-list": mobileList,
    "sync-icon--synced": false,
  });
  return <table className={classes}>{children}</table>;
}
