const objectByString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  try {
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  } catch {
    return undefined;
  }
};

export function localFiltering(filters, setCurrentList, initialList) {
  let filtered = initialList;
  if (filters?.length > 0) {
    filtered = initialList?.filter((item) => {
      const temp = filters?.map((filter) => {
        let item1 = undefined;
        if (filter?.id.includes(".")) {
          if (typeof objectByString(item, filter?.id) === "boolean") {
            item1 = objectByString(item, filter?.id)?.toString().toLowerCase();
          } else {
            item1 = objectByString(item, filter?.id)?.toLowerCase();
          }
        } else {
          if (typeof objectByString(item, filter?.id) === "boolean") {
            item1 = item[filter.id]?.toString().toLowerCase();
          } else {
            item1 = item[filter.id]?.toLowerCase();
          }
        }
        const item2 = filter.value?.toLowerCase();
        if (item2 === "all") {
          return true;
        } else {
          return item1?.includes(item2);
        }
      });
      return temp?.filter((item) => item === true).length === filters?.length;
    });
    setCurrentList(filtered);
  } else if (filters?.length === 0) {
    filtered = initialList;
    setCurrentList(initialList);
  }
  return filtered;
}

export function localPaginate(pageSize, idx, size, array) {
  const newArray = [];
  for (let i = 0; i < pageSize; i++) {
    const index = idx * size - size + i;
    if (array[index]) {
      newArray.push(array[index]);
    }
  }
  return newArray;
}
