import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Module,
  ModuleBody,
  ModuleFooter,
  ModuleHeader,
  Wrapper,
  Button,
} from "@wfp/ui";

import {
  requestAllDistributionReports,
  getAllDistributionReports,
} from "data-handler/ducks/distributionReports";

import { valueLinkCell } from "components/CountryAdmin/CountryAdminContent/Table/Utils";
import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";

const AllDistributionReportProfiles = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const distributionReports = useSelector(getAllDistributionReports);

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filtersState, setFilters] = useState([]);

  const { count = 0, ids = [] } = distributionReports;
  const data = ids.map((id) => distributionReports.results[id]);

  const filterFields = [
    {
      label: intl.formatMessage({
        id: "CountryAdmin.partnershipId",
        defaultMessage: "Partnership",
      }),
      name: "partnership_code",
    },
    {
      label: intl.formatMessage({
        id: "CountryAdmin.partner",
        defaultMessage: "Partner",
      }),
      name: "partner_name",
    },
    {
      label: intl.formatMessage({
        id: "CountryAdmin.activityCode",
        defaultMessage: "Activity code",
      }),
      name: "activity_code",
    },
    {
      label: intl.formatMessage({
        id: "CountryAdmin.location",
        defaultMessage: "Location",
      }),
      name: "location_name",
    },
    // TODO: Add distribution site when more information is given.
    // {
    //   label: intl.formatMessage({
    //     id: "CountryAdmin.distributionSite",
    //     defaultMessage: "Distribution Site",
    //   }),
    //   name: "distribution_site",
    // },
    {
      label: intl.formatMessage({
        id: "CountryAdmin.distributionReportStartDateWithoutTime",
        defaultMessage: "Start date (without time)",
      }),
      name: "start_date",
    },
    {
      label: intl.formatMessage({
        id: "CountryAdmin.distributionReportEndDateWithoutTime",
        defaultMessage: "End date (without time)",
      }),
      name: "end_date",
    },
    {
      label: intl.formatMessage({
        id: "CountryAdmin.country",
        defaultMessage: "Country",
      }),
      name: "country_name",
    },
    {
      label: intl.formatMessage({
        id: "CountryAdmin.schoolName",
        defaultMessage: "School name",
      }),
      name: "school_name",
    },
  ];

  const filterProps = {
    fields: filterFields,
    onSubmit: (e) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
    defaults: filtersState.reduce((a, x) => ({ ...a, [x.id]: x.value }), {}),
  };

  const dateFormatter = (value) => {
    const splittedDate = value.split("T");
    const date = splittedDate[0];
    const time = splittedDate[1];
    return `${date} ${time}`;
  };

  const locationNameFormatter = (value) => {
    return `${value.admin_breakdown_name} ${value.settlement_english_name} ${value.point_of_interest_english_name}`;
  };

  const numberOfSchoolsFormatter = (value) => {
    return value?.length;
  };

  const columns = useMemo(() => {
    const allColumns = [
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.partnershipId",
          defaultMessage: "Partnership",
        }),
        accessor: "partnership.code",
        Cell: ({ cell: { row, value } }) =>
          valueLinkCell(
            value,
            `/countryAdmin/distributionReport/allDistributionReportProfiles/${row["original"]["id"]}/`
          ),
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.partner",
          defaultMessage: "Partner",
        }),
        accessor: "partner.name",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.location",
          defaultMessage: "Location",
        }),
        accessor: "location",
        Cell: ({ cell: { row } }) =>
          locationNameFormatter(row["original"]["location"]),
      },
      // TODO: Add distribution site when more information is given.
      // {
      //   Header: intl.formatMessage({
      //     id: "CountryAdmin.distributionSite",
      //     defaultMessage: "Distribution Site",
      //   }),
      //   accessor: "distribution_site",
      // },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.distributionReportStartDate",
          defaultMessage: "Start date",
        }),
        accessor: "start_date",
        Cell: ({ cell: { row } }) =>
          dateFormatter(row["original"]["start_date"]),
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.distributionReportEndDate",
          defaultMessage: "End date",
        }),
        accessor: "end_date",
        Cell: ({ cell: { row } }) => dateFormatter(row["original"]["end_date"]),
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.country",
          defaultMessage: "Country",
        }),
        accessor: "country.name",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.numberOfSchools",
          defaultMessage: "Number of schools",
        }),
        accessor: "schools",
        Cell: ({ cell: { row } }) =>
          numberOfSchoolsFormatter(row["original"]["schools"]),
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.activityCode",
          defaultMessage: "Activity code",
        }),
        accessor: "location.activity_code",
      },
    ];

    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPage = (size, idx, ordering, filters = filtersState) => {
    setPageSize(size);
    setPageNum(idx);
    dispatch(requestAllDistributionReports(size, idx, ordering, filters));
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
  };

  return (
    <div className="country-admin-main-content schools-table">
      <Wrapper
        pageWidth="lg"
        spacing="md"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleHeader>
            <FormattedMessage
              id="countryAdmin.allDistributionReports"
              defaultMessage="All Distribution Reports"
            />
          </ModuleHeader>
          <ModuleBody>
            <Filtering {...filterProps} />
            <Table {...tableProps} showSort />
          </ModuleBody>
          <ModuleFooter>
            <Link exact="true" to={`/countryAdmin/distributionReport/create`}>
              <Button>
                {
                  <FormattedMessage
                    id="countryAdmin.createDistributionReport"
                    defaultMessage="Create new Distribution Report"
                  />
                }
              </Button>
            </Link>
          </ModuleFooter>
        </Module>
      </Wrapper>
    </div>
  );
};

export default AllDistributionReportProfiles;
