import React from "react";
import PropTypes from "prop-types";
import { FormProvider } from "react-hook-form";

import {
  FormWizard,
  FormControls,
  StepNavigation,
  StepNavigationItem,
  Button,
} from "@wfp/ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

export default class Wizard extends React.Component {
  /**
   * Template for the Wizard component
   * The parent component using this template needs to implement the next(), previous() and onSubmit() functions
   * Examples of already existing parents component that implement this template:
   * - src/components/CountryAdmin/../../../DistributionReportsWizard.js
   * - src/components/TakeHomeRations/TaheHomeRationsWizard.js
   */

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      values: props.initialValues || {},
    };
  }

  render() {
    const {
      children,
      nextDisabled = false,
      history,
      closeButtonRedirectUrl,
    } = this.props;
    const { currentPage } = this.state;
    const currentPageValue = currentPage + 1;
    const activePage = React.Children.toArray(children)[currentPage];
    const totalPages = React.Children.count(children);
    const isLastPage = currentPageValue === totalPages;
    const methods = this.props.methods;
    const { handleSubmit } = methods;

    const header = (
      <div className="container">
        <div>
          Step: {currentPageValue}/{totalPages} {activePage.props.label}
        </div>
        {closeButtonRedirectUrl && history && (
          <div>
            <Button
              small
              kind="ghost"
              onClick={() => {
                history.push(closeButtonRedirectUrl);
              }}
              icon={<FontAwesomeIcon icon={faTimes} />}
            ></Button>
          </div>
        )}
      </div>
    );

    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <FormWizard
            stickySidebar
            formHeader={header}
            formControls={
              <FormControls
                onPreviousClick={this.previous}
                previousHidden={currentPage > 0 ? false : true}
                nextHidden={isLastPage}
                nextDisabled={nextDisabled}
                submitHidden={!isLastPage}
                onNextClick={handleSubmit(this.next)}
                onSubmitClick={handleSubmit(this.onSubmit)}
              />
            }
            sidebar={
              <div className="wfp--step-navigation-no-click">
                <StepNavigation selectedPage={currentPage}>
                  {React.Children.map(children, (child, i) => {
                    return (
                      <StepNavigationItem
                        currentPage={i}
                        status={currentPage >= i ? "complete" : "not-started"}
                        {...child.props}
                      />
                    );
                  })}
                </StepNavigation>
              </div>
            }
          >
            {/* Adding the wfp--form-long class is important */}
            <div className="wfp--form-long">{activePage}</div>
          </FormWizard>
        </form>
      </FormProvider>
    );
  }
}
