import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import bcrypt from "bcryptjs";

export const LOCAL_AUTH_LOGIN = "schoolconnect/localAuth/LOCAL_AUTH_LOGIN";
export const LOCAL_AUTH_LOGOUT = "schoolconnect/localAuth/LOCAL_AUTH_LOGOUT";
export const LOCAL_AUTH_UPDATE_USER =
  "schoolconnect/localAuth/LOCAL_AUTH_UPDATE_USER";
export const LOCAL_AUTH_UPDATE_PASSCODE =
  "schoolconnect/localAuth/LOCAL_AUTH_UPDATE_PASSCODE";
export const LOCAL_AUTH_RESET_PASSCODE =
  "schoolconnect/localAuth/LOCAL_AUTH_RESET_PASSCODE";
export const LOCAL_AUTH_DELETE_USER =
  "schoolconnect/localAuth/LOCAL_AUTH_DELETE_USER";
export const LOCAL_AUTH_LOCAL_LOGIN =
  "schoolconnect/localAuth/LOCAL_AUTH_LOCAL_LOGIN";

const initialState = { users: {}, localLogin: false };

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOCAL_AUTH_LOGIN:
      return {
        ...state,
        ...action.data,
      };
    case LOCAL_AUTH_LOGOUT:
      return initialState;
    case LOCAL_AUTH_UPDATE_USER:
      const { role } = action.data.values;
      let values = {};

      if (role) {
        values = {
          ...action.data.values,
          passcode: state.users[action.data.id]
            ? state.users[action.data.id].passcode
            : undefined,
          groups: [],
        };
      }

      if (Object.keys(values).length > 0) {
        return {
          ...state,
          users: { ...state.users, [action.data.id]: values },
        };
      } else {
        return state;
      }
    case LOCAL_AUTH_UPDATE_PASSCODE:
      let hash = false;
      if (action.data.passcode) hash = bcrypt.hashSync(action.data.passcode, 8);

      return {
        ...state,
        users: {
          ...state.users,
          [action.data.id]: {
            ...state.users[action.data.id],
            passcode: hash,
          },
        },
      };
    case LOCAL_AUTH_RESET_PASSCODE:
      return {
        ...state,
        users: {
          ...state.users,
          [action.data]: {
            ...state.users[action.data],
            passcode: undefined,
          },
        },
      };

    case LOCAL_AUTH_DELETE_USER:
      let tempState = state;
      delete tempState.users[action.data];
      return tempState;

    case LOCAL_AUTH_LOCAL_LOGIN:
      return {
        ...state,
        localLogin: true,
      };

    default:
      return state;
  }
}

const persistedReducer = persistReducer(
  {
    key: "localAuth",
    storage,
    blacklist: ["error", "errorResponse", "fetching"],
  },
  reducer
);

export default persistedReducer;

export const login = (data) => ({
  type: LOCAL_AUTH_LOGIN,
  data,
});
export const logout = () => ({
  type: LOCAL_AUTH_LOGOUT,
});
export const updateUser = (data) => ({
  type: LOCAL_AUTH_UPDATE_USER,
  data,
});
export const updatePasscode = (data) => ({
  type: LOCAL_AUTH_UPDATE_PASSCODE,
  data,
});
export const resetPasscode = (data) => ({
  type: LOCAL_AUTH_RESET_PASSCODE,
  data,
});
export const deleteUser = (data) => ({
  type: LOCAL_AUTH_DELETE_USER,
  data,
});
export const localLogin = () => ({
  type: LOCAL_AUTH_LOCAL_LOGIN,
});

export const getLocalUsers = (state) => state.localAuth.users;
export const getLocalUsersList = (state) =>
  Object.entries(state.localAuth.users).map((e) => ({
    id: e[0],
    ...e[1],
  }));
export const getLocalUserById = (state, id) => state.localAuth.users[id];
