import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import ButtonRouter from "components/ButtonRouter";

import { Modal } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

const DeliveriesModal = ({ state, setState }) => {
  const intl = useIntl();
  const { schoolId } = useParams();

  return (
    <Modal
      open={state}
      modalHeading={intl.formatMessage({
        id: "DeliveriesSidebar.newDelivery",
        defaultMessage: "New delivery",
      })}
      onSecondarySubmit={() => setState(false)}
      onRequestClose={() => setState(false)}
      passiveModal={true}
    >
      <div className="deliveries-row">
        <ButtonRouter
          kind="primary"
          iconReverse
          icon={<FontAwesomeIcon icon={faPlus} />}
          to={`/school/${schoolId}/deliveries/undefined/new-delivery?qrCode=true`}
          extraOnClick={() => setState(false)}
        >
          <FormattedMessage
            id="QrCodeReader.qrCodeLabel"
            defaultMessage="QR Code"
          />
        </ButtonRouter>
        <ButtonRouter
          kind="primary"
          iconReverse
          icon={<FontAwesomeIcon icon={faPlus} />}
          to={`/school/${schoolId}/deliveries/undefined/new-delivery`}
          extraOnClick={() => setState(false)}
        >
          <FormattedMessage
            id="QrCodeReader.manualLabel"
            defaultMessage="Manual Entry"
          />
        </ButtonRouter>
      </div>
    </Modal>
  );
};

export default DeliveriesModal;
