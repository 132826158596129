import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Controller, FormProvider } from "react-hook-form";

import { Blockquote, InlineLoading, TextArea } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";
import SignatureInput from "components/SignatureInput";

import { getCurrentUser } from "data-handler/ducks/auth";
import { getFilteredReportSignatures } from "data-handler/ducks/reportSignatures";
import { store } from "data-handler/store";

import { getIsPrincipal } from "helpers/users";

import useReportAction from "./useReportAction";

const MODAL_LABEL = (
  <FormattedMessage id="ReportActionSubmit.label" defaultMessage="Reporting" />
);
const MODAL_HEADING = (period) => (
  <FormattedMessage
    id="ReportActionSubmit.heading"
    defaultMessage="Submit Report {period}"
    values={{ period }}
  />
);
const BUTTON_LABEL = (
  <FormattedMessage
    id="ReportActionSubmit.buttonLabel"
    defaultMessage="Submit"
  />
);
const EXPLANATION_1 = (
  <FormattedMessage
    id="ReportActionSubmit.explanation1"
    defaultMessage="All necessary school staff have signed."
  />
);
const EXPLANATION_2 = (
  <FormattedMessage
    id="ReportActionSubmit.explanation2"
    defaultMessage="You may now submit the report."
  />
);
const SUBMITTING_REPORT_AS = (
  <FormattedMessage
    id="ReportActionSubmit.whichUser"
    defaultMessage="Submitting report as"
  />
);
const COMMENTS_FIELD_LABEL = (
  <FormattedMessage
    id="ReportActionSubmit.commentsLabel"
    defaultMessage="Comments (optional)"
  />
);

const SIGNATURE_FIELD_LABEL = (
  <FormattedMessage
    id="ReportActionSubmit.signatureLabel"
    defaultMessage="Signature"
  />
);

const ReportModalSubmit = ({ report }) => {
  const currentUser = useSelector(getCurrentUser);

  const {
    actionFeedback,
    closeModal,
    form,
    performAction,
    isPerforming,
    period,
  } = useReportAction({
    report,
    action: "submit",
  });

  const localSignatures = useSelector(
    getFilteredReportSignatures({
      reportMonth: report.month,
      reportYear: report.year,
      reportStartDay: report.start_day,
      reportEndDay: report.end_day,
    })
  );

  let defaultSignature = null;
  const offlineUsers = store.getState().localAuth.users;
  for (let localSignature of localSignatures) {
    if (
      localSignature.userId in offlineUsers &&
      getIsPrincipal(offlineUsers[localSignature.userId])
    ) {
      defaultSignature = localSignature.signature;
    }
  }

  return (
    <ModalExtended
      onRequestSubmit={performAction}
      onRequestClose={closeModal}
      modalLabel={MODAL_LABEL}
      modalHeading={MODAL_HEADING(period)}
      primaryButtonText={isPerforming ? <InlineLoading /> : BUTTON_LABEL}
    >
      {actionFeedback}
      {localSignatures.length === 0 && (
        <Blockquote kind="error">
          <FormattedMessage
            id="SignatureWarning.missingStorekeeper"
            defaultMessage="The storekeeper has not signed the report yet. Do you want to submit this report without the storekeeper's signature? You may still submit, but the storekeeper will not be able to sign after you submit this report."
          />
        </Blockquote>
      )}
      {localSignatures.length === 1 && !localSignatures[0].signature && (
        <Blockquote kind="error">
          <FormattedMessage
            id="SignatureWarning.invalidStorekeeperSignature"
            defaultMessage="The storekeeper has signed the report, but the signature seems to be invalid. You may still submit, but the storekeeper's signature will not be saved."
          />
        </Blockquote>
      )}
      <Blockquote title={SUBMITTING_REPORT_AS}>
        {currentUser.other_names} {currentUser.last_name}
      </Blockquote>

      {localSignatures.length !== 0 && (
        <>
          <div style={{ marginTop: 16 }}>{EXPLANATION_1}</div>
          <div style={{ marginTop: 16, marginBottom: 16 }}>{EXPLANATION_2}</div>
        </>
      )}
      <FormProvider {...form}>
        <form>
          <div className="wfp--form wfp--form-long">
            <Controller
              as={<TextArea labelText={COMMENTS_FIELD_LABEL} />}
              defaultValue=""
              name={`comments`}
            />
            <Controller
              as={<SignatureInput labelText={SIGNATURE_FIELD_LABEL} />}
              defaultValue={defaultSignature}
              name={`signature`}
            />
          </div>
        </form>
      </FormProvider>
    </ModalExtended>
  );
};

export default ReportModalSubmit;
