/**
 * File to fetch links from BE
 * Currently using links for Dashboard / Raw Data Extraction
 */
import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const GET_LINKS_REQUEST = "GET_LINKS_REQUEST";
const GET_LINKS_SUCCESS = "GET_LINKS_SUCCESS";
const FAILURE = "FAILURE";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  dashboard: [],
  rawDataExtraction: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LINKS_REQUEST:
      return { ...state, fetching: true, error: null };
    case GET_LINKS_SUCCESS:
      const dashboard = action.data.dashboard;
      const rawDataExtraction = action.data.rawDataExtraction;
      const data = {
        ...state,
        fetching: false,
        error: null,
      };
      let newData = {};
      if (dashboard.length > 0) {
        newData = { ...data, dashboard };
      } else if (rawDataExtraction.length > 0) {
        newData = { ...data, rawDataExtraction };
      }
      return newData;
    case FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}

const persistedReducer = persistReducer(
  {
    key: "links",
    storage,
    blacklist: ["error", "errorResponse", "fetching"],
  },
  reducer
);

export default persistedReducer;

// Action
export const requestLinks = (filters) => ({
  type: GET_LINKS_REQUEST,
  filters,
});

// Saga
export function* getLinksSagaWatcher() {
  yield takeEvery(GET_LINKS_REQUEST, linksSagaWorker);
}

function fetchLinks(action) {
  const { name, type, internal } = action.filters;

  const url = `${process.env.REACT_APP_API_URL}/link/`;

  return axios({
    method: "GET",
    url: url,
    params: { name, type, internal },
  });
}

function* linksSagaWorker(payload) {
  try {
    const response = yield call(fetchLinks, payload);

    const dashboard = response.data.filter((item) => item.type === "dashboard");
    const rawDataExtraction = response.data.filter(
      (item) => item.type === "raw_data_extraction"
    );

    yield put({
      type: GET_LINKS_SUCCESS,
      data: { dashboard, rawDataExtraction },
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: FAILURE, error });
  }
}

export const getDashboardLinks = (state) => state.links.dashboard;
export const getRawDataExtractionLinks = (state) =>
  state.links.rawDataExtraction;
