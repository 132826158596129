import React, { memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { TextInput, Checkbox, Button } from "@wfp/ui";
import { iconHeaderSearch } from "@wfp/icons";
import { Icon, InlineLoading } from "@wfp/ui";

import SubTitle from "components/SubTitle";

const FormFillingSelection = ({
  intl,
  manualOrAutomaticFilling,
  setManualOrAutomaticFilling,
  distributionReportId,
  setDistributionReportId,
  openIdInput,
  setOpenIdInput,
  fetchDistributionReportHeaderData,
  loading,
}) => {
  const manualCheckboxOnChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setManualOrAutomaticFilling("manual");
        setOpenIdInput(false);
        setDistributionReportId(undefined);
      } else {
        setManualOrAutomaticFilling(undefined);
      }
    },
    [setManualOrAutomaticFilling, setOpenIdInput, setDistributionReportId]
  );

  const automaticCheckboxOnChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setOpenIdInput(true);
      } else {
        setManualOrAutomaticFilling(undefined);
        setOpenIdInput(false);
        setDistributionReportId(undefined);
      }
    },
    [setManualOrAutomaticFilling, setOpenIdInput, setDistributionReportId]
  );

  const searchButtonOnClick = useCallback(() => {
    fetchDistributionReportHeaderData(distributionReportId);
  }, [fetchDistributionReportHeaderData, distributionReportId]);

  const textInputOnChange = useCallback(
    (e) => {
      setDistributionReportId(e.target.value);
    },
    [setDistributionReportId]
  );
  return (
    <div className="row distibutionReportProfile filling-selection-container">
      <SubTitle>
        <FormattedMessage
          id="distributionReport.manualOrAutomaticFillingLabel"
          defaultMessage="How would you like to fill the form?"
        />
      </SubTitle>
      <div className="filling-choiches-row">
        <Checkbox
          id="manual-checkbox"
          labelText={
            <FormattedMessage
              id="distributionReport.manualFilling"
              defaultMessage="Manually"
            />
          }
          checked={manualOrAutomaticFilling === "manual"}
          onChange={manualCheckboxOnChange}
        />
        <div className="automatic-filling-container">
          <Checkbox
            id="automatic-checkbox"
            labelText={
              <FormattedMessage
                id="distributionReport.automaticFilling"
                defaultMessage="Automatically"
              />
            }
            checked={
              manualOrAutomaticFilling === "automatic" || openIdInput === true
            }
            onChange={automaticCheckboxOnChange}
          />
          {openIdInput && (
            <div className="distribution-id-input">
              <TextInput
                placeholder={intl.formatMessage({
                  id: "distributionReport.distributionReportIdLabel",
                  defaultMessage: "Distribution report code",
                })}
                defaultValue={distributionReportId}
                onChange={textInputOnChange}
              />
              <Button
                icon={!loading && <Icon icon={iconHeaderSearch} />}
                onClick={searchButtonOnClick}
                disabled={loading}
              >
                {loading && <InlineLoading />}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(FormFillingSelection);
