import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { faQuestionSquare } from "@fortawesome/pro-light-svg-icons";

import {
  Button,
  Module,
  ModuleBody,
  List,
  ListItem,
  InlineLoading,
} from "@wfp/ui";

import { getReportedStores } from "helpers/report";

import CommodityShow from "components/CommodityShow";
import SubTitle from "components/SubTitle";
import Empty from "components/Empty";
import MainContent from "components/MainContent";
import DeleteModalButton from "components/DeleteModalButton";
import {
  getCurrentSchoolYear,
  getPreviousSchoolYears,
  getSchoolYearByDate,
} from "data-handler/ducks/stores";
import "./_incident-show.scss";
import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";
import { getIsSyncableLocked } from "../../data-handler/ducks/stores";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";
import { getModelDateFormat } from "components/UneditableMessage";

const IncidentShow = ({ currentStoreData }) => {
  const { schoolId, item } = useParams();

  const reportedStores = useSelector((state) => getReportedStores(state));
  const incidentCauses = useSelector(incidentCausesDuck.getList);
  const isReported = reportedStores[item];

  const incidentDateLabel = moment(currentStoreData.occurred_at).format(
    "dddd, DD.MM.YYYY"
  );
  const isLocked = useSelector((state) =>
    getIsSyncableLocked(state, currentStoreData)
  );
  const selectedIncidentSchoolYear = useSelector(
    getSchoolYearByDate(
      getModelDateFormat(currentStoreData) || moment().format("YYYY-MM-DD")
    )
  );

  const currentYear = useSelector((state) => getCurrentSchoolYear(state)); //users current year
  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  // TODO: define app URLs in a single place, this is unmaintainable
  const editUrl = `/school/${schoolId}/deliveries/${item}/incident`;

  if (!currentStoreData) {
    return (
      <Empty
        kind="section"
        icon={<FontAwesomeIcon icon={faQuestionSquare} size="1x" />}
        title="Incidents"
        button={
          <Gate forbidRoles={[UserPermissionRoles.is_viewer]}>
            <NavLink to={editUrl}>
              {" "}
              <Button
                kind="accent"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={isReported}
              >
                <FormattedMessage
                  id="IncidentShow.provideIncidents"
                  defaultMessage="Provide incidents"
                />
              </Button>{" "}
            </NavLink>
          </Gate>
        }
      >
        <FormattedMessage
          id="IncidentShow.noIncidentsToday"
          defaultMessage="No incidents today"
        />
      </Empty>
    );
  }

  const causes = currentStoreData?.causes?.map((cause) =>
    incidentCauses?.find((e) => e?.id === cause)
  );

  const isInitialStock = currentStoreData?.is_initial_stock_incident
    ? true
    : false;

  if (!causes) {
    return <InlineLoading />;
  }

  return (
    <MainContent title={incidentDateLabel} className="deliveries-content">
      <SubTitle
        additional={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedIncidentSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears[0] !== selectedIncidentSchoolYear)
            }
          >
            {currentStoreData.type !== "delete" && (
              <DeleteModalButton
                currentStoreData={currentStoreData}
                disabled={isInitialStock}
              />
            )}
            <NavLink to={editUrl} className="edit-nav-link">
              <Button
                kind="secondary"
                disabled={currentStoreData?.is_initial_stock_incident === true}
                icon={<FontAwesomeIcon icon={faChevronRight} />}
              >
                <FormattedMessage
                  id="IncidentShow.editIncident"
                  defaultMessage="Edit incident"
                />
              </Button>
            </NavLink>
          </Gate>
        }
      >
        <FormattedMessage
          id="IncidentShow.incidentFrom"
          defaultMessage="Incident from {date}"
          values={{ date: incidentDateLabel }}
        />
      </SubTitle>
      <Module noMargin>
        <ModuleBody>
          <>
            <List kind="details" colon>
              <ListItem title="Date">{incidentDateLabel}</ListItem>
              <ListItem
                title={
                  <FormattedMessage
                    id="IncidentShow.reasons"
                    defaultMessage="Reasons"
                  />
                }
                className="reason-list"
              >
                {causes.map((cause) => (
                  <>
                    <span key={cause.id} className="reason-list__item">
                      {cause.name}
                    </span>
                  </>
                ))}
                {currentStoreData.causes.length === 0 && (
                  <span className="reason-list__item">
                    <FormattedMessage
                      id="Common.noReason"
                      defaultMessage="No reason"
                    />
                  </span>
                )}
              </ListItem>

              {currentStoreData.other_cause && (
                <ListItem
                  title={
                    <FormattedMessage
                      id="IncidentShow.other_cause"
                      defaultMessage="Other reason"
                    />
                  }
                >
                  {currentStoreData.other_cause}
                </ListItem>
              )}
            </List>
          </>
        </ModuleBody>
      </Module>

      <CommodityShow
        title={
          <FormattedMessage
            id="IncidentShow.affectedCommodities"
            defaultMessage="Affected commodities"
          />
        }
        emptyTitle={
          <FormattedMessage
            id="IncidentShow.noAffectedCommodities"
            defaultMessage="No affected commodities"
          />
        }
        model={currentStoreData.model}
        commodities={currentStoreData.commodities}
      />
    </MainContent>
  );
};

export default IncidentShow;
