import { getRoles } from "data-handler/ducks/roles";
import { useSelector } from "react-redux";
import { CountryAdminLabel } from "SCConstants";

/**
 *
 * @param {list} roles
 * @param {list} roleMapping
 * @param {string} language
 * @param {boolean} isUserAdmin "This is the boolean on the user is_admin"
 * @returns List
 *
 * Do not pass current User Role if global Administrator should not be an item in the list
 * Example: Global admin should not be customised therefore should not be in the list and no is_admin boolean is passed.
 */
export const generateCustomRoleList = (
  roles,
  roleMapping,
  language,
  isUserAdmin = false
) =>
  roles
    ?.filter((item) =>
      isUserAdmin ? true : item.type !== "global_administrator"
    )
    ?.map((item) => {
      const roleMappingObject = roleMapping?.find((e) => e.role.id === item.id);
      return {
        role: item.default_name,
        custom_name: roleMappingObject?.custom_name,
        id: item.id,
        type: item.type,
      };
    });

/**
 * This function returns the correct label for a user role for the row within the user table
 * @param {object} user
 * @returns string
 */
export const GetRoleLabel = (user) => {
  const { roles, countryRoles } = useSelector(getRoles);

  if (user?.user_role) {
    const role = countryRoles.find((item) => item.role.type === user.user_role);
    const defaultName = roles.find((item) => item.type === user.user_role)
      ?.default_name;
    const roleLabel =
      role?.custom_name && role?.custom_name !== ""
        ? role?.custom_name
        : defaultName;

    return roleLabel;
  } else {
    return CountryAdminLabel.find((item) => item.name === "countryAdmin.noRole")
      .label;
  }
};
