import React, { useMemo, useState } from "react";
import Table from "../../Table/Table";
import { CountryAdminLabel } from "SCConstants";

export default ({ schools }) => {
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const paginate = (schools) => {
    const paginatedList = [];
    var i;
    for (i = 0; i < pageSize; i++) {
      const page = pageNum * pageSize - pageSize + i;
      if (schools[page] !== undefined) {
        paginatedList.push(schools[page]);
      }
    }
    return paginatedList;
  };

  let data = schools ? paginate(schools) : [];

  const columns = useMemo(() => {
    return [
      {
        Header: CountryAdminLabel.find((e) => e.name === "name").label,
        accessor: "name",
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "region").label,
        accessor: "region.name",
      },
    ];
  }, []);

  const loadPage = (size, idx) => {
    setPageSize(size);
    setPageNum(idx);
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: schools?.length,
    loadPage,
  };
  return (
    <div>
      <Table {...tableProps} showSort />
    </div>
  );
};
