import React from "react";
import withSizes from "react-sizes";
import { useSelector } from "react-redux";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import * as R from "ramda";

import { TextInput, Blockquote } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";
import DateInput from "components/DateInput";
import TableExtended from "components/TableExtended";
import CompactNumberInput from "components/CompactNumberInput";
import LevelTitle from "components/LevelTitle";

import {
  getCurrentSchoolYear,
  checkEnrolmentUpdateIsUniquePerDate,
  getPreviousSchoolYears,
  getCurrentOrLatestSchoolYear,
} from "data-handler/ducks/stores";

import { studentKinds } from "SCConstants";

import { mapSizesToProps } from "helpers/mapSizesToProps";

import { getFilteredEnrolmentTotalOnDate } from "helpers/enrolment";
import useStore from "helpers/useStore";

import AddLevel from "./AddLevel";
import styles from "./styles.module.scss";
import {
  currentSchoolYearDateValidation,
  ERROR_MESSAGES_INCIDENTS,
  isReportApprovedOrSubmitted,
  isReportClosedOrRejected,
  schoolYearDateValidation,
} from "components/IncidentEdit";
import { isEmpty } from "lodash";
import ErrorList from "components/ErrorList";

const modalTitleLabel = (
  <FormattedMessage id="EnrolmentEdit.reporting" defaultMessage="Reporting" />
);

const modalTitleEditEnrolmentUpdate = (
  <FormattedMessage
    id="EnrolmentEdit.edit_enrolment_update"
    defaultMessage="Edit enrolment update"
  />
);

const modalTitleAddEnrolmentUpdate = (
  <FormattedMessage
    id="EnrolmentEdit.add_enrolment_update"
    defaultMessage="Add enrolment update"
  />
);

const modalTitleUpdateEnrolment = (
  <FormattedMessage
    id="EnrolmentEdit.update_enrolment"
    defaultMessage="Update enrolment"
  />
);

const modalTitleSaveNewEnrolmentUpdate = (
  <FormattedMessage
    id="EnrolmentEdit.save_new_enrolment_update"
    defaultMessage="Save new enrolment update"
  />
);

const dateOfEnrolmentChangeLabelText = (
  <FormattedMessage
    id="EnrolmentEdit.date_of_enrolment_change"
    defaultMessage="Date of enrolment change"
  />
);

export const ERROR_MESSAGES = {
  missingDate: (
    <FormattedMessage
      id="EnrolmentEditError.missingDate"
      defaultMessage="Enter date"
    />
  ),
  missingValues: (
    <FormattedMessage
      id="EnrolmentEditError.missingValues"
      defaultMessage="Input data for level changes"
    />
  ),
  invalidDropouts: (
    <FormattedMessage
      id="EnrolmentEditError.invalidDropouts"
      defaultMessage="Dropouts number higher than current enrolment number"
    />
  ),
};
/** Handles validation for ALL form values */
const validationResolver = (
  values,
  { state, defaultValues, currentSchoolYear, previousSchoolYears }
) => {
  const errors = {};
  const isSelectedDateInSchoolYearValid = schoolYearDateValidation(
    values.date,
    currentSchoolYear,
    previousSchoolYears
  );
  const isValueInputted = values.levels.find(
    (level) =>
      level.female_dropouts !== "undefined" ||
      level.male_dropouts !== "undefined" ||
      level.female_newcomers !== "undefined" ||
      level.male_newcomers !== "undefined"
  )
    ? true
    : false;

  if (!values.occurred_on) {
    errors[`occurred_on`] = {
      message: ERROR_MESSAGES["missingDate"],
    };
  }
  if (values.occurred_on && !isSelectedDateInSchoolYearValid) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["invalidDate"],
    };
  }

  if (
    values.occurred_on &&
    isReportClosedOrRejected(state, values.occurred_on)
  ) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["reportClosedOrRejected"],
    };
  }
  if (
    values.occurred_on &&
    isReportApprovedOrSubmitted(state, values.occurred_on)
  ) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["reportApprovedOrSubmitted"],
    };
  }
  if (!isValueInputted) {
    errors[`levels`] = {
      message: ERROR_MESSAGES["missingValues"],
    };
  }

  // dropouts validation against current enrolment number
  if (isValueInputted) {
    const tempDate =
      currentSchoolYear?.ends_on ||
      (previousSchoolYears?.length > 0
        ? previousSchoolYears[0].ends_on
        : moment());
    values.levels.forEach((level, index) => {
      // when calculating the current enrolment values per kind for the selected enrolment update,
      // we need to take into consideration the default values on the form (for both dropouts and newcomers),
      // and the current form's values for the newcomers
      const enrolmentOnDatePerKind = studentKinds.reduce((acc, kind) => {
        const defaultValuesKindDropouts = parseFloat(
          R.pathOr(
            0,
            ["levels", `${index}`, `${kind.value}_dropouts`],
            defaultValues
          )
        );
        const defaultValuesKindNewComers = parseFloat(
          R.pathOr(
            0,
            ["levels", `${index}`, `${kind.value}_newcomers`],
            defaultValues
          )
        );
        const valuesKindNewComers =
          R.pathOr(
            0,
            ["levels", `${index}`, `${kind.value}_newcomers`],
            values
          ) === "undefined"
            ? 0
            : parseFloat(
                R.pathOr(
                  0,
                  ["levels", `${index}`, `${kind.value}_newcomers`],
                  values
                )
              );

        acc[kind.value] =
          getFilteredEnrolmentTotalOnDate({
            date: tempDate,
            kinds: [kind.value],
            levels: [level.level],
          })(state) +
          defaultValuesKindDropouts -
          defaultValuesKindNewComers +
          valuesKindNewComers;
        return acc;
      }, {});

      studentKinds.forEach((item) => {
        const kind = item.value;
        if (
          Object.keys(enrolmentOnDatePerKind).includes(kind) &&
          enrolmentOnDatePerKind[kind] <
            (parseFloat(level[`${kind}_dropouts`]) || 0)
        ) {
          errors[`levels[${index}].${kind}_dropouts`] = {
            message: ERROR_MESSAGES["invalidDropouts"],
          };
        }
      });
    });
  }

  return {
    values: !isEmpty(errors) ? {} : values,
    errors,
  };
};

const storeToFormValues = (store) => {
  const _store = JSON.parse(JSON.stringify(store)); // Deep copy
  _store.levels.forEach((level) => {
    studentKinds.forEach((kind) => {
      // cast to str
      level[`${kind.value}_newcomers`] = `${level[`${kind.value}_newcomers`]}`;
    });
    studentKinds.forEach((kind) => {
      // cast to str
      level[`${kind.value}_dropouts`] = `${level[`${kind.value}_dropouts`]}`;
    });
  });
  return _store;
};

const formValuesToStore = (values) => {
  values.levels.forEach((level) => {
    studentKinds.forEach((kind) => {
      // cast to int
      level[`${kind.value}_newcomers`] =
        parseInt(level[`${kind.value}_newcomers`]) || 0;
    });
    studentKinds.forEach((kind) => {
      // cast to int
      level[`${kind.value}_dropouts`] =
        parseInt(level[`${kind.value}_dropouts`]) || 0;
    });
  });
  return values;
};

const EnrolmentEdit = ({ isMobile, currentStoreData }) => {
  const {
    closeModal,
    submitBtnRef,
    submitViaBtnRefClick,
    updateStore,
  } = useStore();
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  const latestSchoolYear = useSelector(getCurrentOrLatestSchoolYear);
  const state = useSelector((state) => state);

  const id = (currentStoreData && currentStoreData.client_id) || undefined;

  // levels that will be shown on the page
  const levelsToShow = {};
  // Add all levels from currentStore
  // This will be available when we edit an already existing enrolment
  if (currentStoreData?.levels) {
    for (const level of currentStoreData.levels) {
      levelsToShow[level.level] = level;
    }
  }

  // Update defaultValues with all latestSchoolYear levels
  if (latestSchoolYear) {
    for (const yearLevel of latestSchoolYear.levels) {
      if (!(yearLevel.level in levelsToShow)) {
        levelsToShow[yearLevel.level] = yearLevel;
      }
    }
  }
  const defaultValues = {
    ...currentStoreData,
    levels: Object.values(levelsToShow),
  };

  const methods = useForm({
    defaultValues: storeToFormValues(defaultValues),
    resolver: validationResolver,
    context: {
      state,
      defaultValues,
      currentSchoolYear,
      previousSchoolYears,
    },
  });
  const { control, handleSubmit, register, watch, errors } = methods;

  const selectedOccurredOn = watch("occurred_on");
  const isEnrolmentUpdateUnique = useSelector(
    checkEnrolmentUpdateIsUniquePerDate(selectedOccurredOn, currentStoreData)
  );
  const isSelectedDateInSchoolYearValid = schoolYearDateValidation(
    selectedOccurredOn,
    currentSchoolYear,
    previousSchoolYears
  );
  const currentSchoolYearDate = currentSchoolYearDateValidation(
    currentSchoolYear,
    previousSchoolYears
  );
  const isErrorListEmpty = isEmpty(errors);

  const onSubmit = (values) => {
    updateStore({
      id: id,
      values: formValuesToStore(values),
      model: "enrolment",
      type: id ? "update" : "create",
    });
  };

  const numberFieldProps = {
    hideLabel: !isMobile,
    placeholder: 0,
    min: 0,
    max: 10000,
  };

  const { fields: levelRows, append } = useFieldArray({
    control,
    name: "levels",
  });

  const addLevel = (label) => {
    const value = { level: label };
    for (const kind of studentKinds) {
      value[`${kind.value}_newcomers`] = 0;
      value[`${kind.value}_dropouts`] = 0;
    }
    append(value);
  };

  /** <tr>s containing level label, and newcomer/dropout cells for each studentKind */
  const levelRowsJSX = levelRows.map((level, i) => (
    <tr key={i}>
      <td className="wfp--table__title">
        <LevelTitle level={level} />
      </td>

      <input
        name={`levels[${i}].level`}
        type="hidden"
        ref={register}
        value={level.level}
      />

      {studentKinds.map((kind) => (
        <td className="wfp--table__show-value">
          <Controller
            as={
              <CompactNumberInput
                {...numberFieldProps}
                labelText={
                  <>
                    <FormattedMessage
                      id="Common.newcomers"
                      defaultMessage="Newcomers"
                    />
                    <FormattedMessage
                      id={`Common.${kind.key}`}
                      defaultMessage={kind.key}
                    />
                  </>
                }
              />
            }
            name={`levels[${i}].${kind.key}_newcomers`}
            control={control}
          />
        </td>
      ))}
      {studentKinds.map((kind) => (
        <td className="wfp--table__show-value">
          <Controller
            as={
              <CompactNumberInput
                {...numberFieldProps}
                labelText={
                  <>
                    <FormattedMessage
                      id="Common.dropouts"
                      defaultMessage="Dropouts"
                    />
                    <FormattedMessage
                      id={`Common.${kind.key}`}
                      defaultMessage={kind.key}
                    />
                  </>
                }
              />
            }
            name={`levels[${i}].${kind.key}_dropouts`}
            control={control}
            invalid={errors[`levels[${i}].${kind.key}_dropouts`]}
          />
        </td>
      ))}
    </tr>
  ));

  return (
    <ModalExtended
      onRequestSubmit={submitViaBtnRefClick}
      onRequestClose={() => closeModal()}
      modalLabel={modalTitleLabel}
      modalHeading={
        id ? modalTitleEditEnrolmentUpdate : modalTitleAddEnrolmentUpdate
      }
      primaryButtonText={
        id ? modalTitleUpdateEnrolment : modalTitleSaveNewEnrolmentUpdate
      }
      primaryButtonDisabled={
        !isEnrolmentUpdateUnique ||
        isReportClosedOrRejected(state, selectedOccurredOn) ||
        isReportApprovedOrSubmitted(state, selectedOccurredOn)
      }
    >
      <FormProvider {...methods}>
        {!isEnrolmentUpdateUnique && (
          <Blockquote kind="warning">
            <p>
              <FormattedMessage
                id="errorGeneric.enrolmentUpdateDateUnique"
                defaultMessage="You can only create one enrolment update per day. To add information for this day, please edit the existing enrolment update."
              />
            </p>
          </Blockquote>
        )}
        {!isSelectedDateInSchoolYearValid && isErrorListEmpty && (
          <Blockquote kind="warning" className="date-not-valid">
            {ERROR_MESSAGES_INCIDENTS.invalidDate}
          </Blockquote>
        )}
        {isReportClosedOrRejected(state, selectedOccurredOn) &&
          isErrorListEmpty && (
            <Blockquote kind="warning" className="date-not-valid">
              {ERROR_MESSAGES_INCIDENTS.reportClosedOrRejected}
            </Blockquote>
          )}
        {isReportApprovedOrSubmitted(state, selectedOccurredOn) &&
          isErrorListEmpty && (
            <Blockquote kind="warning" className="date-not-valid">
              {ERROR_MESSAGES_INCIDENTS.reportApprovedOrSubmitted}
            </Blockquote>
          )}
        <ErrorList errors={errors} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="wfp--form wfp--form-long">
            <Controller
              as={
                <DateInput
                  labelText={dateOfEnrolmentChangeLabelText}
                  min={moment(currentSchoolYearDate.min)
                    .add(1, "days")
                    .format("YYYY-MM-DD")}
                  max={currentSchoolYearDate.max}
                />
              }
              invalid={errors.occurred_on}
              invalidText={errors.occurred_on && errors.occurred_on.message}
              name="occurred_on"
            />
            <div className={styles.comment}>
              <Controller
                as={
                  <TextInput
                    maxLength={200}
                    labelText={
                      <>
                        <FormattedMessage
                          id="EnrolmentEdit.comments"
                          defaultMessage="Comments"
                        />{" "}
                        <FormattedMessage
                          id="Form.optional"
                          defaultMessage="(optional)"
                        />
                      </>
                    }
                  />
                }
                name={`comments`}
              />
            </div>

            <TableExtended className={styles.table}>
              <thead>
                <th></th>
                <th colSpan={studentKinds.length}>
                  <FormattedMessage
                    id="EnrolmentEdit.newcomers"
                    defaultMessage="Newcomers"
                  />
                </th>
                <th colSpan={studentKinds.length}>
                  <FormattedMessage
                    id="EnrolmentEdit.dropouts"
                    defaultMessage="Dropouts"
                  />
                </th>
              </thead>
              <thead>
                <th></th>
                {studentKinds.map((kind) => (
                  <th>{kind.labelTrans}</th>
                ))}
                {studentKinds.map((kind) => (
                  <th>{kind.labelTrans}</th>
                ))}
              </thead>
              <tbody>{levelRowsJSX}</tbody>
            </TableExtended>
            <div className={styles.add_level_container}>
              <AddLevel shownLevels={levelRows} addLevel={addLevel} />
            </div>
          </div>
          <button className="hidden-btn" ref={submitBtnRef} />
        </form>
      </FormProvider>
    </ModalExtended>
  );
};

export default withSizes(mapSizesToProps)(EnrolmentEdit);
