import React from "react";
import moment from "moment";
import { connect } from "react-redux";

const getStaffRank = (user) => {
  if (!user) return false;
  if (user.principal_at) return "principal";
  if (user.teacher_at) return "teacher";
  if (user.store_keeper_at) return "store-keeper";
  return false;
};

function LastEditor({ store, user }) {
  if (!store) return null;
  return (
    <div>
      last edit by:{" "}
      {getStaffRank(user)
        ? getStaffRank(user)
        : `unknown rank (ID: ${user ? user.id : "no ID"})`}{" "}
      {moment(store.last_edit).format("DD.MM.YYYY")}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.users.users.find((e) => {
      return ownProps.store && e.user.id === ownProps.store.last_editor;
    }),
  };
};

export default connect(mapStateToProps)(LastEditor);
