import React from "react";
import { FormattedMessage } from "react-intl";
import { ModuleBody } from "@wfp/ui";

import {
  allLevels,
  studentLevelTotals,
  studentKinds,
  staffLevelTotals,
} from "SCConstants";
import TableExtended from "components/TableExtended";

import styles from "./school-report.module.scss";
import { useSelector } from "react-redux";
import { getLevelAgeMappings } from "data-handler/ducks/ageGroups";

import { orderRowsByLevels } from "helpers/utils";

export const displayStaffLevelsReport = (rows) =>
  rows?.find((level) => level?.level === "staff") ? true : false;
const Absences = ({
  report: {
    aggregates: { absence_rows, absence_totals_rows, enrolment_rows },
  },
}) => {
  absence_rows = orderRowsByLevels(absence_rows);
  const levelMapping = useSelector(getLevelAgeMappings);
  return (
    <ModuleBody className={styles.reportModule}>
      <h5 className={styles.sectionTitle}>
        <FormattedMessage
          id="absences.number_of_absences_title"
          defaultMessage="2. Number of absences by gender and level during the month (source :
                figures from the register)"
        />
      </h5>
      <TableExtended>
        <thead className={styles.reportTHead}>
          <tr>
            <td>
              <span>
                <FormattedMessage id="absences.level" defaultMessage="Level" />
              </span>
            </td>
            {studentKinds.map((kind) => (
              <td key={kind.value}>
                <span>{kind.labelTrans}</span>
              </td>
            ))}
            <td>
              <span>
                <FormattedMessage id="absences.total" defaultMessage="Total" />
              </span>
            </td>
          </tr>
        </thead>
        <tbody>
          {/* Per-level rows */}
          {absence_rows.map((row, i) => {
            const customName = levelMapping.find(
              (item) => item.level === row.level
            )?.custom_name;
            const defaultName = allLevels.find(
              (item) => item.value === row.level
            )?.label;
            const level =
              customName && customName !== "" ? customName : defaultName;
            return (
              <tr key={i}>
                <td className={styles.headerData}>
                  <span>{level}</span>
                </td>
                {studentKinds.map((kind) => (
                  <td key={kind.value} className={styles.headerData}>
                    <span>{row[kind.value]}</span>
                  </td>
                ))}
                <td className={styles.headerData}>
                  <span>{row.total}</span>
                </td>
              </tr>
            );
          })}
          {/* Totals rows */}
          {absence_totals_rows
            .filter(function (absence_totals_row) {
              if (
                !enrolment_rows.some((e) => e.level === "staff") &&
                absence_totals_row.name === "staff"
              ) {
                return false;
              } else {
                return true;
              }
            })
            .map((row, i) => (
              <tr key={i}>
                <td className={styles.headerData}>
                  <span className={styles.boldText}>
                    {
                      studentLevelTotals.find((total) => total.key === row.name)
                        ?.title
                    }
                  </span>
                  {displayStaffLevelsReport(enrolment_rows) && (
                    <span className={styles.boldText}>
                      {
                        staffLevelTotals?.find(
                          (staffTotal) => staffTotal.key === row.name
                        )?.title
                      }
                    </span>
                  )}
                </td>
                {studentKinds.map((kind) => (
                  <td className={styles.headerData} key={kind.value}>
                    <span className={styles.boldText}>{row[kind.value]}</span>
                  </td>
                ))}
                <td className={styles.headerData}>
                  <span className={styles.boldText}>{row.total}</span>
                </td>
              </tr>
            ))}
        </tbody>
      </TableExtended>
    </ModuleBody>
  );
};

export default Absences;
