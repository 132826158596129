import React from "react";
import { ContentSwitcher, Switch } from "@wfp/ui";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import "./_date-input.scss";

export default function HistoryFilter({ setFromDate }) {
  const onChangeAction = (e) => {
    let fromDate;
    switch (e.name) {
      case "last-week":
        fromDate = moment().subtract(1, "weeks").startOf("day");
        break;
      case "last-month":
        fromDate = moment().subtract(1, "months").startOf("day");
        break;
      case "last-year":
        fromDate = moment().subtract(1, "year").startOf("day");
        break;
      default:
        fromDate = moment().startOf("day");
    }
    setFromDate(fromDate);
  };

  return (
    <ContentSwitcher onChange={onChangeAction} small>
      <Switch
        name="last-year"
        text={
          <FormattedMessage
            id="StockHistory.filterByOneYear"
            defaultMessage="1 year"
          />
        }
        kind="anchor"
        href="#"
      />
      <Switch
        name="last-month"
        text={
          <FormattedMessage
            id="StockHistory.filterByOneMonth"
            defaultMessage="1 month"
          />
        }
      />
      <Switch
        name="last-week"
        text={
          <FormattedMessage
            id="StockHistory.filterByOneWeek"
            defaultMessage="1 week"
          />
        }
      />
    </ContentSwitcher>
  );
}
