/*
 * I added this file to convert schools.js to typescript.
 * schools.js turns out to be too big to convert so I will create this ts version.
 * After whole schools.js is converted this file sould be renamed into schools.ts
 */

import axios, { AxiosResponse } from "axios";
import { RootState } from "data-handler/rootReducer";
import { call, put, takeLatest } from "redux-saga/effects";

export const REQUEST_COMMODITY_SOURCES =
  "schoolconnect/schools/REQUEST_COMMODITY_SOURCES";
export const SUCCESS_COMMODITY_SOURCES =
  "schoolconnect/schools/SUCCESS_COMMODITY_SOURCES";
export const FAILURE_COMMODITY_SOURCES =
  "schoolconnect/schools/FAILURE_COMMODITY_SOURCES";

export const REQUEST_ALL_COMMODITY_SOURCES =
  "schoolconnect/schools/REQUEST_ALL_COMMODITY_SOURCES";
export const SUCCESS_ALL_COMMODITY_SOURCES =
  "schoolconnect/schools/SUCCESS_ALL_COMMODITY_SOURCES";
export const FAILURE_ALL_COMMODITY_SOURCES =
  "schoolconnect/schools/FAILURE_ALL_COMMODITY_SOURCES";

interface FetchCommoditySourcesArgs {
  data: {
    schoolId: number;
  };
}

export type CommoditySource = {
  code: string;
  name: string;
  order: number;
};

export type CommodityIdToSourceMapping = { [commodityId: number]: string[] };

/*
 * CommoditySources
 */
// ***************************************************************************************************************
function fetchCommoditySources(action: FetchCommoditySourcesArgs) {
  const url = `${process.env.REACT_APP_API_URL}/schools/${action.data.schoolId}/commodity-sources/`;
  return axios({
    method: "GET",
    url: url,
  });
}

export function* workerCommoditySourcesSaga(action: FetchCommoditySourcesArgs) {
  try {
    const response: AxiosResponse = yield call(fetchCommoditySources, action);
    const data = {
      ...response.data,
    };
    yield put({ type: SUCCESS_COMMODITY_SOURCES, data });
  } catch (error) {
    yield put({ type: FAILURE_COMMODITY_SOURCES, error });
  }
}

export function* commoditySourcesSagaWatcher() {
  yield takeLatest(
    REQUEST_COMMODITY_SOURCES as any,
    workerCommoditySourcesSaga
  );
}

export const requestCommoditySources = (data: { schoolId: number }) => ({
  type: REQUEST_COMMODITY_SOURCES,
  data: data,
});

export const getCommoditySources = (
  state: RootState
): CommodityIdToSourceMapping => state.schools.commoditySources.data;

export const getCommoditySourcesFetching = (state: RootState): boolean =>
  state.schools.commoditySources.fetching;

export const getCommoditySourcesError = (state: RootState): any =>
  state.schools.commoditySources.error;

// ***************************************************************************************************************

/*
 * AllCommoditySources
 */
// ***************************************************************************************************************
function fetchAllCommoditySources() {
  const url = `${process.env.REACT_APP_API_URL}/schools/all-commodity-sources/`;
  return axios({
    method: "GET",
    url: url,
  });
}

export function* workerAllCommoditySourcesSaga() {
  try {
    const response: AxiosResponse = yield call(fetchAllCommoditySources);
    const data: CommoditySource[] = response.data;
    yield put({ type: SUCCESS_ALL_COMMODITY_SOURCES, data });
  } catch (error) {
    yield put({ type: FAILURE_ALL_COMMODITY_SOURCES, error });
  }
}

export function* allCommoditySourcesSagaWatcher() {
  yield takeLatest(
    REQUEST_ALL_COMMODITY_SOURCES as any,
    workerAllCommoditySourcesSaga
  );
}

export const requestAllCommoditySources = () => ({
  type: REQUEST_ALL_COMMODITY_SOURCES,
});

export const getAllCommoditySources = (state: RootState): CommoditySource[] =>
  state.schools.allCommoditySources.data;

export const getAllCommoditySourcesFetching = (state: RootState): boolean =>
  state.schools.allCommoditySources.fetching;

export const getAllCommoditySourcesError = (state: RootState): any =>
  state.schools.allCommoditySources.error;

// ***************************************************************************************************************
