import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { FormattedMessage } from "react-intl";
import { Button } from "@wfp/ui";
import ModalExtended from "components/ModalExtended";

import "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  requestPrivacyStatement,
  getPrivacyStatement,
} from "data-handler/ducks/statements";
import { getLanguage } from "data-handler/ducks/language";

const PrivacyStatementModal = () => {
  const language = useSelector(getLanguage);
  const history = useHistory();
  const dispatch = useDispatch(language);
  const file = useSelector(getPrivacyStatement);
  const closeWindow = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(requestPrivacyStatement(language));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalExtended
      className="modal-terms-conditions"
      onRequestClose={closeWindow}
      onRequestSubmit={closeWindow}
      modalLabel={
        <FormattedMessage
          id="PrivacyStatement.modalLabel"
          defaultMessage="Privacy Statement"
        />
      }
      wide
      passiveModal
      selectorPrimaryFocus={false}
    >
      <div
        classname="privacy-statement"
        dangerouslySetInnerHTML={{ __html: file }}
      />
      <Button style={{ marginTop: "1.5%" }} onClick={closeWindow}>
        <FormattedMessage id="ModalExtended.cancel" defaultMessage="Cancel" />
      </Button>
    </ModalExtended>
  );
};

export default PrivacyStatementModal;
