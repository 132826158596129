import React from "react";
import StockSidebar from "./StockSidebar";
import StockContent from "./StockContent";
import SidebarWrapper from "components/SidebarWrapper";

const Stock = () => {
  return (
    <>
      <SidebarWrapper>
        <StockSidebar />
      </SidebarWrapper>
      <StockContent />
    </>
  );
};

export default Stock;
