import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { getToken } from "data-handler/ducks/auth";

/**
 * Renders the given `component` if logged in, else a Redirect to login
 */
export default ({ component: Component, ...rest }) => {
  // TODO: check these two consts (they're probably no longer correct)
  const JWTToken = useSelector(getToken);
  const isUserAuthenticated = JWTToken !== undefined;
  const isUserLocalAuthenticated = JWTToken;
  return (
    <Route
      {...rest}
      render={(props) =>
        isUserAuthenticated && isUserLocalAuthenticated ? (
          <Component {...props} {...rest} />
        ) : isUserAuthenticated && !isUserLocalAuthenticated ? (
          <Redirect
            to={{
              pathname: "/locallogin/",
              state: { from: props.location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
