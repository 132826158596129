import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import SidebarItem from "components/SidebarItem";
import CommodityShowSmall from "components/CommodityShowSmall";

export const displayByCommodityId = (id, commodities) => {
  const selectedCommodityId = parseInt(id);
  const commodityToDisplay = commodities.filter(
    (i) => i.commodity === selectedCommodityId
  );
  return commodityToDisplay;
};

export default function PurchaseItem({
  currentStoreData,
  store,
  ...extraProps
}) {
  const selected =
    currentStoreData && currentStoreData.client_id === store.client_id;
  const params = useParams();

  const title = (
    <FormattedMessage id="title.purchase" defaultMessage="Purchase" />
  );

  return (
    <SidebarItem
      {...extraProps}
      store={store}
      selected={selected}
      id={store.client_id}
      title={title}
      to={`/school/${params.schoolId}/deliveries/${store.client_id}`}
      type={store.type}
    >
      <CommodityShowSmall commodities={store?.commodities} />
    </SidebarItem>
  );
}
