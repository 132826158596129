import React from "react";
import { withRouter } from "react-router-dom";
import "./_sidebar.scss";

const SidebarWrapper: React.ElementType<{}> = ({ children }) => (
  <div id="scroll-content" className="sidebar sidebar__active">
    <div className="sidebar__content">{children}</div>
  </div>
);

export default withRouter(SidebarWrapper);
