import React from "react";
import { FormattedMessage } from "react-intl";
import lodash from "lodash";
import classnames from "classnames";
import Select from "react-select";
import { Input } from "@wfp/ui";

import CurrentStock from "components/CurrentStock";

import "./_commodity-view.scss";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { getAllStores } from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";
import {
  getCurrentSchoolProfileOtherCommodities,
  getCurrentSchoolProfileWfpCommodities,
} from "data-handler/ducks/schools";
import { getCommoditiesInStoreByCategory } from "helpers/stock";

export default function CommodityTextView({
  className,
  control,
  invalidOverride,
  invalidTextOverride,
  name,
  value,
  category,
  showStock = false,
}) {
  const params = useParams();
  const allStores = useSelector(getAllStores);
  const commoditiesIndex = useSelector(commoditiesDuck.getIndex);
  const commoditiesListWfpFromSchoolProfile = useSelector(
    getCurrentSchoolProfileWfpCommodities
  );
  const wfpCommodities = getCommoditiesInStoreByCategory(
    allStores,
    commoditiesIndex,
    true,
    category
  );
  const commodityListOther = useSelector(
    getCurrentSchoolProfileOtherCommodities
  );

  const invalid = control
    ? lodash.get(control.errorsRef.current, name)
    : // I was unable to get Commodity to read from custom validation errors
      invalidOverride && { message: invalidTextOverride };

  const classes = classnames(`wfp--form-item commodity-select`, className, {
    [`wfp--text-input--invalid`]: invalid,
  });

  const commodityListWfp =
    params.details === "new-year" || params.details === "year"
      ? lodash.uniqBy(
          commoditiesListWfpFromSchoolProfile.concat(wfpCommodities),
          "id"
        )
      : params.details === "new-delivery" ||
        params.details === "delivery" ||
        params.details === "purchase-detail" ||
        params.details === "new-purchase"
      ? commoditiesListWfpFromSchoolProfile
      : wfpCommodities;

  const commodityListAll = commodityListWfp.concat(commodityListOther);

  const option =
    commodityListAll.find((item) => item.id === value) ||
    params.details === "delivery" ||
    params.details === "purchase-detail"
      ? commodityListAll.find((item) => item.id === value)
      : undefined;

  const defaultValue = option
    ? {
        value: option && option.id,
        label: option ? option.name : "label missing",
        category: category,
      }
    : undefined;

  const customStyles = (showStock, invalid) => ({
    option: (provided, state) => {
      return {
        ...provided,
        color:
          showStock &&
          state.data?.stock &&
          parseFloat(state.data?.stock) > 0 &&
          !state.isSelected
            ? "#689e18"
            : "inherit",
      };
    },
    control: (styles) =>
      invalid === false || invalid === undefined
        ? { ...styles, border: "1px solid #8ca4b5" }
        : { ...styles, borderColor: "#C5192D !important" },
  });

  return (
    <Input
      formItemClassName={classes}
      labelText={
        <FormattedMessage
          id="CommoditySelect.commodityInputLabel"
          defaultMessage="Commodity"
        />
      }
      name={name}
      invalid={invalid}
      invalidText={invalid && invalid.message}
    >
      {() => (
        <div
          className="wfp--input-wrapper commodity-text-view"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="commodity-select__input">
            <div className="wfp--react-select--view-container">
              <div className="wfp--react-select__value-container">
                <div className="wfp--react-select__single-value">
                  {defaultValue?.label}

                  {showStock && (
                    <div className="commodity-select__stock">
                      <CurrentStock
                        commodityId={defaultValue?.value}
                        category={category}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "none" }}>
            <Select styles={customStyles(true, invalid)} />
          </div>
        </div>
      )}
    </Input>
  );
}
