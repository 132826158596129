import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, TextInput, InlineLoading, Select, SelectItem } from "@wfp/ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

import { getLoginState } from "data-handler/ducks/auth";
import { getIsInternal } from "helpers/users";

import {
  requestCountryAuthorityTypes,
  requestEducationAuthorities,
  getCountryAuthorityTypes,
  getEducationAuthoritiesByAuthorityType,
} from "../../../../data-handler/ducks/authorityLevels";

import PageTitle from "components/PageTitle";

import styles from "components/Registration/registration.module.scss";
import "../_content.scss";

import { languagesOptions } from "SCConstants";

const ReportApproverRegistrationForm = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { fetching } = useSelector((state) => getLoginState(state));
  const countryAuthorityTypes = useSelector(getCountryAuthorityTypes);
  const educationAuthorities = useSelector(
    getEducationAuthoritiesByAuthorityType
  );

  const [submitErrorMessage, setSubmitErrorMessage] = useState("");

  const {
    userData,
    onBackToRoleSelection,
    countries,
    onSubmit,
    authorityData,
  } = props;

  const {
    handleSubmit,
    errors,
    register,
    watch,
    setValue,
    getValues,
  } = useForm();

  // Check if the values change, used to repopulate fields on "back"
  const selectedCountry = watch("country");
  const selectedAuthorityOne = watch("authorityLevelOne");
  const selectedAuthorityTwo = watch("authorityLevelTwo");
  const selectedAuthorityThree = watch("authorityLevelThree");
  const selectedDesignation = watch("designation");

  useEffect(() => {
    if (selectedCountry) {
      const country = countries.find(
        (country) => country.iso2 === selectedCountry
      );

      if (country) {
        dispatch(requestCountryAuthorityTypes(country.id));
        dispatch(requestEducationAuthorities(country.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, dispatch]);

  // Display the required fields message if there are errors
  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setSubmitErrorMessage(
        intl.formatMessage({
          id: "Registration.allFieldsMustBeFilledMessage",
          defaultMessage:
            "Please fill all the fields marked with red before proceeding",
        })
      );
    } else {
      setSubmitErrorMessage("");
    }
  }, [errors, intl]);

  useEffect(() => {
    if (userData.country) {
      setValue("country", userData.country.iso2, { shouldDirty: true });
    }
  }, [setValue, userData]);

  // Set select fields to previous values if present.
  // used for when coming back from terms and conditions
  useEffect(() => {
    if (authorityData.length > 0) {
      if (authorityData[0] !== "" && getValues("authorityLevelOne") === "") {
        setValue("authorityLevelOne", authorityData[0], { shouldDirty: true });
      }
      if (authorityData[1] !== "" && getValues("authorityLevelTwo") === "") {
        setValue("authorityLevelTwo", authorityData[1], { shouldDirty: true });
      }
      if (authorityData[2] !== "" && getValues("authorityLevelThree") === "") {
        setValue("authorityLevelThree", authorityData[2], {
          shouldDirty: true,
        });
      }
    }
  }, [
    authorityData,
    getValues,
    setValue,
    userData,
    selectedAuthorityOne,
    selectedAuthorityTwo,
    selectedAuthorityThree,
  ]);

  useEffect(() => {
    if (userData.designation) {
      setValue("designation", userData.designation, {
        shouldDirty: true,
      });
    }
  }, [setValue, userData, selectedDesignation]);

  const invalidStyle = { borderColor: "#c5192d" };

  const labelText = (name) =>
    intl.formatMessage(
      {
        id: "User.RegistrationSelectLocation",
        defaultMessage: "Select {name}",
      },
      {
        name: name,
      }
    );

  const helperText = (name) =>
    intl.formatMessage(
      {
        id: "User.RegistrationSelectLocationHelper",
        defaultMessage: "Select your {name}",
      },
      {
        name: name,
      }
    );

  const approverDesignations = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "User.RegistrationGovernment",
        defaultMessage: "Government",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "User.RegistrationCooperatingPartner",
        defaultMessage: "Cooperating/Delivery Partner",
      }),
    },
  ];

  return (
    <div className="role-registration-form">
      <form>
        <Button
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          iconReverse
          kind="secondary"
          className={styles.backLink}
          onClick={() => onBackToRoleSelection()}
        >
          <FormattedMessage
            id="Registration.backToAccessTypeSelectionButton"
            defaultMessage="Back to access type selection"
          />
        </Button>
        <PageTitle>
          <FormattedMessage
            id="User.RegisterToSchoolConnectAsReportApprover"
            defaultMessage="Register to School Connect as a Report Approver"
          />
        </PageTitle>

        <div className={styles.nameWrapper}>
          <div style={{ marginBottom: "24px" }}>
            <span className="wfp--label">
              <FormattedMessage
                id="User.RegistrationName"
                defaultMessage="Name"
              />
            </span>
            <br />
            {`${userData.other_names} ${userData.last_name}`}
          </div>
        </div>
        <div className={styles.contactWrapper}>
          <TextInput
            style={errors.email ? invalidStyle : {}}
            autocorrect="off"
            autoCapitalize="off"
            defaultValue={userData.email}
            disabled
            labelText={
              <FormattedMessage
                id="user.emailFieldLabel"
                defaultMessage="Email"
              />
            }
            helperText={intl.formatMessage({
              id: "User.RegistrationRequiredEmailHelper",
              defaultMessage: "Your email is required",
            })}
            inputRef={register({ required: true })}
            name="email"
            id="email"
          />
        </div>

        <div className={styles.locationWrapper}>
          <Select
            style={errors.language ? invalidStyle : {}}
            inputRef={register({ required: true })}
            className="custom_language"
            name="custom_language"
            id="custom_language"
            labelText={intl.formatMessage({
              id: "User.RegistrationSelectLanguage",
              defaultMessage: "Select language",
            })}
            helperText={intl.formatMessage({
              id: "User.RegistrationSelectLanguageHelper",
              defaultMessage: "Select your language",
            })}
            defaultValue={userData.custom_language}
          >
            <SelectItem
              value=""
              text={intl.formatMessage({
                id: "User.RegistrationSelectLanguage",
                defaultMessage: "Select language",
              })}
            />
            {languagesOptions.map((custom_language) => (
              <SelectItem
                key={custom_language.value}
                value={custom_language.value}
                text={intl.formatMessage({
                  id: `User.RegistrationSelectLanguage.${custom_language.label}`,
                  defaultMessage: `${custom_language.label}`,
                })}
              />
            ))}
          </Select>
          <Select
            style={errors.country ? invalidStyle : {}}
            inputRef={register({ required: true })}
            className="country"
            name="country"
            id="country"
            labelText={intl.formatMessage({
              id: "User.RegistrationSelectCountry",
              defaultMessage: "Select country",
            })}
            helperText={intl.formatMessage({
              id: "User.RegistrationSelectCountryHelper",
              defaultMessage: "Select your country",
            })}
          >
            <SelectItem
              value=""
              text={intl.formatMessage({
                id: "User.RegistrationSelectCountry",
                defaultMessage: "Select country",
              })}
            />
            {countries &&
              countries.map((country) => (
                <SelectItem
                  key={country.iso2}
                  value={country.iso2}
                  text={intl.formatMessage({
                    id: `User.RegistrationSelectCountry.${country.iso2}`,
                    defaultMessage: `${country.name}`,
                  })}
                />
              ))}
          </Select>
          {selectedCountry && countryAuthorityTypes[0] && (
            <Select
              style={errors.authorityLevelOne ? invalidStyle : {}}
              inputRef={register()}
              className="authorityLevelOne"
              name="authorityLevelOne"
              id="authorityLevelOne"
              labelText={labelText(countryAuthorityTypes[0].name)}
              helperText={helperText(countryAuthorityTypes[0].name)}
            >
              <SelectItem
                value=""
                text={labelText(countryAuthorityTypes[0].name)}
              />
              {educationAuthorities &&
                educationAuthorities[countryAuthorityTypes[0].id] &&
                educationAuthorities[countryAuthorityTypes[0].id].map(
                  (item) => (
                    <SelectItem
                      key={item.id}
                      value={item.id}
                      text={intl.formatMessage({
                        id: `User.RegistrationSelectLocationItem.${item.id}`,
                        defaultMessage: `${item.name}`,
                      })}
                    />
                  )
                )}
            </Select>
          )}
        </div>

        <div className={styles.locationWrapper}>
          {selectedCountry && countryAuthorityTypes[1] && (
            <Select
              style={errors.authorityLevelTwo ? invalidStyle : {}}
              inputRef={register()}
              className="authorityLevelTwo"
              name="authorityLevelTwo"
              id="authorityLevelTwo"
              labelText={labelText(countryAuthorityTypes[1].name)}
              helperText={helperText(countryAuthorityTypes[1].name)}
            >
              <SelectItem
                value=""
                text={labelText(countryAuthorityTypes[1].name)}
              />
              {educationAuthorities &&
                educationAuthorities[countryAuthorityTypes[1].id] &&
                educationAuthorities[countryAuthorityTypes[1].id].map(
                  (item) => (
                    <SelectItem
                      key={item.id}
                      value={item.id}
                      text={intl.formatMessage({
                        id: `User.RegistrationSelectLocationItem.${item.id}`,
                        defaultMessage: `${item.name}`,
                      })}
                    />
                  )
                )}
            </Select>
          )}
          {selectedCountry && countryAuthorityTypes[2] && (
            <Select
              style={errors.authorityLevelThree ? invalidStyle : {}}
              inputRef={register()}
              className="authorityLevelThree"
              name="authorityLevelThree"
              id="authorityLevelThree"
              labelText={labelText(countryAuthorityTypes[2].name)}
              helperText={helperText(countryAuthorityTypes[2].name)}
            >
              <SelectItem
                value=""
                text={labelText(countryAuthorityTypes[2].name)}
              />
              {educationAuthorities &&
                educationAuthorities[countryAuthorityTypes[2].id] &&
                educationAuthorities[countryAuthorityTypes[2].id].map(
                  (item) => (
                    <SelectItem
                      key={item.id}
                      value={item.id}
                      text={intl.formatMessage({
                        id: `User.RegistrationSelectCountry.${item.id}`,
                        defaultMessage: `${item.name}`,
                      })}
                    />
                  )
                )}
            </Select>
          )}
        </div>
        <div className={styles.locationWrapper}>
          {selectedCountry && !getIsInternal(userData.email) && (
            <Select
              style={errors.designation ? invalidStyle : {}}
              inputRef={register({ required: true })}
              className="designation"
              name="designation"
              id="designation"
              labelText={intl.formatMessage({
                id: "User.RegistrationSelectApproverDesignation",
                defaultMessage: "Select Approver Designation",
              })}
              helperText={intl.formatMessage({
                id: "User.RegistrationSelectApproverDesignationHelper",
                defaultMessage: "Select your Approver Designation",
              })}
            >
              <SelectItem
                value=""
                text={intl.formatMessage({
                  id: "User.RegistrationSelectApproverDesignation",
                  defaultMessage: "Select Approver Designation",
                })}
              />
              {approverDesignations.map((item) => (
                <SelectItem key={item.id} value={item.id} text={item.name} />
              ))}
            </Select>
          )}
        </div>
        {submitErrorMessage && (
          <p className={styles.submitErrorMessage}>{submitErrorMessage}</p>
        )}
        <div className={styles.submitWrapper}>
          <div className={styles.button}>
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              <FormattedMessage
                id="User.RegistrationSubmit"
                defaultMessage="Submit registration"
              />
            </Button>
            {fetching && <InlineLoading />}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReportApproverRegistrationForm;
