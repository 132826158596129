import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import {
  getAllDeliveries,
  getAllIncidents,
  getAllPurchases,
  getAllTakeHomeRationsDeliveries,
  syncableSortFnDecDateTime,
} from "data-handler/ducks/stores";

import { Button } from "@wfp/ui";
import SidebarHeader from "components/SidebarHeader";
import ButtonRouter from "components/ButtonRouter";
import DeliveriesModal from "components/Deliveries/DeliveriesModal";
import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";
import SchoolYearGroupDropdown from "components/SchoolYearGroupDropdown";

import { getMonthsInSchoolYear } from "helpers/dates";
import { getCurrentSchoolProfile } from "data-handler/ducks/schools";

const DeliveriesSidebar = ({ currentSchoolYear, previousSchoolYears }) => {
  const { schoolId } = useParams();
  const allDeliveries = useSelector(getAllDeliveries);
  const allIncidents = useSelector(getAllIncidents);
  const allPurchases = useSelector(getAllPurchases);
  const allTakeHomeRations = useSelector(getAllTakeHomeRationsDeliveries);
  const currentSchool = useSelector(getCurrentSchoolProfile);
  const currentSchoolEnabledPurchases = currentSchool.enable_cash_purchases;
  const [deliveriesModalState, setDeliveriesModalState] = useState(false);
  const sidebarStores = [
    ...allDeliveries,
    ...allIncidents,
    ...allPurchases,
    ...allTakeHomeRations,
  ];

  const filteredSideBarStores = [
    ...new Map(sidebarStores.map((v) => [v.client_id, v])).values(),
  ];

  const sortedSidebarStores = useMemo(
    () => filteredSideBarStores.sort(syncableSortFnDecDateTime),
    [filteredSideBarStores]
  );

  const schoolYearsArray = currentSchoolYear
    ? [currentSchoolYear, ...previousSchoolYears]
    : [...previousSchoolYears];

  /**
   * Create the schoolYears array from by getting all valid years from
   * the currentSchoolYear and all the previousSchoolYears.
   * Append months and collapsed fields to every school year item
   */
  const [schoolYears, setSchoolYears] = useState(
    schoolYearsArray
      .filter((item) => (item ? true : false))
      .map((item) => ({
        ...item,
        months: getMonthsInSchoolYear(item, schoolYearsArray),
        collapsed: schoolYearsArray.indexOf(item) === 0 ? false : true,
      }))
  );

  // Update the collapsed field for the according item (school year or month)
  function setCollapsedYearOrMonth(schoolYear, month) {
    let updatedSchoolYears = [...schoolYears];
    const index = updatedSchoolYears.indexOf(schoolYear);
    if (!month) {
      const collapsed = updatedSchoolYears[index].collapsed;
      updatedSchoolYears[index].collapsed = !collapsed;
    } else {
      const collapsed = updatedSchoolYears[index].months[month].collapsed;
      updatedSchoolYears[index].months[month].collapsed = !collapsed;
    }
    setSchoolYears(updatedSchoolYears);
  }

  const deliveriesAndIncidentsAndPurchasesByMonth = (month) =>
    sortedSidebarStores.filter((item) => {
      if (item.model === "delivery" || item.model === "purchasedetail") {
        return moment(item.delivered_at || item.purchased_at).isBetween(
          month.starts_on,
          month.ends_on,
          "days",
          "[]"
        );
      } else {
        return moment(item.occurred_at).isBetween(
          month.starts_on,
          month.ends_on,
          "days",
          "[]"
        );
      }
    });
  return (
    <div className="attendance-table">
      <SidebarHeader>
        <Gate forbidRoles={[UserPermissionRoles.is_viewer]}>
          <div className="buttons-wrapper">
            {currentSchoolEnabledPurchases && (
              <ButtonRouter
                kind="primary"
                to={`/school/${schoolId}/deliveries/undefined/new-purchase`}
              >
                <FormattedMessage
                  id="DeliveriesSidebar.newPurchase"
                  defaultMessage="New purchase"
                />
              </ButtonRouter>
            )}
            <Button onClick={() => setDeliveriesModalState(true)}>
              <FormattedMessage
                id="DeliveriesSidebar.newDelivery"
                defaultMessage="New delivery"
              />
            </Button>
            <ButtonRouter
              kind="secondary"
              to={`/school/${schoolId}/deliveries/undefined/new-incident`}
            >
              <FormattedMessage
                id="DeliveriesSidebar.newIncident"
                defaultMessage="New incident"
              />
            </ButtonRouter>
          </div>
        </Gate>
      </SidebarHeader>
      <DeliveriesModal
        state={deliveriesModalState}
        setState={setDeliveriesModalState}
      />
      <SchoolYearGroupDropdown
        schoolYears={schoolYears}
        setCollapsedYearOrMonth={setCollapsedYearOrMonth}
        currentSchoolYear={currentSchoolYear}
        deliveriesAndIncidentsAndPurchasesByMonth={
          deliveriesAndIncidentsAndPurchasesByMonth
        }
        type={"deliveriesAndIncidentsAndPurchases"}
        byMonths={true}
      />
    </div>
  );
};

export default DeliveriesSidebar;
