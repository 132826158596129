import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { Module, ModuleBody, ModuleHeader, Wrapper, Button } from "@wfp/ui";

import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";

import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";

import { CountryAdminLabel } from "SCConstants";

import { getBeneficiaryGroups } from "data-handler/ducks/distributionReports";
import {
  getAdminVendors,
  requestVendorCategories,
  requestAdminVendors,
  performVendorAction,
} from "data-handler/ducks/vendors";

import "./_content.scss";

const AllVendors = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vendorList = useSelector(getAdminVendors);

  const cometDistributionGroups = useSelector(getBeneficiaryGroups);
  const cometDistributionGroupsOptions = cometDistributionGroups?.map(
    (item) => ({
      value: item.id,
      label: `${item?.description} - ${item?.code}`,
    })
  );
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filtersState, setFilters] = useState([]);

  const { count = 0, ids = [] } = vendorList;
  const data = ids.map((id) => vendorList.results[id]);

  if (cometDistributionGroupsOptions) {
    cometDistributionGroupsOptions.unshift({
      value: null,
      label: (
        <FormattedMessage
          id="SchoolVendorsShow.EmptyCometBeneficiaryGroupMessage"
          defaultMessage="No Beneficiary Group"
        />
      ),
    });
  }

  useEffect(() => {
    dispatch(requestVendorCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stateOptions = [
    {
      name: "all",
      label: intl.formatMessage({
        id: "countryAdmin.AllVendorsTitle",
        defaultMessage: "All vendors",
      }),
    },
    {
      name: "school_defined",
      label: intl.formatMessage({
        id: "countryAdmin.SchoolDefined",
        defaultMessage: "School defined",
      }),
    },
    {
      name: "admin_approved",
      label: intl.formatMessage({
        id: "countryAdmin.AdminApproved",
        defaultMessage: "Admin approved",
      }),
    },
    {
      name: "deactivated",
      label: intl.formatMessage({
        id: "countryAdmin.Deactivated",
        defaultMessage: "Deactivated",
      }),
    },
    {
      name: "new",
      label: intl.formatMessage({
        id: "countryAdmin.New",
        defaultMessage: "New",
      }),
    },
  ];

  const filterProps = {
    fields: [
      {
        name: "vendor_category",
        label: CountryAdminLabel.find(
          (e) => e.name === "countryAdmin.vendorType"
        ).label,
      },
      {
        name: "name",
        label: CountryAdminLabel.find(
          (e) => e.name === "countryAdmin.vendorName"
        ).label,
      },
      {
        name: "ref_number",
        label: CountryAdminLabel.find((e) => e.name === "countryAdmin.refNo")
          .label,
      },
      {
        name: "country_name",
        label: intl.formatMessage({
          id: "CountryAdmin.country",
          defaultMessage: "Country",
        }),
      },
      {
        name: "state",
        label: CountryAdminLabel.find((e) => e.name === "state").label,
        type: "select",
        options: stateOptions,
      },
    ],
    onSubmit: (e) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
    defaults: filtersState.reduce((a, x) => ({ ...a, [x?.id]: x?.value }), {}),
  };

  const loadPage = (size, idx, ordering, filters = filtersState) => {
    setPageSize(size);
    setPageNum(idx);
    dispatch(requestAdminVendors(size, idx, ordering, filters));
  };

  const setStatus = (state) => {
    const labels = {
      new: intl.formatMessage({
        id: "countryAdmin.New",
        defaultMessage: "New",
      }),
      admin_approved: intl.formatMessage({
        id: "countryAdmin.AdminApproved",
        defaultMessage: "Admin approved",
      }),
      school_defined: intl.formatMessage({
        id: "countryAdmin.SchoolDefined",
        defaultMessage: "School defined",
      }),
      deactivated: intl.formatMessage({
        id: "countryAdmin.Deactivated",
        defaultMessage: "Deactivated",
      }),
    };
    return labels[state] || "";
  };

  // Generate the action labels for the buttons
  const getActionsLabels = (vendorState) => {
    switch (vendorState) {
      case "new":
        return [
          {
            label: CountryAdminLabel.find(
              (e) => e.name === "countryAdmin.promote"
            ).label,
            action: "adminDefine",
          },
        ];
      case "school_defined":
        return [
          {
            label: CountryAdminLabel.find(
              (e) => e.name === "countryAdmin.promote"
            ).label,
            action: "promote",
          },
          {
            label: CountryAdminLabel.find(
              (e) => e.name === "countryAdmin.deactivate"
            ).label,
            action: "deactivateSchoolDefined",
          },
        ];
      case "admin_approved":
        return [
          {
            label: CountryAdminLabel.find(
              (e) => e.name === "countryAdmin.deactivate"
            ).label,
            action: "deactivateAdminApproved",
          },
        ];
      case "deactivated":
        return [
          {
            label: CountryAdminLabel.find(
              (e) => e.name === "countryAdmin.activate"
            ).label,
            action: "activate",
          },
        ];
      default:
        return [];
    }
  };

  const actionButtons = useCallback(
    ({ row }) => {
      const actions = getActionsLabels(row["original"]["state"]);
      const vendorId = row["original"]["id"];

      return (
        <div className="vendors-action-buttons">
          {actions.map((item) => (
            <Button
              className="action-button"
              onClick={() => {
                // Dispatch the perform action on the vendor
                dispatch(performVendorAction(item.action, vendorId));
              }}
            >
              {item.label}
            </Button>
          ))}
        </div>
      );
    },
    // Disabled due to issue with calling disaptch function on ever render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = useMemo(() => {
    return [
      {
        Header: (
          <FormattedMessage
            id="SchoolVendorsShow.VendorType"
            defaultMessage="Vendor type"
          />
        ),
        accessor: "vendor_category.name",
      },

      {
        Header: (
          <FormattedMessage
            id="SchoolVendorsShow.VendorName"
            defaultMessage="Vendor name"
          />
        ),
        accessor: "name",
      },
      {
        Header: (
          <FormattedMessage
            id="SchoolVendorsShow.ref-no"
            defaultMessage="Reference no."
          />
        ),
        accessor: "ref_number",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.country",
          defaultMessage: "Country",
        }),
        accessor: "country.name",
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "state").label,
        accessor: "state",
        Cell: ({ cell: { row } }) => setStatus(row["values"]["state"]),
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.action",
          defaultMessage: "Action",
        }),
        accessor: "approve",
        Cell: actionButtons,
        width: 250,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionButtons]);

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
  };

  return (
    <Wrapper pageWidth="lg" spacing="md" background="lighter" className="table">
      <Module>
        <ModuleHeader>
          <FormattedMessage
            id="SchoolVendorsShow.CountryAdminVendors"
            defaultMessage="Vendors"
          />
        </ModuleHeader>
        <ModuleBody>
          <Filtering {...filterProps} />
          <Table {...tableProps} showSort />
        </ModuleBody>
      </Module>
    </Wrapper>
  );
};

export default AllVendors;
