import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Modal } from "@wfp/ui";

import { requestMoveSchoolToNewProfile } from "data-handler/ducks/schools";
import {
  requestSchoolProfilesNonPaginated,
  getAllSchoolProfilesNonPaginated,
  getSchoolProfileFetching,
} from "data-handler/ducks/schoolProfile";

import Select from "react-select";
import SchoolTable from "./SchoolTable";

export default ({
  name,
  schools,
  schoolProfileId,
  setSchoolModalState,
  schoolModalState,
  countryId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedProfile, setSelectedProfile] = useState(schoolProfileId);
  const allSchoolProfilesNonPaginated = useSelector(
    getAllSchoolProfilesNonPaginated
  );
  const schoolProfileFetching = useSelector(getSchoolProfileFetching);
  useEffect(() => {
    if (!schoolProfileFetching && allSchoolProfilesNonPaginated === null) {
      dispatch(requestSchoolProfilesNonPaginated(countryId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const schoolModalOptions = allSchoolProfilesNonPaginated
    ? allSchoolProfilesNonPaginated?.map((profile) => ({
        value: profile.id,
        label: profile.name,
      }))
    : [];

  const submit = () => {
    setSchoolModalState(false);
    const schoolList = schools.map((school) => school.id);
    const data = {
      profile: selectedProfile,
      schools: schoolList,
    };
    dispatch(requestMoveSchoolToNewProfile(data));
  };

  return (
    <Modal
      open={schoolModalState}
      modalHeading={intl.formatMessage({
        id: "schoolProfile.Schools",
        defaultMessage: "Schools",
      })}
      primaryButtonText={intl.formatMessage({
        id: "Report.actionSubmit",
        defaultMessage: "Submit",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      onSecondarySubmit={() => setSchoolModalState(false)}
      onRequestSubmit={() => submit()}
      onRequestClose={() => setSchoolModalState(false)}
    >
      {intl.formatMessage({
        id: "countryAdmin.moveProfileExplanation",
        defaultMessage:
          "Move these schools from this current profile to a new profile.",
      })}
      <SchoolTable schools={schools} />
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        defaultValue={{ value: schoolProfileId, label: name }}
        options={schoolModalOptions}
        onChange={(profile) => setSelectedProfile(profile.value)}
      />
    </Modal>
  );
};
