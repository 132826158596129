import React from "react";
import { useParams } from "react-router-dom";

import AttendanceHistory from "components/AttendanceHistory";
import StockHistory from "components/StockHistory";
import EnrolmentHistory from "components/EnrolmentHistory";

const StockContent = () => {
  const params: { item: string } = useParams();
  return (
    <>
      {params.item === "overview" ? (
        <AttendanceHistory />
      ) : params.item === "enrolment" ? (
        <EnrolmentHistory />
      ) : (
        <StockHistory />
      )}
    </>
  );
};

export default StockContent;
