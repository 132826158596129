import React from "react";

import InfoSidebar from "./InfoSidebar";
import InfoContent from "./InfoContent";
import SidebarWrapper from "components/SidebarWrapper";
import "./_attendance.scss";

const Info = () => {
  return (
    <>
      <SidebarWrapper>
        <InfoSidebar />
      </SidebarWrapper>
      <InfoContent />
    </>
  );
};

export default Info;
