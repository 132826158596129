import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  Module,
  ModuleBody,
  ModuleFooter,
  ModuleHeader,
  Wrapper,
  Button,
} from "@wfp/ui";

import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";
import { valueLinkCell } from "components/CountryAdmin/CountryAdminContent/Table/Utils";
import CsvImportModal from "../CsvImportModal";

import { CountryAdminLabel } from "SCConstants";

import {
  actionSchool,
  getPendingAdminSchools,
  requestPendingAdminSchools,
} from "data-handler/ducks/schools";
import { getCurrentUser } from "data-handler/ducks/auth";

import "../_content.scss";

const NewSchools = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const pendingAdminSchools = useSelector(getPendingAdminSchools);
  const currentUser = useSelector(getCurrentUser);

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filtersState, setFilters] = useState([]);
  const [csvImportModalOpen, setCsvImportModalOpen] = useState(false);

  const { count = 0, ids = [] } = pendingAdminSchools;

  const data = ids.map((id) => pendingAdminSchools.results[id]);

  const filterFields = [
    {
      name: "name",
      label: intl.formatMessage({
        id: "CountryAdmin.name",
        defaultMessage: "Name",
      }),
    },
    {
      name: "profile_name",
      label: intl.formatMessage({
        id: "CountryAdmin.profile",
        defaultMessage: "Profile",
      }),
    },
    {
      name: "adm_4_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminFour",
        defaultMessage: "Admin 4",
      }),
    },
    {
      name: "adm_3_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminThree",
        defaultMessage: "Admin 3",
      }),
    },
    {
      name: "adm_2_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminTwo",
        defaultMessage: "Admin 2",
      }),
    },
    {
      name: "adm_1_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminOne",
        defaultMessage: "Admin 1",
      }),
    },
  ];

  if (currentUser.is_admin) {
    filterFields.push({
      name: "country_name",
      label: intl.formatMessage({
        id: "CountryAdmin.country",
        defaultMessage: "Country",
      }),
    });
  }

  const filterProps = {
    fields: filterFields,
    onSubmit: (e) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
    defaults: filtersState.reduce((a, x) => ({ ...a, [x.id]: x.value }), {}),
  };

  const setActionButton = (original, state) => {
    const label =
      state === "activate"
        ? CountryAdminLabel.find((e) => e.name === "countryAdmin.activate")
            .label
        : CountryAdminLabel.find((e) => e.name === "countryAdmin.deactivate")
            .label;

    return (
      <Button
        className="active-button"
        onClick={() =>
          dispatch(actionSchool({ schools: [original["id"]] }, state, history))
        }
      >
        {label}
      </Button>
    );
  };

  const columns = useMemo(() => {
    const allColumns = [
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.name",
          defaultMessage: "Name",
        }),
        accessor: "name",
        Cell: ({ cell: { row, value } }) =>
          valueLinkCell(
            value,
            `/countryAdmin/schools/newSchools/${row["original"]["id"]}/`
          ),
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.profile",
          defaultMessage: "Profile",
        }),
        accessor: "profile.name",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminFour",
          defaultMessage: "Admin 4",
        }),
        accessor: "adm_4.name",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminThree",
          defaultMessage: "Admin 3",
        }),
        accessor: "adm_3.name",
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminTwo",
          defaultMessage: "Admin 2",
        }),
        accessor: "adm_2.name",
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminOne",
          defaultMessage: "Admin 1",
        }),
        accessor: "adm_1.name",
        disableSortBy: true,
      },
    ];
    if (currentUser.is_admin) {
      allColumns.push({
        Header: intl.formatMessage({
          id: "CountryAdmin.country",
          defaultMessage: "Country",
        }),
        accessor: "country.name",
      });
    }

    allColumns.push(
      {
        Header: "",
        accessor: "activateAction",
        Cell: ({ cell: { row } }) => setActionButton(row.original, "activate"),
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "deactivateAction",
        Cell: ({ cell: { row } }) =>
          setActionButton(row.original, "deactivate"),
        disableSortBy: true,
      }
    );
    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPage = (size, idx, ordering, filters = filtersState) => {
    setPageSize(size);
    setPageNum(idx);
    dispatch(requestPendingAdminSchools(size, idx, ordering, filters));
  };

  const csvImportModalProps = {
    csvImportModalOpen,
    setCsvImportModalOpen,
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
  };

  return (
    <div className="country-admin-main-content schools-table new-schools">
      <Wrapper
        pageWidth="lg"
        spacing="md"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleHeader>
            {intl.formatMessage({
              id: "CountryAdmin.NewSchools",
              defaultMessage: "New Schools",
            })}
          </ModuleHeader>
          <ModuleBody>
            <Filtering {...filterProps} />
            <Table {...tableProps} showSort />
            <CsvImportModal {...csvImportModalProps} />
          </ModuleBody>
          <ModuleFooter>
            <Link exact="true" to={`/countryAdmin/schools/create`}>
              <Button>
                {
                  <FormattedMessage
                    id="countryAdmin.createNewSchool"
                    defaultMessage="Create new school"
                  />
                }
              </Button>
            </Link>
            <Button
              className="wfp--btn--secondary"
              onClick={() => {
                setCsvImportModalOpen(true);
              }}
            >
              {
                <FormattedMessage
                  id="modal.importCsv"
                  defaultMessage="Import CSV"
                />
              }
            </Button>
          </ModuleFooter>
        </Module>
      </Wrapper>
    </div>
  );
};

export default NewSchools;
