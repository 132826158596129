import { clearLocalStorage } from "helpers/utils";

/**
 * Update School Connect's assets installed on the client
 *
 * TODO: check whether this is an efficient way to update. See:
 * https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#updates
 */
export default () => {
  window.location.reload(true);
  clearLocalStorage();
};
