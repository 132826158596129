import { FormattedMessage } from "react-intl";
import React from "react";

export const userRoles = [
  {
    value: "teacher",
    label: <FormattedMessage id="UsersRole.teacher" defaultMessage="Teacher" />,
  },
  {
    value: "principal",
    label: (
      <FormattedMessage id="UsersRole.principal" defaultMessage="Principal" />
    ),
  },
  {
    value: "store_keeper",
    label: (
      <FormattedMessage
        id="UsersRole.store_keeper"
        defaultMessage="Store keeper"
      />
    ),
  },
];

export const userRegistrationRoles = [
  {
    value: "schoolStaff",
    label: (
      <FormattedMessage
        id="UsersRole.schoolStaff"
        defaultMessage="School Staff"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="UsersRole.schoolStaffTooltip"
        defaultMessage="Applies only to school users"
      />
    ),
  },
  {
    value: "countryAdmin",
    label: (
      <FormattedMessage
        id="UsersRole.countryAdmin"
        defaultMessage="Country Admin"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="UsersRole.countryAdminTooltip"
        defaultMessage="Applies to overall administrator user of School Connect within a Country"
      />
    ),
  },
  {
    value: "schoolGroupAdmin",
    label: (
      <FormattedMessage
        id="UsersRole.schoolGroupAdmin"
        defaultMessage="School Group Admin"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="UsersRole.schoolGroupAdminTooltip"
        defaultMessage="Applies to administrator user of School Connect for schools within a school profile"
      />
    ),
  },
  {
    value: "reportApprover",
    label: (
      <FormattedMessage
        id="UsersRole.reportApprover"
        defaultMessage="Report Approver"
      />
    ),
    tooltip: (
      <FormattedMessage
        id="UsersRole.reportApproverTooltip"
        defaultMessage="Applies to all users responsible for approving school reports"
      />
    ),
  },
  {
    value: "viewer",
    label: <FormattedMessage id="UsersRole.viewer" defaultMessage="Viewer" />,
    tooltip: (
      <FormattedMessage
        id="UsersRole.viewerTooltip"
        defaultMessage="Applies to all users who want to only view school connect (read-only mode)"
      />
    ),
  },
];

// TODO: makes no sense to have is_<role> within a `school` attribute.
// Collaborate with BE team to narrow user handling down to fewer serializers
// with saner naming
export const getIsPrincipal = (user) =>
  user?.school?.is_principal === true ||
  user?.role === "principal_at" ||
  user?.role === "principal";

export const getIsStoreKeeper = (user) =>
  user?.school?.is_store_keeper === true ||
  user?.role === "store_keeper_at" ||
  user?.role === "store_keeper";

export const getIsTeacher = (user) =>
  user?.school?.is_teacher === true ||
  user?.role === "teacher_at" ||
  user?.role === "teacher";

export const getIsAdmin = (user) => user?.is_admin === true;
export const getIsCountryAdmin = (user) => user?.is_country_admin === true;
export const getIsInternal = (email) => email?.endsWith("@wfp.org");
export const getIsSchoolGroupAdmin = (user) =>
  user?.is_school_group_admin === true;
export const getIsApprover = (user) => user?.is_approver === true;
export const getIsViewer = (user) =>
  user?.is_external_viewer || user?.is_wfp_viewer;

export const getIsNonSchoolUser = (user) =>
  getIsAdmin(user) ||
  getIsCountryAdmin(user) ||
  getIsSchoolGroupAdmin(user) ||
  getIsApprover(user) ||
  getIsViewer(user);

/**
 * This is for admin users who have access to multiple schools while offline
 * Add more roles if needed
 */
export const getIsOfflineAdminUser = (user) => getIsSchoolGroupAdmin(user);
