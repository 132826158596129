import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import { Module, ModuleBody, ModuleHeader, Wrapper, Button } from "@wfp/ui";

import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";
import {
  boolIconCell,
  valueLinkCell,
} from "components/CountryAdmin/CountryAdminContent/Table/Utils";
import { getCurrentUser } from "data-handler/ducks/auth";
import {
  getCountryAdminAllUsersResponse,
  requestCountryAdminAllUsers,
  setCountryAdminUserStatus,
} from "data-handler/ducks/users";
import { CountryAdminLabel } from "SCConstants";

import "./_content.scss";
import { RoleFilterOptions } from "../index";
import { GetRoleLabel } from "helpers/roles";

const AllUsers = () => {
  const dispatch = useDispatch();
  const response = useSelector(getCountryAdminAllUsersResponse);
  const currentUser = useSelector(getCurrentUser);
  const intl = useIntl();

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filtersState, setFilters] = useState([]);

  const { count = 0, ids = [] } = response;

  const data = ids.map((id) => response.results[id]);
  const stateOptions = [
    {
      name: "all",
      label: intl.formatMessage({
        id: "countryAdmin.AllUsersTitle",
        defaultMessage: "All Users",
      }),
    },
    {
      name: "approved",
      label: intl.formatMessage({
        id: "countryAdmin.Approved",
        defaultMessage: "Approved",
      }),
    },
    {
      name: "rejected",
      label: intl.formatMessage({
        id: "countryAdmin.Rejected",
        defaultMessage: "Rejected",
      }),
    },
    {
      name: "new",
      label: intl.formatMessage({
        id: "countryAdmin.New",
        defaultMessage: "New",
      }),
    },
  ];

  const isActiveOptions = [
    {
      name: "all",
      label: intl.formatMessage({
        id: "countryAdmin.AllUsersTitle",
        defaultMessage: "All Users",
      }),
    },
    {
      name: "true",
      label: intl.formatMessage({
        id: "countryAdmin.activeUsers",
        defaultMessage: "True",
      }),
    },
    {
      name: "false",
      label: intl.formatMessage({
        id: "countryAdmin.inactiveUsers",
        defaultMessage: "False",
      }),
    },
  ];

  const filterFields = [
    CountryAdminLabel.find((e) => e.name === "email"),
    CountryAdminLabel.find((e) => e.name === "other_names"),
    CountryAdminLabel.find((e) => e.name === "last_name"),
    {
      name: "school_name",
      label: CountryAdminLabel.find((e) => e.name === "countryAdmin.school")
        .label,
    },
    {
      name: "state",
      label: CountryAdminLabel.find((e) => e.name === "state").label,
      type: "select",
      options: stateOptions,
    },
    {
      name: "isActive",
      label: CountryAdminLabel.find((e) => e.name === "countryAdmin.active")
        .label,
      type: "select",
      options: isActiveOptions,
    },
    {
      name: "user_role",
      label: CountryAdminLabel.find((e) => e.name === "countryAdmin.role")
        .label,
      type: "select",
      options: RoleFilterOptions(),
    },
  ];
  if (currentUser.is_admin) {
    filterFields.push({
      name: "country_name",
      label: intl.formatMessage({
        id: "CountryAdmin.country",
        defaultMessage: "Country",
      }),
    });
  }
  const filterProps = {
    fields: filterFields,
    onSubmit: (e) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
    defaults: filtersState.reduce((a, x) => ({ ...a, [x?.id]: x?.value }), {}),
  };

  const setStatus = (state) => {
    const labels = {
      approved: intl.formatMessage({
        id: "countryAdmin.Approved",
        defaultMessage: "Approved",
      }),
      rejected: intl.formatMessage({
        id: "countryAdmin.Rejected",
        defaultMessage: "Rejected",
      }),
      new: intl.formatMessage({
        id: "countryAdmin.New",
        defaultMessage: "New",
      }),
      approved_deactivated: intl.formatMessage({
        id: "countryAdmin.Approved and Deactivated",
        defaultMessage: "Approved",
      }),
    };
    return labels[state] || "";
  };

  const setSchool = (school) => {
    return school
      ? school.name
      : CountryAdminLabel.find((e) => e.name === "countryAdmin.noSchool").label;
  };

  const setActionButton = ({ original }) => {
    const label =
      original["state"] === "approved"
        ? CountryAdminLabel.find((e) => e.name === "countryAdmin.deactivate")
            .label
        : CountryAdminLabel.find((e) => e.name === "countryAdmin.activate")
            .label;
    const action =
      original["state"] === "approved"
        ? "deactivate"
        : original["state"] === "rejected"
        ? "activateRejected"
        : "activate";
    if (original["state"] !== "new") {
      return (
        <Button
          className="active-button"
          onClick={() =>
            dispatch(setCountryAdminUserStatus(action, original["id"]))
          }
        >
          {label}
        </Button>
      );
    } else {
      return null;
    }
  };

  const isActiveCell = ({ row }) => {
    const isActive = { value: false };

    switch (row["original"]["state"]) {
      case "approved":
        isActive.value = true;
        break;
      case "approved_deactivate":
        isActive.value = false;
        break;
      case "new":
        isActive.value = false;
        break;
      case "rejected":
        isActive.value = false;
        break;
      default:
        isActive.value = false;
    }

    return boolIconCell(isActive);
  };

  const columns = useMemo(() => {
    const allColumns = [
      {
        Header: CountryAdminLabel.find((e) => e.name === "email").label,
        accessor: "email",
        Cell: ({ cell: { row, value } }) =>
          valueLinkCell(
            value,
            `/countryAdmin/users/allUsers/${row["original"]["id"]}/`
          ),
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "other_names").label,
        accessor: "other_names",
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "last_name").label,
        accessor: "last_name",
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin.school")
          .label,
        accessor: "school.name",
        Cell: ({ cell: { row } }) => setSchool(row["original"]["school"]),
      },
    ];
    if (currentUser.is_admin) {
      allColumns.push({
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin.country")
          .label,
        accessor: "country.name",
      });
    }
    allColumns.push(
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin.role")
          .label,
        accessor: "user_role",
        Cell: ({ cell: { row } }) => GetRoleLabel(row["original"]),
        disableSortBy: true,
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin.admin")
          .label,
        accessor: "is_admin",
        Cell: boolIconCell,
        disableSortBy: true,
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryadmin.WFP")
          .label,
        accessor: "is_wfp",
        Cell: boolIconCell,
        disableSortBy: true,
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin").label,
        accessor: "is_country_admin",
        Cell: boolIconCell,
        disableSortBy: true,
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "countryAdmin.active")
          .label,
        accessor: "is_active",
        Cell: isActiveCell,
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "state").label,
        accessor: "state",
        Cell: ({ cell: { row } }) => setStatus(row["values"]["state"]),
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ cell: { row } }) => setActionButton(row),
      }
    );

    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPage = (size, idx, ordering, filters = filtersState) => {
    setPageSize(size);
    setPageNum(idx);
    dispatch(requestCountryAdminAllUsers(size, idx, ordering, filters));
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
  };

  return (
    <Wrapper pageWidth="lg" spacing="md" background="lighter" className="table">
      <Module>
        <ModuleHeader>
          <FormattedMessage
            id="countryAdmin.AllUsersTitle"
            defaultMessage="All Users"
          />
        </ModuleHeader>
        <ModuleBody>
          <Filtering {...filterProps} />
          <Table {...tableProps} showSort />
        </ModuleBody>
      </Module>
    </Wrapper>
  );
};

export default AllUsers;
