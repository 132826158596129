/*
 * This module is only used to trigger re-rendering the whole app when mocking date,
 * NOT to get the actual date.
 *
 * To get the date you call the usual function `new Date()`.
 */
export const SET = "schoolconnect/date/SET";

export default function date(state = "", action) {
  switch (action.type) {
    case SET:
      return action.date;
    default:
      return state;
  }
}

export const getDate = (state) => state.date;

export const setDate = (date) => ({
  type: SET,
  date,
});
