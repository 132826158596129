import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  Module,
  ModuleBody,
  ModuleHeader,
  Wrapper,
  ModuleFooter,
  Button,
  InlineLoading,
} from "@wfp/ui";

import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";
import { valueLinkCell } from "components/CountryAdmin/CountryAdminContent/Table/Utils";
import {
  getSchoolProfiles,
  requestSchoolProfiles,
  getSchoolProfileFetching,
} from "data-handler/ducks/schoolProfile";
import { CountryAdminLabel } from "SCConstants";

import "./_content.scss";
import { getCurrentUser } from "data-handler/ducks/auth";

const CountryAdminSchoolProfilesTable = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const response = useSelector(getSchoolProfiles);
  const currentUser = useSelector(getCurrentUser);
  const schoolProfileFetching = useSelector(getSchoolProfileFetching);

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filtersState, setFilters] = useState([]);

  const { count = 0, ids = [] } = response;

  const data = ids.map((id) => response.results[id]);

  const filterFields = [
    {
      name: "name",
      label: CountryAdminLabel.find((e) => e.name === "name").label,
    },
    {
      name: "region_name",
      label: CountryAdminLabel.find((e) => e.name === "region").label,
    },
  ];

  if (currentUser.is_admin) {
    filterFields.push({
      name: "country_name",
      label: intl.formatMessage({
        id: "CountryAdmin.country",
        defaultMessage: "Country",
      }),
    });
  }

  const filterProps = {
    fields: filterFields,
    onSubmit: (e) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
    defaults: filtersState.reduce((a, x) => ({ ...a, [x.id]: x.value }), {}),
  };

  const columns = useMemo(() => {
    const allColumns = [
      {
        Header: CountryAdminLabel.find((e) => e.name === "name").label,
        accessor: "name",
        Cell: ({ cell: { row, value } }) =>
          valueLinkCell(
            value,
            `/countryAdmin/schoolProfiles/${row["original"]["id"]}/`
          ),
      },
      {
        Header: CountryAdminLabel.find((e) => e.name === "region").label,
        accessor: "region.name",
      },
    ];
    if (currentUser.is_admin) {
      allColumns.push({
        Header: intl.formatMessage({
          id: "CountryAdmin.country",
          defaultMessage: "Country",
        }),
        accessor: "country.name",
      });
    }
    return allColumns;
  }, [currentUser.is_admin, intl]);

  const loadPage = (size, idx, ordering, filters = filtersState) => {
    setPageSize(size);
    setPageNum(idx);
    dispatch(requestSchoolProfiles(size, idx, ordering, filters));
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
  };

  return (
    <div className="country-admin-main-content-center school-profiles-table">
      <Wrapper
        pageWidth="lg"
        spacing="md"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleHeader>
            {
              CountryAdminLabel.find(
                (e) => e.name === "countryAdmin.schoolProfiles"
              ).label
            }
          </ModuleHeader>
          <ModuleBody>
            <div style={schoolProfileFetching ? { display: "none" } : {}}>
              <Filtering {...filterProps} />
              <Table {...tableProps} showSort />
            </div>
            <div
              className="inlineLoading"
              style={!schoolProfileFetching ? { display: "none" } : {}}
            >
              <InlineLoading description="Loading..." />
            </div>
          </ModuleBody>
          <ModuleFooter>
            <Link exact="true" to={`/countryAdmin/schoolProfiles/create`}>
              <Button>
                {
                  CountryAdminLabel.find(
                    (e) => e.name === "countryAdmin.createNewSchoolProfile"
                  ).label
                }
              </Button>
            </Link>
          </ModuleFooter>
        </Module>
      </Wrapper>
    </div>
  );
};

export default CountryAdminSchoolProfilesTable;
