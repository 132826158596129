import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

export const REQUEST_PRIVACY_STATEMENT =
  "schoolconnect/statements/REQUEST_PRIVACY_STATEMENT";
export const SUCCESS_PRIVACY_STATEMENT =
  "schoolconnect/statements/SUCCESS_PRIVACY_STATEMENT";
export const FAILURE = "schoolconnect/statements/FAILURE";
export const REQUEST_TERMS_STATEMENT =
  "schoolconnect/statements/REQUEST_TERMS_STATEMENT";
export const SUCCESS_TERMS_STATEMENT =
  "schoolconnect/statements/SUCCESS_TERMS_STATEMENT";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  privacy: null,
  terms: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TERMS_STATEMENT:
    case REQUEST_PRIVACY_STATEMENT:
      return { ...state, fetching: true };
    case SUCCESS_PRIVACY_STATEMENT:
      return { ...state, fetching: false, privacy: action.data };
    case SUCCESS_TERMS_STATEMENT:
      return { ...state, fetching: false, terms: action.data };
    case FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}
// Action
export const requestPrivacyStatement = (language) => ({
  type: REQUEST_PRIVACY_STATEMENT,
  language,
});

// Watcher
export function* privacyStatementSagaWatcher() {
  yield takeEvery(REQUEST_PRIVACY_STATEMENT, privacyStatementWorkerSaga);
}

// Sagas
function fetchPrivacyStatement(action) {
  const url = process.env.REACT_APP_PRIVACY_STATEMENT_URL;
  return axios({
    method: "GET",
    url: url,
    params: {
      lang: action.language,
    },
  });
}

function* privacyStatementWorkerSaga(action) {
  let response;
  try {
    response = yield call(fetchPrivacyStatement, action);

    yield put({ type: SUCCESS_PRIVACY_STATEMENT, data: response.data });
  } catch (error) {
    yield put({ type: FAILURE, error });
  }
}

// Action
export const requestTermsStatement = (language) => ({
  type: REQUEST_TERMS_STATEMENT,
  language,
});

// Watcher
export function* termsStatementSagaWatcher() {
  yield takeEvery(REQUEST_TERMS_STATEMENT, termsStatementWorkerSaga);
}

// Sagas
function fetchTermsStatement(action) {
  const url = process.env.REACT_APP_TERMS_HTML_URL;
  return axios({
    method: "GET",
    url: url,
    params: {
      lang: action.language,
    },
  });
}

function* termsStatementWorkerSaga(action) {
  let response;
  try {
    response = yield call(fetchTermsStatement, action);

    yield put({ type: SUCCESS_TERMS_STATEMENT, data: response.data });
  } catch (error) {
    yield put({ type: FAILURE, error });
  }
}

export const getPrivacyStatement = (state) => state.statements.privacy;
export const getTermsStatement = (state) => state.statements.terms;
