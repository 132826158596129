import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";

import {
  listObjectsToListIds,
  listToObject,
} from "data-handler/ducks/paginationTools";

import { successToast, errorToast } from "data-handler/ducks/toast";
import { toastFormattedMessages } from "SCConstants";
import { downloadBlob } from "./utils";

export const IMPORT_CSV_FILE_REQUEST =
  "schoolconnect/schoolsImports/IMPORT_CSV_FILE_REQUEST";
export const IMPORT_CSV_FILE_SUCCESS =
  "schoolconnect/schoolsImports/IMPORT_CSV_FILE_SUCCESS";
export const IMPORT_CSV_FILE_FAILURE =
  "schoolconnect/schoolsImports/IMPORT_CSV_FILE_FAILURE";

export const DOWNLOAD_CSV_TEMPLATE_REQUEST =
  "schoolconnect/schoolsImports/DOWNLOAD_CSV_TEMPLATE_REQUEST";
export const DOWNLOAD_CSV_TEMPLATE_SUCCESS =
  "schoolconnect/schoolsImports/DOWNLOAD_CSV_TEMPLATE_SUCCESS";
export const DOWNLOAD_CSV_TEMPLATE_FAILURE =
  "schoolconnect/schoolsImports/DOWNLOAD_CSV_TEMPLATE_FAILURE";

export const SCHOOLS_IMPORTS_REQUEST =
  "schoolconnect/schoolsImports/SCHOOLS_IMPORTS_REQUEST";
export const SCHOOLS_IMPORTS_SUCCESS =
  "schoolconnect/schoolsImports/SCHOOLS_IMPORTS_SUCCESS";
export const SCHOOLS_IMPORTS_FAILURE =
  "schoolconnect/schoolsImports/SCHOOLS_IMPORTS_FAILURE";

export const CURRENT_SCHOOLS_IMPORT_REQUEST =
  "schoolconnect/schoolsImports/CURRENT_SCHOOLS_IMPORT_REQUEST";
export const CURRENT_SCHOOLS_IMPORT_SUCCESS =
  "schoolconnect/schoolsImports/CURRENT_SCHOOLS_IMPORT_SUCCESS";
export const CURRENT_SCHOOLS_IMPORT_FAILURE =
  "schoolconnect/schoolsImports/CURRENT_SCHOOLS_IMPORT_FAILURE";

export const SCHOOLS_IMPORT_RECORDS_REQUEST =
  "schoolconnect/schoolsImports/SCHOOLS_IMPORT_RECORDS_REQUEST";
export const SCHOOLS_IMPORT_RECORDS_SUCCESS =
  "schoolconnect/schoolsImports/SCHOOLS_IMPORT_RECORDS_SUCCESS";
export const SCHOOLS_IMPORT_RECORDS_FAILURE =
  "schoolconnect/schoolsImports/SCHOOLS_IMPORT_RECORDS_FAILURE";

const initialState = {
  error: null,
  errorResponse: null,
  fetching: false,
  schoolsImports: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  schoolsImportRecords: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case IMPORT_CSV_FILE_REQUEST:
      return { ...state, fetching: true, error: null };
    case IMPORT_CSV_FILE_SUCCESS:
      return {
        ...state,
        error: null,
        fetching: false,
      };
    case IMPORT_CSV_FILE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case DOWNLOAD_CSV_TEMPLATE_REQUEST:
      return { ...state, error: null, fetching: true };
    case DOWNLOAD_CSV_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: null,
        fetching: false,
      };
    case DOWNLOAD_CSV_TEMPLATE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case SCHOOLS_IMPORTS_REQUEST:
      return { ...state, fetching: true, error: null };
    case SCHOOLS_IMPORTS_SUCCESS: {
      return {
        ...state,
        schoolsImports: action.data,
        fetching: false,
        error: null,
      };
    }
    case SCHOOLS_IMPORTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case CURRENT_SCHOOLS_IMPORT_REQUEST:
      return { ...state, fetching: true, error: null };
    case CURRENT_SCHOOLS_IMPORT_SUCCESS: {
      const { currentSchoolsImport } = action;
      const currentSchoolsImportObject = {
        [currentSchoolsImport.id]: currentSchoolsImport,
      };
      return {
        ...state,
        schoolsImports: {
          ...state.schoolsImports,
          results: {
            ...state.schoolsImports.results,
            ...currentSchoolsImportObject,
          },
        },
        fetching: false,
        error: null,
      };
    }
    case CURRENT_SCHOOLS_IMPORT_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case SCHOOLS_IMPORT_RECORDS_REQUEST:
      return { ...state, fetching: true, error: null };
    case SCHOOLS_IMPORT_RECORDS_SUCCESS: {
      return {
        ...state,
        schoolsImportRecords: action.data,
        fetching: false,
        error: null,
      };
    }
    case SCHOOLS_IMPORT_RECORDS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}

// Action creators
export const importCsvFile = (file, countryId, isUpdate) => ({
  type: IMPORT_CSV_FILE_REQUEST,
  file,
  countryId,
  isUpdate,
});

export const downloadCsvTemplate = () => ({
  type: DOWNLOAD_CSV_TEMPLATE_REQUEST,
});

export const requestSchoolsImports = (
  pageSize,
  pageIndex,
  ordering,
  filters
) => ({
  type: SCHOOLS_IMPORTS_REQUEST,
  pageSize,
  pageIndex,
  ordering,
  filters,
});

export const requestCurrentSchoolsImport = (schoolsImportId, history) => ({
  type: CURRENT_SCHOOLS_IMPORT_REQUEST,
  schoolsImportId,
  history,
});

export const requestSchoolsImportRecords = (
  pageSize,
  pageIndex,
  ordering,
  schoolsImportId
) => ({
  type: SCHOOLS_IMPORT_RECORDS_REQUEST,
  pageSize,
  pageIndex,
  ordering,
  schoolsImportId,
});

// Sagas
export function* importCsvFileSagaWatcher() {
  yield takeLatest(IMPORT_CSV_FILE_REQUEST, importCsvFileSagaWorker);
}

export function* downloadCsvTemplateSagaWatcher() {
  yield takeLatest(
    DOWNLOAD_CSV_TEMPLATE_REQUEST,
    downloadCsvTemplateSagaWorker
  );
}

export function* requestSchoolsImportsSagaWatcher() {
  yield takeLatest(SCHOOLS_IMPORTS_REQUEST, requestSchoolsImportsSagaWorker);
}

export function* requestCurrentSchoolsImportSagaWatcher() {
  yield takeLatest(
    CURRENT_SCHOOLS_IMPORT_REQUEST,
    requestCurrentSchoolsImportSagaWorker
  );
}

export function* requestSchoolsImportRecordsSagaWatcher() {
  yield takeLatest(
    SCHOOLS_IMPORT_RECORDS_REQUEST,
    requestSchoolsImportRecordsSagaWorker
  );
}

function postImportCsvFile(action) {
  const { file, countryId, isUpdate } = action;

  let formData = new FormData();

  formData.append("csv_file", file);
  formData.append("country", countryId);
  formData.append("is_update", isUpdate);

  const url = `${process.env.REACT_APP_API_URL}/school-imports/`;
  return axios({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

function* importCsvFileSagaWorker(action) {
  try {
    const response = yield call(postImportCsvFile, action);
    const responseData = response.data;
    yield put({ type: IMPORT_CSV_FILE_SUCCESS, responseData });
    yield put(successToast());
    window.location.reload();
  } catch (error) {
    yield put({ type: IMPORT_CSV_FILE_FAILURE, error });
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}

function fetchCsvTemplate() {
  const url = `${process.env.REACT_APP_API_URL}/school-imports/download-template/`;
  return axios({
    method: "GET",
    url: url,
    responseType: "blob",
  });
}

function* downloadCsvTemplateSagaWorker(action) {
  try {
    const response = yield call(fetchCsvTemplate, action);
    const blob = response.data;
    downloadBlob(blob, "School_Reg_File_Template.csv");
    yield put({ type: DOWNLOAD_CSV_TEMPLATE_SUCCESS });
  } catch (error) {
    yield put({ type: DOWNLOAD_CSV_TEMPLATE_FAILURE, error });
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}

function fetchSchoolsImports(action) {
  const page_size = action.pageSize;
  const page = action.pageIndex * page_size - page_size;
  const filters = action.filters;
  const ordering = action.ordering.replace(/\./g, "_");

  const url =
    `${process.env.REACT_APP_API_URL}/school-imports/` +
    `?limit=${page_size}&offset=${page}` +
    (ordering ? `&ordering=${ordering}` : "") +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            if (value && value !== "") {
              return `&${id}=${value}`;
            }
            return "";
          })
          .join("")
      : "");

  return axios({
    method: "GET",
    url: url,
  });
}

function* requestSchoolsImportsSagaWorker(action) {
  try {
    const response = yield call(fetchSchoolsImports, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };

    yield put({
      type: SCHOOLS_IMPORTS_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: SCHOOLS_IMPORTS_FAILURE, error });
  }
}

function fetchSchoolsImport(action) {
  const url = `${process.env.REACT_APP_API_URL}/school-imports/${action.schoolsImportId}/`;
  return axios({
    method: "GET",
    url: url,
  });
}

function* requestCurrentSchoolsImportSagaWorker(action) {
  try {
    const response = yield call(fetchSchoolsImport, action);
    const currentSchoolsImport = response.data;
    yield put({ type: CURRENT_SCHOOLS_IMPORT_SUCCESS, currentSchoolsImport });
  } catch (error) {
    const { history } = action;
    history.push(`/countryAdmin/schools/schoolsImports`);
    yield put({ type: CURRENT_SCHOOLS_IMPORT_FAILURE, error });
  }
}

function fetchSchoolsImportRecords(action) {
  const page_size = action.pageSize;
  const page = action.pageIndex * page_size - page_size;
  const ordering = action.ordering.replace(/\./g, "_");
  const schoolsImportId = action.schoolsImportId;

  const url =
    `${process.env.REACT_APP_API_URL}/school-import-records/` +
    `?limit=${page_size}&offset=${page}` +
    (ordering ? `&ordering=${ordering}` : "") +
    (schoolsImportId ? `&school_import=${schoolsImportId}` : "");

  return axios({
    method: "GET",
    url: url,
  });
}

function* requestSchoolsImportRecordsSagaWorker(action) {
  try {
    const response = yield call(fetchSchoolsImportRecords, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };

    yield put({
      type: SCHOOLS_IMPORT_RECORDS_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: SCHOOLS_IMPORT_RECORDS_FAILURE, error });
  }
}

export const getSchoolsImports = (state) => state.schoolsImports.schoolsImports;

export const getSchoolsImportRecords = (state) =>
  state.schoolsImports.schoolsImportRecords;
