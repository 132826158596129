import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import MockDate from "mockdate";

import { Button, Module, ModuleBody, ModuleHeader } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";
import DateInput from "components/DateInput";

import { setDate } from "data-handler/ducks/date";

import updateSchoolConnect from "updateSchoolConnect";

import { clearLocalStorage } from "helpers/utils";

export const setMockDate = (date) => {
  MockDate.set(date);
};

const MODAL_LABEL = (
  <FormattedMessage id="SupportModal.modalLabel" defaultMessage="Support" />
);
const MODAL_HEADING = (
  <FormattedMessage id="SupportModal.modalHeading" defaultMessage="Support" />
);

const INFO_HEADER = (
  <FormattedMessage
    id="SupportModal.infoHeader"
    defaultMessage="School Connect Info"
  />
);

const RESET_HEADER = (
  <FormattedMessage
    id="SupportModal.resetHeader"
    defaultMessage="Reset School Connect"
  />
);
const RESET_BUTTON_LABEL = (
  <FormattedMessage
    id="SupportModal.resetButtonLabel"
    defaultMessage="Reset School Connect"
  />
);
const RESET_EXPLANATION_1 = (
  <FormattedMessage
    id="SupportModal.resetExplanation1"
    defaultMessage="Some School Connect malfunctions may be recovered by resetting the application. Only reset School Connect if advised to do so by Technical Support."
  />
);
const RESET_EXPLANATION_2 = (
  <FormattedMessage
    id="SupportModal.resetExplanation2"
    defaultMessage="CAUTION: you will lose all unsynced data."
  />
);

const UPDATE_HEADER = (
  <FormattedMessage
    id="SupportModal.updateHeader"
    defaultMessage="Update School Connect"
  />
);
const UPDATE_BUTTON_LABEL = (
  <FormattedMessage
    id="SupportModal.updateButtonLabel"
    defaultMessage="Update School Connect"
  />
);
const UPDATE_EXPLANATION_1 = (
  <FormattedMessage
    id="SupportModal.updateExplanation1"
    defaultMessage="If you rejected a School Connect update, you can update it now by using the following button."
  />
);
const CLEAR_LOCAL_STORAGE = (
  <FormattedMessage
    id="SupportModal.clearLocalStorageHeader"
    defaultMessage="Clear cache"
  />
);
const STORAGE_EXPLANATION_1 = (
  <FormattedMessage
    id="SupportModal.storageExplanation"
    defaultMessage="You can clear your local storage here. You will be logged out after this operation."
  />
);

const UPDATE_EXPLANATION_2 = (
  <FormattedMessage
    id="SupportModal.updateExplanation2"
    defaultMessage="CAUTION: you will lose all unsynced data."
  />
);

export const SupportModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorTrigger, setErrorTrigger] = useState(false);

  const urlParams = queryString.parse(history.location.search);

  if (urlParams.select !== "debug") return null;

  const closeWindow = () => {
    history.push(`${history.location.pathname}?nav=true`);
  };

  /** Clears local storage, logs out */
  const clearLocalStorageHandler = () => {
    clearLocalStorage();
    history.push("/");
    window.location.reload(false);
  };

  if (errorTrigger) {
    // We must do this inside the component function's body,
    // not in an async event handler.
    throw new Error("Requested error");
  }

  return (
    <ModalExtended
      open
      onRequestClose={closeWindow}
      onRequestSubmit={closeWindow}
      modalLabel={MODAL_LABEL}
      modalHeading={MODAL_HEADING}
      wide
      passiveModal
      selectorPrimaryFocus={false}
    >
      <Module noMargin style={{ marginBottom: "0.75rem" }}>
        <ModuleHeader>{INFO_HEADER}</ModuleHeader>
        <ModuleBody>
          {/* The following env vars are substituted at build time via DefinePlugin */}
          <p>App Name: {process.env.REACT_APP_NAME}</p>
          <p>App Version: {process.env.REACT_APP_VERSION}</p>
          {/* Azure DevOps Build.BuildId */}
          <p>Build Id: {process.env.REACT_APP_BUILD_ID}</p>
          {/* Azure DevOps Build.SourceVersion */}
          <p>Commit Id: {process.env.REACT_APP_COMMIT_ID}</p>
          {/* Azure DevOps Build.SourceVersionMessage */}
          <p>Commit Msg: {process.env.REACT_APP_COMMIT_MSG}</p>
        </ModuleBody>
      </Module>
      <Module noMargin style={{ marginBottom: "0.75rem" }}>
        <ModuleHeader>{UPDATE_HEADER}</ModuleHeader>
        <ModuleBody>
          <p style={{ marginBottom: "0.75rem" }}>{UPDATE_EXPLANATION_1}</p>
          <p style={{ marginBottom: "0.75rem", color: "red" }}>
            {UPDATE_EXPLANATION_2}
          </p>
          <Button onClick={updateSchoolConnect}>{UPDATE_BUTTON_LABEL}</Button>
        </ModuleBody>
      </Module>
      <Module noMargin style={{ marginBottom: "0.75rem" }}>
        <ModuleHeader>{CLEAR_LOCAL_STORAGE}</ModuleHeader>
        <ModuleBody>
          <p style={{ marginBottom: "0.75rem" }}>{STORAGE_EXPLANATION_1}</p>
          <p style={{ marginBottom: "0.75rem", color: "red" }}>
            {UPDATE_EXPLANATION_2}
          </p>
          <Button onClick={clearLocalStorageHandler}>
            {CLEAR_LOCAL_STORAGE}
          </Button>
        </ModuleBody>
      </Module>
      <Module noMargin style={{ marginBottom: "0.75rem" }}>
        <ModuleHeader>{RESET_HEADER}</ModuleHeader>
        <ModuleBody>
          <p style={{ marginBottom: "0.75rem" }}>{RESET_EXPLANATION_1}</p>
          <p style={{ marginBottom: "0.75rem", color: "red" }}>
            {RESET_EXPLANATION_2}
          </p>
          <Button kind={"danger"} onClick={clearLocalStorageHandler}>
            {RESET_BUTTON_LABEL}
          </Button>
        </ModuleBody>
      </Module>
      {process.env.NODE_ENV !== "production" && (
        <Module noMargin style={{ marginBottom: "0.75rem" }}>
          <ModuleHeader>Developer tools</ModuleHeader>
          <ModuleBody>
            <DateInput
              labelText="Mock current date"
              onChange={(e) => {
                setMockDate(e.target.value);
                dispatch(setDate(e.target.value));
              }}
              value={new Date()}
            />
            {/* <Button onClick={clearStoresHandler}>Clear stores</Button> // DEPRECATED */}
            <Button kind={"danger"} onClick={() => setErrorTrigger(true)} small>
              Raise an error
            </Button>
          </ModuleBody>
        </Module>
      )}
    </ModalExtended>
  );
};

export default SupportModal;
