import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Select, SelectItem } from "@wfp/ui";

const TableActions = ({ selectedRows, actions = [] }) => {
  const intl = useIntl();
  const numSelected = Object.keys(selectedRows).length;

  const [actionIdx, setActionIdx] = useState("");

  function selectChange(e) {
    setActionIdx(e.target.value);
  }

  function doAction() {
    const action = actions.find(({ key }) => key === actionIdx);
    if (action) action.callback(selectedRows);
  }
  const selectAction = intl.formatMessage({
    id: "table.selectAction",
    defaultMessage: "Select an action",
  });

  return (
    <div className="wfp--pagination">
      <div className="wfp--pagination__left">
        <span className="wfp--pagination__text">
          <FormattedMessage
            id="schools.numSelected"
            defaultMessage="{num} items selected"
            values={{
              num: numSelected,
            }}
          />
        </span>
        <Select
          id=""
          labelText={selectAction}
          hideLabel
          inline
          onChange={selectChange}
          value={actionIdx}
        >
          <SelectItem key="" value="" text={selectAction} />
          {actions.map(({ key, label }) => (
            <SelectItem key={key} value={key} text={label ? label : key} />
          ))}
        </Select>
        <Button
          small
          kind="ghost"
          disabled={!actionIdx || !numSelected}
          onClick={doAction}
        >
          {
            <FormattedMessage
              id="Report.actionSubmit"
              defaultMessage="Submit"
            />
          }
        </Button>
      </div>
    </div>
  );
};

export default TableActions;
