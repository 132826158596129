import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import { getFilteredAttendanceTotal } from "helpers/attendance";
import CommodityShowSmall from "components/CommodityShowSmall";
import SidebarItem from "components/SidebarItem";
import { noMealsOptions } from "SCConstants";
import { AttendanceItemProps } from "./types";

type MealAttendanceItemParams = {
  item: string;
  schoolId: string;
};

const MealAttendanceItem: React.ComponentType<AttendanceItemProps> = ({
  currentStoreData,
  store,
  studentStore,
  thrStore,
  currentSchoolEnabledStudentAttendance,
  ...extraProps
}) => {
  const params: MealAttendanceItemParams = useParams();
  const selected =
    (currentStoreData && currentStoreData.client_id === store.client_id) ||
    (currentStoreData === undefined && store.client_id === params.item);
  const noClass =
    store?.consumption?.no_meal_reasons?.includes(1) ||
    store?.consumption?.no_meal_reasons?.includes(5) ||
    store?.consumption?.no_meal_reasons?.includes(7);
  const meal_provided = store.consumption && store.consumption.meal_provided;
  let subTitle: any;
  if (store.levels) {
    subTitle = (
      <>
        {getFilteredAttendanceTotal({
          attendance: store,
          kinds: ["male"],
        })}{" "}
        <FormattedMessage id="app.boys" defaultMessage="boys" />,{" "}
        {getFilteredAttendanceTotal({
          attendance: store,
          kinds: ["female"],
        })}{" "}
        <FormattedMessage id="app.girls" defaultMessage="girls" />
      </>
    );
  } else {
    subTitle = (
      <>
        <FormattedMessage
          id="AttendanceItem.noAttendanceAdded"
          defaultMessage="No attendance added"
        />
      </>
    );
  }

  const subTitleNoClass = store?.consumption?.no_meal_reasons?.map(
    (reasonId: number) => {
      const option = noMealsOptions?.find(
        (option) => option?.value === reasonId
      );
      return option?.label;
    }
  );
  const title =
    !store.levels || meal_provided === null || meal_provided === undefined ? (
      <>
        <FormattedMessage
          id="AttendanceItem.provide"
          defaultMessage="Provide"
        />{" "}
        {!store.levels && (
          <FormattedMessage
            id="AttendanceItem.attendance"
            defaultMessage="attendance"
          />
        )}
        {!store.levels &&
          (meal_provided === null || meal_provided === undefined) &&
          ", "}
        {(meal_provided === null || meal_provided === undefined) && (
          <FormattedMessage id="AttendanceItem.meals" defaultMessage="meals" />
        )}
      </>
    ) : (
      <>
        {getFilteredAttendanceTotal({
          attendance: store,
        })}{" "}
        <FormattedMessage id="app.students" defaultMessage="students" />
      </>
    );

  const noClassTitle = (
    <FormattedMessage
      id="AttendanceItem.noClassMessage"
      defaultMessage="No Class"
    />
  );

  const thrTitle: React.ReactElement | null = thrStore ? (
    <FormattedMessage
      id="AttendanceItem.thrInformationDistributed"
      defaultMessage="Take-home ration information distributed"
    />
  ) : null;

  const comment =
    meal_provided === true && !noClass ? (
      <div>
        <FormattedMessage id="app.mealProvided" defaultMessage="Meal served" />
      </div>
    ) : meal_provided === false && !noClass ? (
      <FormattedMessage
        id="app.noMealProvided"
        defaultMessage="No meal served"
      />
    ) : noClass ? (
      <FormattedMessage id="app.noClassMealInfo" defaultMessage=" " />
    ) : (
      <FormattedMessage id="app.noMealInfo" defaultMessage="No meal info" />
    );

  return (
    <SidebarItem
      {...extraProps}
      store={store}
      studentStore={studentStore}
      thrStore={thrStore}
      currentSchoolEnabledStudentAttendance={
        currentSchoolEnabledStudentAttendance
      }
      selected={selected}
      subTitle={noClass ? subTitleNoClass : subTitle}
      thrTitle={thrTitle}
      id={store.client_id}
      highlight={
        (!store.levels ||
          meal_provided === null ||
          meal_provided === undefined) &&
        !noClass
      }
      title={noClass ? noClassTitle : title}
      to={`/school/${params.schoolId}/attendance/${store.client_id}`}
      comment={comment}
    >
      {meal_provided && (
        <CommodityShowSmall
          commodities={store?.consumption?.commodities!}
          className="MealsStock"
        />
      )}
    </SidebarItem>
  );
};

export default MealAttendanceItem;
