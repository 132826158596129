import React from "react";

import { Route, Switch } from "react-router-dom";

import CountryAdminDistributionReportTable from "./CountryAdminDistributionReportTable";
import CountryAdminDistributionReportProfilePage from "./CountryAdminDistributionReportPage";

const CountryAdminDistributionReport = () => {
  const renderCountryAdminDistributionReportProfilePageCreate = () => {
    return <CountryAdminDistributionReportProfilePage create={true} />;
  };

  const renderCountryAdminDistributionReportProfilePageEdit = () => {
    return <CountryAdminDistributionReportProfilePage create={false} />;
  };

  return (
    <div className="admin-schools country-admin-main-content">
      <Switch>
        <Route
          path="/countryAdmin/distributionReport/create"
          component={renderCountryAdminDistributionReportProfilePageCreate}
        />
        <Route
          path="/countryAdmin/distributionReport/allDistributionReportProfiles/:distributionReportId"
          component={renderCountryAdminDistributionReportProfilePageEdit}
        />
        <Route component={CountryAdminDistributionReportTable} />
      </Switch>
    </div>
  );
};

export default CountryAdminDistributionReport;
