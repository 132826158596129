import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { Blockquote, InlineLoading } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";
import LogEntry from "components/SchoolReport/LogEntry";

import { getCurrentUser } from "data-handler/ducks/auth";

import { useIsReportInLatestSchoolYear } from "./selectors";
import useReportAction from "./useReportAction";

const MODAL_LABEL = (
  <FormattedMessage id="ReportActionAmend.label" defaultMessage="Reporting" />
);
const MODAL_HEADING = (period) => (
  <FormattedMessage
    id="ReportActionAmend.heading"
    defaultMessage="Amend Report {period}"
    values={{ period }}
  />
);
const BUTTON_LABEL = (
  <FormattedMessage id="ReportActionAmend.buttonLabel" defaultMessage="Amend" />
);
const EXPLANATION_1 = (
  <FormattedMessage
    id="ReportActionAmend.explanation1"
    defaultMessage="Reason for rejection:"
  />
);
const EXPLANATION_2 = (
  <FormattedMessage
    id="ReportActionAmend.explanation2"
    defaultMessage="In order to provide corrections, you may reopen it."
  />
);
const AMENDING_REPORT_AS = (
  <FormattedMessage
    id="ReportActionAmend.whichUser"
    defaultMessage="Amending report as"
  />
);
const REPORT_CANNOT_BE_AMENDED = (
  <FormattedMessage
    id="ReportActionAmend.reportCannotBeAmended"
    defaultMessage="Report cannot be amended"
  />
);

const ReportModalAmend = ({ report }) => {
  const currentUser = useSelector(getCurrentUser);
  const isReportInLatestSchoolYear = useIsReportInLatestSchoolYear(report);

  const {
    actionFeedback,
    closeModal,
    performAction,
    isPerforming,
    period,
  } = useReportAction({
    report,
    action: "amend",
  });

  const rejectLogEntries = report?.log_entries
    ?.filter((entry) => entry.transition === "reject")
    .sort((a, b) => moment.utc(b.timestamp).diff(moment.utc(a.timestamp)));

  const rejectionLogEntry = rejectLogEntries?.[0];

  return (
    <ModalExtended
      onRequestSubmit={performAction}
      onRequestClose={closeModal}
      modalLabel={MODAL_LABEL}
      modalHeading={MODAL_HEADING(period)}
      primaryButtonText={isPerforming ? <InlineLoading /> : BUTTON_LABEL}
      primaryButtonDisabled={!isReportInLatestSchoolYear}
    >
      {isReportInLatestSchoolYear ? (
        <div>
          {actionFeedback}
          <Blockquote title={AMENDING_REPORT_AS}>
            {currentUser.other_names} {currentUser.last_name}
          </Blockquote>
          <div style={{ marginTop: 16 }}>{EXPLANATION_1}</div>
          {rejectionLogEntry && <LogEntry log_entry={rejectionLogEntry} />}
          <div style={{ marginTop: 16 }}>{EXPLANATION_2}</div>
        </div>
      ) : (
        <Blockquote kind="error" title={REPORT_CANNOT_BE_AMENDED}>
          <FormattedMessage
            id="ReportActionAmend.reportInPreviousSchoolYear"
            defaultMessage="Cannot amend this report because it is in a previous school year"
          />
        </Blockquote>
      )}
    </ModalExtended>
  );
};

export default ReportModalAmend;
