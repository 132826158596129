import React from "react";
import { FormattedMessage } from "react-intl";
import {
  deliveryCategory,
  studentAttendanceCategory,
  takeHomeRationCategory,
} from "SCConstants";

export default ({
  store,
}: {
  store: { model: string; category: string };
}): React.ReactElement | null => {
  const model: string | undefined = store?.model;
  const category: string | undefined = store?.category;
  let result;
  if (model === "year") {
    result = <FormattedMessage id="storeModel_year" defaultMessage="Year" />;
  } else if (model === "attendance" && category === takeHomeRationCategory) {
    result = (
      <FormattedMessage
        id="storeModel_takehomeration"
        defaultMessage="Take Home Ration"
      />
    );
  } else if (model === "attendance" && category === deliveryCategory) {
    result = (
      <FormattedMessage
        id="storeModel_mealattendance"
        defaultMessage="Meal Attendance"
      />
    );
  } else if (model === "attendance" && category === studentAttendanceCategory) {
    result = (
      <FormattedMessage
        id="storeModel_studentattendance"
        defaultMessage="Student Attendance"
      />
    );
  } else if (model === "enrolment") {
    result = (
      <FormattedMessage id="storeModel_enrolment" defaultMessage="Enrolment" />
    );
  } else if (model === "delivery") {
    result = (
      <FormattedMessage id="storeModel_delivery" defaultMessage="Delivery" />
    );
  } else if (model === "incident") {
    result = (
      <FormattedMessage id="storeModel_incident" defaultMessage="Incident" />
    );
  } else if (model === "purchasedetail") {
    result = (
      <FormattedMessage
        id="storeModel_purchasedetail"
        defaultMessage="Purchase detail"
      />
    );
  } else if (model === "offlineuser") {
    result = (
      <FormattedMessage
        id="storeModel_offlineUser"
        defaultMessage="Offline user"
      />
    );
  } else if (model === "report") {
    result = (
      <FormattedMessage id="storeModel_report" defaultMessage="Report" />
    );
  } else {
    result = null;
  }
  return result;
};
