import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { LOGOUT } from "data-handler/ducks/auth";
/**
 * Logs out, redirects to root, renders `null`
 */
export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  dispatch({ type: LOGOUT });
  history.push("");
  return null;
};
