import React from "react";

import { iconWfpLogoVerticalEn } from "@wfp/icons";

import styles from "./login.module.scss";

import { Icon, Button } from "@wfp/ui";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

import "./login.module.scss";

const LoginWrapper = ({ children, rightSide, showBackLink }) => (
  <div className={styles.login}>
    <div className={styles.loginForm}>
      <div className={styles.loginLogo}>
        <Icon icon={iconWfpLogoVerticalEn} />
      </div>

      {showBackLink && (
        <Link to="/login" className={styles.backLink}>
          <Button
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            iconReverse
            kind="secondary"
            className={styles.backLink}
          >
            Back to login
          </Button>
        </Link>
      )}
      <div className={styles.loginFormContent}>{children}</div>
    </div>
    <div className={styles.loginContent}>
      {rightSide ? (
        rightSide
      ) : (
        <div>
          <h2>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt
          </h2>
          <p>
            Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
            ut labore et dolore magna aliquyam erat, sed diam voluptua.
          </p>
        </div>
      )}
    </div>
  </div>
);

export default LoginWrapper;
