import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetStore, updateStore } from "data-handler/ducks/stores";
import { useRef } from "react";
import { clearDeliveryOptions } from "data-handler/ducks/deliveries";
import { clearBatchNumberOptions } from "data-handler/ducks/batchNumbers";

/**
 * Returns methods for dealing with syncables' forms.
 */
export default function useStore() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const submitBtnRef = useRef();

  const dispatchResetStore = (id, customRedirectPath = undefined) => {
    dispatch(resetStore(id));
    if (customRedirectPath) {
      history.push(customRedirectPath);
    }
  };

  const dispatchUpdateStore = (formValues, customRedirectPath = undefined) => {
    const storeActionData = dispatch(updateStore(formValues));
    closeModal(storeActionData, customRedirectPath);
  };

  const closeModal = (storeActionData, customRedirectPath = undefined) => {
    dispatch(clearDeliveryOptions());
    dispatch(clearBatchNumberOptions());
    if (customRedirectPath) {
      history.push(customRedirectPath);
    } else {
      history.push(
        `/school/${params.schoolId}/${
          history.location.pathname.split("/")[3]
        }/${storeActionData ? storeActionData.data.id : params.item}`
      );
    }
  };

  const submitViaBtnRefClick = () => {
    submitBtnRef.current.click();
  };

  return {
    resetStore: dispatchResetStore,
    updateStore: dispatchUpdateStore,
    closeModal,
    submitViaBtnRefClick,
    submitBtnRef,
  };
}
