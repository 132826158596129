import moment from "moment";

/**
 * JS modulo operator doesn't return a positive integer. Weird.
 *
 * So we had to pull this snippet from https://stackoverflow.com/a/17323608
 */
function mod(n, m) {
  return ((n % m) + m) % m;
}

/**
 * Returns an int representing the weekday on the given `date`
 *
 * where:
 * 0 is Monday
 * 1 is Tuesday
 * ...
 * 6 is Sunday
 */
export const getWeekDay = (date) => {
  // Adjust weekday. `moment.day()` thinks sunday is 0, we need monday to be 0
  let weekDay = moment(date).day() - 1;
  weekDay = mod(weekDay, 7);
  return weekDay;
};

/**
 * Sorts the given months object from newest to oldest
 */
export function getSortedMonths(months) {
  return Object.keys(months).sort((a, b) =>
    moment(months[a].starts_on).isBefore(months[b].starts_on, "month") ? 1 : -1
  );
}

/**
 * Gets the months in a given school year up until the current time
 * and it returns them as an object of objects add the starts_on,
 * ends_on, collapsed and year fields to each month
 */
export function getMonthsInSchoolYear(schoolYear, schoolYears) {
  const startDate = moment(schoolYear.starts_on);
  const endDate = moment(schoolYear.ends_on);
  const months = {};
  while (
    (endDate.isAfter(startDate) ||
      startDate.format("M") === endDate.format("M")) &&
    startDate.isSameOrBefore(moment().format("YYYY-MM-DD"), "month")
  ) {
    let ends_on = null;
    const startDateFormatted = startDate.startOf("month").format("YYYY-MM-DD");
    const endDateFormatted = startDate.endOf("month").format("YYYY-MM-DD");
    if (moment().isBefore(schoolYear.ends_on)) {
      ends_on = moment(endDateFormatted).isSameOrBefore(
        moment().format("YYYY-MM-DD")
      )
        ? endDateFormatted
        : moment().format("YYYY-MM-DD");
    } else {
      ends_on = moment(endDateFormatted).isSameOrBefore(schoolYear.ends_on)
        ? endDateFormatted
        : schoolYear.ends_on;
    }
    months[startDate.format("MM")] = {
      starts_on: moment(startDateFormatted).isSameOrAfter(schoolYear.starts_on)
        ? startDateFormatted
        : schoolYear.starts_on,
      ends_on: ends_on,
      collapsed: true,
      year: startDate.format("YYYY"),
    };
    startDate.add(1, "month");
  }
  // If the school year is the first one, expand its first month
  if (schoolYears.indexOf(schoolYear) === 0) {
    months[getSortedMonths(months)[0]] = {
      ...months[getSortedMonths(months)[0]],
      collapsed: false,
    };
  }
  return months;
}
