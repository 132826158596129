import React, { useEffect } from "react";
import { usePagination, useTable, useSortBy, useRowSelect } from "react-table";

import "./_content.scss";
import Pagination from "./Pagination";
import TableActions from "./TableActions";
const DefaultFilter = (props) => {
  const {
    column: { filterValue, setFilter },
  } = props;
  return (
    <input
      value={filterValue || ""}
      type="text"
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={"Search"}
      className={"wfp--text-input wfp--text__input"}
    />
  );
};

const Table = (props) => {
  const {
    showPagination = true,
    showSort = false,
    showActions = false,
    pageSizes = [10, 20, 30, 40, 50],
    disableFields = false,
  } = props;

  const defaultColumn = React.useMemo(
    () => ({ Filter: DefaultFilter, width: 10 }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      manualSortBy: true,
      autoResetSelectedRows: true,
      autoResetSortBy: false,
      initialState: props.initialState || {},
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const getOrdering = () =>
    sortBy.map(({ id, desc }) => `${desc ? "-" : ""}${id}`).join(",");

  useEffect(() => {
    const ordering = getOrdering();
    props.loadPage(pageSize, pageIndex + 1, ordering);
    // The linter complains that this effect `props`, or `loadPage`
    // from the deconstruction of `props`, is not in the dependency
    // list. Adding one of them, however, will cause this effect to
    // be called endlessly.
    // eslint-disable-next-line
  }, [sortBy, pageSize, pageIndex]);

  useEffect(() => {
    if (props.setSelected) props.setSelected(selectedRowIds);
    /* eslint-disable-next-line */
  }, [selectedRowIds]);

  useEffect(() => {
    setPageSize(props.pageSize);
  }, [setPageSize, props.pageSize]);

  const onChange = (event) => {
    const ordering = getOrdering();
    setPageSize(event.pageSize);
    props.loadPage(event.pageSize, event.page, ordering);
  };

  const paginationProps = {
    pageSizes,
    page: props.pageNum,
    onChange,
    pageSize,
    totalItems: props.totalItems,
  };

  const getHeaderStyle = ({ id }) => {
    if (id === "checkbox") {
      return disableFields
        ? { width: "10px", "pointer-events": "none", opacity: "0.5" }
        : { width: "10px" };
    } else if (props.columns.find((item) => item.accessor === id).width) {
      return {
        width: `${props.columns.find((item) => item.accessor === id).width}px`,
      };
    } else return;
  };

  return (
    <>
      <div className="admin-table-container">
        <table className="admin-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{ ...getHeaderStyle(column) }}
                  >
                    {showSort && column.canSort ? (
                      <div {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        <br />
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ↓"
                            : " ↑"
                          : ""}
                      </div>
                    ) : (
                      column.render("Header")
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      style={
                        disableFields && cell?.column?.id === "checkbox"
                          ? { "pointer-events": "none", opacity: "0.5" }
                          : {}
                      }
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showPagination && <Pagination {...paginationProps} />}
      {showActions && (
        <TableActions selectedRows={selectedRowIds} actions={props.actions} />
      )}
    </>
  );
};

export default Table;
