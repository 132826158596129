import React from "react";
import { FormattedMessage } from "react-intl";

export const textInputFields = [
  {
    id: "name",
    name: "name",
    labelText: (
      <FormattedMessage id="CountryAdmin.name" defaultMessage="Name" />
    ),
    required: true,
  },
  {
    id: "geo_coords_long",
    name: "geo_coords_long",
    labelText: (
      <FormattedMessage
        id="CountryAdmin.geoCoordsLong"
        defaultMessage="Geo coords long"
      />
    ),
    required: false,
  },
  {
    id: "geo_coords_lat",
    name: "geo_coords_lat",
    labelText: (
      <FormattedMessage
        id="CountryAdmin.geoCoordsLat"
        defaultMessage="Geo coords lat"
      />
    ),
    required: false,
  },
  {
    id: "geo_coords_alt",
    name: "geo_coords_alt",
    labelText: (
      <FormattedMessage
        id="CountryAdmin.geoCoordsAlt"
        defaultMessage="Geo coords alt"
      />
    ),
    required: false,
  },
  {
    id: "programme_manager",
    name: "programme_manager",
    labelText: (
      <FormattedMessage
        id="CountryAdmin.programmeManager"
        defaultMessage="Programme manager"
      />
    ),
    required: false,
  },
  {
    id: "school_type",
    name: "school_type",
    labelText: (
      <FormattedMessage
        id="CountryAdmin.schoolType"
        defaultMessage="School type"
      />
    ),
    required: false,
  },
];

export const selectFields = [
  {
    id: "country",
    name: "country",
    labelText: (
      <FormattedMessage id="CountryAdmin.country" defaultMessage="Country" />
    ),
    isMulti: false,
  },
  {
    id: "profile",
    name: "profile",
    labelText: (
      <FormattedMessage
        id="CountryAdmin.schoolProfile"
        defaultMessage="School profile"
      />
    ),
    isMulti: false,
  },
  {
    id: "authorities",
    name: "authorities",
    labelText: (
      <FormattedMessage
        id="CountryAdmin.schoolAuthorities"
        defaultMessage="Authorities"
      />
    ),
    isMulti: true,
  },
];

export const stateOptions = [
  {
    value: "active",
    label: (
      <FormattedMessage id="countryAdmin.active" defaultMessage="Active" />
    ),
  },
  {
    value: "inactive",
    label: (
      <FormattedMessage id="countryAdmin.inactive" defaultMessage="Inactive" />
    ),
  },
];
