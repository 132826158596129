import React from "react";
import { useSelector } from "react-redux";
import { getRoleList } from "../../data-handler/ducks/auth";

export default function Gate({
  children,
  // mode = "render",
  forbidRoles = [],
  allowRoles = [],
  isForbidden,
}) {
  const currentUserRoles = useSelector(getRoleList);

  // if isForbidden is passed and equals to `true`, don't show children components
  if (isForbidden) {
    return <>{null}</>;
  }
  if (currentUserRoles.filter((role) => role["admin"]).length > 0)
    return <>{children}</>; // allow admin access
  // if allowRoles or forbidRoles weren't passed, allow access by default, otherwise check if user has certain roles
  const userHasAllowedRole =
    allowRoles.length === 0
      ? true
      : currentUserRoles
          .map((role) => allowRoles.filter((allow) => role[allow]))
          .filter((e) => e.length > 0).length > 0;
  const userHasForbiddenRole =
    forbidRoles.length === 0
      ? false
      : currentUserRoles
          .map((role) => forbidRoles.filter((forbid) => role[forbid]))
          .filter((e) => e.length > 0).length > 0
      ? true
      : false;

  if (userHasAllowedRole && !userHasForbiddenRole) return <>{children}</>;
  return <>{null}</>; //TODO: use 'mode' to either return null or pass 'disabled' to children
}
