import axios from "axios";
import { call, put, takeLatest } from "@redux-saga/core/effects";

import { successToast, errorToast } from "data-handler/ducks/toast";
import { toastFormattedMessages } from "SCConstants";

export const LEVELS_REQUEST = "schoolconnect/levelagemapping/REQUEST";
export const LEVELS_SUCCESS = "schoolconnect/levelagemapping/SUCCESS";
export const LEVELS_FAILURE = "schoolconnect/levelagemapping/FAILURE";
export const AGE_GROUPS_REQUEST = "schoolconnect/agegroups/REQUEST";
export const AGE_GROUPS_SUCCESS = "schoolconnect/agegroups/SUCCESS";
export const AGE_GROUPS_FAILURE = "schoolconnect/agegroups/FAILURE";
export const UPDATE_LEVELS_REQUEST =
  "schoolconnect/updatelevelagemapping/REQUEST";
export const UPDATE_LEVELS_SUCCESS =
  "schoolconnect/updatelevelagemapping/SUCCESS";
export const UPDATE_LEVELS_FAILURE =
  "schoolconnect/updatelevelagemapping/FAILURE";
export const CLEAR_LEVELS = "schoolconnect/levelagemapping/CLEAR_LEVELS";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  levels: [],
  cometAgeGroups: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LEVELS_REQUEST:
      return { ...state, fetching: true, error: null };
    case LEVELS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    case LEVELS_SUCCESS:
      let levels = action.response?.data?.results;
      const getLevelIndex = (string, index) => string.split("-")[index];
      // Sort into youngest to oldest
      const pre = levels
        .filter((level) => getLevelIndex(level.level, 0) === "pre")
        .sort((a, b) => getLevelIndex(a.level, 1) - getLevelIndex(b.level, 1));
      const primary = levels
        .filter((level) => getLevelIndex(level.level, 0) === "primary")
        .sort((a, b) => getLevelIndex(a.level, 1) - getLevelIndex(b.level, 1));
      const staff = levels
        .filter((level) => getLevelIndex(level.level, 0) === "staff")
        .sort((a, b) => getLevelIndex(a.level, 1) - getLevelIndex(b.level, 1));
      return {
        ...state,
        fetching: false,
        levels: [...pre, ...primary, ...staff],
      };
    case AGE_GROUPS_REQUEST:
      return { ...state, fetching: true, error: null };
    case AGE_GROUPS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    case AGE_GROUPS_SUCCESS:
      return {
        ...state,
        fetching: false,
        cometAgeGroups: action.response?.data?.results,
      };
    case CLEAR_LEVELS:
      return initialState;
    default:
      return state;
  }
}

export const getLevelAgeMappings = (state) => state.ageGroups.levels;
export const getCometAgeGroups = (state) => state.ageGroups.cometAgeGroups;

export const requestLevelGroupMappings = (countryId) => ({
  type: LEVELS_REQUEST,
  countryId,
});

export const requestCometAgeGroups = () => ({
  type: AGE_GROUPS_REQUEST,
});

export const updateLevelGroupMappings = (data) => ({
  type: UPDATE_LEVELS_REQUEST,
  data,
});

export const clearLevels = () => ({ type: CLEAR_LEVELS });

export function* levelAgeGroupsSagaWatcher() {
  yield takeLatest(LEVELS_REQUEST, levelAgeMappingsWorker);
}

export function* cometAgeGroupsSagaWatcher() {
  yield takeLatest(AGE_GROUPS_REQUEST, cometAgeGroupsWorker);
}

export function* updateLevelMappingsSagaWatcher() {
  yield takeLatest(UPDATE_LEVELS_REQUEST, updateLevelMappingsWorker);
}

function fetchLevelAgeMappings(payload) {
  const { countryId } = payload;
  const url =
    `${process.env.REACT_APP_API_URL}/level-age-mapping/` +
    (countryId ? `?country=${countryId}` : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function fetchCometAgeGroups() {
  const url = `${process.env.REACT_APP_API_URL}/comet-age-groups/`;
  return axios({
    method: "GET",
    url: url,
  });
}

function updateLevelGroups(action) {
  const { data } = action;
  const url = `${process.env.REACT_APP_API_URL}/level-age-mapping/`;

  return axios(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}

function* levelAgeMappingsWorker(payload) {
  try {
    const response = yield call(fetchLevelAgeMappings, payload);

    yield put({ type: LEVELS_SUCCESS, response });
  } catch (error) {
    yield put({ type: LEVELS_FAILURE, error });
  }
}

function* cometAgeGroupsWorker(payload) {
  try {
    const response = yield call(fetchCometAgeGroups, payload);

    yield put({ type: AGE_GROUPS_SUCCESS, response });
  } catch (error) {
    yield put({ type: AGE_GROUPS_FAILURE, error });
  }
}

function* updateLevelMappingsWorker(action) {
  try {
    yield call(updateLevelGroups, action);
    yield put({ type: UPDATE_LEVELS_SUCCESS });
    yield put(successToast());
  } catch (error) {
    yield put({ type: UPDATE_LEVELS_FAILURE, error });
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}
