import React from "react";
import { useIntl } from "react-intl";
import { Modal } from "@wfp/ui";
export default ({
  confirmationModalState,
  modalType,
  setConfirmationModalState,
  onConfirmationModalSubmit,
  submitMessage,
}) => {
  const intl = useIntl();

  const modalPrimaryButtonText = (modalType) =>
    modalType === "delete"
      ? intl.formatMessage({
          id: "schoolProfile.modalDelete",
          defaultMessage: "Delete",
        })
      : intl.formatMessage({
          id: "Report.actionSubmit",
          defaultMessage: "Submit",
        });

  return (
    <Modal
      open={confirmationModalState}
      modalHeading={intl.formatMessage({
        id: "countyAdmin.modalConfirmation",
        defaultMessage: "Confirmation",
      })}
      primaryButtonText={modalPrimaryButtonText(modalType)}
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      danger={modalType === "delete"}
      onSecondarySubmit={() => setConfirmationModalState(false)}
      onRequestSubmit={onConfirmationModalSubmit}
      onRequestClose={() => setConfirmationModalState(false)}
    >
      {submitMessage}
    </Modal>
  );
};
