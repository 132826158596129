import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { useState } from "react";
import axios from "axios";
import { errorToast } from "data-handler/ducks/toast";

export default function (
  dispatch,
  populateCurrentDistributionReportFields,
  setManualOrAutomaticFilling
) {
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    (code) => {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/distribution-reports/distribution-report-header/`,
          {
            params: {
              distribution_report_code: code,
            },
          }
        )
        .then((response) => {
          populateCurrentDistributionReportFields(response.data);
          setLoading(false);
          setManualOrAutomaticFilling("automatic");
        })
        .catch((error) => {
          setLoading(false);
          dispatch(
            errorToast(
              <FormattedMessage
                id="distributionReport.distributionReportHeaderImportError"
                defaultMessage="An error has when trying to import the distribution report with code {code}. Please check your information"
                values={{ code: code }}
              />
            )
          );
        });
    },
    [
      dispatch,
      populateCurrentDistributionReportFields,
      setManualOrAutomaticFilling,
    ]
  );

  return { loading, fetchData };
}
