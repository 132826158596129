import React from "react";

import { AttendanceItemProps } from "./types";
import MealAttendanceItem from "./MealAttendanceItem";
import StudentAttendanceItem from "./StudentAttendanceItem";
import THRAttendanceItem from "./THRAttendanceItem";
import {
  deliveryCategory,
  studentAttendanceCategory,
  takeHomeRationCategory,
} from "SCConstants";

const AttendanceItem: React.ComponentType<AttendanceItemProps> = ({
  currentStoreData,
  store,
  studentStore,
  thrStore,
  currentSchoolEnabledStudentAttendance,
}) => {
  let Component;
  if (store.category === deliveryCategory) {
    Component = MealAttendanceItem;
  } else if (store.category === studentAttendanceCategory) {
    Component = StudentAttendanceItem;
  } else if (store.category === takeHomeRationCategory) {
    Component = THRAttendanceItem;
  } else {
    // I do not know why this part exists but sometimes we get attendance
    // that does not have any category.
    // For those cases I am keeping previous behaviour.
    Component = MealAttendanceItem;
  }

  return (
    <Component
      currentStoreData={currentStoreData}
      store={store}
      studentStore={studentStore}
      thrStore={thrStore}
      currentSchoolEnabledStudentAttendance={
        currentSchoolEnabledStudentAttendance
      }
    />
  );
};

export default AttendanceItem;
