import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  getCurrentSchoolYear,
  getUpcomingSchoolYear,
  getPreviousSchoolYears,
} from "data-handler/ducks/stores";

import SidebarWrapper from "components/SidebarWrapper";
import StartASchoolYearFirst from "components/StartASchoolYearFirst";

import DeliveriesSidebar from "./DeliveriesSidebar";
import DeliveriesContent from "./DeliveriesContent";

import "./_deliveries.scss";

const Deliveries = () => {
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  // Deliveries are not necessarily related to an ongoing school year,
  // they may also arrive in preparation of an upcoming school year
  // or they can depend on previous school years
  const upcomingSchoolYear = useSelector(getUpcomingSchoolYear);
  const previousSchoolYears = useSelector(getPreviousSchoolYears);

  if (
    !currentSchoolYear &&
    !upcomingSchoolYear &&
    previousSchoolYears.length === 0
  ) {
    return <StartASchoolYearFirst />;
  }

  return (
    <>
      <SidebarWrapper
        title={
          <FormattedMessage
            id="Deliveries.deliveries"
            defaultMessage="Deliveries"
          />
        }
      >
        <DeliveriesSidebar
          currentSchoolYear={currentSchoolYear}
          previousSchoolYears={previousSchoolYears}
        />
      </SidebarWrapper>
      <DeliveriesContent />
    </>
  );
};

export default Deliveries;
