import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@wfp/ui";
import {
  listObjectsToListIds,
  listToObject,
} from "data-handler/ducks/paginationTools";
import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";
import { boolIconCell } from "components/CountryAdmin/CountryAdminContent/Table/Utils";

import "./_content.scss";
import { localFiltering, localPaginate } from "./localTableHelper";

export default ({
  methods,
  initialSelectedCommodities,
  setUpdatedCommodities,
  pageNum,
  setPageNum,
  pageSize,
  setPageSize,
  filtersState,
  setFilters,
}) => {
  const intl = useIntl();
  const [paginatedCommodities, setPaginatedCommodities] = useState([]);
  const [selectedCommodities, setSelectedCommodities] = useState(
    initialSelectedCommodities.sort((a, b) => (a.name < b.name ? -1 : 1))
  );

  const { register, setValue } = methods;

  useEffect(() => {
    register("commodities");
  }, [register]);

  const loadPage = (size, idx, ordering, filters = filtersState) => {
    setPageSize(size);
    setPageNum(idx);

    // Filtering
    let filtered = localFiltering(
      filters,
      setSelectedCommodities,
      initialSelectedCommodities
    );

    // Pagination
    const commodityArray = localPaginate(pageSize, idx, size, filtered);
    setPaginatedCommodities(commodityArray);
  };

  useEffect(() => {
    // Reload table when initialSelectedCommodities are changed.
    // This happens when adding from available commodities.
    loadPage(pageSize, pageNum);
    setValue("commodities", initialSelectedCommodities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelectedCommodities]);

  const commodityData = {
    count: selectedCommodities?.length,
    ids: listObjectsToListIds(paginatedCommodities, "id"),
    results: listToObject(paginatedCommodities, "id"),
  };

  const { count = 0, ids = [] } = commodityData;
  let data = ids.map((id) => {
    return commodityData.results[id];
  });

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.name",
          defaultMessage: "Name",
        }),
        accessor: "name",
        width: 500,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.LESSCode",
          defaultMessage: "Code",
        }),
        accessor: "commodity_gateway.code",
        width: 350,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.measureUnit",
          defaultMessage: "Measure Unit",
        }),
        accessor: "measure_unit.name",
        width: 350,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.isFood",
          defaultMessage: "Is food",
        }),
        accessor: "is_food",
        Cell: boolIconCell,
        width: 350,
      },
      {
        Header: "",
        accessor: "remove",
        Cell: ({ cell: { row } }) => (
          <Button onClick={() => setUpdatedCommodities(row.original)}>
            {intl.formatMessage({
              id: "CountryAdmin.remove",
              defaultMessage: "Remove",
            })}
          </Button>
        ),
        width: 150,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl]
  );

  const typeOptions = [
    {
      name: "all",
      label: intl.formatMessage({
        id: "CountryAdmin.allCommodities",
        defaultMessage: "All Commodities",
      }),
    },
    {
      name: "true",
      label: intl.formatMessage({
        id: "CountryAdmin.wfpCommodities",
        defaultMessage: "WFP Commodities",
      }),
    },
    {
      name: "false",
      label: intl.formatMessage({
        id: "CountryAdmin.otherCommodities",
        defaultMessage: "Other items",
      }),
    },
  ];

  const filterProps = {
    fields: [
      {
        name: "name",
        label: intl.formatMessage({
          id: "CountryAdmin.name",
          defaultMessage: "Name",
        }),
      },
      {
        name: "commodity_gateway.code",
        label: intl.formatMessage({
          id: "CountryAdmin.LESSCode",
          defaultMessage: "Code",
        }),
      },
      {
        name: "is_wfp",
        label: intl.formatMessage({
          id: "CountryAdmin.type",
          defaultMessage: "Type",
        }),
        type: "select",
        options: typeOptions,
      },
    ],
    defaults: filtersState?.reduce((a, x) => ({ ...a, [x?.id]: x?.value }), {}),
    onSubmit: (e) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
    showPagination: count > 5 ? true : false,
    pageSizes: count > 50 ? [5, 10, 15, 20, 50, count] : [5, 10, 15, 20, 50],
  };

  return (
    <div
      style={{ paddingBottom: 16 }}
      className={"currently-selected-commodities-table"}
    >
      <Filtering {...filterProps} />
      <Table {...tableProps} />
    </div>
  );
};
