import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { ListItem, List } from "@wfp/ui";

import SidebarItem from "components/SidebarItem";
import { getCurrentSchoolYear } from "data-handler/ducks/stores";
import { getSchoolYearName } from "helpers/year";

export default function YearItem({ currentStoreData, store, ...extraProps }) {
  const currentYearStore = useSelector((state) => getCurrentSchoolYear(state));
  const params = useParams();

  const isSelected =
    currentStoreData && currentStoreData.client_id === store.client_id;

  const schoolYearName = getSchoolYearName(store);

  const currentSchoolYearTitle = (
    <FormattedMessage
      id="YearItem.current_school_year"
      defaultMessage="Current school year {schoolYearName}"
      values={{ schoolYearName }}
    />
  );

  const previousSchoolYearTitle = (
    <FormattedMessage
      id="YearItem.previous_school_year"
      defaultMessage="Previous school year {schoolYearName}"
      values={{ schoolYearName }}
    />
  );

  const futureSchoolYearTitle = (
    <FormattedMessage
      id="YearItem.future_school_year"
      defaultMessage="Future school year {schoolYearName}"
      values={{ schoolYearName }}
    />
  );

  const now = moment();
  const otherYearsTitle = now.isAfter(store.starts_on)
    ? previousSchoolYearTitle
    : futureSchoolYearTitle;

  const title =
    currentYearStore && currentYearStore.client_id === store.client_id
      ? currentSchoolYearTitle
      : otherYearsTitle;

  return (
    <SidebarItem
      {...extraProps}
      store={store}
      selected={isSelected}
      id={store.client_id}
      title={title}
      subTitle={
        <>
          <List kind="simple" colon>
            <ListItem
              title={
                <FormattedMessage
                  id="YearItem.start_date"
                  defaultMessage="Start date"
                />
              }
            >
              {moment(store.starts_on).format("DD.MM.YYYY")}
            </ListItem>
            <ListItem
              title={
                <FormattedMessage
                  id="YearItem.end_date"
                  defaultMessage="End date"
                />
              }
            >
              {moment(store.ends_on).format("DD.MM.YYYY")}
            </ListItem>
          </List>
        </>
      }
      to={`/school/${params.schoolId}/info/${store.client_id}`}
    ></SidebarItem>
  );
}
