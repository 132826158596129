import React from "react";
import { useSelector } from "react-redux";

import commoditiesDuck from "data-handler/ducks/commodities";
import MeasureUnitValue from "components/MeasureUnitValue";

import { missingCommodity } from "data-handler/ducks/reports";

import "./_commodities-show.scss";
import { displayByCommodityId } from "components/ItemSwitcher/PurchaseItem";
import { useParams } from "react-router";

type Commodity = {
  commodity: number;
};

type CommodityShowProps = {
  commodities: Commodity[];
};

const CommodityShow: React.ComponentType<
  CommodityShowProps & { className?: string }
> = ({ commodities }: CommodityShowProps) => {
  const commodityIndex = useSelector(commoditiesDuck.getIndex);

  const getCommodity = (id: number) => {
    const defaultCommodity = {
      ...missingCommodity,
      name: `Unidentified(${id})`,
    };
    return commodityIndex[id] || defaultCommodity;
  };

  const params: any = useParams();
  const selectedCommodityId = parseInt(params.item);
  if (!commodities || commodities.length === 0) {
    return <div className="commodities-show-small">no commodities found</div>;
  }
  const isCommodityInParams = !!commodities.find(
    (i) => i.commodity === selectedCommodityId
  );

  let commoditiesById;
  if (isCommodityInParams) {
    commoditiesById = displayByCommodityId(selectedCommodityId, commodities);
  } else {
    commoditiesById = commodities;
  }
  return (
    <div className="commodities-show-small">
      {commoditiesById?.map((commodity: any) => {
        const commodityInfo = getCommodity(commodity.commodity);
        return (
          <div
            key={commodity.commodity}
            className="commodities-show-small__item"
          >
            <div className="commodities-show-small__commodity">
              {commodityInfo && commodityInfo.name}
            </div>
            <div className="commodities-show-small__quantity">
              <MeasureUnitValue measureUnit={commodityInfo?.measure_unit}>
                {commodity.quantity}
              </MeasureUnitValue>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommodityShow;
