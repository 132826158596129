import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  listObjectsToListIds,
  listToObject,
} from "data-handler/ducks/paginationTools";
import { successToast, errorToast } from "data-handler/ducks/toast";
import { toastFormattedMessages } from "SCConstants";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  schoolProfiles: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  allSchoolProfiles: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "API_CALL_SET_SCHOOL_PROFILES_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_SET_SCHOOL_PROFILES_SUCCESS":
      return {
        ...state,
        schoolProfiles: action.data,
        fetching: false,
        error: null,
      };
    case "API_CALL_SET_SCHOOL_PROFILES_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_SET_DETAILED_SCHOOL_PROFILE_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_SET_DETAILED_SCHOOL_PROFILE_SUCCESS":
      const { currentSchoolProfile } = action;
      const currentSchoolProfileObject = {
        [currentSchoolProfile.id]: currentSchoolProfile,
      };
      const updateObject = {
        schoolProfiles: {
          ...state.schoolProfiles,
          results: {
            ...state.schoolProfiles.results,
            ...currentSchoolProfileObject,
          },
        },
      };
      return {
        ...state,
        ...updateObject,
        fetching: false,
        error: null,
      };
    case "API_CALL_SET_DETAILED_SCHOOL_PROFILE_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_PUT_DETAILED_SCHOOL_PROFILE_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_PUT_DETAILED_SCHOOL_PROFILE_SUCCESS":
      return {
        ...state,
        fetching: false,
        error: null,
      };
    case "API_CALL_PUT_DETAILED_SCHOOL_PROFILE_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_SET_NEW_SCHOOL_PROFILE_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_SET_NEW_SCHOOL_PROFILE_SUCCESS":
      return { ...state, fetching: false, error: null };
    case "API_CALL_SET_NEW_SCHOOL_PROFILE_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_DELETE_SCHOOL_PROFILE_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_DELETE_SCHOOL_PROFILE_SUCCESS":
      return { ...state, fetching: false, error: null };
    case "API_CALL_DELETE_SCHOOL_PROFILE_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_SET_SCHOOL_PROFILE_NON_PAGINATED_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_SET_SCHOOL_PROFILE_NON_PAGINATED_SUCCESS":
      return {
        ...state,
        fetching: false,
        allSchoolProfiles: action.data,
        error: null,
      };
    case "API_CALL_SET_SCHOOL_PROFILE_NON_PAGINATED_RESET":
      return {
        ...state,
        allSchoolProfiles: null,
        error: null,
      };
    case "API_CALL_SET_SCHOOL_PROFILE_NON_PAGINATED_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}

const persistedReducer = persistReducer(
  {
    key: "schoolProfile",
    storage,
    blacklist: ["error", "errorResponse", "fetching"],
  },
  reducer
);

export default persistedReducer;

// Action creators
export const requestSchoolProfiles = (
  pageSize,
  pageIndex,
  ordering,
  filters
) => ({
  type: "API_CALL_SET_SCHOOL_PROFILES_REQUEST",
  pageSize,
  pageIndex,
  ordering,
  filters,
});

export const requestSchoolProfilesNonPaginated = (id) => ({
  type: "API_CALL_SET_SCHOOL_PROFILE_NON_PAGINATED_REQUEST",
  id,
});

export const requestDetailSchoolProfile = (id, history) => ({
  type: "API_CALL_SET_DETAILED_SCHOOL_PROFILE_REQUEST",
  id,
  history,
});

export const requestPutDetailSchoolProfile = (data, id, history) => ({
  type: "API_CALL_PUT_DETAILED_SCHOOL_PROFILE_REQUEST",
  data,
  id,
  history,
});

export const requestPostNewSchoolProfile = (data, history) => ({
  type: "API_CALL_SET_NEW_SCHOOL_PROFILE_REQUEST",
  data,
  history,
});

export const requestDeleteSchoolProfile = (profileId, history) => ({
  type: "API_CALL_DELETE_SCHOOL_PROFILE_REQUEST",
  profileId,
  history,
});

// Saga
export function* countryAdminSchoolProfilesSagaWatcher() {
  yield takeEvery(
    "API_CALL_SET_SCHOOL_PROFILES_REQUEST",
    schoolProfilesSagaWorker
  );
}

export function* countryAdminSchoolProfilesNonPaginatedSagaWatcher() {
  yield takeEvery(
    "API_CALL_SET_SCHOOL_PROFILE_NON_PAGINATED_REQUEST",
    schoolProfilesNonPaginatedSagaWorker
  );
}

export function* countryAdminDetailedSchoolProfileSagaWatcher() {
  yield takeEvery(
    "API_CALL_SET_DETAILED_SCHOOL_PROFILE_REQUEST",
    detailedSchoolProfileSagaWorker
  );
}

export function* countryAdminPostDetailedSchoolProfileSagaWatcher() {
  yield takeEvery(
    "API_CALL_PUT_DETAILED_SCHOOL_PROFILE_REQUEST",
    putDetailedSchoolProfileSagaWorker
  );
}

export function* countryAdminPostNewSchoolProfileSagaWatcher() {
  yield takeEvery(
    "API_CALL_SET_NEW_SCHOOL_PROFILE_REQUEST",
    postNewSchoolProfileSagaWorker
  );
}

export function* countryAdminDeleteSchoolProfileSagaWatcher() {
  yield takeEvery(
    "API_CALL_DELETE_SCHOOL_PROFILE_REQUEST",
    deleteSchoolProfileSagaWorker
  );
}

function fetchSchoolProfiles(action) {
  const page_size = action.pageSize;
  const page = action.pageIndex * page_size - page_size;
  const filters = action.filters;
  const ordering = action.ordering.replace(/\./g, "_");

  const url =
    `${process.env.REACT_APP_API_URL}/school-profiles/` +
    `?limit=${page_size}&offset=${page}` +
    (ordering ? `&ordering=${ordering}` : "") +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            if (value && value !== "") {
              return `&${id}=${value}`;
            }
            return "";
          })
          .join("")
      : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function fetchDetailedSchoolProfile(action) {
  const url = `${process.env.REACT_APP_API_URL}/school-profiles/${action.id}/`;
  return axios({
    method: "GET",
    url: url,
  });
}

function putDetailedSchoolProfile(action) {
  const url = `${process.env.REACT_APP_API_URL}/school-profiles/${action.id}/`;

  return axios(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(action.data),
  });
}

function postNewSchoolProfile(action) {
  const url = `${process.env.REACT_APP_API_URL}/school-profiles/`;

  return axios(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(action.data),
  });
}

function deleteSchoolProfile(action) {
  const url = `${process.env.REACT_APP_API_URL}/school-profiles/${action.profileId}`;

  return axios(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function fetchSchoolProfilesNonPaginated(payload) {
  const { id } = payload;
  const url =
    `${process.env.REACT_APP_API_URL}/school-profiles/non-paginated/` +
    (id ? `?country=${payload.id}` : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function* schoolProfilesSagaWorker(payload) {
  try {
    const response = yield call(fetchSchoolProfiles, payload);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");
    const data = {
      ...response.data,
      results,
      ids,
    };

    yield put({ type: "API_CALL_SET_SCHOOL_PROFILES_SUCCESS", data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "API_CALL_SET_SCHOOL_PROFILES_FAILURE", error });
  }
}

function* detailedSchoolProfileSagaWorker(payload) {
  try {
    const response = yield call(fetchDetailedSchoolProfile, payload);
    const currentSchoolProfile = response.data;

    yield put({
      type: "API_CALL_SET_DETAILED_SCHOOL_PROFILE_SUCCESS",
      currentSchoolProfile,
    });
  } catch (error) {
    const { history } = payload;
    history.push("/countryAdmin/schoolProfiles");
    // dispatch a failure action to the store with the error
    yield put({ type: "API_CALL_SET_DETAILED_SCHOOL_PROFILE_FAILURE", error });
  }
}

function* putDetailedSchoolProfileSagaWorker(payload) {
  try {
    const { history } = payload;
    const response = yield call(putDetailedSchoolProfile, payload);
    const data = response.data;

    yield put({ type: "API_CALL_PUT_DETAILED_SCHOOL_PROFILE_SUCCESS", data });
    yield put(successToast());
    history.push(`/countryAdmin/schoolProfiles/`);
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "API_CALL_PUT_DETAILED_SCHOOL_PROFILE_FAILURE", error });
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}

function* postNewSchoolProfileSagaWorker(payload) {
  try {
    const { history } = payload;
    const response = yield call(postNewSchoolProfile, payload);
    const data = response.data;

    yield put({ type: "API_CALL_SET_NEW_SCHOOL_PROFILE_SUCCESS", data });
    yield put(successToast());
    history.push(`/countryAdmin/schoolProfiles/`);
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "API_CALL_SET_NEW_SCHOOL_PROFILE_FAILURE", error });
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}

function* deleteSchoolProfileSagaWorker(payload) {
  try {
    const { history } = payload;
    const response = yield call(deleteSchoolProfile, payload);
    const data = response.data;

    yield put({ type: "API_CALL_DELETE_SCHOOL_PROFILE_SUCCESS", data });
    yield put(successToast());
    history.push(`/countryAdmin/schoolProfiles/`);
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "API_CALL_DELETE_SCHOOL_PROFILE_FAILURE", error });
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}

function* schoolProfilesNonPaginatedSagaWorker(payload) {
  try {
    yield put({
      type: "API_CALL_SET_SCHOOL_PROFILE_NON_PAGINATED_RESET",
    });
    const response = yield call(fetchSchoolProfilesNonPaginated, payload);
    const data = response.data;
    yield put({
      type: "API_CALL_SET_SCHOOL_PROFILE_NON_PAGINATED_SUCCESS",
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "API_CALL_SET_SCHOOL_PROFILE_NON_PAGINATED_FAILURE",
      error,
    });
  }
}

export const getSchoolProfiles = (state) => state.schoolProfile.schoolProfiles;

export const getSchoolProfileFetching = (state) => state.schoolProfile.fetching;

export const getSchoolProfileError = (state) => state.schoolProfile.error;

export const getAllSchoolProfilesNonPaginated = (state) =>
  state.schoolProfile.allSchoolProfiles;
