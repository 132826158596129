import {
  studentLevels,
  studentKinds,
  deliveryCategory,
  studentAttendanceCategory,
  StudentKind,
  AttendanceShift,
} from "SCConstants";
import {
  Attendance,
  getSchoolYearByDate,
  AttendanceLevel,
} from "data-handler/ducks/stores";
import { RootState } from "data-handler/rootReducer";
import moment from "moment";

/**
 * Returns the amount of students who attended on a given day and meet the criteria
 */
export const getFilteredAttendanceTotal = ({
  attendance,
  kinds = studentKinds.map((kind) => kind.value) as StudentKind[],
  levels = studentLevels.map((level) => level.value),
  shifts = ["morning", "afternoon"],
}: {
  attendance: Attendance;
  kinds?: StudentKind[];
  levels?: string[];
  shifts?: AttendanceShift[];
}) => {
  if (!attendance || !attendance.levels) return 0;
  let total = 0;
  attendance.levels.forEach((level) => {
    if (levels.includes(level.level) && shifts.includes(level.shift)) {
      kinds.forEach((kind) => {
        // https://github.com/microsoft/TypeScript/issues/38471
        total += parseInt((level[kind] as unknown) as string) || 0;
      });
    }
  });
  return total;
};

export const getPreviousStoreByDate = (
  date: any,
  model: string,
  category: string
) => (state: RootState) => {
  const stores = state.stores.stores;
  const attendanceStores = stores.filter(
    (store) => store.model === model && store.category === category
  );
  let currentRecentStore: any = undefined;
  let currentLowestDiff: any = undefined;

  // SchoolYear weekdays are mon-sun
  const schoolYear = getSchoolYearByDate(date)(state);
  const daysOfWeek = schoolYear?.weekdays
    ? [...schoolYear.weekdays]
    : [false, false, false, false, false, false, false];

  // Moment .days() are 0 indexed at sunday and saturday being 6
  // Moving sunday to the start of the list therefore each list is indexed the same.
  daysOfWeek?.unshift(daysOfWeek[6]);
  daysOfWeek?.pop();

  const twoWeeks = [...daysOfWeek, ...daysOfWeek];
  let diffThreshold = 0;

  const selectedDayConvertedToTwoWeeks = date.day() + 7;

  let nearestDay = false;
  for (let i = selectedDayConvertedToTwoWeeks - 1; nearestDay === false; i--) {
    diffThreshold++;
    nearestDay = twoWeeks[i];
  }

  attendanceStores.forEach((store: any) => {
    const occurred_on = moment(store.occurred_on);
    const diff = date.diff(occurred_on, "days");
    if (diff <= 0 || diff > currentLowestDiff) return;
    if (diff <= diffThreshold) {
      currentLowestDiff = diff;
      currentRecentStore = store;
    }
  });

  return currentRecentStore;
};

export const getPreviousAttendanceStoreByDate = (date: any) =>
  getPreviousStoreByDate(date, "attendance", deliveryCategory);
export const getPreviousStudentAttendanceStoreByDate = (date: any) =>
  getPreviousStoreByDate(date, "attendance", studentAttendanceCategory);

export const addAttendanceDataByDate = (store: any) => {
  let hasAttendance = false;
  store?.levels?.forEach((level: AttendanceLevel) => {
    if (level.female !== 0 || level.male !== 0) {
      hasAttendance = true;
    }
  });

  return hasAttendance ? store : undefined;
};

export const getPreviousAttendanceStoreWithAttendanceDataByDate = (
  date: any
) => (state: RootState) =>
  addAttendanceDataByDate(getPreviousAttendanceStoreByDate(date)(state));
export const getPreviousStudentAttendanceStoreWithAttendanceDataByDate = (
  date: any
) => (state: RootState) =>
  addAttendanceDataByDate(getPreviousStudentAttendanceStoreByDate(date)(state));

export const getPreviousAttendanceStoreWithConsumptionDataByDate = (
  date: any
) => (state: RootState) => {
  const currentRecentStore = getPreviousAttendanceStoreByDate(date)(state);

  let hasConsumptionData =
    currentRecentStore?.consumption?.meal_provided === true &&
    currentRecentStore?.consumption?.commodities.length > 0;

  return hasConsumptionData ? currentRecentStore : undefined;
};

type AttendanceUrlDetails =
  | "new-attendance"
  | "attendance"
  | "new-attendance-previous-attendance"
  | "attendance-previous-attendance"
  | "new-attendance-prepopulate-enrolment"
  | "attendance-prepopulate-enrolment"
  | "new-attendance-previous-attendance"
  | "attendance-previous-attendance"
  | "student-attendance"
  | "student-attendance-prepopulate-enrolment"
  | "student-attendance-previous-attendance"
  | "new-student-attendance"
  | "new-student-attendance-prepopulate-enrolment"
  | "new-student-attendance-previous-attendance"
  | "take-home-ration-data"
  | "new-take-home-ration-data"
  | "meals"
  | "new-meals"
  | "meals-prepopulate-per-student"
  | "new-meals-prepopulate-per-student"
  | "meals-prepopulate-total-quantity"
  | "new-meals-prepopulate-total-quantity";

/**
 * Buids attendance url in a central location.
 *
 * At some point we should remove encoding relations into url and just query the data everytime we show a new page.
 * For now this function will connect every location we encode the relations between attendance, studentattendance, thr.
 * Here is some example url structures this function encapsulates.
 * /school/${params.schoolId}/attendance/${params.item}/${params.thrItem}/${params.studentItem}/new-attendance
 * /school/${params.schoolId}/attendance/${params.item}/${params.thrItem}/${params.studentItem}/${urlDetails}
 * /school/${params.schoolId}/attendance/${params.item}/${params.thrItem}/${newId ? newId : id}
 * /school/${params.schoolId}/attendance/${params.item}/${params.thrItem}/${params.studentItem}/${urlDetails}
 * /school/${params.schoolId}/attendance/${"newDate+" + moment(currentStoreData?.occurred_on).format("YYYY-MM-DD")}/${params.thrItem}/${params.studentItem}
 */
export const buildAttendanceUrl = (
  schoolId: string,
  attendanceId: string,
  thrId: string,
  studentAttendanceId: string,
  details?: AttendanceUrlDetails
): string => {
  let url = `/school/${schoolId}/attendance/${attendanceId}/${thrId}/${studentAttendanceId}`;
  if (details) {
    url = `${url}/${details}`;
  }
  return url;
};
