import React from "react";

const schoolsCheckCell = ({ row, flatRows }) => {
  const currentRowCountryName = row?.original?.country?.name;
  const currentRowState = row.original.state;
  let isDisabled = false;
  let selected = null;
  if (!row.isSelected) {
    for (let i = 0; i < flatRows.length; i++) {
      const currentRow = flatRows[i];
      if (currentRow.isSelected) {
        selected = currentRow.index;
        if (
          flatRows[selected]?.original.country?.name !==
            currentRowCountryName ||
          flatRows[selected]?.original.state !== currentRowState
        ) {
          isDisabled = true;
        }
      }
    }
  }
  return (
    <input
      type="checkbox"
      disabled={isDisabled}
      checked={row.isSelected}
      onChange={() => row.toggleRowSelected()}
    />
  );
};

const schoolsCheckHeader = ({ getToggleAllRowsSelectedProps, flatRows }) => {
  // If a the table contains at least two different countries / states. The "Select all" button will be disabled
  let isDisabled = false;
  for (let i = 0; i < flatRows.length; i++) {
    for (let k = 0; k < flatRows.length; k++) {
      if (
        flatRows[i]?.original.country?.name !==
          flatRows[k]?.original.country?.name ||
        flatRows[i]?.original.state !== flatRows[k]?.original.state
      ) {
        isDisabled = true;
      }
    }
  }
  const { indeterminate: ind, ...rest } = getToggleAllRowsSelectedProps();
  return (
    <input
      {...rest}
      disabled={isDisabled}
      type="checkbox"
      ref={(e) => e && (e.indeterminate = ind)}
    />
  );
};

export const schoolsCheckCol = {
  Header: schoolsCheckHeader,
  Cell: schoolsCheckCell,
  id: "checkbox",
  accessor: "",
  defaultCanFilter: false,
  disableFilters: true,
  defaultCanSort: false,
  disableSortBy: true,
};
