import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { userRegistrationRoles } from "helpers/users";

import PageTitle from "../../PageTitle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { Button, RadioButton, RadioButtonGroup } from "@wfp/ui";

import styles from "../registration.module.scss";
import "./_content.scss";

const RoleSelection = (props) => {
  const intl = useIntl();
  const [roleErrorMessage, setRoleErrorMessage] = useState("");

  const { userData, onRoleSubmit } = props;

  const { handleSubmit, errors, register } = useForm();

  const handleFormSubmit = (data) => {
    const role = data["role"];
    onRoleSubmit(role);
  };

  // Display the required access type message if there are errors
  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setRoleErrorMessage(
        intl.formatMessage({
          id: "Registration.noAccessTypeSelectedErrorMessage",
          defaultMessage: "Please select an access type before proceeding",
        })
      );
    } else {
      setRoleErrorMessage("");
    }
  }, [errors, intl]);

  return (
    <div className="role-selection-container">
      <form>
        <Link to="/login" className={styles.backLink}>
          <Button
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            iconReverse
            kind="secondary"
            className={styles.backLink}
          >
            <FormattedMessage
              id="Registration.backToLoginButton"
              defaultMessage="Back to login"
            />
          </Button>
        </Link>

        <PageTitle>
          <FormattedMessage
            id="User.RegisterToSchoolConnect"
            defaultMessage="Register to School Connect"
          />
        </PageTitle>

        <div style={{ marginBottom: "24px" }}>
          <FormattedMessage
            id="Registration.selectAccessTypeMessage"
            defaultMessage="Dear {firstName} {lastName}, please select your access type"
            values={{
              firstName: userData.other_names,
              lastName: userData.last_name,
            }}
          />
        </div>

        <RadioButtonGroup
          labelText={intl.formatMessage({
            id: "Registration.selectAccessType",
            defaultMessage: "Select Access Type",
          })}
          name="role"
        >
          {userRegistrationRoles.map((role) => (
            <div key={role.value} className="radio-role-selection">
              <span className="tooltiptext">{role.tooltip}</span>
              <RadioButton
                key={role.value}
                labelText={role.label}
                id={`role-${role.value}`}
                name="role"
                value={role.value}
                ref={register({ required: true })}
              />
            </div>
          ))}
        </RadioButtonGroup>
        {roleErrorMessage && (
          <p className={styles.submitErrorMessage}>{roleErrorMessage}</p>
        )}
      </form>
      <div className={styles.submitWrapper}>
        <div className={styles.button}>
          <Button onClick={handleSubmit(handleFormSubmit)}>
            <FormattedMessage
              id="Registration.nextButton"
              defaultMessage="Next"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RoleSelection;
