import React from "react";
import { Element } from "react-scroll";
import { NavLink } from "react-router-dom";

import classNames from "classnames";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

import SyncIcon from "components/SyncSelect/SyncIcon";
import LastEditor from "components/LastEditor";

import SidebarItemType from "./SidebarItemType";
import {
  Attendance,
  attendanceStoreSidebarItemStyling,
} from "data-handler/ducks/stores";

import "./_sidebar.scss";

type SidebarItemProps = {
  additionalRight?: React.ReactElement | null;
  icon?: React.ReactElement;
  selected: boolean;
  title: React.ReactElement | boolean;
  subTitle: React.ReactElement | boolean;
  thrTitle?: React.ReactElement | null | boolean;
  to: string;

  id?: number | string;
  comment?: React.ReactElement | boolean;
  kind?: string;
  highlight?: boolean;
  store?: Attendance;
  studentStore?: Attendance;
  thrStore?: Attendance;
  currentSchoolEnabledStudentAttendance?: boolean;
};

const SidebarItem: React.ElementType<SidebarItemProps> = ({
  children,
  additionalRight,
  comment,
  icon,
  id,
  kind,
  highlight,
  selected,
  store,
  studentStore,
  currentSchoolEnabledStudentAttendance,
  subTitle,
  thrTitle,
  title,
  to,
}) => {
  const classes = classNames({
    "sidebar-item": true,
    "sidebar-item--highlight": highlight,
    "sidebar-item--sync": kind === "sync",
    "sidebar-item--selected": selected,
    [`sidebar-item--${store && store.model}`]: store && store.model,
  });

  const date =
    store &&
    ((store as any).delivered_at ||
      (store as any).occurred_at ||
      (store as any).occurred_on ||
      (store as any).purchased_at);

  return (
    <Element
      key={id}
      className="sidebar-item__wrapper"
      name={`scroll-${store && store.client_id}`}
      style={attendanceStoreSidebarItemStyling(
        store!,
        studentStore!,
        currentSchoolEnabledStudentAttendance!
      )}
    >
      <NavLink
        className={classes}
        to={to ? to : "no link defined"}
        role="button"
        tabIndex={-2}
      >
        {icon && <div className="sidebar-item__icon">{icon}</div>}
        <div className="sidebar-item__content">
          <div className="sidebar-item__type">
            <SidebarItemType store={store!} />
          </div>
          <h5 className="sidebar-item__title">
            {title}
            {highlight && <FontAwesomeIcon icon={faChevronRight} />}
          </h5>
          <div className="sidebar-item__sub-title">{subTitle}</div>
          <p className="sidebar-item__comment">{comment}</p>

          {children}
          {thrTitle && <p className="sidebar-item__thr-title">{thrTitle}</p>}
          {store && store.last_edit && (
            <p className="sidebar-item__last-editor">
              <LastEditor store={store} />
            </p>
          )}
        </div>
        <div className="sidebar-item__info">
          {additionalRight}
          {date && (
            <div className="sidebar-item__date">
              <span>{moment(date).format("dddd")}</span>
              <span>{moment(date).format("DD.MM.YYYY")}</span>
            </div>
          )}
          {/* store.sync is undefined for reports */}
          {store && store.sync !== undefined && (
            <SyncIcon isSynced={store.sync} type={store.type} />
          )}
        </div>
      </NavLink>
    </Element>
  );
};

export default SidebarItem;
