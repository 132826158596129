import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { listObjectsToListIds, listToObject } from "./paginationTools";

export const PURCHASE_OTHER_COSTS_REQUEST =
  "schoolconnect/purchasedetails/PURCHASE_OTHER_COSTS_REQUEST";
export const PURCHASE_OTHER_COSTS_SUCCESS =
  "schoolconnect/purchasedetails/PURCHASE_OTHER_COSTS_SUCCESS";
export const PURCHASE_OTHER_COSTS_FAILURE =
  "schoolconnect/purchasedetails/PURCHASE_OTHER_COSTS_FAILURE";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  index: {},
  purchaseOtherCostsList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PURCHASE_OTHER_COSTS_REQUEST:
      return { ...state, fetching: true, error: null };
    case PURCHASE_OTHER_COSTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case PURCHASE_OTHER_COSTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        purchaseOtherCostsList: action.data,
      };
    default:
      return state;
  }
}

export const getPurchaseOtherCostsList = (state) =>
  state.purchaseOtherCosts?.purchaseOtherCostsList;
export const requestPurchaseOtherCosts = () => ({
  type: PURCHASE_OTHER_COSTS_REQUEST,
});

export function* purchaseOtherCostsSagaWatcher() {
  yield takeLatest(PURCHASE_OTHER_COSTS_REQUEST, purchaseOtherCostsSagaWorker);
}

function fetchPurchaseOtherCosts() {
  const url = `${process.env.REACT_APP_API_URL}/purchase-other-cost/`;
  const params = {
    limit: 99999,
  };
  return axios({
    method: "GET",
    url: url,
    params,
  });
}

function* purchaseOtherCostsSagaWorker(action) {
  try {
    const response = yield call(fetchPurchaseOtherCosts, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };
    yield put({ type: PURCHASE_OTHER_COSTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: PURCHASE_OTHER_COSTS_FAILURE, error });
  }
}
