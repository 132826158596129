import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@wfp/ui";
import ModalExtended from "components/ModalExtended";
import {
  requestTermsStatement,
  getTermsStatement,
} from "data-handler/ducks/statements";
import { getLanguage } from "data-handler/ducks/language";

import "./styles.module.scss";

const TermsAndConditionsModal = () => {
  const language = useSelector(getLanguage);
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch(language);
  const file = useSelector(getTermsStatement);

  const closeWindow = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(requestTermsStatement(intl.locale));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalExtended
      className="modal-terms-conditions"
      onRequestClose={closeWindow}
      onRequestSubmit={closeWindow}
      modalLabel={
        <FormattedMessage
          id="TermsAndConditions.modalLabel"
          defaultMessage="Terms And Conditions"
        />
      }
      wide
      passiveModal
      selectorPrimaryFocus={false}
    >
      <div dangerouslySetInnerHTML={{ __html: file }} />
      <Button onClick={closeWindow}>
        <FormattedMessage id="ModalExtended.cancel" defaultMessage="Cancel" />
      </Button>
    </ModalExtended>
  );
};

export default TermsAndConditionsModal;
