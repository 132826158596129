import withSizes from "react-sizes";
import { store } from "data-handler/store";
import { getViewLayout } from "data-handler/ducks/auth";

export const mapSizesToProps = (props: any) => {
  const userAgent = (navigator as any)?.userAgentData;
  const width = props.width;
  const currentViewLayout = getViewLayout(store.getState());

  switch (currentViewLayout) {
    case "web":
      return { isMobile: false };
    case "mobile":
      return { isMobile: true };
    default:
      return {
        isMobile: (userAgent && userAgent.mobile) || width <= 990,
        // isSmartphone: width <= 500, // UNUSED
      };
  }
};

export interface SCSizes {
  isMobile: boolean;
}

/**
 * "With School Connect sizes". Uses react-sizes.
 *
 * I admit that this aberration is due to my TS inexperience, plus my immediate need
 * to suppress `withSizes`'s errors.
 *
 * Here's some reference if you want to improve this:
 * https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
 */
export const withSCSizes = <P extends object>(
  Component: React.ComponentType<P>
) =>
  (withSizes(mapSizesToProps)(
    (Component as unknown) as React.ComponentType<SCSizes>
  ) as unknown) as React.ComponentType<Omit<P, "isMobile">>;

/*
  bp--xs--major: 500px,
  bp--sm--major: 768px,
  bp--md--major: 992px,
  bp--lg--major: 1200px,
*/
