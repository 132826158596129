import React from "react";
import { getLanguage } from "data-handler/ducks/language";
import { useSelector } from "react-redux";
import { toMeasureUnitString } from "helpers/measure_unit";

/**
 * Renders `children` as a string representing a number formatted according to the
 * "en-EN" locale and to minimum/maximumFractionDigits.
 *
 * Rationale for not using UI Kit's `Unit`:
 * https://github.com/wfp/ui/issues/307
 */
export default function MeasureUnitValue({
  measureUnit,
  children,
}: {
  measureUnit: { id: number; name: string; symbol: string; decimals: number };
  children: number | string;
}) {
  return (
    <span>
      {toMeasureUnitString(children, measureUnit, useSelector(getLanguage))}
    </span>
  );
}
