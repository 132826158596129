import React, { ReactNode } from "react";
import classNames from "classnames";
import MobileNavDetails from "components/MobileNavDetails";
import "./MainContent.scss";

const MainContent = ({
  children,
  className = undefined,
  kind = undefined,
  right = undefined,
  subTitle = undefined,
  title = undefined,
}: {
  children: ReactNode;
  className?: string;
  kind?: string;
  right?: ReactNode;
  subTitle?: ReactNode;
  title?: ReactNode;
}) => {
  const classes = classNames(
    "main-content",
    {
      "address--inline": kind === "noPadding",
    },
    className
  );

  return (
    <div className={classes}>
      <MobileNavDetails subTitle={subTitle} title={title} right={right} />
      <div className="main-content__content">{children}</div>
    </div>
  );
};

export default MainContent;
