import { useSelector } from "react-redux";
import moment from "moment";
import {
  getCurrentOrLatestSchoolYear,
  getSchoolYearByDate,
} from "data-handler/ducks/stores";

export const useIsReportInLatestSchoolYear = (report) => {
  const reportDate = moment(
    `${report.year}/${report.month}/${report.start_day}`
  );
  const selectedSchoolYear = useSelector(getSchoolYearByDate(reportDate));
  const currentOrLatestSchoolYear = useSelector(getCurrentOrLatestSchoolYear);

  return selectedSchoolYear === currentOrLatestSchoolYear;
};
