import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import moment from "moment";

import { ListItem, List } from "@wfp/ui";

import SidebarItem from "components/SidebarItem";

import "./report-item.scss";

const REPORT_STATE_LABELS = {
  open: (
    <div className="report-state">
      <FormattedMessage id="Report.stateOpen" defaultMessage="Open" />
    </div>
  ),
  closed: (
    <div className="report-state">
      <FormattedMessage id="Report.stateClosed" defaultMessage="Closed" />
    </div>
  ),

  submitted: (
    <div className="report-state">
      <FormattedMessage id="Report.stateSubmitted" defaultMessage="Submitted" />
    </div>
  ),
  approved: (
    <div className="report-state report-state-approved">
      <FormattedMessage id="Report.stateApproved" defaultMessage="Approved" />
    </div>
  ),
  rejected: (
    <div className="report-state report-state-rejected">
      <FormattedMessage id="Report.stateRejected" defaultMessage="Rejected" />
    </div>
  ),
  validated: (
    <div className="report-state report-state-validated">
      <FormattedMessage id="Report.stateValidated" defaultMessage="Validated" />
    </div>
  ),
};

export default function ReportItem({ report }) {
  const params = useParams();

  const date = moment(`${report.year}/${report.month}/1`);
  const startDate = moment(
    `${report.year}/${report.month}/${report.start_day}`
  );
  const endDate = moment(`${report.year}/${report.month}/${report.end_day}`);

  return (
    <SidebarItem
      store={report}
      selected={params.item === report.client_id}
      id={report.client_id}
      title={date.format("MMMM YYYY")}
      subTitle={
        <>
          <List colon>
            <ListItem
              title={
                <FormattedMessage
                  id="ReportItem.startDate"
                  defaultMessage="Start date"
                />
              }
            >
              {startDate.format("DD.MM.YYYY")}
            </ListItem>
            <ListItem
              title={
                <FormattedMessage
                  id="ReportItem.endDate"
                  defaultMessage="End date"
                />
              }
            >
              {endDate.format("DD.MM.YYYY")}
            </ListItem>
          </List>
        </>
      }
      to={`/school/${params.schoolId}/report/${report.client_id}`}
    >
      {REPORT_STATE_LABELS[report.state]}
    </SidebarItem>
  );
}
