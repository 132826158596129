import React, { useRef } from "react";
import styles from "./updateLocalPassword.module.scss";
import { Controller, useForm } from "react-hook-form";
import CodeInput from "../CodeInput";
import { FormattedMessage, useIntl } from "react-intl";
import ModalExtended from "components/ModalExtended";
import { useSelector } from "react-redux";
import { getCurrentUser } from "data-handler/ducks/auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { updatePasscode, getLocalUserById } from "data-handler/ducks/localAuth";

export default function UpdateLocalPassword() {
  const { control, errors, handleSubmit } = useForm();

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const submitBtnRef = useRef();

  const currentUser = useSelector(getCurrentUser);
  const user = useSelector((state) => getLocalUserById(state, currentUser.id));

  const onSubmit = (values) => {
    dispatch(
      updatePasscode({
        id: currentUser.id,
        passcode: values.password,
      })
    );
    history.push(`${history.location.pathname}?nav=true`);
  };

  const submitViaBtnRefClick = () => {
    submitBtnRef.current.click();
  };

  const noLocalPassword = () => {
    onSubmit({ password: false });
  };

  // const cancelLocalPassword = () => {
  //   history.push(`${history.location.pathname}?nav=true`);
  // };

  const urlParams = queryString.parse(history.location.search);

  if (
    (!user || user.passcode !== undefined) &&
    urlParams.select !== "updateLocalPasscode"
  )
    return null;

  return (
    <ModalExtended
      open
      onRequestSubmit={submitViaBtnRefClick}
      onSecondarySubmit={noLocalPassword}
      onRequestClose={noLocalPassword}
      modalLabel={intl.formatMessage({
        id: "Login.localLogin",
        defaultMessage: "Local login",
      })}
      modalHeading={intl.formatMessage({
        id: "Login.localPasscode",
        defaultMessage: "Please set a passcode for local login",
      })}
      wide={false}
      primaryButtonText={intl.formatMessage({
        id: "Login.savePasscode",
        defaultMessage: "Save passcode",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "Login.cancel",
        defaultMessage: "Cancel",
      })}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.passCodeInputWrapper}>
          <Controller
            as={<CodeInput />}
            name="password"
            labelText={
              <FormattedMessage id="Login.code" defaultMessage="Code" />
            }
            helperText={
              <FormattedMessage
                id="Login.enterCode"
                defaultMessage="Enter your passcode"
              />
            }
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "Login.noPasscodeEntered",
                  defaultMessage:
                    "No passcode entered. If you don't want to set a passcode click the link below.",
                }),
              },
              minLength: {
                value: 6,
                message: intl.formatMessage({
                  id: "Login.passcodeIsTooShort",
                  defaultMessage: "Passcode is too short",
                }),
              },
            }}
            invalid={errors.password}
            invalidText={errors.password && errors.password.message}
            control={control}
          />
        </div>
        {/*<br />
        <Link onClick={noLocalPassword}>
          <FormattedMessage
            id="Login.doNotUseLocalLogin"
            defaultMessage="Do not use local login"
          />
        </Link>*/}
        <button className="hidden-btn" ref={submitBtnRef} />
      </form>
    </ModalExtended>
  );
}
