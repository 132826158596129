import React, { useRef, useState } from "react";

import { TextInput, RadioButtonGroup, RadioButton } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";
import { FormProvider, useForm } from "react-hook-form";

import { withRouter } from "react-router-dom";

import { Blockquote, Button, Link } from "@wfp/ui";

import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import { FormattedMessage } from "react-intl";

import { staffKind } from "SCConstants";
import ErrorList from "components/ErrorList";
import {
  updateUser,
  resetPasscode,
  deleteUser,
  getLocalUserById,
} from "data-handler/ducks/localAuth";
import { useDispatch } from "react-redux";
import v4 from "uuid/v4";
import { useSelector } from "react-redux";
import { getRoles } from "data-handler/ducks/roles";
import { GetRoleLabel } from "helpers/roles";

const UserEdit = ({ history, match }) => {
  const dispatch = useDispatch();
  const submitBtnRef = useRef();
  const [deleteQuestion, setDeleteQuestion] = useState(0);
  const [passcodeReseted, setPasscodeReseted] = useState(false);
  const { roles } = useSelector(getRoles);

  const _roles = [];
  staffKind.forEach((kind) => {
    const role = roles.find((item) => item.type === kind.name);
    const label = GetRoleLabel({ user_role: kind.name });
    _roles.push({
      type: role.type,
      label: label,
    });
  });

  // eslint-disable-next-line no-unused-vars
  const [submit, setSubmit] = useState(false);

  const user = useSelector((state) =>
    getLocalUserById(state, match.params.details)
  );

  const methods = useForm({
    defaultValues: user,
  });

  const { errors, handleSubmit, register } = methods;

  const closeModal = () => {
    history.push(`/school/${match.params.schoolId}/info/user`);
  };

  const deleteStaff = () => {
    dispatch(deleteUser(match.params.details));
    closeModal();
  };

  const onSubmit = (values) => {
    const id = match.params.details === "newuser" ? v4() : match.params.details;
    dispatch(updateUser({ id, values }));
    closeModal();
  };

  const resetPasscodeHandler = () => {
    dispatch(resetPasscode(match.params.details));
    setPasscodeReseted(true);
  };

  const submitViaBtnRefClick = () => {
    submitBtnRef.current.click();
  };

  const userRoleLabelText = (
    <FormattedMessage id="userEdit.user_role" defaultMessage="User role" />
  );

  const lastNameLabelText = (
    <FormattedMessage id="userEdit.last_name" defaultMessage="Last name" />
  );

  const nameLabelText = (
    <FormattedMessage id="userEdit.name" defaultMessage="Name" />
  );

  return (
    <ModalExtended
      onRequestSubmit={submitViaBtnRefClick}
      onRequestClose={() => closeModal()}
      modalLabel="Edit user"
      modalHeading={
        match.params.details !== "newuser" ? (
          <>
            <div>
              <FormattedMessage
                id="userEdit.update_user"
                defaultMessage="Update user"
              />
            </div>
            {deleteQuestion ? (
              <div className={styles.deleteWrapper}>
                <Button
                  kind="danger"
                  icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
                  onClick={deleteStaff}
                >
                  <FormattedMessage
                    id="userEdit.yes_delete"
                    defaultMessage="Yes, delete"
                  />
                </Button>
                <Button
                  kind="secondary"
                  onClick={() => setDeleteQuestion(false)}
                >
                  <FormattedMessage
                    id="userEdit.do_not_delete"
                    defaultMessage="Do not delete"
                  />
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  kind="danger"
                  onClick={() => setDeleteQuestion(!deleteQuestion)}
                >
                  <FormattedMessage
                    id="userEdit.delete_user"
                    defaultMessage="Delete user"
                  />
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <FormattedMessage
              id="userEdit.create_new_user"
              defaultMessage="Create new user"
            />
          </>
        )
      }
      primaryButtonText="Save user"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {submit && (
            <Blockquote kind="success">
              <FormattedMessage
                id="userEdit.user_updated"
                defaultMessage="User updated!"
              />
            </Blockquote>
          )}
          {passcodeReseted && (
            <Blockquote kind="success">
              <FormattedMessage
                id="userEdit.passcodeReseted"
                defaultMessage="Passcode reseted!"
              />
            </Blockquote>
          )}
          <ErrorList errors={errors} />
          <div className="wfp--form wfp--form-long">
            <RadioButtonGroup
              labelText={userRoleLabelText}
              className={styles.role}
            >
              {_roles.map((e) => {
                return (
                  <RadioButton
                    labelText={e.label}
                    id={`role-${e.type}`}
                    name="role"
                    value={e.type}
                    ref={register}
                  />
                );
              })}
            </RadioButtonGroup>
            <div className={styles.name}>
              <TextInput
                labelText={lastNameLabelText}
                name="last_name"
                inputRef={register}
              />
              <TextInput
                labelText={nameLabelText}
                name="other_names"
                inputRef={register}
              />
            </div>
            {match.params.details !== "newuser" && (
              <Link onClick={resetPasscodeHandler}>
                <FormattedMessage
                  id="userEdit.resetPasscode"
                  defaultMessage="Reset passcode"
                />
              </Link>
            )}
          </div>
          <button className="hidden-btn" ref={submitBtnRef} />
        </form>
      </FormProvider>
    </ModalExtended>
  );
};

export default withRouter(UserEdit);
