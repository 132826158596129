import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";

import { Button, Module, ModuleBody, List, ListItem } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import DeleteModalButton from "components/DeleteModalButton";
import {
  getCurrentSchoolYear,
  getPreviousSchoolYears,
  getSchoolYearByDate,
} from "data-handler/ducks/stores";
import Section from "components/Section";
import SubTitle from "components/SubTitle";
import CommodityShow from "components/CommodityShow";
import MainContent from "components/MainContent";

import { getReportedStores } from "helpers/report";

import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";
import { getIsSyncableLocked } from "../../data-handler/ducks/stores";
import UneditableMessage, {
  getModelDateFormat,
} from "components/UneditableMessage";

const DeliveriesShow = ({ deliveryCurrentStoreData }) => {
  const params = useParams();
  const reportedStores = useSelector((state) => getReportedStores(state));
  const isReported = reportedStores[params.item];
  const isStockRebalance =
    deliveryCurrentStoreData.is_negative_stock_rebalance === true;
  const formattedDeliveryDate = moment(
    deliveryCurrentStoreData.delivered_at
  ).format("dddd, DD.MM.YYYY");
  const isLocked = useSelector((state) =>
    getIsSyncableLocked(state, deliveryCurrentStoreData)
  );

  const selectedDeliverySchoolYear = useSelector(
    getSchoolYearByDate(
      getModelDateFormat(deliveryCurrentStoreData) ||
        moment().format("YYYY-MM-DD")
    )
  );

  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  const firstSchoolYear = previousSchoolYears.length === 0;

  const currentYear = useSelector((state) => getCurrentSchoolYear(state));

  const commoditiesOpeningStock = deliveryCurrentStoreData?.commodities.filter(
    (deliveryCommodity) => deliveryCommodity.is_carry_over === true
  );
  const deliveredCommodities = deliveryCurrentStoreData?.commodities.filter(
    (deliveryCommodity) => !deliveryCommodity.is_carry_over
  );

  const carryOverCreated = commoditiesOpeningStock?.length > 0;

  const carryOverTitle = firstSchoolYear ? (
    <FormattedMessage
      id="DeliveriesShow.initialStockCommodities"
      defaultMessage="Initial stock commodities"
    />
  ) : (
    <FormattedMessage
      id="DeliveriesShow.carryOverCommodities"
      defaultMessage="Carry-over commodities"
    />
  );

  const carryOverEmptyTitle = firstSchoolYear ? (
    <FormattedMessage
      id="DeliveriesShow.noInitialStockCommoditiesDelivered"
      defaultMessage="No initial stock commodities "
    />
  ) : (
    <FormattedMessage
      id="DeliveriesShow.noCarryOverDelivered"
      defaultMessage="No carry-over commodities "
    />
  );

  return (
    <MainContent title={formattedDeliveryDate} className="deliveries-content">
      <Section>
        <UneditableMessage
          deliveryCurrentStoreData={deliveryCurrentStoreData}
        />
      </Section>
      <SubTitle
        additional={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedDeliverySchoolYear) ||
              (currentYear === null &&
                previousSchoolYears[0] !== selectedDeliverySchoolYear)
            }
          >
            {deliveryCurrentStoreData.type !== "delete" && (
              <DeleteModalButton
                currentStoreData={deliveryCurrentStoreData}
                disabled={isStockRebalance || carryOverCreated}
              />
            )}
            <NavLink
              to={`/school/${params.schoolId}/deliveries/${params.item}/delivery`}
              className="edit-nav-link"
            >
              <Button
                kind="secondary"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={isReported || isStockRebalance || carryOverCreated}
              >
                <FormattedMessage
                  id="DeliveriesShow.editDelivery"
                  defaultMessage="Edit Delivery"
                />
              </Button>
            </NavLink>
          </Gate>
        }
      >
        <FormattedMessage
          id="DeliveriesShow.deliveryFrom"
          defaultMessage="Delivery {waybill_no} from {date}"
          values={{
            waybill_no: deliveryCurrentStoreData.waybill_no,
            date: formattedDeliveryDate,
          }}
        />
      </SubTitle>
      <Module noMargin>
        <ModuleBody>
          <List kind="details" colon>
            <ListItem
              title={
                <FormattedMessage
                  id="DeliveriesShow.waybill"
                  defaultMessage="Waybill"
                />
              }
            >
              {deliveryCurrentStoreData.waybill_no}
            </ListItem>
            <ListItem
              title={
                <FormattedMessage
                  id="DeliveriesShow.formattedDeliveryDate"
                  defaultMessage="Delivery date"
                />
              }
            >
              {formattedDeliveryDate}
            </ListItem>
          </List>
        </ModuleBody>
      </Module>
      {(deliveryCurrentStoreData.waybill_no?.includes("IS") ||
        deliveryCurrentStoreData.waybill_no === "Initial stock") &&
        commoditiesOpeningStock?.length > 0 && (
          <CommodityShow
            emptyTitle={carryOverEmptyTitle}
            title={carryOverTitle}
            model={deliveryCurrentStoreData.model}
            commodities={commoditiesOpeningStock}
            type={"Carry-over"}
          />
        )}

      <CommodityShow
        emptyTitle={
          <FormattedMessage
            id="DeliveriesShow.noCommoditiesDelivered"
            defaultMessage="No commodities delivered"
          />
        }
        title={
          <FormattedMessage
            id="DeliveriesShow.deliveredCommodities"
            defaultMessage="Delivered commodities"
          />
        }
        model={deliveryCurrentStoreData.model}
        commodities={deliveredCommodities}
      />
    </MainContent>
  );
};

export default DeliveriesShow;
