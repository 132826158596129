import React from "react";
import styles from "./styles.module.scss";
import { Button, Module, ModuleBody, ModuleHeader } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FormattedMessage } from "react-intl";

type RepeaterItemProps = {
  title?: string;
  className?: string;
  disableRemove?: boolean;
  horizontalLayout?: boolean;
  removeAction?: unknown;
  disableItem?: boolean;
  extraTopRightChild?: JSX.Element;
};

export const RepeaterItem: React.ComponentType<RepeaterItemProps> = ({
  title,
  disableRemove,
  horizontalLayout = true,
  removeAction,
  disableItem,
  extraTopRightChild,
  children,
}) => {
  return (
    <div
      className={`${styles.repeaterItem} ${
        horizontalLayout ? styles.repeaterItemFlex : ""
      }`}
      style={disableItem ? { pointerEvents: "none", opacity: "0.5" } : {}}
    >
      <Module noMargin light>
        {title && <ModuleHeader>{title}</ModuleHeader>}
        {disableItem && (
          <ModuleHeader>
            <FormattedMessage
              id="RepeaterItem.commodityNotInProfile"
              defaultMessage="This commodity was removed from the school profile. You can use existing stock of this commodity but cannot edit this delivery commodity."
            />
          </ModuleHeader>
        )}
        <ModuleBody>
          <div className={styles.repeaterItemContent}>{children}</div>
        </ModuleBody>
        <div className={styles.repeaterTopRight}>
          {removeAction && disableRemove !== true && (
            <Button
              className={styles.removeButon}
              small
              kind="ghost"
              onClick={removeAction}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
          {extraTopRightChild && (
            <div className={styles.extraTopRightChild}>
              {extraTopRightChild}
            </div>
          )}
        </div>
      </Module>
    </div>
  );
};

export const Repeater: React.ComponentType<{}> = ({ children }) => {
  return <div className={styles.repeater}>{children}</div>;
};

export const RepeaterTitle: React.ComponentType<{}> = ({ children }) => {
  return <h3 className={styles.repeaterTitle}>{children}</h3>;
};

export const RepeaterEntryTitle: React.ComponentType<{}> = ({ children }) => {
  return <h3 className={styles.repeaterEntryTitle}>{children}</h3>;
};
export const RepeaterEntrySubTitle: React.ComponentType<{}> = ({
  children,
}) => {
  return <div className={styles.repeaterEntrySubTitle}>{children}</div>;
};
