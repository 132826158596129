import React from "react";
import { FormattedMessage } from "react-intl";

export const statusOptions = [
  {
    value: 1,
    label: (
      <FormattedMessage id="countryAdmin.pending" defaultMessage="Pending" />
    ),
  },
  {
    value: 2,
    label: (
      <FormattedMessage id="countryAdmin.running" defaultMessage="Running" />
    ),
  },
  {
    value: 3,
    label: (
      <FormattedMessage
        id="countryAdmin.completed"
        defaultMessage="Completed"
      />
    ),
  },
  {
    value: 101,
    label: (
      <FormattedMessage id="countryAdmin.success" defaultMessage="Success" />
    ),
  },
  {
    value: 102,
    label: (
      <FormattedMessage id="countryAdmin.failed" defaultMessage="Failed" />
    ),
  },
  {
    value: 103,
    label: (
      <FormattedMessage id="countryAdmin.unknown" defaultMessage="Unknown" />
    ),
  },
  {
    value: 104,
    label: (
      <FormattedMessage
        id="countryAdmin.duplicate"
        defaultMessage="Duplicate"
      />
    ),
  },
  {
    value: 105,
    label: (
      <FormattedMessage id="countryAdmin.updated" defaultMessage="Updated" />
    ),
  },
];

export const messageLevelOptions = [
  {
    value: 201,
    label: <FormattedMessage id="countryAdmin.info" defaultMessage="Info" />,
  },
  {
    value: 202,
    label: (
      <FormattedMessage id="countryAdmin.success" defaultMessage="Success" />
    ),
  },
  {
    value: 203,
    label: (
      <FormattedMessage id="countryAdmin.warning" defaultMessage="Warning" />
    ),
  },
  {
    value: 204,
    label: <FormattedMessage id="countryAdmin.error" defaultMessage="Error" />,
  },
];
