import { createBrowserHistory } from "history";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Offline as OfflineSentryIntegration } from "@sentry/integrations";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";

import { store, persistor } from "data-handler/store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import updateSchoolConnect from "./updateSchoolConnect";

const history = createBrowserHistory();

const _trimSentryEvent = (event: any) => {
  if (event.breadcrumbs !== undefined && event.breadcrumbs.length > 1) {
    event.breadcrumbs = event.breadcrumbs.slice(0, 1);
  }
  if (
    event.exception !== undefined &&
    event.exception.values !== undefined &&
    event.exception.values.length > 1
  ) {
    event.exception.values = event.exception.values.slice(0, 1);
  }
  return event;
};

// Sentry.init MUST be called before ReactDOM.render()
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new OfflineSentryIntegration() as any,
    new TracingIntegrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  normalizeDepth: 10, // Redux state context max depth.
  release: `school-connect@${process.env.REACT_APP_COMMIT_ID}`, // Git commit id
  tracesSampleRate: 0.001,
  maxBreadcrumbs: 10,
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === "console") {
      return null;
    }
    return breadcrumb;
  },
  beforeSend: (event) => {
    const MAX_PAYLOAD_SIZE = 1_048_576; // 1 megabyte
    const eventAsString = JSON.stringify(event);
    const sizeInBytes = new TextEncoder().encode(eventAsString).length;

    if (sizeInBytes >= MAX_PAYLOAD_SIZE) {
      // trim event to prevent 413 error from sentry.
      return _trimSentryEvent(event);
    }
    return event;
  },
});

// ReactGA init
if (process.env.REACT_APP_ENV === "production") {
  const trackingId = "UA-143874907-10";
  ReactGA.initialize(trackingId);
}

// ReactGA: listen for changes to the current location.
if (process.env.REACT_APP_ENV === "production") {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* PersistGate delays rendering until state is rehydrated */}
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// TODO: make updates transparent to end users
serviceWorker.register({
  onUpdate: (registration: any) => {
    const state = localStorage.getItem("persist:stores");
    const localStorageObject = state ? JSON.parse(state) : undefined;
    const storesObject =
      localStorageObject !== undefined
        ? JSON.parse(localStorageObject?.stores)
        : [];

    const unSyncedStores = storesObject?.find(
      (i: { sync: boolean }) => i.sync === false
    )
      ? true
      : false;

    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event: any) => {
        if (event.target.state === "activated" && !unSyncedStores) {
          if (
            window.confirm(
              `An update of School Connect is available! Please click OK to accept.`
            )
          ) {
            updateSchoolConnect();
          }
        } else if (event.target.state === "activated" && unSyncedStores) {
          window.alert(
            `An update of School Connect is available, but unsynced data exists! Please Sync the data before proceeding.`
          );
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});
