import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Module,
  ModuleBody,
  ModuleFooter,
  ModuleHeader,
  Wrapper,
  Button,
} from "@wfp/ui";

import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";
import CsvImportModal from "../CsvImportModal";

import { valueLinkCell } from "components/CountryAdmin/CountryAdminContent/Table/Utils";

import {
  requestSchoolsImports,
  getSchoolsImports,
} from "data-handler/ducks/schoolsImports";
import { getCurrentUser } from "data-handler/ducks/auth";

import "../_content.scss";

const SchoolsImports = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const schoolsImports = useSelector(getSchoolsImports);
  const currentUser = useSelector(getCurrentUser);

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filtersState, setFilters] = useState([]);
  const [csvImportModalOpen, setCsvImportModalOpen] = useState(false);

  const { count = 0, ids = [] } = schoolsImports;

  const data = ids.map((id) => schoolsImports.results[id]);

  const statusOptions = [
    {
      name: "",
      label: intl.formatMessage({
        id: "countryAdmin.allImports",
        defaultMessage: "All imports",
      }),
    },
    {
      name: 1,
      label: intl.formatMessage({
        id: "countryAdmin.pending",
        defaultMessage: "Pending",
      }),
    },
    {
      name: 2,
      label: intl.formatMessage({
        id: "countryAdmin.running",
        defaultMessage: "Running",
      }),
    },
    {
      name: 3,
      label: intl.formatMessage({
        id: "countryAdmin.completed",
        defaultMessage: "Completed",
      }),
    },
  ];

  const filterFields = [
    {
      name: "csv_file",
      label: intl.formatMessage({
        id: "countryAdmin.csvFile",
        defaultMessage: "CSV File",
      }),
    },
    {
      name: "created_by_email",
      label: intl.formatMessage({
        id: "countryAdmin.createdBy",
        defaultMessage: "Created by",
      }),
    },
    {
      name: "status",
      label: intl.formatMessage({
        id: "countryAdmin.status",
        defaultMessage: "Status",
      }),
      type: "select",
      options: statusOptions,
    },
  ];

  if (currentUser?.user_role === "global_administrator") {
    filterFields.push({
      name: "country_name",
      label: intl.formatMessage({
        id: "CountryAdmin.country",
        defaultMessage: "Country",
      }),
    });
  }

  const filterProps = {
    fields: filterFields,
    onSubmit: (e) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
    defaults: filtersState.reduce((a, x) => ({ ...a, [x.id]: x.value }), {}),
  };

  const statusCell = ({ row }) => {
    const status = row["original"]["status"];
    return statusOptions.find((item) => item.name === status).label;
  };

  const columns = useMemo(() => {
    const allColumns = [
      {
        Header: intl.formatMessage({
          id: "countryAdmin.csvFilename",
          defaultMessage: "CSV filename",
        }),
        accessor: "csv_filename",
        Cell: ({ cell: { row, value } }) =>
          valueLinkCell(
            value,
            `/countryAdmin/schools/schoolsImports/${row["original"]["id"]}/`
          ),
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.createdBy",
          defaultMessage: "Created by",
        }),
        accessor: "created_by.email",
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.status",
          defaultMessage: "Status",
        }),
        accessor: "status",
        Cell: statusCell,
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.created",
          defaultMessage: "Created",
        }),
        accessor: "num_created",
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.updated",
          defaultMessage: "Updated",
        }),
        accessor: "num_updated",
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.failed",
          defaultMessage: "Failed",
        }),
        accessor: "num_failed",
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "countryAdmin.total",
          defaultMessage: "Total",
        }),
        accessor: "total",
        disableSortBy: true,
      },
    ];
    if (currentUser?.user_role === "global_administrator") {
      allColumns.push({
        Header: intl.formatMessage({
          id: "CountryAdmin.country",
          defaultMessage: "Country",
        }),
        accessor: "country.name",
      });
    }
    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPage = (size, idx, ordering, filters = filtersState) => {
    setPageSize(size);
    setPageNum(idx);
    dispatch(requestSchoolsImports(size, idx, ordering, filters));
  };

  const csvImportModalProps = {
    csvImportModalOpen,
    setCsvImportModalOpen,
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
  };

  return (
    <div className="country-admin-main-content schools-table schools-imports">
      <Wrapper
        pageWidth="lg"
        spacing="md"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleHeader>
            {intl.formatMessage({
              id: "CountryAdmin.schoolsImports",
              defaultMessage: "Schools Imports",
            })}
          </ModuleHeader>
          <ModuleBody>
            <Filtering {...filterProps} />
            <Table {...tableProps} showSort />
            <CsvImportModal {...csvImportModalProps} />
          </ModuleBody>
          <ModuleFooter>
            <Button
              className="wfp--btn--secondary"
              onClick={() => {
                setCsvImportModalOpen(true);
              }}
            >
              {
                <FormattedMessage
                  id="modal.importCsv"
                  defaultMessage="Import CSV"
                />
              }
            </Button>
          </ModuleFooter>
        </Module>
      </Wrapper>
    </div>
  );
};

export default SchoolsImports;
