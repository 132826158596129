import React from "react";

import { FormattedMessage } from "react-intl";

import SubTitle from "components/SubTitle";
import Section from "components/Section";
import CommodityShow from "components/CommodityShow";

const ThrMealsShow = ({ currentStoreData }) => {
  const consumption = currentStoreData && currentStoreData.consumption;

  if (!consumption) {
    return null;
  }

  return (
    <Section>
      <SubTitle>
        <FormattedMessage
          id="THRMealsShow.mealsSubtitle"
          defaultMessage="Take-home ration meals"
        />
      </SubTitle>
      <div className="thr-meals-section">
        <CommodityShow
          model={currentStoreData.model}
          commodities={consumption.commodities}
        />
      </div>
    </Section>
  );
};

export default ThrMealsShow;
