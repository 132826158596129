import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import ModalExtended from "components/ModalExtended";
import UserNavigation from "components/UserNavigation";

import "./_school-select.scss";

const USER_MODAL_PRIMARY_BUTTON_LABEL = (
  <FormattedMessage
    id="UserModal.modalPrimaryButtonLabel"
    defaultMessage="Done"
  />
);

const UserModal = () => {
  const history = useHistory();
  const urlParams = queryString.parse(history.location.search);

  if (urlParams.select !== "user") return null;

  const closeWindow = () => {
    history.push(`${history.location.pathname}?nav=true`);
  };

  return (
    <ModalExtended
      open
      onRequestClose={closeWindow}
      onRequestSubmit={closeWindow}
      modalLabel={
        <FormattedMessage id="UserModal.modalLabel" defaultMessage="User" />
      }
      modalHeading={
        <FormattedMessage
          id="UserModal.modalHeading"
          defaultMessage="User Settings"
        />
      }
      wide
      secondaryButtonText={undefined}
      primaryButtonText={USER_MODAL_PRIMARY_BUTTON_LABEL}
      selectorPrimaryFocus={false}
    >
      <UserNavigation closeWindow={closeWindow} />
    </ModalExtended>
  );
};

export default UserModal;
