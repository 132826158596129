import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import Select from "react-select";
import { RadioButtonGroup, RadioButton } from "@wfp/ui";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";
import { incidentTypes } from "components/IncidentEdit/index";
import { selectContainerStyles } from "SCConstants";

const YearEditIncidentReasons = ({ control, errors, index, register }) => {
  const [incidentType, setIncidentType] = useState(false);
  const incidentCauses = useSelector(incidentCausesDuck.getList);
  const isInvalid = (key) => (errors.hasOwnProperty(key) ? true : false);
  const reasonKey = `commodities[${index}].reason`;
  const causeKey = (cause) => `commodities[${index}].causes[${cause.id}]`;

  const requiredLabelReasons = (
    <div
      className="wfp--form-required"
      styles={{ borderColor: " 2px solid #C5192D" }}
    >
      <FormattedMessage
        id="DeliveriesEdit.IncidentTypeError"
        defaultMessage="* Select one reason"
      />
    </div>
  );
  return (
    <>
      <div className="wfp--form-item wfp--form-item--invalid commodity-select">
        <div className="wfp--label">
          <FormattedMessage
            id="IncidentEdit.incidentType"
            defaultMessage="Incident Type"
          />
        </div>
        <Controller
          control={control}
          render={({ onChange, onBlur, value }) => {
            return (
              <div className="commodity-select__input">
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  key={reasonKey}
                  styles={
                    isInvalid(reasonKey)
                      ? selectContainerStyles.invalid
                      : selectContainerStyles.valid
                  }
                  onChange={(e) => {
                    setIncidentType(e.value);
                    onChange(e.value);
                  }}
                  onBlur={onBlur}
                  defaultValue={value}
                  options={Object.keys(incidentTypes).map((key) => ({
                    label: incidentTypes[key],
                    value: key,
                  }))}
                />
              </div>
            );
          }}
          inputRef={register}
          rules={{ required: true }}
          name={reasonKey}
        />
      </div>
      {incidentType && (
        <RadioButtonGroup
          labelText={
            <FormattedMessage
              id="IncidentEdit.Reason"
              defaultMessage="Reason"
            />
          }
        >
          {incidentCauses.map((cause) => {
            if (cause.reason === parseInt(incidentType) || cause.reason === 3) {
              return (
                <RadioButton
                  key={causeKey(cause)}
                  labelText={cause.name}
                  id={`role-${causeKey(cause)}`}
                  name={`commodities[${index}].cause`}
                  value={cause.id}
                  defaultChecked={false}
                  ref={register({ required: true })}
                />
              );
            } else {
              return <></>;
            }
          })}
          {errors.causeKey && !isInvalid(causeKey) && requiredLabelReasons}
        </RadioButtonGroup>
      )}
    </>
  );
};
export default YearEditIncidentReasons;
