import React from "react";
import { Route, Switch } from "react-router-dom";

import CountryAdminSchoolProfilesTable from "components/CountryAdmin/CountryAdminContent/CountryAdminSchoolProfiles/CountryAdminSchoolProfilesTable";
import CountryAdminDetailedSchoolProfileTemplate from "components/CountryAdmin/CountryAdminContent/CountryAdminSchoolProfiles/CountryAdminDetailedSchoolProfileTemplate";

import "./_content.scss";

const CountryAdminSchoolProfiles = () => {
  const renderCountryAdminSchoolProfileCreate = () => {
    return (
      <CountryAdminDetailedSchoolProfileTemplate createNewProfile={true} />
    );
  };

  const renderCountryAdminSchoolProfileEdit = () => {
    return (
      <CountryAdminDetailedSchoolProfileTemplate createNewProfile={false} />
    );
  };

  return (
    <div className="admin-schools country-admin-main-content">
      <Switch>
        <Route
          path="/countryAdmin/schoolProfiles/create"
          component={renderCountryAdminSchoolProfileCreate}
        />
        <Route
          path="/countryAdmin/schoolProfiles/:schoolProfileId"
          component={renderCountryAdminSchoolProfileEdit}
        />
        <Route component={CountryAdminSchoolProfilesTable} />
      </Switch>
    </div>
  );
};

export default CountryAdminSchoolProfiles;
