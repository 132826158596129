import React from "react";
import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";

import {
  listObjectsToListIds,
  listToObject,
} from "data-handler/ducks/paginationTools";
import { FormattedMessage } from "react-intl";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { errorToast, successToast } from "data-handler/ducks/toast";

export const DISTRIBUTION_REPORTS_REQUEST =
  "schoolconnect/distributionReports/DISTRIBUTION_REPORTS_REQUEST";
export const DISTRIBUTION_REPORTS_SUCCESS =
  "schoolconnect/distributionReports/DISTRIBUTION_REPORTS_SUCCESS";
export const DISTRIBUTION_REPORTS_FAILURE =
  "schoolconnect/distributionReports/DISTRIBUTION_REPORTS_FAILURE";

export const NON_PAGINATED_DISTRIBUTION_REPORTS_REQUEST =
  "schoolconnect/distributionReports/NON_PAGINATED_DISTRIBUTION_REPORTS_REQUEST";
export const NON_PAGINATED_DISTRIBUTION_REPORTS_SUCCESS =
  "schoolconnect/distributionReports/NON_PAGINATED_DISTRIBUTION_REPORTS_SUCCESS";
export const NON_PAGINATED_DISTRIBUTION_REPORTS_FAILURE =
  "schoolconnect/distributionReports/NON_PAGINATED_DISTRIBUTION_REPORTS_FAILURE";

export const PARTNERSHIPS_REQUEST =
  "schoolconnect/distributionReports/PARTNERSHIPS_REQUEST";
export const PARTNERSHIPS_SUCCESS =
  "schoolconnect/distributionReports/PARTNERSHIPS_SUCCESS";
export const PARTNERSHIPS_FAILURE =
  "schoolconnect/distributionReports/PARTNERSHIPS_FAILURE";

export const LOCATIONS_REQUEST =
  "schoolconnect/distributionReports/LOCATIONS_REQUEST";
export const LOCATIONS_SUCCESS =
  "schoolconnect/distributionReports/LOCATIONS_SUCCESS";
export const LOCATIONS_FAILURE =
  "schoolconnect/distributionReports/LOCATIONS_FAILURE";

export const ALL_DISTRIBUTION_REPORTS_REQUEST =
  "schoolconnect/distributionReports/ALL_DISTRIBUTION_REPORTS_REQUEST";
export const ALL_DISTRIBUTION_REPORTS_SUCCESS =
  "schoolconnect/distributionReports/ALL_DISTRIBUTION_REPORTS_SUCCESS";
export const ALL_DISTRIBUTION_REPORTS_FAILURE =
  "schoolconnect/distributionReports/ALL_DISTRIBUTION_REPORTS_FAILURE";

export const LOCATIONS_ACTIVITY_CODES_REQUEST =
  "schoolconnect/distributionReports/LOCATIONS_ACTIVITY_CODES_REQUEST";
export const LOCATIONS_ACTIVITY_CODES_SUCCESS =
  "schoolconnect/distributionReports/LOCATIONS_ACTIVITY_CODES_SUCCESS";
export const LOCATIONS_ACTIVITY_CODES_FAILURE =
  "schoolconnect/distributionReports/LOCATIONS_ACTIVITY_CODES_FAILURE";

export const BENEFICIARY_GROUPS_REQUEST =
  "schoolconnect/distributionReports/BENEFICIARY_GROUPS_REQUEST";
export const BENEFICIARY_GROUPS_SUCCESS =
  "schoolconnect/distributionReports/BENEFICIARY_GROUPS_SUCCESS";
export const BENEFICIARY_GROUPS_FAILURE =
  "schoolconnect/distributionReports/BENEFICIARY_GROUPS_FAILURE";

export const CREATE_DISTRIBUTION_REPORT_REQUEST =
  "schoolconnect/distributionReports/CREATE_DISTRIBUTION_REPORT_REQUEST";
export const CREATE_DISTRIBUTION_REPORT_SUCCESS =
  "schoolconnect/distributionReports/CREATE_DISTRIBUTION_REPORT_SUCCESS";
export const CREATE_DISTRIBUTION_REPORT_FAILURE =
  "schoolconnect/distributionReports/CREATE_DISTRIBUTION_REPORT_FAILURE";

export const EDIT_DISTRIBUTION_REPORT_REQUEST =
  "schoolconnect/distributionReports/EDIT_DISTRIBUTION_REPORT_REQUEST";
export const EDIT_DISTRIBUTION_REPORT_SUCCESS =
  "schoolconnect/distributionReports/EDIT_DISTRIBUTION_REPORT_SUCCESS";
export const EDIT_DISTRIBUTION_REPORT_FAILURE =
  "schoolconnect/distributionReports/EDIT_DISTRIBUTION_REPORT_FAILURE";

export const ORGANIZATION_UNITS_REQUEST =
  "schoolconnect/distributionReports/ORGANIZATION_UNITS_REQUEST";
export const ORGANIZATION_UNITS_SUCCESS =
  "schoolconnect/distributionReports/ORGANIZATION_UNITS_SUCCESS";
export const ORGANIZATION_UNITS_FAILURE =
  "schoolconnect/distributionReports/ORGANIZATION_UNITS_FAILURE";

export const CURRENT_DISTRIBUTION_REPORT_REQUEST =
  "schoolconnect/distributionReports/CURRENT_DISTRIBUTION_REPORT_REQUEST";
export const CURRENT_DISTRIBUTION_REPORT_SUCCESS =
  "schoolconnect/distributionReports/CURRENT_DISTRIBUTION_REPORT_SUCCESS";
export const CURRENT_DISTRIBUTION_REPORT_FAILURE =
  "schoolconnect/distributionReports/CURRENT_DISTRIBUTION_REPORT_FAILURE";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  distributionReports: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  allDistributionReports: null,
  partnerships: null,
  locations: null,
  locationActivityCodes: null,
  beneficiaryGroups: null,
  organizationUnits: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case NON_PAGINATED_DISTRIBUTION_REPORTS_REQUEST:
      return { ...state, fetching: true, error: null };
    case NON_PAGINATED_DISTRIBUTION_REPORTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        allDistributionReports: action.data,
        error: null,
      };
    case NON_PAGINATED_DISTRIBUTION_REPORTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case PARTNERSHIPS_REQUEST:
      return { ...state, fetching: true, error: null };
    case PARTNERSHIPS_SUCCESS:
      return {
        ...state,
        fetching: false,
        partnerships: action.data,
        error: null,
      };
    case PARTNERSHIPS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case LOCATIONS_REQUEST:
      return { ...state, fetching: true, error: null };
    case LOCATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        locations: action.data,
        error: null,
      };
    case LOCATIONS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case ALL_DISTRIBUTION_REPORTS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case ALL_DISTRIBUTION_REPORTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        distributionReports: action.data,
        error: null,
      };
    case ALL_DISTRIBUTION_REPORTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case LOCATIONS_ACTIVITY_CODES_REQUEST:
      return { ...state, fetching: true, error: null };
    case LOCATIONS_ACTIVITY_CODES_SUCCESS:
      return {
        ...state,
        fetching: false,
        locationActivityCodes: action.data,
        error: null,
      };
    case LOCATIONS_ACTIVITY_CODES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case BENEFICIARY_GROUPS_REQUEST:
      return { ...state, fetching: true, error: null };
    case BENEFICIARY_GROUPS_SUCCESS:
      return {
        ...state,
        fetching: false,
        beneficiaryGroups: action.data,
        error: null,
      };
    case BENEFICIARY_GROUPS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case ORGANIZATION_UNITS_REQUEST:
      return { ...state, fetching: true, error: null };
    case ORGANIZATION_UNITS_SUCCESS:
      return {
        ...state,
        fetching: false,
        organizationUnits: action.data,
        error: null,
      };
    case ORGANIZATION_UNITS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case CURRENT_DISTRIBUTION_REPORT_REQUEST:
    case EDIT_DISTRIBUTION_REPORT_REQUEST:
      return { ...state, fetching: true, error: null };
    case CURRENT_DISTRIBUTION_REPORT_SUCCESS:
      const { currentDistributionReport } = action;
      const currentDistributionReportObject = {
        [currentDistributionReport.id]: currentDistributionReport,
      };
      const updateObject = {
        distributionReports: {
          ...state.distributionReports,
          results: {
            ...state.distributionReports.results,
            ...currentDistributionReportObject,
          },
        },
      };
      return {
        ...state,
        ...updateObject,
        fetching: false,
        error: null,
      };
    case CURRENT_DISTRIBUTION_REPORT_FAILURE:
    case EDIT_DISTRIBUTION_REPORT_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case EDIT_DISTRIBUTION_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };
    default:
      return state;
  }
}

const persistedReducer = persistReducer(
  {
    key: "distributionReports",
    storage,
    blacklist: ["error", "errorResponse", "fetching"],
  },
  reducer
);

export default persistedReducer;

export const requestDistributionReportsNonPaginated = (locationId) => ({
  type: NON_PAGINATED_DISTRIBUTION_REPORTS_REQUEST,
  locationId,
});

export const requestAllDistributionReports = (
  pageSize,
  pageIndex,
  ordering,
  filters
) => ({
  type: ALL_DISTRIBUTION_REPORTS_REQUEST,
  pageSize,
  pageIndex,
  ordering,
  filters,
});

export const requestPartnerships = (countryId, onlyActive = false) => ({
  type: PARTNERSHIPS_REQUEST,
  countryId,
  onlyActive,
});

export const requestLocations = (partnershipId) => ({
  type: LOCATIONS_REQUEST,
  partnershipId,
});

export const requestLocationsActivityCodes = (partnershipId) => ({
  type: LOCATIONS_ACTIVITY_CODES_REQUEST,
  partnershipId,
});

export const requestBeneficiaryGroups = () => ({
  type: BENEFICIARY_GROUPS_REQUEST,
});

export const requestCreateDistributionReports = (data, history) => ({
  type: CREATE_DISTRIBUTION_REPORT_REQUEST,
  data,
  history,
});

export const requestEditDistributionReport = (id, data, history) => ({
  type: EDIT_DISTRIBUTION_REPORT_REQUEST,
  id,
  data,
  history,
});

export const requestOrganizationUnits = (countryId) => ({
  type: ORGANIZATION_UNITS_REQUEST,
  countryId,
});

export const requestCurrentDistributionReport = (
  distributionReportId,
  history
) => ({
  type: CURRENT_DISTRIBUTION_REPORT_REQUEST,
  distributionReportId,
  history,
});

export function* distributionReportsNonPaginatedSagaWatcher() {
  yield takeLatest(
    NON_PAGINATED_DISTRIBUTION_REPORTS_REQUEST,
    distributionReportsNonPaginatedSagaWorker
  );
}

export function* allDistributionReportsSagaWatcher() {
  yield takeLatest(
    ALL_DISTRIBUTION_REPORTS_REQUEST,
    allDistributionReportsSagaWorker
  );
}

export function* partnershipsSagaWatcher() {
  yield takeLatest(PARTNERSHIPS_REQUEST, partnershipsSagaWorker);
}

export function* locationsSagaWatcher() {
  yield takeLatest(LOCATIONS_REQUEST, locationsSagaWorker);
}

export function* locationsSagaActivityCodesWatcher() {
  yield takeLatest(
    LOCATIONS_ACTIVITY_CODES_REQUEST,
    locationActivityCodesSagaWorker
  );
}

export function* beneficiaryGroupsSagaWatcher() {
  yield takeLatest(BENEFICIARY_GROUPS_REQUEST, beneficiaryGroupsSagaWorker);
}

export function* createDistributionReportSagaWatcher() {
  yield takeLatest(
    CREATE_DISTRIBUTION_REPORT_REQUEST,
    createDistributionReportSagaWorker
  );
}

export function* editDistributionReportSagaWatcher() {
  yield takeLatest(
    EDIT_DISTRIBUTION_REPORT_REQUEST,
    editDistributionReportSagaWorker
  );
}

export function* organizationUnitsSagaWatcher() {
  yield takeLatest(ORGANIZATION_UNITS_REQUEST, organizationUnitsSagaWorker);
}

export function* currentDistributionReportSagaWatcher() {
  yield takeLatest(
    CURRENT_DISTRIBUTION_REPORT_REQUEST,
    currentDistributionReportSagaWorker
  );
}

function fetchDistributionReportsNonPaginated(action) {
  const { locationId } = action;
  const url =
    `${process.env.REACT_APP_API_URL}/distribution-reports/` +
    (locationId ? `?location=${locationId}` : "");
  return axios({
    method: "GET",
    url: url,
    params: {
      no_pagination: true,
    },
  });
}

function* distributionReportsNonPaginatedSagaWorker(action) {
  try {
    const response = yield call(fetchDistributionReportsNonPaginated, action);
    const data = response.data;
    yield put({
      type: NON_PAGINATED_DISTRIBUTION_REPORTS_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: NON_PAGINATED_DISTRIBUTION_REPORTS_FAILURE,
      error,
    });
  }
}

function fetchAllDistributionReports(action) {
  const page_size = action.pageSize;
  const page = action.pageIndex * page_size - page_size;
  const filters = action.filters;
  const ordering = action.ordering.replace(/\./g, "_");

  const url =
    `${process.env.REACT_APP_API_URL}/distribution-reports/` +
    `?limit=${page_size}&offset=${page}` +
    (ordering ? `&ordering=${ordering}` : "") +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            if (value && value !== "") {
              return `&${id}=${value}`;
            }
            return "";
          })
          .join("")
      : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function* allDistributionReportsSagaWorker(action) {
  try {
    const response = yield call(fetchAllDistributionReports, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");
    const data = {
      ...response.data,
      results,
      ids,
    };
    yield put({
      type: ALL_DISTRIBUTION_REPORTS_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: ALL_DISTRIBUTION_REPORTS_FAILURE,
      error,
    });
  }
}

function fetchPartnerships(action) {
  const { countryId, onlyActive } = action;
  const url = `${process.env.REACT_APP_API_URL}/partnerships/`;

  return axios({
    method: "GET",
    url: url,
    params: { country: countryId, onlyActive },
  });
}

function* partnershipsSagaWorker(action) {
  try {
    const response = yield call(fetchPartnerships, action);
    const data = response.data;

    yield put({
      type: PARTNERSHIPS_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: PARTNERSHIPS_FAILURE,
      error,
    });
  }
}

function fetchLocations(action) {
  const { partnershipId } = action;
  const url =
    `${process.env.REACT_APP_API_URL}/locations/` +
    (partnershipId ? `?partnership=${partnershipId}` : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function* locationsSagaWorker(action) {
  try {
    const response = yield call(fetchLocations, action);
    const data = response.data;
    yield put({
      type: LOCATIONS_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: LOCATIONS_FAILURE,
      error,
    });
  }
}

function fetchLocationActivityCodes(action) {
  const { partnershipId } = action;
  const url =
    `${process.env.REACT_APP_API_URL}/locations/activity-codes/` +
    (partnershipId ? `?partnership=${partnershipId}` : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function* locationActivityCodesSagaWorker(action) {
  try {
    const response = yield call(fetchLocationActivityCodes, action);
    const data = response.data;
    yield put({
      type: LOCATIONS_ACTIVITY_CODES_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: LOCATIONS_ACTIVITY_CODES_FAILURE,
      error,
    });
  }
}

function fetchBeneficiaryGroups(action) {
  const url = `${process.env.REACT_APP_API_URL}/beneficiary-groups/`;
  return axios({
    method: "GET",
    url: url,
  });
}

function* beneficiaryGroupsSagaWorker(action) {
  try {
    const response = yield call(fetchBeneficiaryGroups, action);
    const data = response.data;
    yield put({
      type: BENEFICIARY_GROUPS_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: BENEFICIARY_GROUPS_FAILURE,
      error,
    });
  }
}

function getErrorLabel(error) {
  const errorObject = error?.response?.data?.errors?.message;
  const errorMessage = errorObject ? errorObject[0] : undefined;

  let invalidSchools = [];
  if (errorMessage) {
    invalidSchools = error?.response?.data?.errors?.schools?.join(", ");
  }

  const label =
    errorMessage === "clashing_period" ? (
      <FormattedMessage
        id="toast.clashingPeriod"
        defaultMessage="The following schools ({schools}) already have a distribution report within that period. None of the selected schools were updated"
        values={{ schools: invalidSchools }}
      />
    ) : errorMessage === "invalid_country_schools" ? (
      <FormattedMessage
        id="toast.invalidCountry"
        defaultMessage="The following schools ({schools}) do not match the country admin's country. None of the selected schools were updated"
        values={{ schools: invalidSchools }}
      />
    ) : undefined;
  return label;
}

function postDistributionReport(action) {
  const data = action.data;
  const url = `${process.env.REACT_APP_API_URL}/distribution-reports/`;
  return axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url: url,
    data,
  });
}

function* createDistributionReportSagaWorker(action) {
  try {
    yield call(postDistributionReport, action);
    yield put({
      type: CREATE_DISTRIBUTION_REPORT_SUCCESS,
    });
    yield put(successToast());
    action.history.push(
      "/countryAdmin/distributionReport/allDistributionReportProfiles/"
    );
  } catch (error) {
    const label = getErrorLabel(error);
    // dispatch a failure action to the store with the error
    yield put({
      type: CREATE_DISTRIBUTION_REPORT_FAILURE,
      error,
    });
    yield put(errorToast(label));
  }
}

function putDistributionReport(action) {
  const { id, data } = action;
  const url = `${process.env.REACT_APP_API_URL}/distribution-reports/${id}/`;
  return axios(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
}

function* editDistributionReportSagaWorker(action) {
  try {
    yield call(putDistributionReport, action);
    yield put({
      type: EDIT_DISTRIBUTION_REPORT_SUCCESS,
    });
    yield put(successToast());
    action.history.push(
      "/countryAdmin/distributionReport/allDistributionReportProfiles/"
    );
  } catch (error) {
    const label = getErrorLabel(error);
    // dispatch a failure action to the store with the error
    yield put({
      type: EDIT_DISTRIBUTION_REPORT_FAILURE,
      error,
    });
    yield put(errorToast(label));
  }
}

function fetchOrganizationUnits(action) {
  const { countryId } = action;
  const url =
    `${process.env.REACT_APP_API_URL}/org-units/` +
    (countryId ? `?country_id=${countryId}` : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function* organizationUnitsSagaWorker(action) {
  try {
    const response = yield call(fetchOrganizationUnits, action);
    const data = response.data;
    yield put({
      type: ORGANIZATION_UNITS_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: ORGANIZATION_UNITS_FAILURE,
      error,
    });
  }
}

function fetchGetDistributionReport(action) {
  const { distributionReportId } = action;
  const url = `${process.env.REACT_APP_API_URL}/distribution-reports/${distributionReportId}/`;
  return axios({
    method: "GET",
    url: url,
  });
}

function* currentDistributionReportSagaWorker(action) {
  try {
    const response = yield call(fetchGetDistributionReport, action);
    const currentDistributionReport = response.data;
    yield put({
      type: CURRENT_DISTRIBUTION_REPORT_SUCCESS,
      currentDistributionReport,
    });
  } catch (error) {
    const { history } = action;
    history.push(
      `/countryAdmin/distributionReport/allDistributionReportProfiles`
    );
    yield put({ type: CURRENT_DISTRIBUTION_REPORT_FAILURE, error });
  }
}

export const getAllDistributionReportsNonPaginated = (state) =>
  state.distributionReports.allDistributionReports;

export const getAllDistributionReports = (state) =>
  state.distributionReports.distributionReports;

export const getDistributionReportsIsFetching = (state) =>
  state.distributionReports.fetching;

export const getPartnerships = (state) =>
  state.distributionReports.partnerships;

export const getLocations = (state) => state.distributionReports.locations;

export const getLocationActivityCodes = (state) =>
  state.distributionReports.locationActivityCodes;

export const getBeneficiaryGroups = (state) =>
  state.distributionReports.beneficiaryGroups;

export const getOrganizationUnits = (state) =>
  state.distributionReports.organizationUnits;
