import React from "react";
import { FormattedMessage } from "react-intl";

import { studentKinds, StudentKind } from "SCConstants";
import {
  Attendance,
  SchoolYear,
  AggregateSchoolYearLevel,
} from "data-handler/ducks/stores";
import { getFilteredAttendanceTotal } from "helpers/attendance";
import TableExtended from "components/TableExtended";
import LevelTitle from "components/LevelTitle";

import styles from "../AttendanceEdit/attendance-edit.module.scss";

import { displayStaffLevel } from "components/Attendance/AttendanceContent";

/**
 * The Attendance table for mobile has morning and afternoon stacked vertically
 */
const AttendanceTableMobile = ({
  attendance,
  schoolYear,
  schoolYearLevels,
}: {
  attendance: Attendance;
  schoolYear: SchoolYear;
  schoolYearLevels: AggregateSchoolYearLevel[];
}) => {
  if (attendance.levels === undefined) {
    return <> No Levels </>;
  }

  const collator = new Intl.Collator([], { numeric: true });

  const morningLevels = attendance.levels.filter(
    (level: { shift: string }) => level.shift === "morning"
  );
  const afternoonLevels = attendance.levels.filter(
    (level: { shift: string }) => level.shift === "afternoon"
  );

  const showMorning = morningLevels.length !== 0;
  const showAfternoon =
    !schoolYear.has_same_students_in_both_shifts &&
    afternoonLevels.length !== 0;

  // Morning Rows Only
  const mornlevels = new Set<string>();
  morningLevels.forEach((level) => mornlevels.add(level.level));

  const morningData = [...mornlevels].sort(collator.compare).map((level) => {
    return {
      level,
      morning: morningLevels.find(
        (l) => l.level === level && l.shift === "morning"
      ),
    };
  });

  // Afternoon Rows Only
  const afternoonlevels = new Set<string>();
  morningLevels.forEach((level) => afternoonlevels.add(level.level));

  const afternoonData = [...afternoonlevels]
    .sort(collator.compare)
    .map((level) => {
      return {
        level,
        afternoon: afternoonLevels.find(
          (l) => l.level === level && l.shift === "afternoon"
        ),
      };
    });

  const getTotalsRowsByShift = (shift: "morning" | "afternoon") => {
    return displayStaffLevel(schoolYearLevels).map((row, i) => (
      <tr key={i} className={i === 0 ? "diff" : ""}>
        <td className="wfp--table__title">{row.title}</td>
        {studentKinds.map((kind) => (
          <td className="wfp--table__total">
            {getFilteredAttendanceTotal({
              attendance,
              levels: row.levelValues,
              kinds: [kind.value as StudentKind],
              shifts: [shift],
            })}
          </td>
        ))}
        <td className="wfp--table__total">
          {studentKinds.reduce((acc, kind) => {
            const total = getFilteredAttendanceTotal({
              attendance,
              levels: row.levelValues,
              kinds: [kind.value as StudentKind],
              shifts: [shift],
            });
            acc = acc + total;
            return acc;
          }, 0)}
        </td>
      </tr>
    ));
  };

  const getRowsMorning = morningData.map((levelData) => (
    <tr key={levelData.level}>
      <td className="wfp--table__title">
        <LevelTitle level={levelData} />
      </td>
      {showMorning &&
        studentKinds.map((kind) => (
          <td className="wfp--table__show-value">
            {levelData.morning === undefined
              ? 0
              : levelData.morning[kind.key as "male" | "female"]}
          </td>
        ))}
      <td className="wfp--table__show-total-value">
        {getFilteredAttendanceTotal({
          attendance,
          levels: [levelData.level],
        })}
      </td>
    </tr>
  ));

  const getRowsAfternoon = afternoonData.map((levelData) => (
    <tr key={levelData.level}>
      <td className="wfp--table__title">
        <LevelTitle level={levelData} />
      </td>
      {showMorning &&
        studentKinds.map((kind) => (
          <td className="wfp--table__show-value">
            {levelData.afternoon === undefined
              ? 0
              : levelData.afternoon[kind.key as "male" | "female"]}
          </td>
        ))}
      <td className="wfp--table__show-total-value">
        {getFilteredAttendanceTotal({
          attendance,
          levels: [levelData.level],
        })}
      </td>
    </tr>
  ));

  return (
    <TableExtended noBorder className={styles.tableMobile}>
      <thead>
        <tr>
          <th></th>
          {studentKinds.map((kind) => (
            <th>{kind.labelTrans}</th>
          ))}
          <th>
            <FormattedMessage
              id="AttendanceShow.total"
              defaultMessage="Total"
            />
          </th>
        </tr>
      </thead>
      {showMorning && (
        <>
          <thead>
            <tr>
              <th></th>
              <th colSpan={studentKinds.length}>
                <FormattedMessage
                  id="AttendanceShow.morning"
                  defaultMessage="Morning"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>{getRowsMorning}</tbody>
          <tbody>{getTotalsRowsByShift("morning")}</tbody>
        </>
      )}
      {showAfternoon && (
        <>
          <thead>
            <tr>
              <th></th>
              <th colSpan={studentKinds.length}>
                <FormattedMessage
                  id="AttendanceShow.afternoon"
                  defaultMessage="Afternoon"
                />
              </th>
              <th>
                <FormattedMessage
                  id="AttendanceShow.total"
                  defaultMessage="Total"
                />
              </th>
            </tr>
          </thead>
          <tbody>{getRowsAfternoon}</tbody>
          <tfoot>{getTotalsRowsByShift("afternoon")}</tfoot>
        </>
      )}
    </TableExtended>
  );
};

export default AttendanceTableMobile;
