import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Button, User, Tabs, Icon } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  faDebug,
  faUser,
  faUserFriends as faUserFriendsSolid,
  faTruckContainer as faTruckContainerSolid,
  faChartBar as faChartBarSolid,
  faInfoCircle as faInfoCircleSolid,
  faCommentAltCheck as faCommentAltCheckSolid,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faUserFriends,
  faTruckContainer,
  faChartBar,
  faInfoCircle,
  faCommentAltCheck,
  faWifi,
  faWifiSlash,
} from "@fortawesome/pro-solid-svg-icons";
import TabLink from "components/TabLink";
import OnlineOffline from "components/OnlineOffline";

import "./_content.scss";

class MobileNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.child = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.location && this.props.location) {
      this.closeMenu();
    }
  }

  closeMenu = () => {
    //this.child.current.onChangeSub("close");
  };

  render() {
    const schoolId = this.props.match.params.schoolId;

    const homeScreen = window.innerHeight / window.screen.height > 0.9;
    // Some probability of this being a standalone app.

    const classes = classnames("mobile-nav-bottom", "safari_only", {
      "mobile-nav-bottom--homescreen": homeScreen,
    });

    return (
      <>
        <div className={classes}>
          <Tabs customTabContent>
            <TabLink
              exact
              to={`/school/${schoolId}/stock/overview?nav=true`}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faChartBar} />
                <FontAwesomeIcon icon={faChartBarSolid} />
              </div>
              <FormattedMessage
                id="MobileNav.overview"
                defaultMessage="Overview"
              />
            </TabLink>
            <TabLink
              to={`/school/${schoolId}/attendance?nav=true`}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faUserFriends} />
                <FontAwesomeIcon icon={faUserFriendsSolid} />
              </div>
              <FormattedMessage
                id="MobileNav.attendance"
                defaultMessage="Attendance"
              />
            </TabLink>
            <TabLink
              to={`/school/${schoolId}/deliveries?nav=true`}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faTruckContainer} />
                <FontAwesomeIcon icon={faTruckContainerSolid} />
              </div>
              <FormattedMessage
                id="MobileNav.deliveries"
                defaultMessage="Deliveries"
              />
            </TabLink>
            <TabLink
              to={`/school/${schoolId}/info?nav=true`}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faInfoCircle} />
                <FontAwesomeIcon icon={faInfoCircleSolid} />
              </div>
              <FormattedMessage id="MobileNav.school" defaultMessage="School" />
            </TabLink>
            <TabLink
              to={`/school/${schoolId}/report?nav=true`}
              renderListElement={TabLink}
            >
              <div>
                <FontAwesomeIcon icon={faCommentAltCheck} />
                <FontAwesomeIcon icon={faCommentAltCheckSolid} />
              </div>
              <FormattedMessage id="MobileNav.report" defaultMessage="Report" />
            </TabLink>
          </Tabs>
        </div>
        <div className="mobile-nav">
          <div className="mobile-nav__content">
            <NavLink exact to={`?select=school&nav=true`}>
              <User ellipsis />
            </NavLink>

            <div className="mobile-nav__title">
              <FormattedMessage
                id={`Nav.${
                  this.props.match.params.page
                    ? this.props.match.params.page
                    : "Overview"
                }`}
                defaultMessage={
                  this.props.match.params.page
                    ? this.props.match.params.page
                    : "Overview"
                }
              />
            </div>

            <NavLink exact to={`?select=sync&nav=true`}>
              <OnlineOffline
                onlineChildren={
                  <Button
                    className="mobile-nav__sync-btn"
                    data-testid="mobile-nav__sync-btn"
                    small
                    kind="ghost"
                    icon={<FontAwesomeIcon icon={faSync} />}
                  ></Button>
                }
                offlineChildren={
                  <Button
                    className="mobile-nav__offline-sync-btn"
                    kind="danger--primary"
                    disabled
                    small
                    icon={<FontAwesomeIcon icon={faInfoCircle} />}
                  >
                    <FormattedMessage
                      id="menu.syncNotAvailableMobile"
                      defaultMessage="Offline"
                    />
                  </Button>
                }
              />
            </NavLink>

            <OnlineOffline
              onlineChildren={<Icon icon={<FontAwesomeIcon icon={faWifi} />} />}
              offlineChildren={
                <>
                  <Icon
                    icon={<FontAwesomeIcon icon={faWifiSlash} />}
                    className="content__header__offline-icon"
                    description="Offline"
                  />
                </>
              }
            />

            <NavLink
              exact
              to={`?select=user&nav=true`}
              data-testid="mobile-nav-user"
            >
              <Button
                className="mobile-nav__sync-btn"
                small
                kind="ghost"
                icon={<FontAwesomeIcon icon={faUser} />}
              ></Button>
            </NavLink>

            <NavLink exact to={`?select=debug&nav=true`}>
              <Button
                className="mobile-nav__sync-btn"
                small
                kind="ghost"
                icon={<FontAwesomeIcon icon={faDebug} />}
              ></Button>
            </NavLink>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    currentSchool: state.currentSchool,
    showMenu: state.showMenu,
    language: state.language,
  };
};

export default compose(withRouter, connect(mapStateToProps))(MobileNav);
