/**
 * Returns formatted string of given number with given measureUnit
 */
export const toMeasureUnitString = (
  value: string | number,
  measureUnit: { id: number; name: string; symbol: string; decimals: number },
  language: string
): string => {
  // It would probably better if we just got locale value from the browser.
  // But I'll keep this version since this was already in the codebase.
  const locale = language === "en" ? "en-EN" : "fr-FR";

  const minimumFractionDigits =
    measureUnit?.decimals === undefined ? 3 : measureUnit.decimals;
  const maximumFractionDigits =
    measureUnit?.decimals === undefined ? 3 : measureUnit.decimals;

  let numberValue = value;
  if (typeof value === "string") {
    numberValue = parseFloat(value);
  }

  const formattedValue = numberValue.toLocaleString(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return `${formattedValue} ${measureUnit.symbol}`;
};
