import React from "react";
import { FormattedMessage } from "react-intl";
import { ModuleBody } from "@wfp/ui";

import TableExtended from "components/TableExtended";

import styles from "./school-report.module.scss";
import moment from "moment";

const SchoolData = ({
  report: {
    school_name,
    submitted_at,
    aggregates: {
      closest_wfp_office,
      local_education_authority,
      regional_education_authority,
      latest_delivery_date,
      programme_manager,
    },
  },
}) => {
  const latest_delivery_date_ =
    latest_delivery_date && moment(latest_delivery_date).format("DD-MM-YYYY");
  const date_of_report =
    submitted_at && moment(submitted_at).format("DD-MM-YYYY");
  return (
    <ModuleBody className={styles.reportModule}>
      <div
        className={styles.headerData}
        style={{ margin: "0.5rem", textAlign: "center" }}
      >
        <FormattedMessage
          id="reportHeader.programme_manager_label"
          defaultMessage="The School Canteen programme is managed by: {programme_manager}"
          values={{ programme_manager }}
        />
      </div>
      <TableExtended className={styles.table}>
        <tbody>
          <tr>
            <td className={styles.staticData} style={{ background: "#f0f3f6" }}>
              <FormattedMessage
                id="schoolData.name_of_school"
                defaultMessage="Name of School"
              />
            </td>
            <td className={styles.staticData}>
              <span className={styles.boldText}>{school_name}</span>
            </td>
            <td className={styles.staticData} style={{ background: "#f0f3f6" }}>
              <FormattedMessage
                id="schoolData.nearest_wfp_office"
                defaultMessage="Nearest WFP Office"
              />
            </td>
            <td className={styles.staticData}>{closest_wfp_office}</td>
          </tr>
          <tr>
            <td className={styles.staticData} style={{ background: "#f0f3f6" }}>
              <FormattedMessage
                id="schoolData.municipal_directorate_of_education"
                defaultMessage="Municipal Directorate of Education"
              />
            </td>
            <td className={styles.staticData}>{local_education_authority}</td>
            <td className={styles.staticData} style={{ background: "#f0f3f6" }}>
              <FormattedMessage
                id="schoolData.last_wfp_delivery_date"
                defaultMessage="Date of last WFP food delivery"
              />
            </td>
            <td className={styles.staticData}>{latest_delivery_date_}</td>
          </tr>
          <tr>
            <td className={styles.staticData} style={{ background: "#f0f3f6" }}>
              <FormattedMessage
                id="schoolData.provincial_directorate_of_education"
                defaultMessage="Provincial Directorate of Education"
              />
            </td>
            <td className={styles.staticData}>
              {regional_education_authority}
            </td>
            <td className={styles.staticData} style={{ background: "#f0f3f6" }}>
              <FormattedMessage
                id="schoolData.date_of_report"
                defaultMessage="Date of report"
              />
            </td>
            <td className={styles.staticData}>{date_of_report}</td>
          </tr>
        </tbody>
      </TableExtended>
    </ModuleBody>
  );
};

export default SchoolData;
