import { getSelectedCountry, Currency } from "./countryProfile";
import { RootState } from "data-handler/rootReducer";
import { getCurrentSchool } from "./schools";

// Currency(code="USD", code_number=840, name="US Dollar", decimals=2)
const USD: Currency = {
  id: -1,
  code: "USD",
  code_number: 840,
  name: "US Dollar",
  decimals: 2,
};

export const getCurrencyForSelectedCountry = (state: RootState): Currency =>
  getSelectedCountry(state)?.currency || USD;

export const getCurrencyForCurrentSchool = (state: RootState): Currency =>
  getCurrentSchool(state)?.country?.currency || USD;
