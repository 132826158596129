import React from "react";
import { FormattedMessage } from "react-intl";
import { colors } from "@wfp/ui";

export const buildNumber = process.env.REACT_APP_BUILD_NUMBER
  ? process.env.REACT_APP_BUILD_NUMBER
  : "";

export const noMealsOptions = [
  {
    value: 1,
    isNoSchoolReason: true,
    label: (
      <FormattedMessage
        id="Common.noMealReasonPedagogicalDayOff"
        defaultMessage="Pedagogical day-off"
      />
    ),
  },
  {
    value: 2,
    isNoSchoolReason: false,
    label: (
      <FormattedMessage
        id="Common.noMealReasonNoCook"
        defaultMessage="No cook"
      />
    ),
  },
  {
    value: 3,
    isNoSchoolReason: false,
    label: (
      <FormattedMessage
        id="Common.noMealReasonNoWater"
        defaultMessage="No water"
      />
    ),
  },
  {
    value: 4,
    isNoSchoolReason: false,
    label: (
      <FormattedMessage
        id="Common.noMealReasonNoFirewood"
        defaultMessage="No firewood"
      />
    ),
  },
  {
    value: 5,
    isNoSchoolReason: true,
    label: (
      <FormattedMessage
        id="Common.noMealReasonPublicHoliday"
        defaultMessage="Public holiday"
      />
    ),
  },
  {
    value: 6,
    isNoSchoolReason: false,
    label: (
      <FormattedMessage
        id="Common.noMealReasonNoStock"
        defaultMessage="No stock"
      />
    ),
  },
  {
    value: 7,
    isNoSchoolReason: true,
    label: (
      <FormattedMessage
        id="Common.noMealReasonNoClass"
        defaultMessage="No class today"
      />
    ),
  },
];

/**
 * This is only used to filter out BE Roles.
 */
export const staffKind = [
  {
    name: "principal",
    title: (
      <FormattedMessage
        id="Common.staffKindPrincipal"
        defaultMessage="Principal"
      />
    ),
  },
  {
    name: "teacher",
    title: (
      <FormattedMessage id="Common.staffKindTeacher" defaultMessage="Teacher" />
    ),
  },
  {
    name: "store_keeper",
    title: (
      <FormattedMessage
        id="Common.staffKindStoreKeeper"
        defaultMessage="Store keeper"
      />
    ),
  },
];

export const commoditySourceOptions = [
  {
    value: 1,
    key: "stockSourceSchoolGarden",
    label: "School garden",
    text: (
      <FormattedMessage
        id="Common.stockSourceSchoolGarden"
        defaultMessage="School garden"
      />
    ),
  },
  {
    value: 2,
    key: "stockSourceParents",
    label: "Parents",
    text: (
      <FormattedMessage
        id="Common.stockSourceParents"
        defaultMessage="Parents"
      />
    ),
  },
  {
    value: 3,
    key: "stockSourceCommunity",
    label: "Community",
    text: (
      <FormattedMessage
        id="Common.stockSourceCommunity"
        defaultMessage="Community"
      />
    ),
  },
  {
    value: 4,
    key: "stockSourceDirectionNational",
    label: "Direction National des Cantines Scolaires",
    text: (
      <FormattedMessage
        id="Common.stockSourceDirectionNational"
        defaultMessage="Direction National des Cantines Scolaires"
      />
    ),
  },
  {
    value: 5,
    key: "stockSourceSchoolPurchase",
    label: "School purchase",
    text: (
      <FormattedMessage
        id="Common.stockSourceSchoolPurchase"
        defaultMessage="School purchase"
      />
    ),
  },
  {
    value: 6,
    key: "stockSourceOtherNGO",
    label: "Other NGO",
    text: (
      <FormattedMessage
        id="Common.stockSourceOtherNGO"
        defaultMessage="Other NGO"
      />
    ),
  },
];

export const staffLevels = [
  {
    value: "staff",
    label: (
      <FormattedMessage id="Common.staff" defaultMessage="Staff 18+ years" />
    ),
  },
];

export const prePrimaryLevels = [
  {
    value: "pre-1",
    label: (
      <FormattedMessage
        id="Common.pre-1"
        defaultMessage="Pre-Primary (<2 years)"
      />
    ),
  },
  {
    value: "pre-2",
    label: (
      <FormattedMessage
        id="Common.pre-2"
        defaultMessage="Pre-Primary (2-3 years)"
      />
    ),
  },
  {
    value: "pre-3",
    label: (
      <FormattedMessage
        id="Common.pre-3"
        defaultMessage="Pre-Primary (3-4 years)"
      />
    ),
  },
  {
    value: "pre-4",
    label: (
      <FormattedMessage
        id="Common.pre-4"
        defaultMessage="Pre-Primary (4-5 years)"
      />
    ),
  },
  {
    value: "pre-5",
    label: (
      <FormattedMessage
        id="Common.pre-5"
        defaultMessage="Pre-Primary (5-6 years)"
      />
    ),
  },
  {
    value: "pre-6",
    label: (
      <FormattedMessage
        id="Common.pre-6"
        defaultMessage="Pre-Primary mixed (<6 years)"
      />
    ),
  },
];

export const primaryLevels = [
  {
    value: "primary-1",
    label: (
      <FormattedMessage
        id="Common.primary-1"
        defaultMessage="Primary (6-7 years)"
      />
    ),
  },
  {
    value: "primary-2",
    label: (
      <FormattedMessage
        id="Common.primary-2"
        defaultMessage="Primary (7-8 years)"
      />
    ),
  },
  {
    value: "primary-3",
    label: (
      <FormattedMessage
        id="Common.primary-3"
        defaultMessage="Primary (8-9 years)"
      />
    ),
  },
  {
    value: "primary-4",
    label: (
      <FormattedMessage
        id="Common.primary-4"
        defaultMessage="Primary (9-10 years)"
      />
    ),
  },
  {
    value: "primary-5",
    label: (
      <FormattedMessage
        id="Common.primary-5"
        defaultMessage="Primary (10-11 years)"
      />
    ),
  },
  {
    value: "primary-6",
    label: (
      <FormattedMessage
        id="Common.primary-6"
        defaultMessage="Primary (11-12 years)"
      />
    ),
  },
  {
    value: "primary-7",
    label: (
      <FormattedMessage
        id="Common.primary-7"
        defaultMessage="Secondary (12-13 years)"
      />
    ),
  },
  {
    value: "primary-8",
    label: (
      <FormattedMessage
        id="Common.primary-8"
        defaultMessage="Secondary (13-14 years)"
      />
    ),
  },
  {
    value: "primary-9",
    label: (
      <FormattedMessage
        id="Common.primary-9"
        defaultMessage="Secondary (14-15 years)"
      />
    ),
  },
  {
    value: "primary-10",
    label: (
      <FormattedMessage
        id="Common.primary-10"
        defaultMessage="Secondary (15-16 years)"
      />
    ),
  },
  {
    value: "primary-11",
    label: (
      <FormattedMessage
        id="Common.primary-11"
        defaultMessage="Secondary (16-17 years)"
      />
    ),
  },
  {
    value: "primary-12",
    label: (
      <FormattedMessage
        id="Common.primary-12"
        defaultMessage="Secondary (17-18 years)"
      />
    ),
  },
];
const collator = new Intl.Collator([], { numeric: true });

/**
 * All SC student levels (a concept loosely related to student ages).
 */
export const studentLevels = [
  ...prePrimaryLevels,
  ...primaryLevels,
].sort((a, b) => collator.compare(a.value, b.value));

export const staffLevel = [...staffLevels].sort((a, b) =>
  collator.compare(a.value, b.value)
);
export const allLevels = [...studentLevels, ...staffLevel].sort((a, b) =>
  collator.compare(a.value, b.value)
);
export const staffLevelValues = staffLevel.map((level) => level.value);
export const staffLevelLabelMap = Object.fromEntries(
  staffLevel.map((level) => [level.value, level.label])
);

/**
 * Just the values of `studentLevels` e.g. "pre-1".
 *
 * Useful for sorting levels according to their index within this list.
 */
export const studentLevelValues = studentLevels.map((level) => level.value);

/**
 * A map from student level values to their labels.
 */
export const studentLevelLabelMap = Object.fromEntries(
  studentLevels.map((level) => [level.value, level.label])
);

export const studentTotalsList = [
  {
    key: "pre",
    title: (
      <FormattedMessage
        id="Common.prePrimaryTotal"
        defaultMessage="Pre-Primary total"
      />
    ),
    levels: prePrimaryLevels,
    levelValues: prePrimaryLevels.map((level) => level.value),
  },
  {
    key: "primary",
    title: (
      <FormattedMessage
        id="Common.primaryTotal"
        defaultMessage="Primary total"
      />
    ),
    levels: primaryLevels,
    levelValues: primaryLevels.map((level) => level.value),
  },
];

export const studentLevelTotals = [
  ...studentTotalsList,
  {
    key: "total",
    title: (
      <FormattedMessage
        id="Common.studentAllLevelsTotal"
        defaultMessage="Students Total"
      />
    ),
    levels: studentLevels,
    levelValues: studentLevels.map((level) => level.value),
  },
];

export const staffLevelTotals = [
  {
    key: "staff",
    title: (
      <FormattedMessage
        id="Common.staffLevelsTotal"
        defaultMessage="Staff Total"
      />
    ),
    levels: staffLevel,
    levelValues: staffLevel.map((level) => level.value),
  },
];

export const total = [
  ...staffLevelTotals,
  ...studentLevelTotals,
  {
    key: "finalTotal",
    title: <FormattedMessage id="Common.Total" defaultMessage="Total" />,
    levels: allLevels,
    levelValues: allLevels.map((level) => level.value),
  },
];

// TODO this seems to be not used. We should remove it if we do not need it.
// Date: 2024-07-31
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pages = [
  { label: <FormattedMessage id="Nav.stock" defaultMessage="Overview" /> },
  {
    label: (
      <FormattedMessage
        id="Nav.attendance"
        defaultMessage="Attendance & meals"
      />
    ),
  },
  {
    label: <FormattedMessage id="Nav.deliveries" defaultMessage="Deliveries" />,
  },
  { label: <FormattedMessage id="Nav.info" defaultMessage="School admin" /> },
  { label: <FormattedMessage id="Nav.report" defaultMessage="Report" /> },
];

export type AttendanceShift = "morning" | "afternoon";

export type StudentKind = "male" | "female";

type StudentKinds = {
  key: StudentKind;
  title: string;
  value: StudentKind;
  labelTrans: React.ReactNode;
}[];

// TODO: only keep value and label
export const studentKinds: StudentKinds = [
  {
    key: "male",
    title: "boys", // TODO: remove
    value: "male", // TODO: remove
    labelTrans: <FormattedMessage id="Common.male" defaultMessage="Boys" />,
  },
  {
    key: "female",
    title: "girls", // TODO: remove
    value: "female", // TODO: remove
    labelTrans: <FormattedMessage id="Common.female" defaultMessage="Girls" />,
  },
];

// Use Roles instead of Groups.
export const UserPermissionRoles = {
  is_viewer: "is_viewer",
  is_admin: "is_admin",
  is_countryAdmin: "is_countryAdmin",
  is_wfp: "is_wfp",
  is_approver: "is_approver",
  is_school_staff: "is_school_staff",
  is_school_admin: "is_school_admin",
};

export const CountryAdminLabel = [
  {
    name: "countryAdmin.reject",
    label: (
      <FormattedMessage id="countryAdmin.reject" defaultMessage="Reject" />
    ),
  },
  {
    name: "countryAdmin.approve",
    label: (
      <FormattedMessage id="countryAdmin.approve" defaultMessage="Approve" />
    ),
  },
  {
    name: "last_name",
    label: (
      <FormattedMessage id="countryAdmin.lastName" defaultMessage="Last name" />
    ),
  },
  {
    name: "other_names",
    label: (
      <FormattedMessage
        id="countryAdmin.firstName"
        defaultMessage="First name"
      />
    ),
  },
  {
    name: "countryAdmin.noSchool",
    label: (
      <FormattedMessage
        id="countryAdmin.noSchool"
        defaultMessage="No School Provided"
      />
    ),
  },
  {
    name: "email",
    label: <FormattedMessage id="countryAdmin.email" defaultMessage="Email" />,
  },
  {
    name: "countryAdmin.school",
    label: (
      <FormattedMessage id="countryAdmin.school" defaultMessage="School" />
    ),
  },
  {
    name: "countryAdmin.schoolRole",
    label: (
      <FormattedMessage
        id="countryAdmin.schoolRole"
        defaultMessage="School Role"
      />
    ),
  },
  {
    name: "countryAdmin.role",
    label: <FormattedMessage id="countryAdmin.role" defaultMessage="Role" />,
  },
  {
    name: "countryAdmin.noRole",
    label: (
      <FormattedMessage id="countryAdmin.noRole" defaultMessage="No role" />
    ),
  },
  {
    name: "countryAdmin.admin",
    label: (
      <FormattedMessage id="countryAdmin.admin" defaultMessage="Global Admin" />
    ),
  },
  {
    name: "countryadmin.WFP",
    label: <FormattedMessage id="countryAdmin.WFP" defaultMessage="WFP" />,
  },
  {
    name: "countryAdmin",
    label: (
      <FormattedMessage
        id="countryAdmin.countryAdmin"
        defaultMessage="Country Admin"
      />
    ),
  },
  {
    name: "countryAdmin.active",
    label: (
      <FormattedMessage id="countryAdmin.active" defaultMessage="Active" />
    ),
  },
  {
    name: "countryAdmin.newUser",
    label: (
      <FormattedMessage
        id="countryAdmin.NewUserTitle"
        defaultMessage="New Users"
      />
    ),
  },
  {
    name: "countryAdmin.allUser",
    label: (
      <FormattedMessage
        id="countryAdmin.allUserTitle"
        defaultMessage="All Users"
      />
    ),
  },
  {
    name: "state",
    label: <FormattedMessage id="countryAdmin.State" defaultMessage="State" />,
  },
  {
    name: "countryAdmin.schoolProfiles",
    label: (
      <FormattedMessage
        id="CountryAdmin.schoolProfiles"
        defaultMessage="School Profiles"
      />
    ),
  },
  {
    name: "name",
    label: <FormattedMessage id="CountryAdmin.name" defaultMessage="Name" />,
  },
  {
    name: "region",
    label: (
      <FormattedMessage id="CountryAdmin.region" defaultMessage="Region" />
    ),
  },
  {
    name: "countryAdmin.region",
    label: (
      <FormattedMessage id="CountryAdmin.region" defaultMessage="Region" />
    ),
  },
  {
    name: "country",
    label: (
      <FormattedMessage id="CountryAdmin.country" defaultMessage="Country" />
    ),
  },
  {
    name: "countryAdmin.lastUpdated",
    label: (
      <FormattedMessage
        id="CountryAdmin.lastUpdated"
        defaultMessage="Last updated"
      />
    ),
  },
  {
    name: "countryAdmin.commodities",
    label: (
      <FormattedMessage
        id="CountryAdmin.commodities"
        defaultMessage="Commodities"
      />
    ),
  },
  {
    name: "countryAdmin.approvers",
    label: (
      <FormattedMessage
        id="CountryAdmin.approvers"
        defaultMessage="Approvers"
      />
    ),
  },
  {
    name: "countryAdmin.schoolGroupAdmins",
    label: (
      <FormattedMessage
        id="CountryAdmin.schoolGroupAdmins"
        defaultMessage="School group admins"
      />
    ),
  },
  {
    name: "countryAdmin.createNewSchoolProfile",
    label: (
      <FormattedMessage
        id="countryAdmin.createNewSchoolProfile"
        defaultMessage="Create new school profile"
      />
    ),
  },
  {
    name: "countryAdmin.deactivate",
    label: (
      <FormattedMessage
        id="countryAdmin.deactivate"
        defaultMessage="Deactivate"
      />
    ),
  },
  {
    name: "countryAdmin.activate",
    label: (
      <FormattedMessage id="countryAdmin.activate" defaultMessage="Activate" />
    ),
  },
  {
    name: "countryAdmin.promote",
    label: (
      <FormattedMessage id="countryAdmin.promote" defaultMessage="Promote" />
    ),
  },
  {
    name: "countryAdmin.partnershipId",
    label: (
      <FormattedMessage
        id="countryAdmin.partnershipId"
        defaultMessage="Partnership"
      />
    ),
  },
  {
    name: "countryAdmin.partner",
    label: (
      <FormattedMessage id="CountryAdmin.partner" defaultMessage="Partner" />
    ),
  },
  {
    name: "countryAdmin.beneficiaryGroup",
    label: (
      <FormattedMessage
        id="countryAdmin.beneficiaryGroup"
        defaultMessage="Beneficiary Group"
      />
    ),
  },
  {
    name: "countryAdmin.activityTag",
    label: (
      <FormattedMessage
        id="countryAdmin.activityTag"
        defaultMessage="Activity Tag"
      />
    ),
  },
  {
    name: "countryAdmin.country",
    label: (
      <FormattedMessage id="countryAdmin.country" defaultMessage="Country" />
    ),
  },
  {
    name: "countryAdmin.period",
    label: (
      <FormattedMessage id="countryAdmin.period" defaultMessage="Period" />
    ),
  },
  {
    name: "countryAdmin.location",
    label: (
      <FormattedMessage id="countryAdmin.location" defaultMessage="Location" />
    ),
  },
  {
    name: "countryAdmin.organizationUnit",
    label: (
      <FormattedMessage
        id="countryAdmin.organizationUnit"
        defaultMessage="Organization Unit"
      />
    ),
  },
  {
    name: "countryAdmin.roles",
    label: <FormattedMessage id="countryAdmin.roles" defaultMessage="Roles" />,
  },
  {
    name: "countryAdmin.customName",
    label: (
      <FormattedMessage
        id="countryAdmin.customName"
        defaultMessage=" Custom Name "
      />
    ),
  },
  {
    name: "countryAdmin.vendorType",
    label: (
      <FormattedMessage
        id="countryAdmin.vendorType"
        defaultMessage="Vendor type"
      />
    ),
  },
  {
    name: "countryAdmin.vendorName",
    label: (
      <FormattedMessage
        id="countryAdmin.vendorName"
        defaultMessage="Vendor name"
      />
    ),
  },
  {
    name: "countryAdmin.refNo",
    label: (
      <FormattedMessage
        id="countryAdmin.refNo"
        defaultMessage="Reference no."
      />
    ),
  },
  {
    name: "countryAdmin.vendorType",
    label: (
      <FormattedMessage
        id="countryAdmin.vendorType"
        defaultMessage="Vendor type"
      />
    ),
  },
];

export const UserRegistrationEndpoints = {
  SchoolStaff: "staff-registration",
  CountryAdmin: "country-admins",
  ReportApprover: "approvers",
  Viewer: "viewers",
  SchoolGroupAdmin: "school-group-admins",
};

export const toastFormattedMessages = [
  {
    name: "toast.infomationError",
    label: (
      <FormattedMessage
        id="toast.infomationError"
        defaultMessage="An error has occured. Please check your information"
      />
    ),
  },
  {
    name: "toast.approveSuccess",
    label: (
      <FormattedMessage
        id="toast.approveSuccess"
        defaultMessage="Successful Approval"
      />
    ),
  },
  {
    name: "toast.approveError",
    label: (
      <FormattedMessage
        id="toast.approveError"
        defaultMessage="An Error has occured during Approval"
      />
    ),
  },
  {
    name: "toast.rejectSuccess",
    label: (
      <FormattedMessage
        id="toast.rejectSuccess"
        defaultMessage="Successful Rejection"
      />
    ),
  },
  {
    name: "toast.rejectError",
    label: (
      <FormattedMessage
        id="toast.rejectError"
        defaultMessage="An Error has occured during Rejection"
      />
    ),
  },
  {
    name: "toast.activateError",
    label: (
      <FormattedMessage
        id="toast.activateError"
        defaultMessage="An Error has occured during Activation"
      />
    ),
  },
  {
    name: "toast.activateSuccess",
    label: (
      <FormattedMessage
        id="toast.activateSuccess"
        defaultMessage="Successful Activation"
      />
    ),
  },
  {
    name: "toast.promoteError",
    label: (
      <FormattedMessage
        id="toast.promoteError"
        defaultMessage="An Error has occured during Promotion"
      />
    ),
  },
  {
    name: "toast.promoteSuccess",
    label: (
      <FormattedMessage
        id="toast.promoteSuccess"
        defaultMessage="Successful Promotion"
      />
    ),
  },
  {
    name: "toast.adminDefineSuccess",
    label: (
      <FormattedMessage
        id="toast.promoteSuccess"
        defaultMessage="Successful Promotion"
      />
    ),
  },
  {
    name: "toast.adminDefineError",
    label: (
      <FormattedMessage
        id="toast.promoteError"
        defaultMessage="An Error has occured during Promotion"
      />
    ),
  },
  {
    name: "toast.deactivateSchoolDefinedError",
    label: (
      <FormattedMessage
        id="toast.deactivateError"
        defaultMessage="An Error has occured during Deactivation"
      />
    ),
  },
  {
    name: "toast.deactivateSchoolDefinedSuccess",
    label: (
      <FormattedMessage
        id="toast.deactivateSuccess"
        defaultMessage="Successful Deactivation"
      />
    ),
  },
  {
    name: "toast.deactivateAdminApprovedError",
    label: (
      <FormattedMessage
        id="toast.deactivateError"
        defaultMessage="An Error has occured during Deactivation"
      />
    ),
  },
  {
    name: "toast.deactivateAdminApprovedSuccess",
    label: (
      <FormattedMessage
        id="toast.deactivateSuccess"
        defaultMessage="Successful Deactivation"
      />
    ),
  },
  {
    name: "toast.deactivateError",
    label: (
      <FormattedMessage
        id="toast.deactivateError"
        defaultMessage="An Error has occured during Deactivation"
      />
    ),
  },
  {
    name: "toast.deactivateSuccess",
    label: (
      <FormattedMessage
        id="toast.deactivateSuccess"
        defaultMessage="Successful Deactivation"
      />
    ),
  },
  {
    name: "toast.activateRejectedError",
    label: (
      <FormattedMessage
        id="toast.activateError"
        defaultMessage="An Error has occured during Activation"
      />
    ),
  },
  {
    name: "toast.activateRejectedSuccess",
    label: (
      <FormattedMessage
        id="toast.activateSuccess"
        defaultMessage="Successful Activation"
      />
    ),
  },
  {
    name: "toast.openReportError",
    label: (
      <FormattedMessage
        id="toast.openReportError"
        defaultMessage="One (or more) of the schools selected for deactivation has an open report"
      />
    ),
  },
  {
    name: "toast.registrationSuccess",
    label: (
      <FormattedMessage
        id="toast.registrationSuccess"
        defaultMessage="Successful registration"
      />
    ),
  },
  {
    name: "toast.registrationError",
    label: (
      <FormattedMessage
        id="toast.registrationError"
        defaultMessage="There is an error with the registration. Please register again"
      />
    ),
  },
  {
    name: "toast.dataEnteredForPreviousMonths",
    label: (
      <FormattedMessage
        id="toast.dataEnteredForPreviousMonths"
        defaultMessage="You have entered data for a month that could cause some changes for the next months"
      />
    ),
  },
  {
    name: "toast.languageSelection",
    label: (
      <FormattedMessage
        id="toast.languageSelection"
        defaultMessage="You have successfully changed your language"
      />
    ),
  },
  {
    name: "toast.updateTemplateGenerationStarted",
    label: (
      <FormattedMessage
        id="toast.updateTemplateGenerationStarted"
        defaultMessage="Update template generation started"
      />
    ),
  },
  {
    name: "toast.updateTemplateGenerationFailed",
    label: (
      <FormattedMessage
        id="toast.updateTemplateGenerationFailed"
        defaultMessage="Update template generation did not start"
      />
    ),
  },
  {
    name: "toast.downloadFailed",
    label: (
      <FormattedMessage
        id="toast.downloadFailed"
        defaultMessage="Download failed"
      />
    ),
  },
  {
    name: "toast.downloadTimedOut",
    label: (
      <FormattedMessage
        id="toast.downloadTimedOut"
        defaultMessage="Download timed out"
      />
    ),
  },
];

export const selectContainerStyles = {
  valid: {
    control: (styles: any) => ({
      ...styles,
      border: `1px solid ${colors["field-border-01"].hex}`,
    }),
  },
  invalid: {
    control: (styles: any) => ({
      ...styles,
      borderColor: `${colors["support-01"].hex} !important`,
    }),
  },
};

export const requiredLabel = (
  <div className="wfp--form-required">
    <FormattedMessage id="Form.requiredStar" defaultMessage="* Required" />
  </div>
);

export const purchaseDetailCategory = "purchasedetail";
export const deliveryCategory = "delivery";
export const takeHomeRationCategory = "takehomeration";
export const studentAttendanceCategory = "student";
export const mixedCategory = "mixed";
export const nonWfpCategory = "non-wfp";

/*
 * This is from django base.py we want to support those languages.
 * LANGUAGES = (
 *    ("en", _("English")),
 *    ("fr", _("French")),
 *    ("pt", _("Portuguese")),
 *    ("es", _("Spanish")),
 *    ("ar", _("Arabic")),
 *    ("zh", _("Chinese")),
 *    ("ru", _("Russian")),
 *    ("uk", _("Ukranian")),
 *)
 */
export const languagesOptions = [
  {
    value: "en",
    label: "English",
    labelTranslated: "english",
  },
  {
    value: "fr",
    label: "French",
    labelTranslated: "français",
  },
  {
    value: "pt",
    label: "Portuguese",
    labelTranslated: "português",
  },
  {
    value: "es",
    label: "Spanish",
    labelTranslated: "español",
  },
  {
    value: "ar",
    label: "Arabic",
    labelTranslated: "عربي",
  },
  // We decided we are not ready to support those langues yet.
  // {
  //   value: "zh",
  //   label: "Chinese",
  //   labelTranslated: "中国人",
  // },
  // {
  //   value: "ru",
  //   label: "Russian",
  //   labelTranslated: "Русский",
  // },
  {
    value: "uk",
    label: "Ukranian",
    labelTranslated: "українська",
  },
  {
    value: "so",
    label: "Somali",
    labelTranslated: "Soomaali",
  },
];
