import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  NavLink,
} from "react-router-dom";
import { FormattedMessage } from "react-intl";

import {
  SecondaryNavigation,
  SecondaryNavigationTitle,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbHome,
  Tabs,
  Tab,
} from "@wfp/ui";

import AllDistributionReportProfiles from "./AllDistributionReportProfiles";

const CountryAdminDistributionReportTable = () => {
  const history = useHistory();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      label: (
        <FormattedMessage
          id="countryAdmin.allDistributionReportProfiles"
          defaultMessage="All Distribution Report Profiles"
        />
      ),
      href: `/countryAdmin/distributionReport/allDistributionReportProfiles`,
    },
  ];
  const setInitTabAndRedirect = () => {
    const index = tabs.findIndex((tab) => tab.href === location.pathname);
    if (index < 0) {
      setTabIndex(0);
      history.replace(tabs[0].href);
    } else {
      setTabIndex(index);
    }
  };
  useEffect(setInitTabAndRedirect, [tabs, location.pathname, history]);

  const loadTab = (index) => {
    const tab = tabs[index];
    history.push(tab ? tab.href : `countryAdmin/distributionReport/`);
    setTabIndex(tab ? index : 0);
  };

  const tabsKey = tabs.reduce((acc, cur) => acc + cur.label, "");

  const distributionReportLabel = (
    <FormattedMessage
      id="countryAdmin.distributionReportProfile"
      defaultMessage="Distribution Report Profile"
    />
  );
  return (
    <div className="country-admin-main-content">
      <SecondaryNavigation pageWidth="lg">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/countryAdmin/">
              <BreadcrumbHome />
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink to={location.pathname}>{distributionReportLabel}</NavLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <SecondaryNavigationTitle>
          {distributionReportLabel}
        </SecondaryNavigationTitle>
        <Tabs key={tabsKey} onSelectionChange={loadTab} selected={tabIndex}>
          {tabs.map((tab) => (
            <Tab key={tab.label.props.defaultMessage} label={tab.label} />
          ))}
        </Tabs>
      </SecondaryNavigation>
      <div className="users-table">
        <Switch>
          <Route
            path={`/countryAdmin/distributionReport/allDistributionReportProfiles`}
            component={AllDistributionReportProfiles}
          />
        </Switch>
      </div>
    </div>
  );
};

export default CountryAdminDistributionReportTable;
