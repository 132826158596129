import React, { useState, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";

import { ModuleBody } from "@wfp/ui";
import Select from "react-select";

import {
  listObjectsToListIds,
  listToObject,
} from "data-handler/ducks/paginationTools";
import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import { selectContainerStyles } from "SCConstants";

const LocationTable = ({
  schools,
  locations,
  methods,
  selectedlocationTypeOption,
  selectedLocation,
}) => {
  const intl = useIntl();
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [paginatedSchools, setPaginatedSchools] = useState([]);
  const { register, setValue, getValues, errors } = methods;

  useEffect(() => {
    schools.forEach((e) => {
      register(`school-location-${e.id}`, {
        required:
          selectedlocationTypeOption === "multipleLocations" ? true : false,
      });
      setValue(`school-location-${e.id}`, selectedLocation);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, schools]);

  const schoolsData = {
    count: schools?.length,
    ids: listObjectsToListIds(paginatedSchools, "id"),
    results: listToObject(paginatedSchools, "id"),
  };

  const { count = 0, ids = [] } = schoolsData;
  const data = ids.map((id) => {
    return schoolsData.results[id];
  });

  const columns = useMemo(() => {
    const allColumns = [
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.name",
          defaultMessage: "Name",
        }),
        accessor: "name",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.locationLabel",
          defaultMessage: "Location",
        }),
        accessor: "location",
        Cell: ({ cell: { row } }) => {
          const id = row.original.id;
          const key = `school-location-${id}`;
          return (
            <Select
              key={key}
              styles={
                errors?.hasOwnProperty(key)
                  ? selectContainerStyles.invalid
                  : selectContainerStyles.valid
              }
              className="react-select-container"
              classNamePrefix="react-select"
              options={locations}
              defaultValue={getValues(key)}
              onChange={(e) => {
                setValue(key, e, {
                  shouldDirty: true,
                });
              }}
            />
          );
        },
      },
    ];
    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schools, errors]);

  const loadPage = (size, idx) => {
    setPageSize(size);
    setPageNum(idx);
    // Paginate locally
    const schoolArray = [];

    for (let i = 0; i < pageSize; i++) {
      const index = idx * size - size + i;
      if (schools[index]) {
        schoolArray.push(schools[index]);
      }
    }
    setPaginatedSchools(schoolArray);
  };

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
    showPagination: count > 10 ? true : false,
  };

  return (
    <div className="country-admin-main-content schools-table">
      <ModuleBody>
        <Table {...tableProps} />
      </ModuleBody>
    </div>
  );
};

export default LocationTable;
