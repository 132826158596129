import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import { Modal } from "@wfp/ui";

import { countriesDuck } from "data-handler/ducks/countries";

import "./_content.scss";

const CountrySelectModal = ({
  countrySelectModalOpen,
  setCountrySelectModalOpen,
  country,
  submitSelectedCountry,
}) => {
  const countries = useSelector(countriesDuck.getList);
  const countriesIsFetching = useSelector(countriesDuck.isFetching());
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (countries.length === 0 && !countriesIsFetching) {
      dispatch(countriesDuck.fetchList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className="country-select-modal"
      open={countrySelectModalOpen}
      modalHeading={intl.formatMessage({
        id: "CountryAdmin.selectCountryToEdit",
        defaultMessage: "Select country to edit",
      })}
      primaryButtonText={intl.formatMessage({
        id: "Report.actionSubmit",
        defaultMessage: "Submit",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      passiveModal={true}
      onRequestClose={() => setCountrySelectModalOpen(false)}
      shouldSubmitOnEnter={true}
    >
      <Select
        id={"select-new-country"}
        className="react-select-container"
        classNamePrefix="react-select"
        options={countries?.map((country) => ({
          label: country.name,
          value: country.id,
        }))}
        onChange={(country) => {
          const selectedCountry = countries.find(
            (item) => item.id === country.value
          );
          submitSelectedCountry(selectedCountry);
          setCountrySelectModalOpen(false);
        }}
        defaultValue={
          country ? { value: country.id, label: country.name } : null
        }
      />
    </Modal>
  );
};

export default CountrySelectModal;
