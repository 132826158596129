import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { LOGOUT, getCurrentUser } from "data-handler/ducks/auth";
import { useSelector } from "react-redux";

import { clearLocalStorage } from "helpers/utils";

/**
 * Logs out, redirects to root, renders `null`
 */
export default () => {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const history = useHistory();
  if (currentUser?.is_admin) {
    clearLocalStorage();
  }
  dispatch({ type: LOGOUT });
  history.push("");
  return null;
};
