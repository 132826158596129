import React from "react";
import { useIntl } from "react-intl";

const FileUploader = (props) => {
  const intl = useIntl();
  const {
    disabled = false,
    id,
    accept,
    buttonKind = "secondary",
    filename = "",
  } = props;

  const handleChange = (e) => {
    const files = e.target.files;
    if (files !== null && files.length === 1) {
      props.onChange(files[0]);
    }
  };

  return (
    <div className="file-uploader">
      <label
        tabIndex={disabled ? -1 : 0}
        aria-disabled={disabled}
        className={`wfp--btn wfp--btn--${buttonKind}`}
        htmlFor={id}
      >
        <span role="button">
          {filename ||
            intl.formatMessage({
              id: "modal.addFile",
              defaultMessage: "Upload CSV file",
            })}
        </span>
      </label>

      <input
        id={id}
        disabled={disabled}
        className="wfp--visually-hidden"
        type="file"
        tabIndex={-1}
        multiple={false}
        accept={accept}
        onChange={handleChange}
      />
    </div>
  );
};

export default FileUploader;
