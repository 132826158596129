import React from "react";
import { FormattedMessage } from "react-intl";

import { Module, ModuleBody } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling } from "@fortawesome/pro-duotone-svg-icons";

import TableExtended from "components/TableExtended";
import Empty from "components/Empty";
import SubTitle from "components/SubTitle";
import { useSelector } from "react-redux";
import { getPurchaseOtherCostsList } from "data-handler/ducks/purchaseOtherCosts";
import { getCurrencyForCurrentSchool } from "data-handler/ducks/currency";

export default function OtherCostShow({
  other_costs,
  emptyTitle,
  title,
  model,
}) {
  const purchaseOtherCosts = useSelector(getPurchaseOtherCostsList);
  const defaultCurrencyName = useSelector(getCurrencyForCurrentSchool).name;

  const purchaseOtherCostsOptions = purchaseOtherCosts
    ? Object.values(purchaseOtherCosts?.results)
    : undefined;

  const otherCostsTotalPaid = other_costs?.map((i) => i.total_paid);
  const commoditiesTotalPaidSum = otherCostsTotalPaid?.reduce(
    (a, b) => parseInt(a) + parseInt(b),
    0
  );

  const rows = other_costs
    ? other_costs.map((row, i) => {
        const selected = purchaseOtherCostsOptions.find(
          (i) => i.id === row?.other_cost
        );
        return (
          <tr key={i}>
            <td className="other_costs-show__commodity">
              <span className="mobile-title">
                <FormattedMessage
                  id="OtherCostShow.commodityTitle"
                  defaultMessage="Other cost"
                />
              </span>
              {selected?.name}
            </td>
            <td>
              <span className="mobile-title">
                <FormattedMessage
                  id="PriceShow.price"
                  defaultMessage="Amount paid"
                />{" "}
                ({defaultCurrencyName})
              </span>
              {Number(row.total_paid)}
            </td>
          </tr>
        );
      })
    : null;

  const otherCostExists = other_costs?.find((i) => i.other_cost);

  if (!other_costs || otherCostExists === undefined) {
    return (
      <>
        <SubTitle small> </SubTitle>
        <Module noMargin>
          <Empty
            title={emptyTitle}
            icon={<FontAwesomeIcon icon={faSeedling} size="1x" />}
          >
            <FormattedMessage
              id="OtherCostShow.noCommoditiesAddedYet"
              defaultMessage="No other costs added yet"
            />
          </Empty>
        </Module>
      </>
    );
  }
  return (
    <div className="other_costs-show">
      {title && <SubTitle small>{title}</SubTitle>}
      <Module noMargin>
        <ModuleBody noPadding>
          <TableExtended noBorder>
            {/* TODO: get headers from parent, don't assume `rows` shape */}
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="OtherCostShow.commodityTitle"
                    defaultMessage="Other cost"
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="PriceShow.price"
                    defaultMessage="Amount paid"
                  />{" "}
                  ({defaultCurrencyName})
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>{" "}
            {model === "purchasedetail" && (
              <thead>
                <th>
                  <FormattedMessage
                    id="TotalPriceShow.totalPrice"
                    defaultMessage="Total amount paid"
                  />{" "}
                  ({defaultCurrencyName})
                </th>
                <td> {commoditiesTotalPaidSum}</td>
              </thead>
            )}
          </TableExtended>
        </ModuleBody>
      </Module>
    </div>
  );
}
