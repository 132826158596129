import { errorToast, successToast } from "data-handler/ducks/toast";
import { put, takeLatest } from "redux-saga/effects";
import { toastFormattedMessages } from "SCConstants";

export const SELECT = "schoolconnect/language/SELECT";

const initialState = null;

export default function language(state = initialState, action) {
  switch (action.type) {
    case SELECT:
      return action.data;
    default:
      return state;
  }
}

export const getLanguage = (state) => state.language;

export const changeLanguage = (data, showToast = false) => ({
  type: SELECT,
  data,
  showToast,
});

export function* changeLanguageWatcher(payload) {
  yield takeLatest(SELECT, changeLanguageWorker);
}

function* changeLanguageWorker(payload) {
  try {
    const { showToast } = payload;
    if (showToast) {
      yield put(
        successToast(
          toastFormattedMessages.find(
            (e) => e.name === "toast.languageSelection"
          ).label
        )
      );
    }
  } catch (error) {
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}
