import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { FormProvider, useForm } from "react-hook-form";

import ModalExtended from "components/ModalExtended";
import ErrorList from "components/ErrorList";
import {
  getCurrentSchoolYear,
  getPreviousSchoolYears,
} from "data-handler/ducks/stores";
import { Blockquote } from "@wfp/ui";
import useStore from "helpers/useStore";
import VendorEdit from "components/CashToSchool/vendorEdit";
import "./_cash-to-school.scss";
import PurchaseInformation from "components/CashToSchool/purchaseInfo";
import CommoditiesEdit from "components/CashToSchool/commoditiesEdit";
import { isEmpty } from "lodash";
import {
  getVendorCategories,
  getAllApprovedVendors,
} from "data-handler/ducks/vendors";
import { getPurchaseOtherCostsList } from "data-handler/ducks/purchaseOtherCosts";
import { purchaseDetailCategory } from "SCConstants";

import {
  ERROR_MESSAGES_INCIDENTS,
  isReportApprovedOrSubmitted,
  isReportClosedOrRejected,
  schoolYearDateValidation,
} from "components/IncidentEdit";
import { RootState } from "data-handler/rootReducer";

type Vendor = {
  id: number;
  name: string;
};
const storeToFormValues = (
  store: any,
  vendorCategories: any,
  vendorList: Vendor[],
  otherCost: any,
  purchased_at: any
) => {
  const values = JSON.parse(JSON.stringify(store)); // deep copy
  // Repeat purchases set purchased_at to true
  // If a repeat purchase use current date and time, if not, use the inputted data
  const date = purchased_at
    ? moment().format("YYYY-MM-DD")
    : moment(values.purchased_at).format("YYYY-MM-DD");

  values.date = date;

  // Vendor id gets sent to the be - we need the fe to store the label to display it when editing
  const vendorCategoryId = values.vendor_category;
  const vendorCategory = vendorCategories[vendorCategoryId];
  values.vendor_category = {
    label: vendorCategory?.name,
    value: vendorCategoryId,
  };

  var vendor_created = values.is_vendor_created;
  var purchase_info =
    (values.vendor === "" ||
      values.vendor === null ||
      values.vendor === undefined) &&
    (values.vendor_name === "" ||
      values.vendor_name === undefined ||
      values.vendor_name === null)
      ? false
      : true;
  vendor_created === true && purchase_info === true
    ? (vendor_created = "create")
    : vendor_created === false && purchase_info === true
    ? (vendor_created = "existing")
    : (vendor_created = undefined);
  values.is_vendor_created = vendor_created;
  values.purchase_info = purchase_info;

  const vendorId = values?.vendor;
  const vendor = vendorList
    .filter((vendor) => vendorId === vendor.id)
    .find((vendor) => vendor.name);

  if (values.is_vendor_created === "existing") {
    values.vendor = {
      label: vendor?.name,
      value: vendorId,
    };
    values.receipt_no = "";
    values.vendor_name = "";
  }
  if (values.is_vendor_created === "create") {
    values.vendor_name = vendor?.name || values.vendor_name;
    values.vendor = "";
  }

  if (!values.other_costs || values.other_costs?.length === 0) {
    values.other_costs = [{}];
  } else {
    // Other costs stores the id we need the label for the be
    values.other_costs.forEach((other: any) => {
      const otherCostId = other.other_cost;
      const otherCostValue = otherCost[otherCostId];
      other.other_cost = {
        label: otherCostValue?.name,
        value: otherCostId,
      };
    });
  }
  return values;
};

const formValuesToStore = (values: any) => {
  // Merge `date` and `time` fields into `purchased_at` datetime
  values.purchased_at = moment(
    values.date + " " + moment().format("HH:mm:ss"),
    "YYYY-MM-DD HH:mm:ss"
  ).toISOString();
  const is_vendor_created =
    values.purchase_info && values.is_vendor_created === "create";
  const is_vendor_existing =
    values.purchase_info && values.is_vendor_created === "existing";
  var vendor_created;
  if (is_vendor_created) {
    vendor_created = true;
  } else if (is_vendor_existing) {
    vendor_created = false;
  } else {
    vendor_created = undefined;
  }

  values.is_vendor_created = vendor_created;

  const vendorCategoryId = values.vendor_category;
  values.vendor_category = vendorCategoryId.hasOwnProperty("value")
    ? vendorCategoryId.value
    : vendorCategoryId;
  if (values.vendor && !values.is_vendor_created && values.purchase_info) {
    const vendorId = values?.vendor;
    values.vendor = vendorId?.hasOwnProperty("value")
      ? vendorId.value
      : vendorId;
    values.ref_number = "";
  } else if (
    values.vendor &&
    values.is_vendor_created &&
    values.purchase_info
  ) {
    values.vendor = "";
  }
  if (values.commodities) {
    values.commodities.forEach((commodity: any) => {
      commodity.category = purchaseDetailCategory;
      commodity.measure_unit = commodity.measure_unit.id;
    });
  }
  if (values.other_costs) {
    values.other_costs.forEach((other: any) => {
      if (other.other_cost !== undefined) {
        const otherCostId = other.other_cost;
        other.other_cost = otherCostId.hasOwnProperty("value")
          ? otherCostId?.value
          : otherCostId;
      }
    });
  }
  return values;
};

const ERROR_MESSAGES = {
  missingCommodity: (
    <FormattedMessage
      id="PurchaseEdit.missingCommodity"
      defaultMessage="Select a commodity"
    />
  ),
  missingQuantity: (
    <FormattedMessage
      id="PurchaseEdit.missingQuantity"
      defaultMessage="Enter a quantity"
    />
  ),
  missingPrice: (
    <FormattedMessage
      id="PurchaseEdit.missingPrice"
      defaultMessage="Enter the amount paid"
    />
  ),
  missingDate: (
    <FormattedMessage
      id="PurchaseEdit.missingDate"
      defaultMessage="Enter date of purchase"
    />
  ),
  missingTime: (
    <FormattedMessage
      id="PurchaseEdit.missingTime"
      defaultMessage="Enter time of purchase"
    />
  ),
  missingVendorType: (
    <FormattedMessage
      id="PurchaseEdit.missingType"
      defaultMessage="Select vendor type"
    />
  ),
  missingVendorName: (
    <FormattedMessage
      id="PurchaseEdit.missingName"
      defaultMessage="Vendor name is missing"
    />
  ),
  selectCreationOrExisting: (
    <FormattedMessage
      id="PurchaseEdit.vendorCreation"
      defaultMessage="Select if you want to create or use existing vendor"
    />
  ),
  missingGrandTotal: (
    <FormattedMessage
      id="PurchaseEdit.missingGrandTotal"
      defaultMessage="Enter grand total"
    />
  ),
};

const validationResolver = (
  values: any,
  { state, currentYearStore, previousSchoolYears }: any
) => {
  const errors: Record<string, { message: JSX.Element }> = {};
  const isSelectedDateInSchoolYearValid = schoolYearDateValidation(
    values.date,
    currentYearStore,
    previousSchoolYears
  );
  if (!isSelectedDateInSchoolYearValid && values.date) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["invalidDate"],
    };
  }
  if (isReportClosedOrRejected(state, values.date) && values.date) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["reportClosedOrRejected"],
    };
  }
  if (isReportApprovedOrSubmitted(state, values.date) && values.date) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["reportApprovedOrSubmitted"],
    };
  }
  if (!values.vendor_category) {
    errors[`vendor_category`] = {
      message: ERROR_MESSAGES["missingVendorType"],
    };
  }

  if (!values.date) {
    errors[`date`] = {
      message: ERROR_MESSAGES["missingDate"],
    };
  }
  if (values.commodities) {
    values.commodities.forEach((commodity: any, i: number) => {
      if (!commodity.commodity && commodity.commodity === undefined) {
        errors[`commodities[${i}].commodity`] = {
          message: ERROR_MESSAGES["missingCommodity"],
        };
      }
      // `quantity` is required on each commodity row
      if (!commodity.quantity) {
        errors[`commodities[${i}].quantity`] = {
          message: ERROR_MESSAGES["missingQuantity"],
        };
      } // `price` is required on each commodity row
      if (!commodity.total_paid) {
        errors[`commodities[${i}].total_paid`] = {
          message: ERROR_MESSAGES["missingPrice"],
        };
      }
    });
  }
  if (!values.total_paid) {
    errors[`total_paid`] = {
      message: ERROR_MESSAGES["missingGrandTotal"],
    };
  }
  if (values.purchase_info) {
    if (
      (values.vendor === "" || !values.vendor) &&
      (!values.is_vendor_created || values.is_vendor_created === "existing")
    ) {
      errors[`vendor`] = {
        message: ERROR_MESSAGES["missingVendorName"],
      };
    }
    if (
      (values.vendor_name === "" || !values.vendor_name) &&
      values.is_vendor_created === "create"
    ) {
      errors[`vendor_name`] = {
        message: ERROR_MESSAGES["missingVendorName"],
      };
    }
    if (values.is_vendor_created === "") {
      errors[`is_vendor_created`] = {
        message: ERROR_MESSAGES["selectCreationOrExisting"],
      };
    }
  }

  return {
    values: !isEmpty(errors) ? {} : values,
    errors,
  };
};

const CashToSchool = ({ purchaseCurrentStoreData, purchased_at }: any) => {
  const {
    updateStore,
    closeModal,
    submitBtnRef,
    submitViaBtnRefClick,
  } = useStore();
  const intl = useIntl();
  const state = useSelector((state) => state) as RootState;
  const vendorCategories = useSelector(getVendorCategories);
  const vendorList = useSelector(getAllApprovedVendors);
  const otherCost = useSelector(getPurchaseOtherCostsList);
  const currentYearStore = useSelector((state: RootState) =>
    getCurrentSchoolYear(state)
  );
  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  const defaultValues = purchaseCurrentStoreData
    ? storeToFormValues(
        purchaseCurrentStoreData,
        vendorCategories.results,
        vendorList.results,
        otherCost.results,
        purchased_at
      )
    : { commodities: [{}], other_costs: [{}] };

  const methods = useForm({
    defaultValues,
    resolver: validationResolver,
    context: {
      state,
      purchaseCurrentStoreData,
      defaultValues,
      currentYearStore,
      previousSchoolYears,
    },
  });
  const {
    control,
    handleSubmit,
    errors,
    watch,
    setValue,
    getValues,
    register,
  } = methods;
  const selectedDate = watch("date");
  const isSelectedDateInSchoolYearValid = schoolYearDateValidation(
    selectedDate,
    currentYearStore!,
    previousSchoolYears
  );
  const isErrorListEmpty = isEmpty(errors);
  const onSubmit = (values: any) => {
    updateStore({
      id: !purchased_at
        ? (purchaseCurrentStoreData && purchaseCurrentStoreData.client_id) ||
          undefined
        : undefined,
      values: formValuesToStore(values),
      model: "purchasedetail",
      type:
        purchaseCurrentStoreData && purchaseCurrentStoreData.client_id
          ? "update"
          : "create",
      category: purchaseDetailCategory,
    });
  };
  return (
    <ModalExtended
      open
      onRequestSubmit={submitViaBtnRefClick}
      onRequestClose={() => closeModal()}
      modalLabel={intl.formatMessage({
        id: "PurchaseEdit.purchase",
        defaultMessage: "School purchase",
      })}
      modalHeading={intl.formatMessage({
        id: "PurchaseEdit.purchaseDetails",
        defaultMessage: "Purchase details",
      })}
      wide
      primaryButtonText={intl.formatMessage({
        id: "PurchaseEdit.savePurchase",
        defaultMessage: "Save purchase",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "Form.cancel",
        defaultMessage: "Cancel",
      })}
      selectorPrimaryFocus={false}
      className="cash-to-school"
    >
      <FormProvider {...methods}>
        {!isSelectedDateInSchoolYearValid && isErrorListEmpty && (
          <Blockquote kind="warning" className="date-not-valid">
            {ERROR_MESSAGES_INCIDENTS.invalidDate}
          </Blockquote>
        )}
        {isReportClosedOrRejected(state, selectedDate) && isErrorListEmpty && (
          <Blockquote kind="warning" className="date-not-valid">
            {ERROR_MESSAGES_INCIDENTS.reportClosedOrRejected}
          </Blockquote>
        )}
        {isReportApprovedOrSubmitted(state, selectedDate) && isErrorListEmpty && (
          <Blockquote kind="warning" className="date-not-valid">
            {ERROR_MESSAGES_INCIDENTS.reportApprovedOrSubmitted}
          </Blockquote>
        )}
        <ErrorList errors={errors} />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="wfp--form wfp--form-long"
        >
          <VendorEdit
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            register={register}
            purchaseCurrentStoreData={purchaseCurrentStoreData}
          />

          <PurchaseInformation
            errors={errors}
            currentYearStore={currentYearStore}
            control={control}
            register={register}
          />

          <CommoditiesEdit
            watch={watch}
            errors={errors}
            control={control}
            setValue={setValue}
            getValues={getValues}
            register={register}
          />

          <button className="hidden-btn" ref={submitBtnRef as any} />
        </form>
      </FormProvider>
    </ModalExtended>
  );
};

export default CashToSchool;
