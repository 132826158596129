import { getSelectedVendorName } from "components/CashToSchoolShow";
import moment from "moment";
import {
  getAllStores,
  getSchoolYearByDate,
  getPreviousSchoolYears,
  Store,
  DeliveryCommodity,
  PurchaseCommodity,
  Delivery,
  Incident,
} from "data-handler/ducks/stores";

import { RootState } from "data-handler/rootReducer";
import { store } from "data-handler/store";
import {
  deliveryCategory,
  mixedCategory,
  nonWfpCategory,
  takeHomeRationCategory,
} from "SCConstants";

function sortByDateAndModel(a: Store, b: Store): number {
  // Function used to sort stores by date and model
  const dateA =
    (a as any).occurred_on ||
    (a as any).occurred_at ||
    (a as any).purchased_at ||
    (a as any).delivered_at;

  const dateB =
    (b as any).occurred_on ||
    (b as any).occurred_at ||
    (b as any).purchased_at ||
    (b as any).delivered_at;

  const modelA = a.model;
  const modelB = b.model;

  if (moment(dateA).isAfter(dateB, "days")) return 1;
  if (moment(dateA).isBefore(dateB, "days")) return -1;
  // if the date is the same, prioritize delivery or purchasedetail models
  if (
    ["delivery", "purchasedetail"].includes(modelA) &&
    !["delivery", "purchasedetail"].includes(modelB)
  )
    return -1;
  return 0;
}

const calculateStock = (
  filteredStores: Store[],
  accumulator: number,
  commodityId: number,
  category: string
) => {
  let deliveryCommodityIDsUsed: string[] = [];

  const consumptionCommodityIdKey = [
    deliveryCategory,
    takeHomeRationCategory,
  ].includes(category)
    ? `delivery_commodities`
    : `purchase_detail_commodities`;

  const incidentCommodityIdKey = [
    deliveryCategory,
    takeHomeRationCategory,
  ].includes(category)
    ? `delivery_commodity`
    : `purchase_detail_commodity`;

  filteredStores.forEach((store) => {
    const storeCommodities: DeliveryCommodity[] =
      (store as any).commodities ||
      ((store as any).consumption && (store as any).consumption.commodities);
    storeCommodities.forEach((commodity) => {
      if (
        commodity.commodity === commodityId &&
        commodity.category === category
      ) {
        const quantity = parseFloat(commodity.quantity);
        if (store.model === "attendance") {
          const matchingDeliveryCommodityId =
            Object.keys(commodity).includes(consumptionCommodityIdKey) &&
            Object.keys(
              (commodity as any)[consumptionCommodityIdKey]
            ).some((key) => deliveryCommodityIDsUsed.includes(key));
          // If the consumption is synced, only count it if it has related delivery
          // commodity ids that are included in the used delivery commodity ids
          if (store.sync && matchingDeliveryCommodityId) {
            // Get the consumed quantity from the related delivery commodity ids
            const consumedQuantity = Object.entries(
              (commodity as any)[consumptionCommodityIdKey]
            )
              .filter(([key]) => deliveryCommodityIDsUsed.includes(key))
              .reduce((partialSum, a: any) => partialSum + a[1], 0);
            accumulator = parseFloat(
              (accumulator - consumedQuantity).toFixed(3)
            );
          } else if (!store.sync) {
            accumulator = parseFloat((accumulator - quantity).toFixed(3));
          }
        } else if (store.model === "incident") {
          // Only count incidents that are related to the used delivery commodity ids
          // Or the ones that are marked as initial stock (because they don't
          // have a delivery commodity assigned)
          const matchingDeliveryCommodityId =
            Object.keys(commodity).includes(incidentCommodityIdKey) &&
            deliveryCommodityIDsUsed.includes(
              (commodity as any)[incidentCommodityIdKey].toString()
            );
          if (
            (store.is_initial_stock_incident === true &&
              !matchingDeliveryCommodityId) ||
            matchingDeliveryCommodityId
          ) {
            accumulator = parseFloat((accumulator - quantity).toFixed(3));
          }
        } else if (
          (store.model === "delivery" || store.model === "purchasedetail") &&
          store.type !== "delete"
        ) {
          if (
            commodity.id &&
            !deliveryCommodityIDsUsed.includes(commodity.id.toString())
          ) {
            deliveryCommodityIDsUsed.push(commodity.id.toString());
          }

          accumulator = parseFloat((accumulator + quantity).toFixed(3));
        }
      }
    });
  });
  return accumulator >= 0 ? accumulator : 0;
};

export const stockValueByDateByCommodityByCategory = (
  stores: Store[],
  date: moment.MomentInput,
  commodityId: number,
  category: string,
  forceUntilGivenDate = false
) => {
  const state = store.getState();
  const schoolYear = getSchoolYearByDate(date)(state);
  const previousSchoolYears = getPreviousSchoolYears(state);

  // This is the start date of the current school year (if it exists) or of the latest school year
  // It is used (when the category === delivery) to only count stores that happened on or after the date
  let lastSchoolYearStartDate = schoolYear?.starts_on;
  if (!schoolYear && previousSchoolYears?.length > 0) {
    lastSchoolYearStartDate = previousSchoolYears[0].starts_on;
  }

  let accumulator = 0;
  // filter to stores which deal with commodities, particularly with commodityId

  const storesBeforeDate = stores
    ?.filter((store) => {
      if (store.type === "delete") return false;
      const commodities =
        (store as any).commodities ||
        ((store as any).consumption && (store as any).consumption.commodities);

      return (
        commodities &&
        commodities.find(
          (commodity: DeliveryCommodity) =>
            commodity.commodity === commodityId &&
            commodity.category === category &&
            commodity.category !== nonWfpCategory
        ) &&
        (store.model === "delivery" ||
        store.model === "purchasedetail" ||
        forceUntilGivenDate
          ? moment(
              (store as any).delivered_at ||
                (store as any).purchased_at ||
                (store as any).occurred_on ||
                (store as any).occurred_at
            ).isBetween(lastSchoolYearStartDate, date, "days", "[]")
          : moment(
              (store as any).occurred_on || (store as any).occurred_at
            ).isBetween(lastSchoolYearStartDate, date, "days", "[]"))
      );
    })
    .sort(sortByDateAndModel);

  accumulator = storesBeforeDate
    ? calculateStock(storesBeforeDate, accumulator, commodityId, category)
    : 0;

  return accumulator;
};

const getFilteredDeliveriesOrPuchasesWithCommodities = (
  stores: Store[],
  commodityId: number,
  category: string,
  startDate: moment.MomentInput,
  endDate: moment.MomentInput
) => {
  return stores.filter(
    (store) =>
      (store.model === "delivery" || store.model === "purchasedetail") &&
      store.type !== "delete" &&
      moment(
        (store as any).delivered_at || (store as any).purchased_at
      ).isBetween(startDate, endDate, "days", "[]") &&
      store.commodities &&
      (store.commodities as (DeliveryCommodity | PurchaseCommodity)[]).find(
        (commodity: DeliveryCommodity | PurchaseCommodity) => {
          return (
            commodity.commodity === commodityId &&
            commodity.category === category
          );
        }
      )
  );
};

const sortDeliveryOrPurchaseStores = (stores: Store[], order = "ascending") => {
  return stores.sort((a, b) => {
    const tempStoreA = (a as any)?.delivered_at || (a as any)?.purchased_at;
    const tempStoreB = (b as any)?.delivered_at || (a as any)?.purchased_at;
    return order === "descending"
      ? moment(tempStoreA).isAfter(tempStoreB)
        ? -1
        : 1
      : moment(tempStoreA).isAfter(tempStoreB)
      ? 1
      : -1;
  });
};

const getFilteredStoresWithConsumptionAndIncidents = (
  deliveryCommodityId: string,
  category: string,
  startDate: moment.MomentInput,
  endDate: moment.MomentInput,
  stores: Store[]
) => {
  const commodityIdKey =
    category === deliveryCategory
      ? "delivery_commodity"
      : "purchase_detail_commodity";
  return stores
    .filter((store) => {
      const commodities =
        (store as any).commodities ||
        ((store as any).consumption &&
          (store as any).consumption.commodities) ||
        [];

      return (
        store.type !== "delete" &&
        moment(
          (store as any).occurred_on || (store as any).occurred_at
        ).isBetween(startDate, endDate, "days", "[]") &&
        commodities &&
        commodities.find((commodity: DeliveryCommodity | PurchaseCommodity) => {
          if (store.model === "attendance") {
            return true;
          } else if (
            store.model === "incident" &&
            deliveryCommodityId !== undefined
          ) {
            return (
              (commodity as any)[commodityIdKey] === deliveryCommodityId &&
              commodity.category === category
            );
          } else {
            return false;
          }
        })
      );
    })
    .sort(sortByDateAndModel);
};

const getQuantityUsed = (
  stores: Store[],
  deliveryCommodityId: number,
  commodityId: number,
  category: string,
  allowConsumptionsNotMatched = false
) => {
  let quantityUsed = 0;

  // Stores should only contain incidents and consumptions at this point
  stores.forEach((store) => {
    const commodities: (DeliveryCommodity | PurchaseCommodity)[] =
      (store as any).commodities ||
      ((store as any).consumption && (store as any).consumption.commodities);

    const commodityIdKeyEnding = store.model === "attendance" ? "ies" : "y";

    const commodityIdKey =
      category === deliveryCategory
        ? `delivery_commodit${commodityIdKeyEnding}`
        : `purchase_detail_commodit${commodityIdKeyEnding}`;

    commodities
      // We only care about the quantity used for the given commodity
      .filter(
        (item) => item.commodity === commodityId && item.category === category
      )
      .forEach((item) => {
        if (store.model === "attendance") {
          // Consumption case
          if (allowConsumptionsNotMatched) {
            quantityUsed = parseFloat(
              (quantityUsed + parseFloat(item.quantity)).toFixed(10)
            );
          } else if (
            !allowConsumptionsNotMatched &&
            Object.keys(item).includes(commodityIdKey) &&
            Object.keys((item as any)[commodityIdKey]).includes(
              deliveryCommodityId?.toString()
            )
          ) {
            quantityUsed = parseFloat(
              (
                quantityUsed +
                parseFloat(
                  (item as any)[commodityIdKey][deliveryCommodityId.toString()]
                )
              ).toFixed(10)
            );
          }
        } else if (
          store.model === "incident" &&
          Object.keys(item).includes(commodityIdKey) &&
          (item as any)[commodityIdKey] === deliveryCommodityId
        ) {
          // Incident case
          quantityUsed = parseFloat(
            (quantityUsed + parseFloat(item.quantity)).toFixed(10)
          );
        }
      });
  });
  return quantityUsed;
};

/**
 * Returns the Deliveries (List) in Stock for the given commodityId
 */
export const deliveriesInStockByCommodityByCategory = (
  stores: Store[],
  commodityId: number,
  category: string,
  deliveryCommodityId: string | number | undefined = undefined,
  startDate: moment.MomentInput = moment().format("YYYY-MM-DD"),
  endDate: moment.MomentInput = moment().format("YYYY-MM-DD")
) => {
  const state = store.getState();
  const schoolYear = getSchoolYearByDate(startDate)(state);

  const deliveries: Store[] = [];
  // filter to stores which deal with commodities, particularly with commodityId
  const filteredDeliveries = getFilteredDeliveriesOrPuchasesWithCommodities(
    stores,
    commodityId,
    category,
    schoolYear?.starts_on,
    endDate
  );

  // Get the deliveries, sorted
  const sortedArray = sortDeliveryOrPurchaseStores(filteredDeliveries);

  // Only keep the deliveries that have stock for the given commodityId
  sortedArray
    .filter((item) =>
      moment(
        (item as any).delivered_at || (item as any).purchased_at
      ).isSameOrBefore(startDate, "days")
    )
    .forEach((store, index) => {
      ((store as any).commodities as (DeliveryCommodity | PurchaseCommodity)[])
        .filter(
          (item) => item.commodity === commodityId && item.category === category
        )
        .forEach(({ quantity, id, batch_no, commodity }) => {
          const consumptionsAndIncidentStores = getFilteredStoresWithConsumptionAndIncidents(
            id,
            category,
            store.model === "delivery"
              ? store.delivered_at
              : (store as any).purchased_at,
            endDate,
            stores.filter(
              (item) => item.model === "attendance" || item.model === "incident"
            )
          );

          const consumptionsUnsynced =
            (index >= 1 || (index === 0 && sortedArray.length > 1)) &&
            consumptionsAndIncidentStores.find(
              (item) => item.model === "attendance" && item.sync === false
            )
              ? true
              : false;

          let quantityUsed = getQuantityUsed(
            consumptionsAndIncidentStores,
            id as any,
            commodityId,
            category,
            index === 0 && sortedArray.length === 1
          );

          // Return deliveries with quantity available 0 if the delivery commodity id
          // matches the delivery commodity id param
          if (
            (deliveryCommodityId &&
              (deliveryCommodityId as unknown) === id &&
              parseFloat(quantity) >= parseFloat(quantityUsed as any)) ||
            ((!deliveryCommodityId ||
              (deliveryCommodityId &&
                deliveryCommodityId !== (id as unknown))) &&
              parseFloat(quantity) > parseFloat(quantityUsed as any))
          ) {
            deliveries.push({
              waybill_no: (store as any).waybill_no,
              vendor_category: (store as any).vendor_category,
              delivered_at: (store as any).delivered_at,
              purchased_at: (store as any).purchased_at,
              sync: (store as any).sync,
              objectCommodityId: id,
              commodityId: commodity,
              consumptionsUnsynced,
              quantityAvailable: (quantity as any) - quantityUsed,
              batch_no: batch_no,
            } as any);
          }
        });
    });
  return sortDeliveryOrPurchaseStores(deliveries, "descending");
};

/**
 * Takes in a ID from a commodity delivery, returns the delivery
 */
export const getDeliveryByCommodityAndDeliveryID = (
  stores: Store[],
  commodityDeliveryID: string
) => {
  return stores
    .filter(
      (store) =>
        store.model === "delivery" &&
        store.commodities &&
        store.type !== "delete"
    )
    .find((store) => {
      return (store as any).commodities.find(
        (e: PurchaseCommodity | DeliveryCommodity) =>
          e.id === commodityDeliveryID
      );
    });
};
/**
 * Takes in a ID from a commodity delivery, returns the delivery
 */
export const getPurchaseByCommodityPurchaseID = (
  stores: Store[],
  commodityPurchaseID: string
) => {
  return stores
    .filter(
      (store) =>
        store.model === "purchasedetail" &&
        store.commodities &&
        store.type !== "delete"
    )
    .find((store) => {
      return ((store as any).commodities as (
        | PurchaseCommodity
        | DeliveryCommodity
      )[]).find((e) => e.id === commodityPurchaseID);
    });
};

/**
 * Takes in a ID from a commodity delivery, returns the waybill of the delivery
 */
export const getWaybillByCommodityAndDeliveryID = (
  stores: Store[],
  commodityDeliveryID: string
) => {
  return (getDeliveryByCommodityAndDeliveryID(
    stores,
    commodityDeliveryID
  ) as any)?.waybill_no;
};
/**
 * Takes in a ID from a commodity purchase and a list of vendorCategories
 * and returns the name of the corresponding purchase
 */
export const getPurchaseNameByCommodityPurchaseID = (
  stores: Store[],
  commodityPurchaseID: string,
  vendorCategories: string[]
) => {
  const purchase = getPurchaseByCommodityPurchaseID(
    stores,
    commodityPurchaseID
  );
  const vendorCategoryName = getSelectedVendorName(
    (purchase as any)?.vendor_category,
    vendorCategories
  );

  return `${vendorCategoryName}: ${moment(
    (purchase as any)?.purchased_at
  ).format("dddd, DD.MM.YYYY")}`;
};

/**
 * Returns Boolean True if delivery is not deletable
 */
export const isDeliveryNotDeletable = (delivery: Delivery) => {
  if (!delivery) {
    return false;
  }

  const date = delivery.delivered_at;
  const state = store.getState();
  const allStores = getAllStores(state);
  let quantities: {
    [key: string]: { availableQuantity: number; initialQuantity: number };
  } = {};

  // Loop through all the delivery's commodities and update the "availableQuantity"
  // and "initialQuantity" inside the quantities object for each commodity
  delivery.commodities.forEach((commodityObject) => {
    const commodityId = commodityObject.commodity;

    if (!Object.keys(quantities).includes(commodityId.toString())) {
      const deliveriesInStockPerCommodity = deliveriesInStockByCommodityByCategory(
        allStores,
        commodityId,
        deliveryCategory,
        undefined,
        date
      ).filter((item) =>
        moment((item as any).delivered_at).isSame(date, "days")
      );

      const sum = deliveriesInStockPerCommodity.reduce((accumulator, item) => {
        return accumulator + parseFloat((item as any).quantityAvailable);
      }, 0);

      quantities[commodityId] = {
        availableQuantity: parseFloat(sum as any),
        initialQuantity: parseFloat(commodityObject.quantity),
      };
    } else {
      // The available quantity is calculated once for all possible commodities
      // with commodityId, wheareas the initialQuantity can only be calculated
      // for one commodity at a time, therefore we need to do this
      quantities[commodityId]["initialQuantity"] += parseFloat(
        commodityObject.quantity
      );
    }
  });

  // If the quantities object has a value where availableQuantity is
  // smaller than initialQuantity, return delivery not deletable (true)
  return Object.values(quantities).find(
    (item) => item.availableQuantity < item.initialQuantity
  )
    ? true
    : false;
};

export const getCommoditiesInStoreByCategory = (
  stores: Store[],
  commoditiesIndex: { [x: string]: any },
  isWfp: boolean,
  category: string,
  date: moment.MomentInput
) => {
  let startDate: moment.MomentInput | undefined = undefined;
  let endDate: moment.MomentInput | undefined = undefined;
  if (date) {
    const state = store.getState();
    const schoolYear = getSchoolYearByDate(date)(state);
    startDate = schoolYear?.starts_on;
    endDate = date;
  }
  const filteredStores = stores.filter(
    (store) =>
      (store.category === category || store.category === mixedCategory) &&
      (store as any).commodities &&
      (startDate && endDate
        ? moment(
            (store as any).delivered_at ||
              (store as any).occurred_at ||
              (store as any).occurred_on ||
              (store as any).purchased_at
          ).isBetween(startDate, endDate, "days", "[]")
        : true)
  );
  let commoditiesList: number[] = [];
  filteredStores.forEach((item) => {
    ((item as any).commodities as (
      | PurchaseCommodity
      | DeliveryCommodity
    )[]).forEach((commodity) => {
      if (
        !commoditiesList.includes(commodity.commodity) &&
        category === commodity.category
      ) {
        commoditiesList.push(commodity.commodity);
      }
    });
  });

  const mappedCommoditiesList = commoditiesList.map(
    (id) => commoditiesIndex[id]
  );

  return isWfp !== undefined
    ? mappedCommoditiesList.filter((item) => item?.is_wfp === isWfp)
    : mappedCommoditiesList;
};

export const getPurchasedCommodities = (
  stores: Store[],
  commoditiesIndex: { [key: number]: PurchaseCommodity | DeliveryCommodity },
  date0: moment.MomentInput,
  date1: moment.MomentInput
) => {
  const filteredStores = stores.filter((store) => {
    return (
      (store as any).commodities &&
      store.model === "purchasedetail" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
    );
  });
  let commoditiesList: number[] = [];

  filteredStores.forEach((item) => {
    (item as any).commodities.forEach(
      (commodity: PurchaseCommodity | DeliveryCommodity) => {
        if (!commoditiesList.includes(commodity.commodity)) {
          commoditiesList.push(commodity.commodity);
        }
      }
    );
  });

  const commodities: (
    | PurchaseCommodity
    | DeliveryCommodity
  )[] = commoditiesList.map((id) => commoditiesIndex[id]);

  return commodities;
};

export const getOtherCostsInStore = (
  stores: Store[],
  purchaseOtherCosts: any,
  date0: moment.MomentInput,
  date1: moment.MomentInput
) => {
  const filteredStores = stores.filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.commodities &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  );
  let otherCostList: any[] = [];

  filteredStores.forEach((item: any) => {
    item.other_costs.forEach((other_cost: any) => {
      if (
        other_cost.other_cost &&
        !otherCostList.includes(other_cost.other_cost)
      ) {
        otherCostList.push(other_cost.other_cost);
      }
    });
  });

  const purchaseOtherCostsOptions = purchaseOtherCosts
    ? (Object as any).assign(
        ...Object?.values(purchaseOtherCosts?.results).map(
          ({ ...rest }: any) => ({
            [rest.id]: rest,
          })
        ),
        {}
      )
    : null;

  const otherCostsInStore =
    otherCostList
      .map((id) => purchaseOtherCostsOptions[id])
      .filter((i) => i !== undefined) || [];

  return otherCostsInStore;
};

/**
 * Returns a snapshot of the initial carry over stock on the given `date`
 *
 * in the shape of: {
 *   <commodity_id>: {
 *     "totalQuantity": x,
 *     "quantityPerBatchNo": {
 *       <batch_no>: {
 *         "quantity": y,
 *         "date": z,
 *       },
 *       ...
 *     }
 *   },
 *   ...
 * }
 */
export const getInitialCarryOverStock = (
  state: RootState,
  date: moment.MomentInput,
  currentStoreData: Store
) => {
  const deliveries = getAllStores(state).filter((store) =>
    !currentStoreData
      ? store.model === "delivery" &&
        moment(store.delivered_at).isSameOrBefore(date, "day")
      : store.model === "delivery" &&
        moment(store.delivered_at).isSame(date, "day") &&
        (store as Delivery).is_initial_stock === true
  );

  const accumulator: any = {};

  deliveries.forEach((stock) => {
    const deliveredAt = (stock as any).delivered_at;
    (stock as any).commodities
      .filter(
        (i: any) =>
          i.is_commodity_wfp || (i.is_carry_over && !i.is_commodity_wfp)
      )
      .forEach((item: any) => {
        const quantity = !currentStoreData
          ? parseFloat(item.quantity_available)
          : parseFloat(item.quantity);
        let { commodity, category, batch_no, measure_unit } = item;
        if (category === "delivery" && quantity > 0) {
          if (batch_no === "") {
            batch_no = "unknown";
          }
          // this is just to accomodate the legacy data while creating school years in Sept 2022
          // TODO: remove this line after school years in 2022 have started
          batch_no = "unknown";
          if (!Object.keys(accumulator).includes(commodity.toString())) {
            accumulator[commodity] = {
              totalQuantity: quantity,
              measure_unit: measure_unit,
              quantityPerBatchNo: {
                [batch_no]: {
                  quantity: quantity,
                  date: deliveredAt,
                },
              },
            };
          } else {
            accumulator[commodity]["totalQuantity"] = parseFloat(
              (
                parseFloat(accumulator[commodity]["totalQuantity"] || 0) +
                parseFloat(quantity as any)
              ).toFixed(10)
            );

            if (
              Object.keys(
                accumulator[commodity]["quantityPerBatchNo"]
              ).includes(batch_no)
            ) {
              accumulator[commodity]["quantityPerBatchNo"][batch_no][
                "quantity"
              ] = parseFloat(
                (
                  parseFloat(
                    accumulator[commodity]["quantityPerBatchNo"][batch_no][
                      "quantity"
                    ] || 0
                  ) + parseFloat(quantity as any)
                ).toFixed(10)
              );
            } else {
              accumulator[commodity]["quantityPerBatchNo"][batch_no] = {
                quantity: quantity,
                date: deliveredAt,
              };
            }
            accumulator[commodity]["quantityPerBatchNo"][batch_no][
              "date"
            ] = deliveredAt;
          }
        }
      });
  });

  return accumulator;
};

export const getInitialStockIncidents = (
  state: RootState,
  date: moment.MomentInput
) => {
  const incidents: Incident[] = getAllStores(state).filter(
    (store) =>
      store.model === "incident" &&
      store.type !== "delete" &&
      store.is_initial_stock_incident === true &&
      moment(store.occurred_at).isSame(date, "day")
  ) as Incident[];
  const accumulator: any = {};

  incidents.forEach((incident) => {
    const occurredAt = incident.occurred_at;
    incident.commodities.forEach(
      ({ commodity, batch_no, quantity, measure_unit }: any) => {
        if (!Object.keys(accumulator).includes(commodity.toString())) {
          accumulator[commodity] = {
            totalQuantity: parseFloat(quantity),
            measure_unit: measure_unit,
            quantityPerBatchNo: {
              [batch_no]: {
                quantity: parseFloat(quantity),
                date: occurredAt,
              },
            },
          };
        } else {
          accumulator[commodity]["totalQuantity"] = parseFloat(
            (
              parseFloat(accumulator[commodity]["totalQuantity"] || 0) +
              parseFloat(quantity)
            ).toFixed(10)
          );

          if (
            Object.keys(accumulator[commodity]["quantityPerBatchNo"]).includes(
              batch_no
            )
          ) {
            accumulator[commodity]["quantityPerBatchNo"][batch_no][
              "quantity"
            ] = parseFloat(
              (
                parseFloat(
                  accumulator[commodity]["quantityPerBatchNo"][batch_no][
                    "quantity"
                  ] || 0
                ) + parseFloat(quantity)
              ).toFixed(10)
            );
          } else {
            accumulator[commodity]["quantityPerBatchNo"][batch_no] = {
              quantity: quantity,
              date: occurredAt,
            };
          }
          accumulator[commodity]["quantityPerBatchNo"][batch_no][
            "date"
          ] = occurredAt;
        }
      }
    );
  });

  return { quantityPerCommodity: accumulator, incidentObjects: incidents };
};

export const getInitialStockDeliveryByDate = (
  state: RootState,
  date: moment.MomentInput
) => {
  return getAllStores(state).find(
    (store) =>
      store.model === "delivery" &&
      moment(store.delivered_at).isSame(date, "day") &&
      (store as any).is_initial_stock === true
  );
};
