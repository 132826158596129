import React from "react";
import { Route, Switch } from "react-router-dom";
import CountryAdminVendorsTable from "./CountryAdminVendorsTable";

const CountryAdminVendors = () => {
  return (
    <div className="admin-vendors country-admin-main-content">
      <Switch>
        <Route component={CountryAdminVendorsTable} />
      </Switch>
    </div>
  );
};

export default CountryAdminVendors;
