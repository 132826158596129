import moment from "moment";

import { SchoolYear } from "data-handler/ducks/stores";

/**
 * Returns the SchoolYear's "name" (see wiki)
 */
export const getSchoolYearName = (schoolYear: SchoolYear) => {
  const year0 = moment(schoolYear.starts_on).format("YYYY");
  const year1 = moment(schoolYear.ends_on).format("YYYY");
  return year0 === year1 ? year0 : `${year0} - ${year1}`;
};
