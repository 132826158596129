import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { Blockquote, Button, Module, ModuleBody } from "@wfp/ui";

import SubTitle from "components/SubTitle";
import ItemSwitcher from "components/ItemSwitcher";
import SchoolReport from "components/SchoolReport";
import MainContent from "components/MainContent";
import ConfirmationModal from "components/CountryAdmin/CountryAdminContent/ConfirmationModal";

import {
  getAllStores,
  getSyncableMoment,
  syncableSortFnDecDateTime,
} from "data-handler/ducks/stores";
import { Report } from "data-handler/ducks/reports";
import { withSCSizes } from "helpers/mapSizesToProps";

import ReportActionButtons from "./ReportActionButtons";

import styles from "./styles.module.scss";
import { getCurrentUser } from "data-handler/ducks/auth";

const ReportContent = ({
  report,
  isMobile,
}: {
  report: Report;
  isMobile: boolean;
}) => {
  const allStores = useSelector(getAllStores);
  const currentUser = useSelector(getCurrentUser);
  const [confirmationModalState, setConfirmationModalState] = useState(false);

  const startDate = moment(
    `${report.year}/${report.month}/${report.start_day}`
  );
  const endDate = moment(`${report.year}/${report.month}/${report.end_day}`);

  const relatedStores = allStores
    .filter((store) =>
      getSyncableMoment(store)?.isBetween(startDate, endDate, "days", "[]")
    )
    .sort(syncableSortFnDecDateTime);

  const title = report.isPreview ? (
    <FormattedMessage
      id="Report.titleWhenPreview"
      defaultMessage="Report preview {period}"
      values={{ period: startDate.format("MMMM YYYY") }}
    />
  ) : (
    <FormattedMessage
      id="Report.title"
      defaultMessage="Report {period}"
      values={{ period: startDate.format("MMMM YYYY") }}
    />
  );

  const handleDownloadPDF = () => {
    // We only want the element with the id "report-content-container" in the pdf
    const element = document.getElementById("report-content-container");
    if (element) {
      const contentBox = element.getBoundingClientRect();
      // We use the html2canvas method because it works with Arabic characters as
      // it just takes a screenshot of the provided html element and puts it in a PDF
      html2canvas(element, {
        scale: 2.5, // Adjust the resolution
        ignoreElements: (element) => element.tagName === "svg",
      }).then((canvas) => {
        let img = canvas.toDataURL("image/jpeg");
        let doc = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [contentBox.width, contentBox.height],
          putOnlyUsedFonts: true,
        });
        doc.addImage(img, "JPEG", 0, 0, contentBox.width, contentBox.height);
        doc.save("report.pdf");
        setConfirmationModalState(false);
      });
    } else {
      setConfirmationModalState(false);
    }
  };

  return (
    <>
      <ConfirmationModal
        confirmationModalState={confirmationModalState}
        modalType={"submit"}
        setConfirmationModalState={setConfirmationModalState}
        onConfirmationModalSubmit={handleDownloadPDF}
        submitMessage={
          <FormattedMessage
            id="Report.generatePdfConfirmation"
            defaultMessage="Would you like to download this monthly report as pdf?"
          />
        }
      />
      <MainContent
        title={title}
        subTitle={
          !isMobile &&
          `${startDate.format("DD.MM.YYYY")} – ${endDate.format("DD.MM.YYYY")}`
        }
        right={
          <div className={styles.reportButtons}>
            <Button
              onClick={() => {
                setConfirmationModalState(true);
              }}
            >
              <FormattedMessage
                id="Report.generatePdf"
                defaultMessage="Generate PDF"
              />
            </Button>
            <ReportActionButtons report={report} />
          </div>
        }
        className="report-content"
      >
        {report.isPreview && (
          <Blockquote kind="warning">
            <FormattedMessage
              id="Report.pleaseSync"
              defaultMessage="This report is a preview. If you want to close, sign, or submit the report, you must first synchronise."
            />
          </Blockquote>
        )}

        {report.negative_stock && (
          <Blockquote kind="error">
            <FormattedMessage
              id="Report.negative_stock"
              defaultMessage="Dear {user}, the report cannot be closed because it contains negative total stock values. Please correct the data to be able to close the report"
              values={{
                user: `${currentUser.other_names} ${currentUser.last_name}`,
              }}
            />
          </Blockquote>
        )}

        <Module noMargin>
          <ModuleBody noPadding>
            <SchoolReport report={report} />
          </ModuleBody>
        </Module>
        <SubTitle>
          <FormattedMessage
            id="Report.listOfAllRelatedEntries"
            defaultMessage="List of all related entries"
          />
        </SubTitle>
        <Module noMargin className={styles.list}>
          <ModuleBody noPadding>
            {relatedStores.map((store) => (
              <ItemSwitcher store={store} key={store.client_id} />
            ))}
          </ModuleBody>
        </Module>
      </MainContent>
    </>
  );
};

export default withSCSizes(ReportContent);
