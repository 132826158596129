import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Blockquote, InlineLoading } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";

import { getCurrentUser } from "data-handler/ducks/auth";

import { useIsReportInLatestSchoolYear } from "./selectors";
import useReportAction from "./useReportAction";

const MODAL_LABEL = (
  <FormattedMessage id="ReportActionReopen.label" defaultMessage="Reporting" />
);
const MODAL_HEADING = (period) => (
  <FormattedMessage
    id="ReportActionReopen.heading"
    defaultMessage="Reopen Report {period}"
    values={{ period }}
  />
);
const BUTTON_LABEL = (
  <FormattedMessage
    id="ReportActionReopen.buttonLabel"
    defaultMessage="Reopen"
  />
);
const EXPLANATION_1 = (
  <FormattedMessage
    id="ReportActionReopen.explanation1"
    defaultMessage="If the contents of this report are incorrect or incomplete, you may reopen it, allowing you to edit its related data."
  />
);
const EXPLANATION_2 = (
  <FormattedMessage
    id="ReportActionReopen.explanation2"
    defaultMessage="If signatures have already been provided for this report, reopening it will remove them. School staff will need to sign this report again."
  />
);
const REOPENING_REPORT_AS = (
  <FormattedMessage
    id="ReportActionReopen.whichUser"
    defaultMessage="Reopening report as"
  />
);
const REPORT_CANNOT_BE_REOPENED = (
  <FormattedMessage
    id="ReportActionReopen.reportCannotBeReopened"
    defaultMessage="Report cannot be reopened"
  />
);

const ReportModalReopen = ({ report, action = "reopen" }) => {
  const currentUser = useSelector(getCurrentUser);
  const isReportInLatestSchoolYear = useIsReportInLatestSchoolYear(report);

  const {
    actionFeedback,
    closeModal,
    performAction,
    isPerforming,
    period,
  } = useReportAction({
    report,
    action: action,
  });

  return (
    <ModalExtended
      onRequestSubmit={performAction}
      onRequestClose={closeModal}
      modalLabel={MODAL_LABEL}
      modalHeading={MODAL_HEADING(period)}
      primaryButtonText={isPerforming ? <InlineLoading /> : BUTTON_LABEL}
      primaryButtonDisabled={!isReportInLatestSchoolYear}
    >
      {isReportInLatestSchoolYear ? (
        <div>
          {actionFeedback}
          <Blockquote title={REOPENING_REPORT_AS}>
            {currentUser.other_names} {currentUser.last_name}
          </Blockquote>
          <div style={{ marginTop: 16 }}>{EXPLANATION_1}</div>
          <div style={{ marginTop: 16 }}>{EXPLANATION_2}</div>
        </div>
      ) : (
        <Blockquote kind="error" title={REPORT_CANNOT_BE_REOPENED}>
          <FormattedMessage
            id="ReportActionReopen.reportInPreviousSchoolYear"
            defaultMessage="Cannot reopen this report because it is in a previous school year"
          />
        </Blockquote>
      )}
    </ModalExtended>
  );
};

export default ReportModalReopen;
