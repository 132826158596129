import endpointDuckFactory from "./_endpointDuckFactory";
import moment, { Moment, MomentInput } from "moment";
import { RootState } from "data-handler/rootReducer";

const reportsDuck = endpointDuckFactory({ endpoint: "reports" });
export default reportsDuck;

/**
 * True if report is not undefined and its is_editable flag is false. False otherwise.
 */
export const getIsReportLocked = (report: Report | undefined) =>
  report ? !report.is_editable : false;

export const getReportByMoment = (
  state: RootState,
  date: Moment | MomentInput
): Report | undefined => {
  const reports = reportsDuck.getList(state) as Report[];
  const month = moment(date).month() + 1;
  const year = moment(date).year();
  const day = moment(date).date();
  return reports.find(
    (report) =>
      report.month === month &&
      report.year === year &&
      report.start_day <= day &&
      report.end_day >= day
  );
};

export const getReportByYearMonthDay = (
  state: RootState,
  year: number,
  month: number,
  day: number
) => {
  const reports = reportsDuck.getList(state) as Report[];
  return reports.find(
    (report) =>
      report.month === month &&
      report.year === year &&
      report.start_day <= day &&
      report.end_day >= day
  );
};

export interface ReportAbsenceRow {
  male: number;
  female: number;
  level: string;
  total: number;
}

export interface ReportAbsenceTotalsRow {
  male: number;
  female: number;
  name: "pre" | "primary" | "total";
  total: number;
}

export interface ReportEnrolmentRow {
  male: number;
  female: number;
  level: string;
  total: number;
}

export interface ReportEnrolmentTotalsRow {
  male: number;
  female: number;
  name: "pre" | "primary" | "total";
  total: number;
}

export interface MeasureUnit {
  id: number;
  name: string;
  symbol: string;
  decimals: number;
}

export interface ReportCommodity {
  id: number;
  name: string;
  measure_unit: MeasureUnit;
}

export interface ReportStockMovementsRow {
  qty_lost: string;
  qty_final: string;
  batch_nos: Array<string>;
  commodity: number;
  qty_initial: string;
  qty_received: string;
  qty_distributed: string;
  qty_returned: string;
}

export interface ReportStockMovementsTotals {
  name: "total";
  measure_unit: MeasureUnit;
  qty_lost: string;
  qty_final: string;
  qty_initial: string;
  qty_received: string;
  qty_distributed: string;
  qty_returned: string;
}

export interface ReportPurchaseStockMovementsRow {
  qty_lost: string;
  qty_final: string;
  commodity: number;
  qty_initial: string;
  qty_received: string;
  qty_distributed: string;
}

export interface ReportPurchaseStockMovementsTotals {
  name: "total";
  measure_unit: MeasureUnit;
  qty_lost: string;
  qty_final: string;
  qty_initial: string;
  qty_received: string;
  qty_distributed: string;
}

export interface ReportFoodExpenditure {
  item: number;
  quantity: string;
  total_paid: string;
  commodity: number;
  date: string;
  comments: string;
}

export interface ReportFoodExpenditureTotals {
  name: "total";
  quantity: string;
  total_paid: string;
  measure_unit: MeasureUnit;
}

export interface ReportSchoolDaySummaryRowAttendanceSummary {
  male: number;
  female: number;
}

export interface ReportSchoolDaySummaryRow {
  date: string;
  is_school_day: boolean;
  is_consumption_day: boolean;
  attendance_per_kind: ReportSchoolDaySummaryRowAttendanceSummary;
  student_attendance_per_kind: ReportSchoolDaySummaryRowAttendanceSummary;
  consumption_per_commodity: Record<string, string>;
}

// This should match with wfp/apps/schools/types.py
export interface ReportAggregates {
  absence_rows: ReportAbsenceRow[];
  commodities: ReportCommodity[];
  absence_totals_rows: ReportAbsenceTotalsRow[];
  closest_wfp_office: string;
  enrolment_rows: ReportEnrolmentRow[];
  enrolment_totals_rows: ReportEnrolmentTotalsRow[];
  latest_delivery_date: string;
  local_education_authority: string;
  programme_manager: string;
  regional_education_authority: string;
  school_day_summary_rows: ReportSchoolDaySummaryRow[];
  school_day_summary_totals_row: {
    school_days: number;
    consumption_days: number;
    attendance_per_kind: ReportSchoolDaySummaryRowAttendanceSummary;
    student_attendance_per_kind: ReportSchoolDaySummaryRowAttendanceSummary;
    consumption_per_commodity: Record<string, string>;
  };
  stock_movement_rows_delivery: ReportStockMovementsRow[];
  stock_movement_totals_row_delivery: ReportStockMovementsTotals;
  stock_movement_rows_takehomeration: ReportStockMovementsRow[];
  stock_movement_totals_row_takehomeration: ReportStockMovementsTotals;
  stock_movement_rows_purchasedetail: ReportPurchaseStockMovementsRow[];
  stock_movement_totals_row_purchasedetail: ReportPurchaseStockMovementsTotals;
  food_expenditure_rows: ReportFoodExpenditure[];
  food_expenditure_totals_row: ReportFoodExpenditureTotals;
}

export type ReportState =
  | "open"
  | "closed"
  | "submitted"
  | "approved"
  | "rejected";

export type ReportAction =
  | "update"
  | "close"
  | "submit"
  | "approve"
  | "reject"
  | "amend"
  | "reopen"
  | "reopen_approved"
  | "reopen_validated";

export interface Report {
  aggregates: ReportAggregates;
  comments: string | null;
  date_created: string;
  date_updated: string;
  excel?: string | null;
  id: number;
  is_editable: boolean;
  /** FE-only flag */
  isPreview?: boolean;
  negative_stock: boolean;
  log_entries: ReportLogEntry[];
  month: number;
  pdf?: string | null;
  school_name: string;
  school_year_name: string;
  school_year: number;
  state: ReportState;
  submitted_at: string | null;
  submitter_signature: string;
  year: number;
  start_day: number;
  end_day: number;
}

export interface ReportLogEntry {
  comments: string;
  user: string;
  timestamp: string;
  transition: string;
}

export const kilogramMeasureUnit: MeasureUnit = {
  id: -1,
  name: "Kilogram",
  symbol: "Kg",
  decimals: 3,
};

export const emptyMeasureUnit: MeasureUnit = {
  id: -1,
  name: "",
  symbol: "",
  decimals: 3,
};

export const missingCommodity: ReportCommodity = {
  id: -1,
  name: "Unidentified",
  measure_unit: emptyMeasureUnit,
};
