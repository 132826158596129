import React from "react";
import styles from "./styles.module.scss";

const PageTitle = ({ children, subTitle }) => (
  <>
    {subTitle && <h3 className={styles.subTitle}>{subTitle}</h3>}
    <h1 className={styles.title}>{children}</h1>
  </>
);

export default PageTitle;
