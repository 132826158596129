import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CryptoJS from "crypto-js";

class OpenIdAuthentication extends Component {
  // Base64-urlencode the input string
  base64URL(string) {
    return string
      .toString(CryptoJS.enc.Base64)
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }

  generateCodeChallenge(code_verifier) {
    return this.base64URL(CryptoJS.SHA256(code_verifier));
  }

  getCode = async () => {
    const cryptoRandomString = require("crypto-random-string");
    const code_verifier = cryptoRandomString({ length: 43, type: "url-safe" });
    const code_challenge = this.generateCodeChallenge(code_verifier);
    localStorage.setItem("code_verifier", code_verifier);
    const ciamLoginUrl =
      `${process.env.REACT_APP_CIAM_AUTHENTICATION_URL}` +
      `/authorize?client_id=${process.env.REACT_APP_CIAM_CLIENT_ID}` +
      `&redirect_uri=${process.env.REACT_APP_CIAM_REDIRECT_URL}ciam-redirect/&scope=openid&response_type=code` +
      `&redirect_homepage=${process.env.REACT_APP_CIAM_REDIRECT_URL}` +
      `&code_challenge_method=S256&code_challenge=${code_challenge}`;
    window.open(ciamLoginUrl, "_self");
  };

  render() {
    this.getCode();
    return <></>;
  }
}

export default withRouter(OpenIdAuthentication);
