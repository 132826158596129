import { allLevels } from "SCConstants";

export function clearLocalStorage() {
  const localAuthStorageObject = localStorage.getItem("persist:localAuth");

  localStorage.clear();

  if (localAuthStorageObject) {
    localStorage.setItem("persist:localAuth", localAuthStorageObject);
  }
}

export const orderRowsByLevels = (rows: { level: string }[]) => {
  const findValueInAllLevels = (value: string) =>
    allLevels.find((item) => item.value === value);
  return rows.sort((a, b) =>
    allLevels.indexOf(findValueInAllLevels(a.level)!) >
    allLevels.indexOf(findValueInAllLevels(b.level)!)
      ? 1
      : -1
  );
};

export const checkEmptyOrUndefined = (value: object | any[] | undefined) => {
  switch (Object.prototype.toString.call(value)) {
    case "[object Undefined]":
      return true;
    case "[object Array]":
      return !value || (value as any[]).length === 0;
    case "[object Object]":
      return !value || Object.keys(value).length === 0;
    default:
      return false;
  }
};
