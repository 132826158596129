import React from "react";
import { useParams } from "react-router-dom";

import SidebarItem from "components/SidebarItem";
import CommodityShowSmall from "components/CommodityShowSmall";

export default function DeliveryItem({
  currentStoreData,
  store,
  ...extraProps
}) {
  const selected =
    currentStoreData && currentStoreData.client_id === store.client_id;
  const params = useParams();
  const title = `Waybill ${store.waybill_no}`;
  return (
    <SidebarItem
      {...extraProps}
      store={store}
      selected={selected}
      id={store.client_id}
      title={title}
      to={`/school/${params.schoolId}/deliveries/${store.client_id}`}
    >
      <CommodityShowSmall commodities={store?.commodities} />
    </SidebarItem>
  );
}
