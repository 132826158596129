import React from "react";
import { FormattedMessage } from "react-intl";

export const initialState = {
  toastType: undefined,
  message: undefined,
  msgArgs: {},
  options: {},
};

export const SET_TOAST = "SET_TOAST";
export const CLEAR_TOAST = "CLEAR_TOAST";

export const TOAST_ERROR = "TOAST_ERROR";
export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const TOAST_WARNING = "TOAST_WARNING";
export const TOAST_REGULAR = "TOAST_REGULAR";
export const TOAST_SUCCESS_WITH_STATUS = "TOAST_SUCCESS_WITH_STATUS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOAST:
      return action.payload;
    case CLEAR_TOAST:
      return initialState;
    default:
      return state;
  }
}

export const clearToastAction = () => ({
  type: typeof CLEAR_TOAST,
});

export const setToastAction = (payload) => ({
  type: typeof SET_TOAST,
  payload,
});

export function popToast(type, message, options, msgArgs) {
  return {
    type: SET_TOAST,
    payload: { toastType: type, message, msgArgs, options },
  };
}

const successMessage = (
  <FormattedMessage id="toast.Success" defaultMessage="Success!" />
);

const errorMessage = (
  <FormattedMessage id="toast.error" defaultMessage="An error has occurred." />
);

const warnMessage = (
  <FormattedMessage id="toast.warning" defaultMessage="Warning" />
);

function warnToast(message = warnMessage, options, msgArgs) {
  return popToast(TOAST_WARNING, message, options, msgArgs);
}

function errorToast(message = errorMessage, options, msgArgs) {
  return popToast(TOAST_ERROR, message, options, msgArgs);
}

function successToast(message = successMessage, options, msgArgs) {
  return popToast(TOAST_SUCCESS, message, options, msgArgs);
}

function toast(message, options, msgArgs) {
  return popToast(TOAST_REGULAR, message, options, msgArgs);
}

export { warnToast, errorToast, successToast, toast };

export const getCurrentToast = (state) => state.toast;
