import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import SidebarWrapper from "components/SidebarWrapper";
import StartASchoolYearFirst from "components/StartASchoolYearFirst";

import {
  getCurrentSchoolYear,
  getPreviousSchoolYears,
} from "data-handler/ducks/stores";
import { getCurrentSchoolProfile } from "data-handler/ducks/schools";

import AttendanceSidebar from "./AttendanceSidebar";
import AttendanceContent from "./AttendanceContent";

import "./_attendance.scss";

const Attendance = () => {
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  const currentSchoolProfile = useSelector(getCurrentSchoolProfile);

  if (!currentSchoolYear && previousSchoolYears.length === 0) {
    return <StartASchoolYearFirst />;
  }

  return (
    <>
      <SidebarWrapper
        title={
          <FormattedMessage
            id="Attendance.attendanceTitle"
            defaultMessage="Attendance"
          />
        }
      >
        <AttendanceSidebar
          currentSchoolYear={currentSchoolYear}
          previousSchoolYears={previousSchoolYears}
          currentSchoolProfile={currentSchoolProfile}
        />
      </SidebarWrapper>
      <AttendanceContent currentSchoolProfile={currentSchoolProfile} />
    </>
  );
};

export default Attendance;
