import React from "react";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { TextInput } from "@wfp/ui";
import { FormattedMessage } from "react-intl";
import DateInput from "components/DateInput";
import Camera from "components/Camera";
import "./_cash-to-school.scss";
import { currentSchoolYearDateValidation } from "components/IncidentEdit";
import { getPreviousSchoolYears } from "data-handler/ducks/stores";

type PurchaseInfoProps = {
  errors: any;
  currentYearStore: any;
  control: any;
  register: any;
};
const PurchaseInformation = ({
  errors,
  currentYearStore,
  control,
  register,
}: PurchaseInfoProps) => {
  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  const currentSchoolYearDate = currentSchoolYearDateValidation(
    currentYearStore,
    previousSchoolYears
  );
  return (
    <div className="purchasedetail">
      <TextInput
        labelText={
          <FormattedMessage
            id="PurchaseEdit.receiptNumber"
            defaultMessage="Receipt number (optional)"
          />
        }
        placeholder=""
        autoComplete="off"
        name={"receipt_no"}
        control={control}
        inputRef={register}
      />
      <div className="container-date-time">
        <div className="purchase-date">
          <Controller
            as={
              <DateInput
                labelText={
                  <FormattedMessage
                    id="purchaseEdit.purchaseDate"
                    defaultMessage="Purchase date"
                  />
                }
                min={currentSchoolYearDate.min}
                max={currentSchoolYearDate.max}
              />
            }
            name="date"
            control={control}
            invalid={errors.date}
            invalidText={errors.date && errors.date.message}
          />
        </div>
        <Controller
          control={control}
          render={({ onChange, value }) => (
            <Camera onChange={onChange} image={value} />
          )}
          name={"picture"}
        />
      </div>
    </div>
  );
};

export default PurchaseInformation;
