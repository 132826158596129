import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabLink from "../TabLink";
import styles from "./mobile-nav-details.module.scss";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

class MobileNavDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.child = React.createRef();
  }

  render() {
    const { subTitle, title, history, right } = this.props;
    return (
      <>
        <div className={`mobile-nav-details ${styles.mobileNavDetails}`}>
          <NavLink
            exact
            to={`${history.location.pathname}?nav=true`}
            renderListElement={TabLink}
            data-testid="mobile-back"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </NavLink>
          <h1>
            <span>{title}</span>
            <span className={styles.subTitle}>{subTitle}</span>
          </h1>
          <div className={styles.right}>{right}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    showMenu: state.showMenu,
    language: state.language,
  };
};

export default compose(withRouter, connect(mapStateToProps))(MobileNavDetails);
