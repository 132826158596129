import React from "react";
import { Route, Switch } from "react-router-dom";
import SchoolClassNamesMappingShow from "./SchoolClassNamesMappingShow";

const CountryAdminSchoolClassNames = () => {
  return (
    <div className="country-admin-main-content">
      <div className="schools-ClassNames-table">
        <Switch>
          <Route
            path={`/countryAdmin/schoolClassNames`}
            component={SchoolClassNamesMappingShow}
          />
        </Switch>
      </div>
    </div>
  );
};

export default CountryAdminSchoolClassNames;
