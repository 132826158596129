import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory, NavLink, useParams } from "react-router-dom";

import { Button } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

import infoIcon from "images/notebook.svg";

import Empty from "components/Empty";
import MainContent from "components/MainContent";
import EnrolmentShow from "components/EnrolmentShow";
import EnrolmentEdit from "components/EnrolmentEdit";
import YearEdit from "components/YearEdit";
import YearShow from "components/YearShow";
import UserView from "components/UserView";

import {
  getStoreByClientId,
  getCurrentSchoolYear,
  getUnsyncedStores,
  getCurrentOrLatestSchoolYear,
  storesIsFetching,
} from "data-handler/ducks/stores";
import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";

import "./_attendance.scss";
const InfoContent = () => {
  const history = useHistory();
  const params = useParams();
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  const latestSchoolYear = useSelector(getCurrentOrLatestSchoolYear);
  const currentStoreData = useSelector(getStoreByClientId(params.item));
  const unsyncedStores = useSelector(getUnsyncedStores);
  const isFetching = useSelector(storesIsFetching);

  if (params.item === "user") {
    return <UserView />;
  }

  const selectEntryTitle = (
    <FormattedMessage
      id="content.select_an_entry"
      defaultMessage="Select an entry"
    />
  );

  return (
    <>
      {currentStoreData ? (
        <>
          {currentStoreData.model === "enrolment" && (
            <EnrolmentShow currentStoreData={currentStoreData} />
          )}
          {currentStoreData.model === "year" && (
            <YearShow currentStoreData={currentStoreData} />
          )}
        </>
      ) : (
        <MainContent className="info-content">
          <Empty
            button={
              <Gate forbidRoles={[UserPermissionRoles.is_viewer]}>
                <Button
                  // Prevent adding enrolment updates without a current school year or without a previous school year
                  disabled={!latestSchoolYear}
                  kind="accent"
                  iconReverse
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => {
                    history.push(
                      `/school/${params.schoolId}/info/undefined/new-enrolment`
                    );
                  }}
                >
                  <span className="info-mobile">
                    <FormattedMessage
                      id="content.enrolment"
                      defaultMessage="Enrolment"
                    />
                  </span>
                  <span className="info-desktop">
                    <FormattedMessage
                      id="content.add_enrolment_update"
                      defaultMessage="Add enrolment update"
                    />
                  </span>
                </Button>
                <NavLink
                  to={`/school/${params.schoolId}/info/${
                    currentStoreData ? currentStoreData.client_id : undefined
                  }/new-year`}
                >
                  <Button
                    kind="secondary"
                    // Disable the button if there is an ongoing school year
                    disabled={
                      currentSchoolYear ||
                      unsyncedStores.length > 0 ||
                      isFetching
                    }
                    className="disabled-button"
                  >
                    <FormattedMessage
                      id="content.new_school_year"
                      defaultMessage="New school year"
                    />
                    {currentSchoolYear ? (
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="sideBar.newSchoolYearDisabled"
                          defaultMessage="You can create a new school year, when the current school year is finished."
                        />
                      </span>
                    ) : isFetching ? (
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="sideBar.newSchoolYearDisabledFetchingData"
                          defaultMessage="You can only create/edit a new school year after all the data is loaded."
                        />
                      </span>
                    ) : unsyncedStores.length > 0 ? (
                      <span className="tooltiptext">
                        <FormattedMessage
                          id="sideBar.newSchoolYearDisabledUnsyncedData"
                          defaultMessage="You can only create a new school year after all the data is synced."
                        />
                      </span>
                    ) : null}
                  </Button>
                </NavLink>{" "}
              </Gate>
            }
            title={selectEntryTitle}
            kind="large"
            icon={
              <img
                alt="info illustratation notebook with pen"
                src={infoIcon}
                style={{ marginLeft: "0.5rem", marginBottom: "1rem" }}
              />
            }
          >
            <FormattedMessage
              id="content.or_create_a_new_enrolment"
              defaultMessage="or create a new enrolment"
            />
          </Empty>
        </MainContent>
      )}

      {params.details === "enrolment" && (
        <EnrolmentEdit currentStoreData={currentStoreData} />
      )}

      {params.details === "year" && (
        <YearEdit currentStoreData={currentStoreData} />
      )}

      {params.details === "new-enrolment" && <EnrolmentEdit />}
      {params.details === "new-year" && <YearEdit />}
    </>
  );
};

export default InfoContent;
