import React from "react";
import { FormattedMessage } from "react-intl";
import { ModuleBody } from "@wfp/ui";

import LogEntry from "./LogEntry";

import styles from "./school-report.module.scss";

const History = ({ report }) => {
  return (
    <ModuleBody className={styles.reportModule}>
      <h5 className={styles.sectionTitle}>
        <FormattedMessage
          id="comments.general_comments"
          defaultMessage="8. Report History"
        />
      </h5>
      {(report.log_entries || []).map((log_entry) => (
        <LogEntry log_entry={log_entry} key={log_entry.timestamp} />
      ))}
    </ModuleBody>
  );
};

export default History;
