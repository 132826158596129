import React from "react";
import { Module } from "@wfp/ui";

import ReportHeader from "./ReportHeader";
import SchoolData from "./SchoolData";
import Enrolments from "./Enrolments";
import Absences from "./Absences";
import StockMovements from "./StockMovements";
import DailyAttendance from "./DailyAttendance";
import History from "./History";
import Signatures from "./Signatures";
import PurchasedStockMovementsExpenditures from "components/SchoolReport/PurchasedStockMovementsExpenditure";
import { getCurrentSchoolProfile } from "data-handler/ducks/schools";
import { useSelector } from "react-redux";
import ThrData from "components/SchoolReport/ThrData";
import { Report } from "data-handler/ducks/reports";

type SchoolReportProps = {
  report: Report;
};
const SchoolReport: React.ComponentType<SchoolReportProps> = ({ report }) => {
  const currentSchoolProfile = useSelector(getCurrentSchoolProfile);
  const currentSchoolEnabledPurchases =
    currentSchoolProfile.enable_cash_purchases;
  return (
    <Module noMargin id="report-content-container">
      <ReportHeader report={report as any} />
      <SchoolData report={report} />
      <Enrolments report={report} />
      <Absences report={report} />
      <StockMovements report={report} />
      {currentSchoolEnabledPurchases && (
        <PurchasedStockMovementsExpenditures report={report} />
      )}
      <DailyAttendance report={report} schoolProfile={currentSchoolProfile} />
      <ThrData report={report as any} />
      <Signatures report={report as any} />
      <History report={report} />
    </Module>
  );
};

export default SchoolReport;
