import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Modal, Blockquote } from "@wfp/ui";

import { isDeliveryNotDeletable } from "helpers/stock";

import useStore from "helpers/useStore";

import { studentAttendanceCategory, takeHomeRationCategory } from "SCConstants";

const DeleteModal = ({
  state,
  setState,
  currentStoreData,
  section,
  customRedirectPath = undefined,
}) => {
  const { updateStore, resetStore } = useStore();

  const intl = useIntl();

  const id =
    currentStoreData && currentStoreData.client_id
      ? currentStoreData.client_id
      : undefined;

  const onSubmit = () => {
    // Flag for partially deleting on-site attendance objects if only
    // deleting one section (when both levels and consumption are in the object)
    const onSiteAttendancePartialDeletion =
      currentStoreData.model === "attendance" &&
      currentStoreData.category !== takeHomeRationCategory &&
      currentStoreData.category !== studentAttendanceCategory &&
      currentStoreData.levels &&
      currentStoreData.consumption &&
      section !== undefined;

    currentStoreData.sync === false &&
    !currentStoreData.id &&
    !onSiteAttendancePartialDeletion
      ? resetStore(id, customRedirectPath)
      : updateStore(
          {
            id: id,
            values: currentStoreData,
            model: currentStoreData.model,
            type: "delete",
            section,
            category: currentStoreData.category,
          },
          customRedirectPath
        );
    setState(false);
  };

  const _isDeliveryNotDeletable =
    currentStoreData.model === "delivery"
      ? isDeliveryNotDeletable(currentStoreData)
      : false;

  return (
    <Modal
      open={state}
      modalHeading={intl.formatMessage({
        id: "sync.deleteTitle",
        defaultMessage: "Delete",
      })}
      primaryButtonText={intl.formatMessage({
        id: "Report.actionSubmit",
        defaultMessage: "Submit",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      onSecondarySubmit={() => setState(false)}
      onRequestSubmit={() => onSubmit()}
      onRequestClose={() => setState(false)}
      danger={true}
      primaryButtonDisabled={_isDeliveryNotDeletable}
    >
      {!_isDeliveryNotDeletable && (
        <Blockquote kind="error">
          <p>
            <FormattedMessage
              id="sync.delete"
              defaultMessage="Are you sure you want to delete this {model}?"
              values={{ model: currentStoreData.model }}
            />
          </p>
        </Blockquote>
      )}
      {_isDeliveryNotDeletable && (
        <Blockquote kind="error">
          <p>
            <FormattedMessage
              id="sync.deliveryInUse"
              defaultMessage="This delivery has already been used, please delete relevant consumption and incident for this commodity"
            />
          </p>
        </Blockquote>
      )}
    </Modal>
  );
};

export default DeleteModal;
