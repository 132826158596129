import React from "react";
import { Link } from "react-router-dom";
import { Icon, colors } from "@wfp/ui";
import { iconCheckmarkGlyph, iconCloseGlyph } from "@wfp/icons";
import { FormattedMessage } from "react-intl";

export const boolIconCell = ({ value }) => {
  return (
    <Icon
      fill={
        value
          ? colors["categorical-corporate-03"].hex
          : colors["support-01"].hex
      }
      icon={value ? iconCheckmarkGlyph : iconCloseGlyph}
    />
  );
};

export const valueLinkCell = (name, route) =>
  name ? (
    <Link to={route} title={name}>
      {name}
    </Link>
  ) : null;

export const checkCell = ({ row }) => (
  <input
    type="checkbox"
    checked={row.isSelected}
    onChange={() => row.toggleRowSelected()}
  />
);

export const checkHeader = ({ getToggleAllRowsSelectedProps }) => {
  const { indeterminate: ind, ...rest } = getToggleAllRowsSelectedProps();
  return (
    <div>
      <FormattedMessage
        id="SchoolLevelsMappingShow.levelActivation"
        defaultMessage="Active"
      />
      <input
        {...rest}
        type="checkbox"
        ref={(e) => e && (e.indeterminate = ind)}
      />
    </div>
  );
};

export const checkCol = {
  Header: checkHeader,
  Cell: checkCell,
  id: "checkbox",
  accessor: "",
  defaultCanFilter: false,
  disableFilters: true,
  defaultCanSort: false,
  disableSortBy: true,
};
