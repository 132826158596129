import React from "react";
import { FormattedMessage } from "react-intl";

import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { UserRegistrationEndpoints } from "../../SCConstants";
import { successToast, errorToast } from "data-handler/ducks/toast";
import { toastFormattedMessages } from "SCConstants";

export const REQUEST = "schoolconnect/registration/REQUEST_REGISTER";
export const SUCCESS = "schoolconnect/registration/SUCCESS_REGISTER";
export const FAILURE = "schoolconnect/registration/FAILURE_REGISTER";

const initialState = {
  error: null,
  errorResponse: null,
  fetching: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return { ...state, fetching: true, error: null };
    case SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}

export const requestRegistration = (data, endpoint, history) => ({
  type: REQUEST,
  data: { ...data, endpoint },
  history,
});

export function* registrationSaga() {
  yield takeEvery(REQUEST, workerSaga);
}

function fetchSaga({ data }) {
  const {
    endpoint,
    country,
    authorities,
    designation,
    countries,
    is_all_countries,
    ...userData
  } = data;

  const url = `${process.env.REACT_APP_API_URL}/${endpoint}/`;
  let request_data;

  switch (endpoint) {
    case UserRegistrationEndpoints.SchoolStaff:
      request_data = userData;
      break;
    case UserRegistrationEndpoints.CountryAdmin:
      request_data = { user: userData, country: country.id };
      break;
    case UserRegistrationEndpoints.ReportApprover:
      request_data = {
        user: userData,
        country: country.id,
        authorities: authorities,
        designation: designation,
      };
      break;
    case UserRegistrationEndpoints.SchoolGroupAdmin:
    case UserRegistrationEndpoints.Viewer:
      request_data = {
        user: userData,
        country: country,
        countries: countries,
        is_all_countries: is_all_countries,
      };
      break;
    default:
      request_data = userData;
      break;
  }

  return axios({
    method: "POST",
    url: url,
    data: request_data,
  });
}

const RegistrationErrorInformationContainer = (values) => {
  return (
    <FormattedMessage
      id="toast.registrationErrorInformation"
      defaultMessage="{values} Press the back button to go to the previous page and ammend the data."
      values={{ values: values }}
    />
  );
};

function* workerSaga(data) {
  let response;
  try {
    response = yield call(fetchSaga, data);
    yield put(
      successToast(
        toastFormattedMessages.find(
          (e) => e.name === `toast.registrationSuccess`
        ).label
      )
    );
    data.history.push("/");
    yield put({ type: SUCCESS, data: response.data });
  } catch (error) {
    const errors =
      error.response.data.errors.user || error.response.data.errors.phone;
    let label = "";
    if (errors && Object.keys(errors).length > 0) {
      for (const value of Object.values(errors)) {
        if (value.length > 0) {
          label = Array.isArray(value) ? value[0] : value + " " + label;
        }
      }
      yield put(errorToast(RegistrationErrorInformationContainer(label)));
    } else {
      yield put(
        errorToast(
          toastFormattedMessages.find(
            (e) => e.name === `toast.registrationError`
          ).label
        )
      );
    }
    yield put({ type: FAILURE, error });
  }
}
