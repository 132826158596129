import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { Button, Tag } from "@wfp/ui";

import { resetStore } from "data-handler/ducks/stores";

const ERROR_CODE_LABELS = {
  SERIALIZER_INVALID: (
    <FormattedMessage
      id="SyncError.code.SERIALIZER_INVALID"
      defaultMessage="Malformed Data"
    />
  ),
  SERVER_ERROR: (
    <FormattedMessage
      id="SyncError.code.SERVER_ERROR"
      defaultMessage="Server Error"
    />
  ),
  REPORT_UNEDITABLE: (
    <FormattedMessage
      id="SyncError.code.REPORT_UNEDITABLE"
      defaultMessage="Report Closed"
    />
  ),
};

const ERROR_CODE_HELP = {
  SERIALIZER_INVALID: (
    <FormattedMessage
      id="SyncError.help.SERIALIZER_INVALID"
      defaultMessage="Try editing again before syncing"
    />
  ),
  SERVER_ERROR: (
    <FormattedMessage
      id="SyncError.help.SERVER_ERROR"
      defaultMessage="Try syncing again"
    />
  ),
  REPORT_UNEDITABLE: (
    <FormattedMessage
      id="SyncError.help.REPORT_UNEDITABLE"
      defaultMessage="You must reopen the report to add or edit data"
    />
  ),
};

export default function SyncError({
  syncable: { client_id, id, error, errorDetails },
}) {
  const dispatch = useDispatch();

  if (error === undefined) {
    return null;
  }

  const isNew = id === undefined;

  return (
    <div>
      <Tag type="error">{ERROR_CODE_LABELS[error]}</Tag>
      <div style={{ margin: "0.25rem" }}>{ERROR_CODE_HELP[error]}</div>

      {/* Button for deleting the new sync or undoing its newest edits */}
      <Button kind="ghost" onClick={() => dispatch(resetStore(client_id))}>
        {isNew ? (
          <FormattedMessage
            id="SyncError.dropSyncable"
            defaultMessage="Drop Entry"
          />
        ) : (
          <FormattedMessage
            id="SyncError.undoModifications"
            defaultMessage="Undo Modifications"
          />
        )}
      </Button>

      {/* Debug info */}
      {process.env.REACT_APP_ENV !== "production" && (
        <pre
          style={{
            height: "30px",
            overflow: "auto",
            fontSize: "0.8rem",
            color: "#8c9ba5",
          }}
        >
          {JSON.stringify(errorDetails)}
        </pre>
      )}
    </div>
  );
}
