import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { checkCol } from "components/CountryAdmin/CountryAdminContent/Table/Utils";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { Button, Module, ModuleBody, ModuleHeader, Wrapper } from "@wfp/ui";
import styles from "./styles.module.scss";
import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";

import { getSelectedCountry } from "data-handler/ducks/countryProfile";
import {
  getLevelAgeMappings,
  requestLevelGroupMappings,
} from "data-handler/ducks/ageGroups";
import { CountryAdminLabel } from "SCConstants";
import { allLevels } from "SCConstants";

import Select from "react-select";
import {
  getBeneficiaryGroups,
  requestBeneficiaryGroups,
} from "../../../../../../data-handler/ducks/distributionReports";
import { updateLevelGroupMappings } from "../../../../../../data-handler/ducks/ageGroups";
import { TextInput } from "@wfp/ui";

const SchoolClassNamesMappingShow = () => {
  const dispatch = useDispatch();
  const selectedCountry = useSelector(getSelectedCountry);
  const levelMappings = useSelector(getLevelAgeMappings);
  const cometDistributionGroups = useSelector(getBeneficiaryGroups);
  const cometDistributionGroupsOptions = cometDistributionGroups?.map(
    (item) => ({
      value: item.id,
      label: `${item?.description} - ${item?.code}`,
    })
  );
  const [mappingState, setMappingState] = useState([]);
  const selectedLevelMappings = useRef();

  if (cometDistributionGroupsOptions) {
    cometDistributionGroupsOptions.unshift({
      value: null,
      label: (
        <FormattedMessage
          id="SchoolLevelsMappingShow.EmptyCometBeneficiaryGroupMessage"
          defaultMessage="No Beneficiary Group"
        />
      ),
    });
  }
  const [inEditState, setInEditState] = useState(false);

  useEffect(() => {
    if (selectedCountry?.id) {
      setInEditState(false);
      dispatch(requestLevelGroupMappings(selectedCountry.id));
    }
  }, [dispatch, selectedCountry]);

  useEffect(() => {
    selectedLevelMappings.current = {};
    let mapping = [];
    allLevels.forEach((level) => {
      const levelMapping = levelMappings.find((e) => e.level === level.value);
      if (levelMapping !== undefined) {
        const {
          id,
          custom_name,
          comet_age_group,
          comet_beneficiary_group,
          active,
        } = levelMapping;
        mapping.push({
          id,
          level: level,
          custom_name: custom_name || "",
          comet_age_group: comet_age_group.name,
          comet_beneficiary_group,
          active,
        });
        if (active) {
          selectedLevelMappings.current = {
            ...selectedLevelMappings.current,
            [mapping.length - 1]: true,
          };
        }
      }
    });
    setMappingState(mapping);
  }, [levelMappings]);

  useEffect(() => {
    dispatch(requestBeneficiaryGroups());
  }, [dispatch]);

  const methods = useForm();
  const { handleSubmit, register, control, getValues } = methods;

  const standardStyleController = (disabled) => {
    return {
      control: (styles) => ({
        ...styles,
        border: "1px solid #8ca4b5",
        opacity: disabled ? "0.5" : "",
      }),
    };
  };
  const columns = useMemo(() => {
    return [
      checkCol,
      {
        Header: (
          <FormattedMessage
            id="SchoolLevelsMappingShow.SchoolLevels"
            defaultMessage="Class Level"
          />
        ),
        accessor: "level.label",
      },
      {
        Header: CountryAdminLabel.find(
          (item) => item.name === "countryAdmin.customName"
        ).label,
        accessor: "custom_name",
        Cell: ({ cell: { row } }) => {
          return (
            <Controller
              as={
                <TextInput
                  name={`${row.original.id}`}
                  id={`${row.original.id}`}
                  disabled={!inEditState}
                />
              }
              name={`${row.original.id}`}
              defaultValue={
                `${row.original.id}` in getValues()
                  ? getValues()[`${row.original.id}`]
                  : row.original.custom_name
              }
              control={control}
              inputRef={register}
            />
          );
        },
      },
      {
        Header: (
          <FormattedMessage
            id="SchoolLevelsMappingShow.CometAgeGroup"
            defaultMessage="COMET Age Group"
          />
        ),
        accessor: "comet_age_group",
      },
      {
        Header: (
          <FormattedMessage
            id="SchoolLevelsMappingShow.CometBeneficiaryGroup"
            defaultMessage="COMET beneficiary group"
          />
        ),
        accessor: "comet_beneficiary_group",
        Cell: ({ cell: { row } }) => {
          return (
            <Controller
              as={
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  styles={standardStyleController(!inEditState)}
                  options={cometDistributionGroupsOptions}
                  isDisabled={!inEditState}
                />
              }
              defaultValue={
                `beneficiary-group-${row.original.id}` in getValues()
                  ? getValues()[`beneficiary-group-${row.original.id}`]
                  : row.original.comet_beneficiary_group
                  ? {
                      value: row.original.comet_beneficiary_group.id,
                      label: `${row.original.comet_beneficiary_group?.description} - ${row.original.comet_beneficiary_group?.code}`,
                    }
                  : {
                      value: null,
                      label: (
                        <FormattedMessage
                          id="SchoolLevelsMappingShow.EmptyCometBeneficiaryGroupMessage"
                          defaultMessage="No Beneficiary Group"
                        />
                      ),
                    }
              }
              name={`beneficiary-group-${row.original.id}`}
              control={control}
              inputRef={register}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inEditState, mappingState]);

  const setSelected = (ids) => {
    selectedLevelMappings.current = ids;
  };
  const tableProps = {
    columns,
    data: mappingState,
    pageNum: 1,
    pageSize: 20,
    totalItems: 0,
    setSelected,
    initialState: {
      selectedRowIds: selectedLevelMappings.current || {},
    },
    loadPage: () => {
      if (selectedCountry?.id) {
        return dispatch(requestLevelGroupMappings(selectedCountry.id));
      }
    },
  };

  const onSubmit = (values) => {
    let updatedObjects = {};
    mappingState.forEach((map) => {
      const mapId = map["id"];
      const oldValueId = map["comet_beneficiary_group"]?.id || null;
      const newValueId = values[`beneficiary-group-${mapId}`]?.value;
      const oldValue = map["custom_name"];
      const newValue = values[`${mapId}`];
      const oldValueActive = map["active"];
      const newValueActive =
        selectedLevelMappings.current[mappingState.indexOf(map)] || false;

      if (oldValueId !== newValueId) {
        updatedObjects[mapId] = {
          ...map,
          ...updatedObjects[mapId],
          comet_beneficiary_group: newValueId,
        };
      }
      if (oldValue !== newValue) {
        updatedObjects[mapId] = {
          ...map,
          ...updatedObjects[mapId],
          custom_name: newValue,
        };
      }
      if (oldValueActive !== newValueActive) {
        updatedObjects[mapId] = {
          ...map,
          ...updatedObjects[mapId],
          active: newValueActive,
        };
      }
    });

    const submitValues = Object.values(updatedObjects).map((item) => ({
      comet_beneficiary_group:
        item.comet_beneficiary_group % 1 === 0
          ? item.comet_beneficiary_group
          : item.comet_beneficiary_group?.id,
      level: item.level.value,
      country: selectedCountry?.id,
      active: item.active,
      custom_name: item.custom_name,
    }));
    dispatch(updateLevelGroupMappings(submitValues));
  };

  return (
    <Wrapper pageWidth="lg" spacing="md" background="lighter" className="table">
      <Module>
        <ModuleHeader>
          <FormattedMessage
            id="SchoolLevelsMappingShow.schoolClassNameMappingTitle"
            defaultMessage="School class name mapping"
          />
        </ModuleHeader>

        <ModuleBody>
          <FormProvider {...methods}>
            <form className="levels-form" onSubmit={handleSubmit(onSubmit)}>
              <Table
                {...tableProps}
                showPagination={false}
                disableFields={!inEditState}
              />
              <div className={styles.savebuttoncontainer}>
                {inEditState ? (
                  <Button
                    onClick={() => {
                      setInEditState(false);
                    }}
                  >
                    <FormattedMessage
                      id="SchoolLevelsMappingShow.saveButton"
                      defaultMessage="Save"
                    />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setInEditState(true);
                    }}
                    type="submit"
                  >
                    {
                      <FormattedMessage
                        id="SchoolLevelsMappingShow.editButton"
                        defaultMessage="Edit"
                      />
                    }
                  </Button>
                )}
              </div>
            </form>
          </FormProvider>
        </ModuleBody>
      </Module>
    </Wrapper>
  );
};

export default SchoolClassNamesMappingShow;
