import React from "react";

import Wizard from "components/Wizard";

import "./_content.scss";

export default class DistributionReportsWizard extends Wizard {
  next = (values) => {
    const currentPage = Math.min(
      this.state.currentPage + 1,
      this.props.children.length
    );
    const formState = this.props.formState;
    const reset = this.props.methods.reset;
    const stateValue = {
      ...this.state,
      ...formState,
      [currentPage]: values,
      currentPage: currentPage,
    };
    this.setState(stateValue);
    this.props.setFormState(stateValue);

    if (this.state.currentPage === 1 && stateValue?.hasOwnProperty(1)) {
      reset(stateValue[1]);
    }
    // Manually set the single location field for the third page when going back to it
    // otherwise the fields will not have the correct value
    if (this.state.currentPage === 2) {
      const setValue = this.props.methods.setValue;
      if (
        formState?.hasOwnProperty(3) &&
        formState[3].hasOwnProperty("location")
      ) {
        setValue("location", formState[3]["location"]);
      }
    }
  };

  previous = () => {
    this.props.unregisterSchools();
    this.props.resetRadioButton(undefined);
    this.setState((state) => ({
      currentPage: Math.max(state.currentPage - 1, 0),
    }));
    // Manually reset the fields for the first page when coming back to it
    // otherwise the fields will not have the correct value
    if (this.state.currentPage === 1) {
      const reset = this.props.methods.reset;
      const formState = this.props.formState;
      if (formState?.hasOwnProperty(1)) {
        reset(formState[1]);
      }
    }
  };

  onSubmit = (values) => {
    const { children, onSubmit } = this.props;
    const state = this.state;
    const isLastPage = state.currentPage + 1 === React.Children.count(children);
    if (isLastPage) {
      const currentPage = Math.min(
        this.state.currentPage + 1,
        this.props.children.length
      );

      const stateValue = {
        ...this.state,
        [currentPage]: values,
        locationOption: values.locationOption,
      };
      this.setState(stateValue);
      return onSubmit(stateValue);
    }
  };
}
