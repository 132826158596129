import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "react-dates/initialize";

import {
  Wrapper,
  FormGroup,
  RadioButtonGroup,
  RadioButton,
  InlineLoading,
  Button,
} from "@wfp/ui";
import { iconArrowLeft } from "@wfp/icons";
import { Icon } from "@wfp/ui";

import Select from "react-select";
import { Controller, useForm } from "react-hook-form";

import { CountryAdminLabel } from "SCConstants";
import { countriesDuck } from "data-handler/ducks/countries";
import {
  getDistributionReportsIsFetching,
  getAllDistributionReports,
  requestPartnerships,
  requestLocationsActivityCodes,
  getPartnerships,
  getLocationActivityCodes,
  requestLocations,
  getLocations,
  getOrganizationUnits,
  requestOrganizationUnits,
  requestCreateDistributionReports,
  requestEditDistributionReport,
  requestCurrentDistributionReport,
} from "data-handler/ducks/distributionReports";
import { getCurrentUser } from "data-handler/ducks/auth";
import {
  requestDistributionReportSchools,
  getDistributionReportSchools,
} from "data-handler/ducks/schools";

import DistributionReportsWizard from "./DistributionReportsWizard";
import CompactSchools from "../../Table/CompactSchools";
import LocationTable from "./LocationTable";
import FormFillingSelection from "./FormFillingSelection";
import useDistributionReportHeaderData from "./useDistributionReportHeaderData";

import { selectContainerStyles } from "SCConstants";
import "./_content.scss";

const CountryAdminDistributionReportProfilePage = ({ create }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  // eslint-disable-next-line no-use-before-define
  const { distributionReportId } = useParams(distributionReportId);

  const countries = useSelector(countriesDuck.getList);
  const countriesIsFetching = useSelector(countriesDuck.isFetching());
  const currentDistributionReport = useSelector(getAllDistributionReports)
    .results[distributionReportId];

  const fetching = useSelector(getDistributionReportsIsFetching);
  const currentUser = useSelector(getCurrentUser);
  const partnerships = useSelector(getPartnerships);
  const locationsActivityCodes = useSelector(getLocationActivityCodes);
  const locations = useSelector(getLocations);
  const organizationUnits = useSelector(getOrganizationUnits);
  const selectedSchoolsIds = useRef();

  const [period, setPeriod] = useState({
    start_date: undefined,
    end_date: undefined,
  });
  const [formState, setFormState] = useState();

  const [partnerOptions, setPartnerOptions] = useState([]);
  const [selectedCountryState, setSelectedCountryState] = useState();
  const [selectedlocationTypeOption, setLocationType] = useState();
  const [manualOrAutomaticFilling, setManualOrAutomaticFilling] = useState();
  const [
    distributionReportIdFilling,
    setDistributionReportIdFilling,
  ] = useState();
  const [openIdInput, setOpenIdInput] = useState(false);

  const methods = useForm();
  const {
    errors,
    register,
    watch,
    getValues,
    unregister,
    setValue,
    reset,
  } = methods;

  const selectedCountry = watch("country");
  const selectedPartnership = watch("partnershipId");
  const selectedActivityCode = watch("activityTag");
  const selectedPartner = watch("partner");
  const locationOptions = locations
    ?.filter(function (location) {
      return location?.activity_code === selectedActivityCode?.value;
    })
    ?.map((e) => ({
      label: `${e.admin_breakdown_name} ${e.settlement_english_name} ${e.point_of_interest_english_name}`,
      value: e.id,
    }));

  const organizationUnitsOptions = organizationUnits?.map((e) => ({
    value: e.id,
    label: `${e.org_unit_id}: ${e.text}`,
  }));
  const countryOptions = countries.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const locationsActivityCodesOptions = [...new Set(locationsActivityCodes)];

  const unregisterSchools = () => {
    // When moving between the "multiple locations" and "one location"
    // We need to unregister the multiple locations required fields
    var schoolLocationFields = Object.keys(getValues()).filter((object) =>
      /school-location/.test(object)
    );
    if (
      schoolLocationFields.length > 0 &&
      selectedlocationTypeOption !== "multipleLocations"
    ) {
      schoolLocationFields.forEach((e) => {
        unregister(e);
      });
    }
  };

  const locationDefaultValue =
    currentDistributionReport && currentDistributionReport?.location
      ? {
          label: `${currentDistributionReport?.location?.admin_breakdown_name} ${currentDistributionReport?.location?.settlement_english_name} ${currentDistributionReport?.location?.point_of_interest_english_name}`,
          value: currentDistributionReport?.location?.id,
        }
      : undefined;

  const populateCurrentDistributionReportFields = (distributionReport) => {
    const {
      country: country_object,
      organization_unit: organization_unit_object,
      partner: partner_object,
      partnership: partnership_object,
      schools,
      location: location_object,
    } = distributionReport;

    const activityTag = location_object?.activity_code
      ? {
          value: location_object?.activity_code,
          label: location_object?.activity_code,
        }
      : undefined;
    const country = country_object?.id
      ? {
          value: country_object?.id,
          label: country_object?.name,
        }
      : undefined;
    const organizationUnit = organization_unit_object?.id
      ? {
          value: organization_unit_object?.id,
          label: `${organization_unit_object?.org_unit_id}: ${organization_unit_object?.text}`,
        }
      : undefined;
    const partner = partner_object?.id
      ? {
          value: partner_object?.id,
          label: partner_object?.name,
        }
      : undefined;
    const partnershipId = partnership_object?.id
      ? {
          value: partnership_object?.id,
          label: partnership_object?.code,
        }
      : undefined;

    const stateObject = {
      activityTag,
      country,
      organizationUnit,
      partner,
      partnershipId,
    };
    setFormState({
      1: stateObject,
      3: {
        location: currentDistributionReport
          ? locationDefaultValue
          : {
              label: `${location_object?.admin_breakdown_name} ${location_object?.settlement_english_name} ${location_object?.point_of_interest_english_name}`,
              value: location_object?.id,
            },
      },
    });
    reset(stateObject);
    selectedSchoolsIds.current = {
      ...selectedSchoolsIds.current,
      ...(schools || []).reduce((a, x) => {
        return { ...a, [x.id]: x };
      }, {}),
    };
  };

  const {
    loading: distributionReportHeaderLoading,
    fetchData: fetchDistributionReportHeaderData,
  } = useDistributionReportHeaderData(
    dispatch,
    populateCurrentDistributionReportFields,
    setManualOrAutomaticFilling
  );

  useEffect(() => {
    if (!create) {
      if (!currentDistributionReport) {
        dispatch(
          requestCurrentDistributionReport(distributionReportId, history)
        );
      } else {
        if (formState === undefined) {
          populateCurrentDistributionReportFields(currentDistributionReport);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionReportId, create, currentDistributionReport, dispatch]);

  //#region UseEffect
  useEffect(() => {
    if (countries.length === 0 && !countriesIsFetching) {
      dispatch(countriesDuck.fetchList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(requestPartnerships(selectedCountry.value, true));
      setSelectedCountryState(selectedCountry.value);
      dispatch(requestOrganizationUnits(selectedCountry.value));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [selectedCountry, dispatch, unregister]);

  useEffect(() => {
    // Reset the partnership, activity code and partner fields on country change
    if (
      selectedCountry &&
      selectedCountry?.value !== getDefaultValue(1, "country")?.value
    ) {
      setValue("partnershipId", null);
      setValue("activityTag", null);
      setValue("partner", null);
      setValue("organizationUnit", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    // Reset the activity code and partner fields on country change
    if (
      selectedPartnership &&
      selectedPartnership?.value !== getDefaultValue(1, "partnershipId")?.value
    ) {
      setValue("activityTag", null);
      setValue("partner", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartnership]);

  useEffect(() => {
    if (currentDistributionReport && selectedActivityCode) {
      setFormState({
        ...formState,
        3:
          formState && 3 in formState
            ? {
                ...formState[3],
                location:
                  currentDistributionReport?.location.activity_code ===
                  selectedActivityCode?.value
                    ? locationDefaultValue
                    : undefined,
              }
            : {
                location:
                  currentDistributionReport?.location.activity_code ===
                  selectedActivityCode?.value
                    ? locationDefaultValue
                    : undefined,
              },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivityCode]);

  useEffect(() => {
    if (selectedlocationTypeOption === undefined) {
      setLocationType("oneLocation");
    }
    unregisterSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedlocationTypeOption]);

  useEffect(() => {
    if (selectedPartnership) {
      dispatch(requestLocationsActivityCodes(selectedPartnership.value));
      const selectedPartnershipObject = partnerships?.find(
        (e) => e.id === selectedPartnership.value
      );

      setPeriod({
        start_date: selectedPartnershipObject?.start_date,
        end_date: selectedPartnershipObject?.end_date,
        activity_code: selectedActivityCode?.value,
      });

      setPartnerOptions(
        selectedPartnershipObject?.partners.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );

      dispatch(requestLocations(selectedPartnership?.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartnership, dispatch, partnerships, selectedPartner]);

  //#endregion

  const filterLocationsFromFormData = (object) => {
    // retreive the locations  from form data
    let filter = object.hasOwnProperty("3")
      ? Object.keys(object[3]).reduce((acc, k) =>
          ~k.indexOf("school-location")
            ? { ...acc, [k]: object[3][k] }
            : undefined
        )
      : undefined;
    return Object.keys(filter).map((e) => {
      return {
        location: filter[e].value,
        school: parseInt(e.split("-")[2]),
      };
    });
  };

  const createLocationList = (locationId, schools) => {
    if (schools.length > 0) {
      return schools.map((e) => ({
        location: locationId,
        school: e.id,
      }));
    } else {
      return [
        {
          location: locationId,
        },
      ];
    }
  };

  const formatLocations = (list) => {
    /* Reshape locations into 
    [
      { location: id, schools: [id, id] },
      { location: id_2, schools: [id, id] },
    ]
    */
    return list.reduce((acc, curr) => {
      let bool = false;

      for (let i = 0; i < acc.length; i++) {
        if (acc[i].location === curr.location) {
          acc[i].schools.push(curr.school);
          bool = true;
          break;
        }
      }
      if (bool === false) {
        if (curr.school) {
          acc.push({
            location: curr.location,
            schools: [curr.school],
          });
        } else {
          acc.push({
            location: curr.location,
          });
        }
      }

      return acc;
    }, []);
  };

  const onFormSubmit = (submitValues) => {
    // aggregate all data from pages.
    const {
      activityTag,
      country,
      partner,
      partnershipId,
      organizationUnit,
    } = submitValues.hasOwnProperty("1") ? submitValues[1] : undefined;

    const { locationOption, schools, location } = submitValues.hasOwnProperty(
      "3"
    )
      ? submitValues[3]
      : undefined;

    const oneLocationSelected = locationOption === "oneLocation" ? true : false;
    const locationsFilter = oneLocationSelected
      ? createLocationList(location?.value, schools)
      : filterLocationsFromFormData(submitValues);

    const data = {
      activity_code: activityTag?.value,
      country: country?.value,
      partner: partner?.value,
      partnership: partnershipId?.value,
      organization_unit: organizationUnit?.value,
      start_date: moment(period?.start_date).format("YYYY-MM-DDThh:mm"),
      end_date: moment(period?.end_date).format("YYYY-MM-DDThh:mm"),
      locations: formatLocations(locationsFilter),
    };

    if (create) {
      dispatch(requestCreateDistributionReports(data, history));
    } else {
      dispatch(
        requestEditDistributionReport(distributionReportId, data, history)
      );
    }
  };

  const getDefaultValue = (page, input) => {
    return formState?.hasOwnProperty(page) ? formState[page][input] : null;
  };

  const inputList = [
    {
      name: "country",
      options: countryOptions,
      defaultValue:
        currentUser.user_role === "country_administrator" &&
        currentUser?.country
          ? {
              value: currentUser.country.id,
              label: currentUser.country.name,
            }
          : getDefaultValue(1, "country"),
      disabled: currentUser.user_role === "global_administrator" ? false : true,
    },
    {
      name: "partnershipId",
      options: partnerships?.map((e) => ({ value: e.id, label: e.code })),
      defaultValue: getDefaultValue(1, "partnershipId"),
    },
    {
      name: "activityTag",
      options: locationsActivityCodesOptions?.map((e) => ({
        value: e,
        label: e,
      })),
      defaultValue: getDefaultValue(1, "activityTag"),
    },
    {
      name: "partner",
      options: partnerOptions,
      defaultValue: getDefaultValue(1, "partner"),
    },
    {
      name: "organizationUnit",
      options: organizationUnitsOptions,
      defaultValue: getDefaultValue(1, "organizationUnit"),
    },
  ];

  const createDistributionReportProfileLabel = intl.formatMessage({
    id: "countryAdmin.createDistributionReportProfile",
    defaultMessage: "Create Distribution Report Profile",
  });

  const editDistributionReportProfileLabel = intl.formatMessage({
    id: "countryAdmin.editDistributionReportProfile",
    defaultMessage: "Edit Distribution Report Profile",
  });

  const selectSchoolsLabel = intl.formatMessage({
    id: "countryAdmin.selectSchoolsLabel",
    defaultMessage: "Select Schools",
  });

  const selectLocationLabel = intl.formatMessage({
    id: "countryAdmin.selectLocationLabel",
    defaultMessage: "Select Location",
  });

  const radioButtonList = [
    {
      value: "oneLocation",
      label: (
        <FormattedMessage
          id="distributionReport.oneLocationLabel"
          defaultMessage="Select one location to assign to all schools"
        />
      ),
      default: true,
    },
    getValues("schools")?.length > 1 && {
      value: "multipleLocations",
      label: (
        <FormattedMessage
          id="distributionReport.multipleLocationLabel"
          defaultMessage="Select different locations for each selected school"
        />
      ),
      default: false,
    },
  ];

  const onLoadPageDispatch = (size, idx, filters, ordering) => {
    dispatch(
      requestDistributionReportSchools(period, size, idx, [
        ...filters,
        ...[{ id: "country", value: selectedCountryState }],
      ])
    );
  };

  const loading = useMemo(() => {
    if (!create && !currentDistributionReport && fetching) {
      return (
        <div className="inlineLoading">
          <InlineLoading />
        </div>
      );
    } else {
      return null;
    }
  }, [create, currentDistributionReport, fetching]);

  return (
    <>
      {loading}
      {!loading && (
        <Wrapper background="lighter" pageWidth="lg" spacing="xl">
          <DistributionReportsWizard
            methods={methods}
            onSubmit={onFormSubmit}
            setFormState={setFormState}
            resetRadioButton={setLocationType}
            unregisterSchools={unregisterSchools}
            formState={formState}
            history={history}
            closeButtonRedirectUrl={"/countryAdmin/distributionReport/"}
            nextDisabled={create && !manualOrAutomaticFilling}
          >
            {
              // First page, creating the distribution report
            }
            <DistributionReportsWizard.Page
              label={
                create
                  ? createDistributionReportProfileLabel
                  : editDistributionReportProfileLabel
              }
            >
              {create && !manualOrAutomaticFilling ? (
                <FormFillingSelection
                  intl={intl}
                  manualOrAutomaticFilling={manualOrAutomaticFilling}
                  setManualOrAutomaticFilling={setManualOrAutomaticFilling}
                  distributionReportId={distributionReportIdFilling}
                  setDistributionReportId={setDistributionReportIdFilling}
                  openIdInput={openIdInput}
                  setOpenIdInput={setOpenIdInput}
                  fetchDistributionReportHeaderData={
                    fetchDistributionReportHeaderData
                  }
                  loading={distributionReportHeaderLoading}
                />
              ) : (
                <div className="row">
                  {create && (
                    <div className="distibutionReportProfile">
                      <Button
                        icon={<Icon icon={iconArrowLeft} />}
                        iconReverse
                        kind="secondary"
                        onClick={() => setManualOrAutomaticFilling(null)}
                      >
                        <FormattedMessage
                          id="distributionReport.backToFillingSelectionLabel"
                          defaultMessage="Back to form filling selection"
                        />
                      </Button>
                    </div>
                  )}
                  {inputList.map(
                    ({
                      name,
                      options,
                      defaultValue = null,
                      disabled = false,
                      isMulti = false,
                    }) => (
                      <div key={name} className="distibutionReportProfile">
                        <div>
                          <label className="wfp--label" htmlFor={name}>
                            {
                              CountryAdminLabel.find(
                                (e) => e.name === `countryAdmin.${name}`
                              )?.label
                            }
                          </label>
                        </div>

                        <Controller
                          render={({ value, onChange }) => {
                            return (
                              <Select
                                styles={
                                  errors?.hasOwnProperty(name)
                                    ? selectContainerStyles.invalid
                                    : selectContainerStyles.valid
                                }
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={options}
                                onChange={onChange}
                                value={value}
                                isDisabled={disabled}
                                defaultValue={defaultValue}
                                isMulti={isMulti}
                              />
                            );
                          }}
                          name={name}
                          options={options}
                          rules={{ required: true }}
                          inputRef={register}
                          defaultValue={defaultValue}
                        />
                      </div>
                    )
                  )}
                  <div className="distibutionReportProfile">
                    <div className="distributionReportCreatelabel">
                      <label className="wfp--label" htmlFor={"period"}>
                        {
                          CountryAdminLabel.find(
                            (e) => e.name === `countryAdmin.period`
                          ).label
                        }
                      </label>
                    </div>
                    <div className="distributionReportPeriod">
                      <FormattedMessage
                        id="distributionReport.startDate"
                        defaultMessage="Start date: {startDate}"
                        values={{
                          startDate:
                            period?.start_date &&
                            moment(period?.start_date).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            ),
                        }}
                      />
                    </div>
                    <div className="distributionReportPeriod">
                      <FormattedMessage
                        id="distributionReport.endDate"
                        defaultMessage="End date: {endDate}"
                        values={{
                          endDate:
                            period?.end_date &&
                            moment(period?.end_date).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </DistributionReportsWizard.Page>
            {
              //Second Page, Selecting schools for distribution Report.
            }
            <DistributionReportsWizard.Page label={selectSchoolsLabel}>
              <FormGroup>
                <label htmlFor="linkSchools">
                  <FormattedMessage
                    id="countryAdmin.selectSchools"
                    defaultMessage="Select various schools to link to the previously created profile"
                  />
                </label>
                <div className="distribution-report-schools-table">
                  <CompactSchools
                    register={register}
                    setValue={setValue}
                    selectedSchoolsIds={selectedSchoolsIds}
                    schools={
                      currentDistributionReport?.schools
                        ? currentDistributionReport?.schools
                        : []
                    }
                    onLoadPageDispatch={onLoadPageDispatch}
                    getSchools={getDistributionReportSchools}
                  />
                </div>
              </FormGroup>
            </DistributionReportsWizard.Page>
            {
              //Third Page, Selecting locations for distribution Report.
            }
            <DistributionReportsWizard.Page label={selectLocationLabel}>
              {
                // Using Role selection CSS for radio buttons and label.
              }
              <div className="role-selection-container">
                <RadioButtonGroup
                  name={"select-location-type"}
                  labelText={
                    <FormattedMessage
                      id="countryAdmin.selectLocation"
                      defaultMessage="Select location of the schools to link to the previously created profile"
                    />
                  }
                >
                  {radioButtonList
                    .filter((item) => item)
                    .map((role) => (
                      <div key={role.value} className="radio-role-selection">
                        <RadioButton
                          key={role.value}
                          labelText={role.label}
                          id={`role-${role.value}`}
                          name={"locationOption"}
                          value={role.value}
                          defaultChecked={role.default}
                          onChange={(e) => setLocationType(e.target.value)}
                          ref={register({ required: true })}
                        />
                      </div>
                    ))}
                </RadioButtonGroup>
                <div className="distributionReport-location-select">
                  {selectedlocationTypeOption === "oneLocation" && (
                    <Controller
                      render={({ value, onChange }) => (
                        <Select
                          styles={
                            errors?.location
                              ? selectContainerStyles.invalid
                              : selectContainerStyles.valid
                          }
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={locationOptions}
                          defaultValue={
                            formState?.hasOwnProperty(3) &&
                            formState[3].hasOwnProperty("location")
                              ? formState[3]["location"]
                              : undefined
                          }
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            setFormState({
                              ...formState,
                              3:
                                formState && 3 in formState
                                  ? {
                                      ...formState[3],
                                      location: e,
                                    }
                                  : {
                                      location: e,
                                    },
                            });
                          }}
                        />
                      )}
                      name={"location"}
                      options={locationOptions}
                      rules={{
                        required:
                          selectedlocationTypeOption === "oneLocation"
                            ? true
                            : false,
                      }}
                      defaultValue={
                        formState?.hasOwnProperty(3) &&
                        formState[3].hasOwnProperty("location")
                          ? formState[3]["location"]
                          : undefined
                      }
                      inputRef={register}
                    />
                  )}
                  {selectedlocationTypeOption === "multipleLocations" && (
                    <LocationTable
                      schools={getValues("schools")}
                      locations={locationOptions}
                      methods={methods}
                      selectedlocationTypeOption={selectedlocationTypeOption}
                      selectedLocation={
                        formState?.hasOwnProperty(3) &&
                        formState[3].hasOwnProperty("location")
                          ? formState[3]["location"]
                          : undefined
                      }
                    />
                  )}
                </div>
              </div>
            </DistributionReportsWizard.Page>
          </DistributionReportsWizard>
        </Wrapper>
      )}
    </>
  );
};

export default CountryAdminDistributionReportProfilePage;
