import React from "react";
import { Button } from "@wfp/ui";

import { clearLocalStorage } from "helpers/utils";

import styles from "./styles.module.scss";

/**
 * School Connect's error fallback page.
 *
 * N.B. THIS IS A "RENDER PROP" NOT A FUNCTION COMPONENT
 */
const ErrorFallback = ({ history }) => ({ error }) => {
  const reload = () => {
    history.push("/");
    window.location.reload(false);
  };

  const clearLocalStorageHandler = () => {
    clearLocalStorage();
    history.push("/");
    window.location.reload(false);
  };

  return (
    <div className={styles.errorWrapper}>
      <h2>Something went wrong.</h2>
      <h2 className={styles.frenchTitle}>Quelque chose a mal tourné.</h2>

      <Button onClick={reload}>Reload (recharger)</Button>
      <br />

      <Button kind="secondary" onClick={clearLocalStorageHandler}>
        Throw away unsynced data (Jeter les données non synchronisées)
      </Button>
      <br />

      {process.env.REACT_APP_ENV !== "production" && (
        <div style={{ whiteSpace: "pre-wrap", fontFamily: "monospace" }}>
          {error.toString()}
        </div>
      )}
    </div>
  );
};

export default ErrorFallback;
