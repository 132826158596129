/**
 * Fixes https://dev.azure.com/worldfoodprogramme/SchoolMeals/_workitems/edit/48710
 */
const migration_1_clean_attendance = (state) => {
  const occurredToAttendance = {};
  state.stores.stores.forEach((store) => {
    if (store.model === "attendance") {
      if (occurredToAttendance[store.occurred_on] === undefined) {
        occurredToAttendance[store.occurred_on] = [];
      }
      occurredToAttendance[store.occurred_on].push(store);
    }
  });

  // Among duplicate attendance reports, choose which ones survive the cleanup
  const survivors = [];
  Object.values(occurredToAttendance).forEach((duplicates) => {
    let survivor = duplicates[0];
    let survivorScore = 0;

    duplicates.forEach((attendance) => {
      let currentScore = 0;
      // Prefer attendances with populated levels and consumptions
      if (attendance.levels !== undefined) {
        currentScore += 1;
      }
      if (attendance.consumption !== undefined) {
        currentScore += 1;
      }
      if (currentScore > survivorScore) {
        survivor = attendance;
        survivorScore = currentScore;
      }
    });
    survivors.push(survivor.client_id);
  });

  // Attendances survive only if they're included in survivors.
  // Everything else survives.
  const survivingStores = state.stores.stores
    .filter(
      (store) =>
        store.model !== "attendance" || survivors.includes(store.client_id)
    )
    .map((store) => {
      if (survivors.includes(store.client_id)) {
        return { ...store, sync: false }; // Ask users to re-sync survivors
      }
      return store; // Leave everything else untouched
    });

  return {
    ...state,
    stores: {
      ...state.stores,
      stores: survivingStores,
    },
  };
};

export default migration_1_clean_attendance;
