import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Module, ModuleBody } from "@wfp/ui";

import {
  getAllEnrolmentUpdates,
  getAllSchoolYears,
  syncableSortFnDecDateTime,
} from "data-handler/ducks/stores";
import { getCurrentSchoolYear } from "data-handler/ducks/stores";

import ItemSwitcher from "components/ItemSwitcher";
import MainContent from "components/MainContent";
import EnrolmentTable from "components/EnrolmentTable";
import SubTitle from "components/SubTitle";
import StartASchoolYearFirst from "components/StartASchoolYearFirst";

export default function EnrolmentHistory() {
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  const allEnrolmentUpdates = useSelector(getAllEnrolmentUpdates);
  const allSchoolYears = useSelector(getAllSchoolYears);
  const items = [...allEnrolmentUpdates, ...allSchoolYears];
  const sortedItems = useMemo(() => items.sort(syncableSortFnDecDateTime), [
    items,
  ]);

  return (
    <MainContent
      title={
        <FormattedMessage
          id="EnrolmentHistory.currentEnrolmentTitle"
          defaultMessage="Current enrolment"
        />
      }
    >
      {currentSchoolYear === null ? (
        <StartASchoolYearFirst />
      ) : (
        <>
          <Module noMargin>
            <ModuleBody noPadding>
              <EnrolmentTable />
            </ModuleBody>
          </Module>
          <SubTitle>
            <FormattedMessage
              id="EnrolmentHistory.historySubTitle"
              defaultMessage="History"
            />
          </SubTitle>
          <Module noMargin>
            <ModuleBody noPadding>
              {sortedItems.map((store) => (
                <ItemSwitcher store={store} key={store.client_id} />
              ))}
            </ModuleBody>
          </Module>
        </>
      )}
    </MainContent>
  );
}
