import React, { useMemo, useState } from "react";

import { getLevelAgeMappings } from "data-handler/ducks/ageGroups";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { Input, Button, Icon, Select, SelectItem } from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/pro-regular-svg-icons";

import styles from "./addlevel.module.scss";

type AgeGroupLevel = {
  active: boolean;
  level: string;
  custom_name: string;
};

const getLevelOptions = (
  intl: any,
  ageGroupLevels: AgeGroupLevel[],
  shownLevelsSet: Set<string>
) => {
  // All the active levels for this country.
  const activeLevels = ageGroupLevels.filter((item) => item.active);
  let levelsOptions = activeLevels
    // Do not show the values that are already added to enrolement update.
    .filter((level) => !shownLevelsSet.has(level.level))
    .map((level) => {
      const label =
        level.custom_name ||
        intl.formatMessage({
          id: `Common.${level.level}`,
        });

      return {
        value: level.level,
        label: label,
      };
    });

  levelsOptions = levelsOptions.sort((a: any, b: any) =>
    a.level < b.level ? -1 : 1
  );

  return levelsOptions;
};

type ShownLevel = { level: string };

type ShownLevels = ShownLevel[];

type AddLevelProps = {
  shownLevels: ShownLevels;
  addLevel: (label: string) => void;
};

const AddLevel = ({ shownLevels, addLevel }: AddLevelProps) => {
  // When we click on Initial add button isActive becomes true and we show select component and apply + cancel buttons.
  const [isActive, setIsActive] = useState(false);
  // value of select component
  const [value, setValue] = useState("");
  const resetValue = () => setValue("");
  const ageGroupLevels: AgeGroupLevel[] = useSelector(getLevelAgeMappings);
  const intl = useIntl();
  const levelsOptions = useMemo(() => {
    const shownLevelSet = new Set(shownLevels.map((level) => level.level));
    return getLevelOptions(intl, ageGroupLevels, shownLevelSet);
  }, [ageGroupLevels, intl, shownLevels]);
  const notActiveComponent = (
    <Button
      className="content__addlevel__add-btn"
      large
      kind="primary"
      onClick={() => {
        setIsActive(true);
      }}
    >
      <Icon icon={<FontAwesomeIcon icon={faPlusSquare} size="lg" />} />
    </Button>
  );

  const activeComponent = (
    <div className={styles.active_container}>
      <Input invalid={false} invalidText={"invalid text message"}>
        {() => (
          <Select
            labelText="Select"
            // inputRef={register()}
            className="level"
            name={`level`}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue(e.target.value);
            }}
          >
            <SelectItem value="" text={"Levels"} />
            {levelsOptions.map(({ value, label }) => (
              <SelectItem key={value} value={value} text={label} />
            ))}
          </Select>
        )}
      </Input>
      <div className={styles.active_container__buttons}>
        <Button
          className="content__addLevel__apply-btn"
          disabled={value === ""}
          onClick={() => {
            addLevel(value);
            setIsActive(false);
            resetValue();
          }}
        >
          <FormattedMessage
            id="EnrolmentEdit.addLevel.apply"
            defaultMessage="Add Level"
          />
        </Button>
        <Button
          className="content__addLevel__cancel-btn"
          onClick={() => {
            setIsActive(false);
            resetValue();
          }}
        >
          <FormattedMessage
            id="EnrolmentEdit.addLevel.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </div>
    </div>
  );

  return isActive ? activeComponent : notActiveComponent;
};

export default AddLevel;
