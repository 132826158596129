import React from "react";
import { Switch, Route } from "react-router-dom";

import UsersTable from "./UsersTable";
import UserPage from "./UserPage";

const CountryAdminUsers = () => {
  const renderAllUsersUserPage = () => {
    return <UserPage prevPath={"/countryAdmin/users/allUsers"} />;
  };

  const renderNewUsersUserPage = () => {
    return <UserPage prevPath={"/countryAdmin/users/newUsers"} />;
  };

  return (
    <div className="users-page country-admin-main-content">
      <Switch>
        <Route
          path={"/countryAdmin/users/allUsers/:userId"}
          component={renderAllUsersUserPage}
        />
        <Route
          path={"/countryAdmin/users/newUsers/:userId"}
          component={renderNewUsersUserPage}
        />
        <Route component={UsersTable} />
      </Switch>
    </div>
  );
};

export default CountryAdminUsers;
