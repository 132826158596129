import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";
import bcrypt from "bcryptjs";
import {
  listObjectsToListIds,
  listToObject,
} from "data-handler/ducks/paginationTools";
import { successToast, errorToast } from "data-handler/ducks/toast";
import { toastFormattedMessages } from "SCConstants";
import { camelToSnakeCase } from "./utils";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  users: [],
  pending: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  allCountryUsers: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  approvers: [],
  schoolGroupAdmins: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "API_CALL_GETUSERS_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_GETUSERS_SUCCESS":
      const fakeUsers = action.data.map((e) => e);
      return { ...state, users: fakeUsers, fetching: false };
    case "UPDATE_PASSCODE":
      const index = state.users.findIndex((e) => e.user === action.data.id);

      if (action.data.passcode === false) {
        state.users[index] = {
          ...state.users[index],
          passcode: false,
          localData: undefined,
        };

        return { ...state, users: state.users };
      }
      const hash = bcrypt.hashSync(action.data.passcode, 8);

      state.users[index] = {
        ...state.users[index],
        passcode: hash,
        localData: action.data.localData,
      };
      return { ...state, users: state.users };
    case "API_CALL_GETUSERS_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_GET_COUNTRY_ADMIN_PENDING_USERS_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_GET_COUNTRY_ADMIN_PENDING_USERS_SUCCESS":
      return { ...state, pending: action.data, fetching: false };
    case "API_CALL_GET_COUNTRY_ADMIN_PENDING_USERS_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_GET_COUNTRY_ADMIN_ALL_USERS_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_GET_COUNTRY_ADMIN_ALL_USERS_SUCCESS":
      return { ...state, allCountryUsers: action.data, fetching: false };
    case "API_CALL_GET_COUNTRY_ADMIN_ALL_USERS_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_SET_COUNTY_ADMIN_USER_ACTION_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_SET_COUNTRY_ADMIN_USER_ACTION_SUCCESS":
      return { ...state, fetching: false };
    case "API_CALL_SET_COUNTRY_ADMIN_USER_ACTION_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_SET_COUNTY_ADMIN_APPROVERS_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_SET_COUNTRY_ADMIN_APPROVERS_SUCCESS":
      return { ...state, approvers: action.data, fetching: false };
    case "API_CALL_SET_COUNTRY_ADMIN_APPROVERS_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_SET_COUNTY_ADMIN_SCHOOL_GROUP_ADMINS_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_SET_COUNTRY_ADMIN_SCHOOL_GROUP_ADMINS_SUCCESS":
      return { ...state, schoolGroupAdmins: action.data, fetching: false };
    case "API_CALL_SET_COUNTRY_ADMIN_SCHOOL_GROUP_ADMINS_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_CURRENT_USER_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_CURRENT_USER_SUCCESS":
      const { currentUser } = action;
      const currentUserObject = { [currentUser.id]: currentUser };
      // Append the new user to the correct field
      const updateObject =
        currentUser.state === "new"
          ? {
              pending: {
                ...state.pending,
                results: { ...state.pending.results, ...currentUserObject },
              },
            }
          : {
              allCountryUsers: {
                ...state.allCountryUsers,
                results: {
                  ...state.allCountryUsers.results,
                  ...currentUserObject,
                },
              },
            };
      return {
        ...state,
        ...updateObject,
        fetching: false,
        error: null,
      };
    case "API_CALL_CURRENT_USER_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case "API_CALL_EDIT_USER_REQUEST":
      return { ...state, fetching: true, error: null };
    case "API_CALL_EDIT_USER_SUCCESS":
      return { ...state, fetching: false, error: null };
    case "API_CALL_EDIT_USER_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}

// Action creators
export const requestUsersFromSchool = (schoolId) => ({
  type: "API_CALL_GETUSERS_REQUEST",
  schoolId,
});

export const requestCountryAdminPendingUsers = (
  pageSize,
  pageIndex,
  ordering,
  filters
) => ({
  type: "API_CALL_GET_COUNTRY_ADMIN_PENDING_USERS_REQUEST",
  pageSize,
  pageIndex,
  ordering,
  filters,
});

export const requestCountryAdminAllUsers = (
  pageSize,
  pageIndex,
  ordering,
  filters
) => {
  return {
    type: "API_CALL_GET_COUNTRY_ADMIN_ALL_USERS_REQUEST",
    pageSize,
    pageIndex,
    ordering,
    filters,
  };
};

export const setCountryAdminUserStatus = (statusAction, userID) => ({
  // Takes in a User ID (PK) and a action
  type: "API_CALL_SET_COUNTRY_ADMIN_USER_ACTION_REQUEST",
  statusAction,
  userID,
});

export const requestCountryAdminApprovers = () => {
  return {
    type: "API_CALL_GET_COUNTRY_ADMIN_APPROVERS_REQUEST",
  };
};

export const requestCurrentUser = (userId, history) => ({
  type: "API_CALL_CURRENT_USER_REQUEST",
  userId,
  history,
});

export const editUser = (data, id, history, prevPath) => ({
  type: "API_CALL_EDIT_USER_REQUEST",
  data,
  id,
  history,
  prevPath,
});

// Saga
export function* usersSagaWatcher() {
  yield takeEvery("API_CALL_GETUSERS_REQUEST", usersSagaWorker);
}

export function* countryAdminPendingUsersSagaWatcher() {
  yield takeEvery(
    "API_CALL_GET_COUNTRY_ADMIN_PENDING_USERS_REQUEST",
    countryAdminPendingUsersSagaWorker
  );
}

export function* countryAdminAllUsersSagaWatcher() {
  yield takeEvery(
    "API_CALL_GET_COUNTRY_ADMIN_ALL_USERS_REQUEST",
    countryAdminAllUsersSagaWorker
  );
}
export function* countryAdminActionUserSagaWatcher() {
  yield takeEvery(
    "API_CALL_SET_COUNTRY_ADMIN_USER_ACTION_REQUEST",
    countryAdminActionUserSagaWorker
  );
}

export function* countryAdminAppoversSagaWatcher() {
  yield takeEvery(
    "API_CALL_GET_COUNTRY_ADMIN_APPROVERS_REQUEST",
    countryAdminApproverSagaWorker
  );
}

export function* countryAdminSchoolGroupAdminSagaWatcher() {
  yield takeEvery(
    "API_CALL_GET_COUNTRY_ADMIN_APPROVERS_REQUEST",
    countryAdminSchoolGroupAdminSagaWorker
  );
}

export function* currentUserSagaWatcher() {
  yield takeLatest("API_CALL_CURRENT_USER_REQUEST", currentUserSagaWorker);
}

export function* editUserSagaWatcher() {
  yield takeLatest("API_CALL_EDIT_USER_REQUEST", editUserSagaWorker);
}

function fetchGetUsers(action) {
  const url = `${process.env.REACT_APP_API_URL}/staff/?school=${action.schoolId}`;
  return axios({
    method: "GET",
    url: url,
  });
}

function* usersSagaWorker(schoolId) {
  try {
    const response = yield call(fetchGetUsers, schoolId);
    const data = response.data.results;

    yield put({ type: "API_CALL_GETUSERS_SUCCESS", data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "API_CALL_GETUSERS_FAILURE", error });
  }
}

function fetchGetCountryAdminPendingUsers(action) {
  const page_size = action.pageSize;
  const page = action.pageIndex * page_size - page_size;
  const filters = action.filters;
  const ordering = action.ordering.replace(/\./g, "_");

  const url =
    `${process.env.REACT_APP_API_URL}/users/pending` +
    `?limit=${page_size}&offset=${page}` +
    (ordering ? `&ordering=${ordering}` : "") +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            return `&${id}=${value}`;
          })
          .join("")
      : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function* countryAdminPendingUsersSagaWorker(payload) {
  try {
    const response = yield call(fetchGetCountryAdminPendingUsers, payload);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");
    const data = {
      ...response.data,
      results,
      ids,
    };

    yield put({
      type: "API_CALL_GET_COUNTRY_ADMIN_PENDING_USERS_SUCCESS",
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "API_CALL_GET_COUNTRY_ADMIN_PENDING_USERS_FAILURE",
      error,
    });
  }
}

function combineActiveStateFilters(isActiveFilter, stateFilter) {
  switch (stateFilter[0]?.value) {
    case undefined:
    case "all":
      if (isActiveFilter[0]?.value === "true") {
        return { id: "state", value: "approved" };
      } else if (isActiveFilter[0]?.value === "false") {
        return { id: "state", value: "inactive" };
      } else {
        return {};
      }
    case "approved":
      if (isActiveFilter[0]?.value === "true") {
        return { id: "state", value: "approved" };
      } else if (isActiveFilter[0]?.value === "false") {
        return { id: "state", value: "approved_deactivated" };
      } else {
        return { id: "state", value: "approved_activated_deactivated" };
      }
    case "rejected":
      if (isActiveFilter[0]?.value === "true") {
        return { id: "state", value: "rejected_active" };
      } else {
        return { id: "state", value: "rejected" };
      }
    case "new":
      if (isActiveFilter[0]?.value === "true") {
        return { id: "state", value: "new_isactive" };
      } else {
        return { id: "state", value: "new" };
      }
    default:
      return {};
  }
}

function getFormattedFilters(filters) {
  // Save the isActive and state Filter
  const isActiveFilter = filters.filter((filter) => filter.id === "isActive");
  const stateFilter = filters.filter((filter) => filter.id === "state");

  let filterArray = filters.filter(
    (filter) => filter.id !== "isActive" && filter.id !== "state"
  );

  // Combine filters for the BE
  // isActive Field on user model has been removed.
  // This allows for better user interace by keeping both filters.
  if (isActiveFilter.length > 0 || stateFilter.length > 0) {
    filterArray.push(combineActiveStateFilters(isActiveFilter, stateFilter));
  }
  return filterArray;
}

function fetchGetCountryAdminAllUsers(action) {
  const page_size = action.pageSize;
  const page = action.pageIndex * page_size - page_size;
  const filters = getFormattedFilters(action.filters);
  const ordering = action.ordering.replace(/\./g, "_");

  const url =
    `${process.env.REACT_APP_API_URL}/users` +
    `?limit=${page_size}&offset=${page}` +
    (ordering ? `&ordering=${ordering}` : "") +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            if (value && value !== "") {
              return `&${id}=${value}`;
            }
            return "";
          })
          .join("")
      : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function* countryAdminAllUsersSagaWorker(payload) {
  try {
    const response = yield call(fetchGetCountryAdminAllUsers, payload);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };

    yield put({
      type: "API_CALL_GET_COUNTRY_ADMIN_ALL_USERS_SUCCESS",
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "API_CALL_GET_COUNTRY_ADMIN_ALL_USERS_FAILURE",
      error,
    });
  }
}

function putCountryAdminActionUser(action) {
  const pk = action.userID;
  const perform = camelToSnakeCase(action.statusAction);
  const url = `${process.env.REACT_APP_API_URL}/users/${pk}/perform/${perform}/`;
  return axios({
    method: "PUT",
    url: url,
  });
}

function* countryAdminActionUserSagaWorker(payload) {
  try {
    const response = yield call(putCountryAdminActionUser, payload);
    const data = response.data;

    yield put({
      type: "API_CALL_SET_COUNTRY_ADMIN_USER_ACTION_SUCCESS",
      data,
    });
    yield put(
      successToast(
        toastFormattedMessages.find(
          (e) => e.name === `toast.${payload.statusAction}Success`
        ).label
      )
    );
    window.location.reload();
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "API_CALL_SET_COUNTRY_ADMIN_USER_ACTION_FAILURE",
      error,
    });
    yield put(
      errorToast(
        toastFormattedMessages.find(
          (e) => e.name === `toast.${payload.statusAction}Error`
        ).label
      )
    );
  }
}

function getCountryAdminApprover() {
  const url = `${process.env.REACT_APP_API_URL}/approvers/`;
  return axios({
    method: "GET",
    url: url,
    params: {
      limit: 99999,
    },
  });
}

function getCountryAdminSchoolGroupAdmin() {
  const url = `${process.env.REACT_APP_API_URL}/school-group-admins/`;
  return axios({
    method: "GET",
    url: url,
    params: {
      limit: 99999,
    },
  });
}

function* countryAdminApproverSagaWorker() {
  try {
    const response = yield call(getCountryAdminApprover);
    const data = response.data.results;

    yield put({
      type: "API_CALL_SET_COUNTRY_ADMIN_APPROVERS_SUCCESS",
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "API_CALL_SET_COUNTRY_ADMIN_APPROVERS_FAILURE",
      error,
    });
  }
}

function* countryAdminSchoolGroupAdminSagaWorker() {
  try {
    const response = yield call(getCountryAdminSchoolGroupAdmin);
    const data = response.data.results;

    yield put({
      type: "API_CALL_SET_COUNTRY_ADMIN_SCHOOL_GROUP_ADMINS_SUCCESS",
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "API_CALL_SET_COUNTRY_ADMIN_SCHOOL_GROUP_ADMINS_FAILURE",
      error,
    });
  }
}

function fetchGetUser(action) {
  const url = `${process.env.REACT_APP_API_URL}/users/${action.userId}/`;
  return axios({
    method: "GET",
    url: url,
  });
}

function* currentUserSagaWorker(action) {
  try {
    const response = yield call(fetchGetUser, action);
    const currentUser = response.data;
    yield put({ type: "API_CALL_CURRENT_USER_SUCCESS", currentUser });
  } catch (error) {
    const { history } = action;
    history.push(`/countryAdmin/users/`);
    yield put({ type: "API_CALL_CURRENT_USER_FAILURE", error });
  }
}

function putUser(action) {
  const { id, data } = action;
  const {
    email,
    last_name,
    other_names,
    has_raw_data_extraction_permission,
    ...remainingData
  } = data;
  const formattedData = {
    user: {
      email,
      last_name,
      other_names,
    },
    has_raw_data_extraction_permission,
    ...remainingData,
  };
  const url = `${process.env.REACT_APP_API_URL}/users/${id}/`;

  return axios(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(formattedData),
  });
}

function* editUserSagaWorker(action) {
  try {
    const { history, prevPath } = action;
    yield call(putUser, action);

    yield put({ type: "API_CALL_EDIT_USER_SUCCESS" });
    yield put(successToast());
    history.push(prevPath);
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "API_CALL_EDIT_USER_FAILURE", error });
    // Check for message
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}

export const getUsers = (state) => state.users.users;

export const getUserById = (state, userId) =>
  state.users.users.find((e) => e.user === userId);

export const getUserByUserId = (state, userId) =>
  state.users.users.find((e) => e.user.email === userId);

export const getCountryAdminPendingUsersResponse = (state) =>
  state.users.pending;

export const getCountryAdminAllUsersResponse = (state) =>
  state.users.allCountryUsers;

export const getAllUsers = (state) => ({
  ...state.users.allCountryUsers.results,
  ...state.users.pending.results,
});

export const getCountryAdminApprovers = (state) => state.users.approvers;

export const getUserIsFetching = (state) => state.users.fetching;

export const getUserApprovers = (state) => state.users.approvers;

export const getUserSchoolGroupAdmins = (state) =>
  state.users.schoolGroupAdmins;
