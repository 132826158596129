import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { Button, ModuleBody } from "@wfp/ui";

import TableExtended from "components/TableExtended";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

import "./school-report.module.scss";
import styles from "./school-report.module.scss";
import * as R from "ramda";

const ReportHeader = ({
  report: { year, month, school_year_name, comet_data },
}) => {
  const comet_id = R.pathOr(null, [0, "comet_code"], comet_data);
  const cometIdMessage =
    comet_id === null || comet_id === undefined
      ? "No comet id is linked to this report"
      : "Comet id:".concat(" ", comet_id);
  return (
    <ModuleBody noPadding>
      <TableExtended className={styles.table}>
        <thead>
          <tr>
            <td
              className={styles.headerData}
              style={{ textAlign: "center" }}
              colSpan={2}
            >
              <span className={styles.boldText}>
                <FormattedMessage
                  id="reportHeader.monthly_school_canteen_activities_report"
                  defaultMessage="MONTHLY SCHOOL CANTEEN ACTIVITIES REPORT"
                />{" "}
                <Button
                  kind="ghost"
                  disabled
                  className="disabled-button"
                  style={{ cursor: "default" }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <span className="tooltiptext">{cometIdMessage}</span>
                </Button>
              </span>
            </td>
          </tr>
          <tr>
            <td className={styles.headerData} colSpan={2}>
              <FormattedMessage
                id="reportHeader.to_be_completed_by_brackets"
                defaultMessage="(To be completed by the school administration and the Management
              Committee)"
              />
            </td>
          </tr>
          <tr>
            <td className={styles.headerData}>
              <FormattedMessage
                id="reportHeader.for_the_month_of"
                defaultMessage="For the month of"
              />
              :{" "}
              <span className={styles.underlineText}>
                {moment(`${year}/${month}/1`).format(`MMMM`)}
              </span>
            </td>
            <td className={styles.headerData}>
              <FormattedMessage
                id="reportHeader.school_year"
                defaultMessage="School Year"
              />
              : <span className={styles.underlineText}>{school_year_name}</span>
            </td>
          </tr>
        </thead>
      </TableExtended>
    </ModuleBody>
  );
};

export default ReportHeader;
