import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import {
  Button,
  Module,
  ModuleBody,
  Wrapper,
  TextInput,
  InlineLoading,
} from "@wfp/ui";

import {
  requestCountryAdminLevels,
  updateCountryAdminLevels,
  getCountryAdminLevels,
  getAdminLevelsIsFetching,
  getAdminLevelsError,
} from "data-handler/ducks/adminLevels";
import { getSelectedCountry } from "data-handler/ducks/countryProfile";
import { getCurrentUser } from "data-handler/ducks/auth";

import ConfirmationModal from "components/CountryAdmin/CountryAdminContent/ConfirmationModal";

import "./_content.scss";

const CountryAdminLevels = () => {
  const dispatch = useDispatch();
  const selectedCountry = useSelector(getSelectedCountry);
  const currentUser = useSelector(getCurrentUser);
  const countryAdminLevels = useSelector(getCountryAdminLevels);
  const isFetching = useSelector(getAdminLevelsIsFetching);
  const error = useSelector(getAdminLevelsError);

  const [editMode, setEditMode] = useState(false);
  const [confirmationModalState, setConfirmationModalState] = useState(false);

  const submitBtnRef = useRef();

  const methods = useForm();
  const { handleSubmit, register, errors, control, reset } = methods;

  const { fields, remove, insert } = useFieldArray({
    control,
    name: "adminLevels",
  });

  const invalidStyle = { borderColor: "#c5192d" };
  const mandatoryLevels = [1, 4];

  useEffect(() => {
    if (selectedCountry?.id) {
      dispatch(requestCountryAdminLevels(selectedCountry.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, currentUser]);

  const optionalLevels = () => {
    // Returns an array with the optional levels
    let optionalLevelsArray = [];
    if (mandatoryLevels.length > 0) {
      let minLevel = mandatoryLevels[0];
      let maxLevel = mandatoryLevels[0];
      for (let index in mandatoryLevels) {
        const level = mandatoryLevels[index];
        if (level <= minLevel) {
          minLevel = level;
        }
        if (level >= maxLevel) {
          maxLevel = level;
        }
      }
      for (let level = minLevel; level <= maxLevel; level++) {
        if (!fields.find((item) => item.level === level)) {
          optionalLevelsArray.push(level);
        }
      }
      return optionalLevelsArray;
    } else {
      return [];
    }
  };

  function insertLevel(adminLevel) {
    // Insert a level at the correct position
    const insertPosition = fields.indexOf(
      fields.find((object) => object.level > adminLevel)
    );
    insert(insertPosition !== -1 ? insertPosition : fields.length, {
      level: adminLevel,
      labelText: mandatoryLevels.includes(adminLevel) ? (
        <FormattedMessage
          id="countryProfile.adminLevelRequired"
          defaultMessage="Admin {level} (required)"
          values={{ level: adminLevel.toString() }}
        />
      ) : (
        <FormattedMessage
          id="countryProfile.adminLevel"
          defaultMessage="Admin {level}"
          values={{ level: adminLevel.toString() }}
        />
      ),
      defaultValue: "",
    });
  }

  useEffect(() => {
    // Create the admin fields array from the existing admin fields
    let formFields = [];
    if (countryAdminLevels.length !== 0) {
      formFields = countryAdminLevels.map((item) => ({
        id: item.level,
        level: item.level,
        labelText: mandatoryLevels.includes(item.level) ? (
          <FormattedMessage
            id="countryProfile.adminLevelRequired"
            defaultMessage="Admin {level} (required)"
            values={{ level: item.level.toString() }}
          />
        ) : (
          <FormattedMessage
            id="countryProfile.adminLevel"
            defaultMessage="Admin {level}"
            values={{ level: item.level.toString() }}
          />
        ),
        defaultValue: item.name,
      }));
      setEditMode(false);
    }
    for (let index in mandatoryLevels) {
      // If there are any mandatory levels missing, add them
      const level = mandatoryLevels[index];
      if (!formFields.find((item) => item.level === level)) {
        const insertPosition = formFields.indexOf(
          formFields.find((object) => object.level > level)
        );
        formFields.splice(
          insertPosition !== -1 ? insertPosition : formFields.length,
          0,
          ...[
            {
              id: level,
              level: level,
              labelText: (
                <FormattedMessage
                  id="countryProfile.adminLevelRequired"
                  defaultMessage="Admin {level} (required)"
                  values={{ level: level.toString() }}
                />
              ),
              defaultValue: "",
            },
          ]
        );
        // Set edit mode to true so that the user is forced
        // to add values for the missing mandatory levels
        setEditMode(true);
      }
    }
    reset({ adminLevels: formFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryAdminLevels, error]);

  const onSubmit = (data) => {
    const adminLevelsToDelete = countryAdminLevels.filter(
      (item) => !Object.keys(data).includes(item.level.toString())
    );
    const adminLevelsToUpdate = countryAdminLevels
      .filter(
        (item) =>
          Object.keys(data).includes(item.level.toString()) &&
          data[item.level] !== item.name
      )
      .map((item) => ({ ...item, name: data[item.level] }));
    const adminLevelsToCreate = Object.keys(data)
      .filter(
        (item) =>
          !countryAdminLevels.find((object) => object.level.toString() === item)
      )
      .map((level) => ({
        level: parseInt(level),
        name: data[level],
      }));
    const submitData = {
      adm_levels_to_delete: adminLevelsToDelete,
      adm_levels_to_update: adminLevelsToUpdate,
      adm_levels_to_create: adminLevelsToCreate,
      country: selectedCountry?.id,
    };
    dispatch(updateCountryAdminLevels(submitData));
    setEditMode(false);
  };

  // In order to submit form data. Hidden button is created within the form.
  // As the primary button within the modal cannot be set to type="submit"
  // When the modal submit button is clicked. This subsequently clicks the designated form submit button.
  const onConfirmationModalSubmit = () => {
    setConfirmationModalState(false);
    submitBtnRef.current.click();
  };

  if (isFetching) {
    return (
      <div className="inlineLoading">
        <InlineLoading />
      </div>
    );
  }

  return (
    <div className="admin-levels-page">
      <Wrapper
        pageWidth="sm"
        spacing="md"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleBody>
            <div className="country-admin-levels-form">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {fields.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        className="country-admin-levels-form-row"
                      >
                        <TextInput
                          name={item.level}
                          id={item.id}
                          labelText={item.labelText}
                          disabled={!editMode}
                          style={
                            errors && errors[item.level] ? invalidStyle : {}
                          }
                          defaultValue={item.defaultValue}
                          inputRef={register({
                            required: true,
                          })}
                        />

                        <div className="remove-button">
                          {editMode && !mandatoryLevels.includes(item.level) && (
                            <Button
                              small
                              kind="ghost"
                              onClick={() => remove(index)}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="country-admin-levels-buttons-container">
                    {editMode ? (
                      <div className="save-button">
                        <Button
                          kind="primary"
                          onClick={() => setConfirmationModalState(true)}
                        >
                          <FormattedMessage
                            id="SchoolLevelsMappingEdit.submitButton"
                            defaultMessage="Save"
                          />
                        </Button>
                        {/* Fake Button to submit form data 
                          Check onConfirmationModalSubmit
                          That function controls this button.                  
                        */}
                        <button
                          type="submit"
                          className="hidden-btn"
                          ref={submitBtnRef}
                        />
                      </div>
                    ) : (
                      <Button
                        kind="primary"
                        onClick={() => {
                          setEditMode(true);
                        }}
                      >
                        <FormattedMessage
                          id="countryProfile.editButton"
                          defaultMessage="Edit"
                        />
                      </Button>
                    )}
                    {editMode && (
                      <div className="add-admin-levels-container">
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={optionalLevels().map((adminLevel) => ({
                            value: adminLevel,
                            label: (
                              <FormattedMessage
                                id="countryProfile.level"
                                defaultMessage="Level {level}"
                                values={{ level: adminLevel.toString() }}
                              />
                            ),
                          }))}
                          value={{
                            value: -1,
                            label: (
                              <FormattedMessage
                                id="countryProfile.addLevel"
                                defaultMessage="Add level"
                              />
                            ),
                          }}
                          isSearchable={false}
                          isDisabled={!optionalLevels().length > 0}
                          onChange={(e) => {
                            insertLevel(e.value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <ConfirmationModal
                    confirmationModalState={confirmationModalState}
                    modalType={"submit"}
                    setConfirmationModalState={setConfirmationModalState}
                    onConfirmationModalSubmit={onConfirmationModalSubmit}
                    submitMessage={
                      <FormattedMessage
                        id="countyProfile.adminLevelsConfirmation"
                        defaultMessage="Are you sure you want to submit the current admin levels?"
                      />
                    }
                  />
                </form>
              </FormProvider>
            </div>
          </ModuleBody>
        </Module>
      </Wrapper>
    </div>
  );
};

export default CountryAdminLevels;
