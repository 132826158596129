/**
 * Messages formatted according to ICU Message Syntax
 *
 * For more information, see:
 * - https://formatjs.io/docs/react-intl/
 * - http://userguide.icu-project.org/formatparse/messages
 *
 * Message id convention: semanticCategory.specificName
 * You can use placeholders using curly braces ({variable})
 */

import fr from "./fr.json";
import en from "./en.json";
import pt from "./pt.json";
import es from "./es.json";
import ar from "./ar.json";
import uk from "./uk.json";
import so from "./so.json";

export default {
  fr,
  en,
  pt,
  es,
  ar,
  uk,
  so,
};
