import React from "react";

import {
  Attendance,
  SchoolYear,
  AggregateSchoolYearLevel,
} from "data-handler/ducks/stores";
import TableExtended from "components/TableExtended";
import LevelTitle from "components/LevelTitle";
import { studentKinds, StudentKind } from "SCConstants";
import { getFilteredAttendanceTotal } from "helpers/attendance";
import { FormattedMessage } from "react-intl";
import { displayStaffLevel } from "components/Attendance/AttendanceContent";

const AttendanceTableDesktop = ({
  attendance,
  schoolYear,
  schoolYearLevels,
  category,
}: {
  attendance: Attendance;
  schoolYear: SchoolYear;
  schoolYearLevels: AggregateSchoolYearLevel[];
  category: string;
}) => {
  if (attendance?.levels === undefined) {
    return <> No Levels </>;
  }

  const morningLevels = attendance.levels.filter(
    (level: { shift: string }) => level.shift === "morning"
  );
  const afternoonLevels = attendance.levels.filter(
    (level: { shift: string }) => level.shift === "afternoon"
  );

  const showMorning = morningLevels.length !== 0;
  const showAfternoon =
    !schoolYear.has_same_students_in_both_shifts &&
    afternoonLevels.length !== 0;

  // Join morning and afternoon into single rows
  const levels = new Set<string>();
  morningLevels.forEach((level) => levels.add(level.level));
  afternoonLevels.forEach((level) => levels.add(level.level));
  const collator = new Intl.Collator([], { numeric: true });
  const perLevelData = [...levels].sort(collator.compare).map((level) => {
    return {
      level,
      morning: morningLevels.find(
        (l) => l.level === level && l.shift === "morning"
      ),
      afternoon: afternoonLevels.find(
        (l) => l.level === level && l.shift === "afternoon"
      ),
    };
  });

  const rows = perLevelData.map((levelData) => (
    <tr key={levelData.level}>
      <td className="wfp--table__title">
        <LevelTitle level={levelData} />
      </td>
      {showMorning &&
        studentKinds.map((kind) => (
          <td className="wfp--table__show-value">
            {levelData.morning === undefined
              ? 0
              : levelData.morning[kind.key as "male" | "female"]}
          </td>
        ))}
      {showAfternoon &&
        studentKinds.map((kind) => (
          <td className="wfp--table__show-value">
            {levelData.afternoon === undefined
              ? 0
              : levelData.afternoon[kind.key as "male" | "female"]}
          </td>
        ))}
      <td className="wfp--table__show-total-value">
        {getFilteredAttendanceTotal({
          attendance,
          levels: [levelData.level],
        })}
      </td>
    </tr>
  ));
  const allTotalRows = displayStaffLevel(schoolYearLevels).map((row, i) => (
    <tr key={row.key} className={i === 0 ? "diff" : ""}>
      <td className="wfp--table__title">{row.title}</td>
      {showMorning &&
        studentKinds.map((kind) => (
          <td className="wfp--table__total">
            {getFilteredAttendanceTotal({
              attendance,
              levels: row.levelValues,
              kinds: [kind.value as StudentKind],
              shifts: ["morning"],
            })}
          </td>
        ))}
      {showAfternoon &&
        studentKinds.map((kind) => (
          <td className="wfp--table__total">
            {getFilteredAttendanceTotal({
              attendance,
              levels: row.levelValues,
              kinds: [kind.value as StudentKind],
              shifts: ["afternoon"],
            })}
          </td>
        ))}
      <td className="wfp--table__total">
        {studentKinds.reduce((acc, kind) => {
          const total = getFilteredAttendanceTotal({
            attendance,
            levels: row.levelValues,
            kinds: [kind.value as StudentKind],
            shifts: ["afternoon", "morning"],
          });
          acc = acc + total;
          return acc;
        }, 0)}
      </td>
    </tr>
  ));

  return (
    <TableExtended noBorder>
      <thead>
        <tr>
          <th></th>
          {showMorning && (
            <th colSpan={studentKinds.length}>
              <FormattedMessage
                id="AttendanceShow.morning"
                defaultMessage="Morning"
              />
            </th>
          )}
          {showAfternoon && (
            <th colSpan={studentKinds.length}>
              <FormattedMessage
                id="AttendanceShow.afternoon"
                defaultMessage="Afternoon"
              />
            </th>
          )}
          <th></th>
        </tr>
        <tr>
          <th></th>
          {showMorning &&
            studentKinds.map((kind) => <th>{kind.labelTrans}</th>)}
          {showAfternoon &&
            studentKinds.map((kind) => <th>{kind.labelTrans}</th>)}
          <th>
            <FormattedMessage
              id="AttendanceShow.total"
              defaultMessage="Total"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows}
        {allTotalRows}
      </tbody>
    </TableExtended>
  );
};

export default AttendanceTableDesktop;
