import React from "react";
import { FormattedMessage } from "react-intl";

import { Modal } from "@wfp/ui";
import "./_modal-extended.scss";

type ModalExtendedProps = {
  children: React.ReactChild;
  className?: string;
  open?: any;
  onRequestSubmit?: () => void;
  onRequestClose: () => void;
  modalLabel: React.ReactNode;
  modalHeading: React.ReactNode;
  passiveModal?: boolean;
  wide?: boolean;
  primaryButtonText?: React.ReactNode;
  secondaryButtonText?: React.ReactNode;
  selectorPrimaryFocus?: boolean;
  modalFooter?: React.ComponentType | undefined;
  primaryButtonDisabled?: boolean;
};

const ModalExtended = (props: ModalExtendedProps) => {
  return (
    <Modal
      secondaryButtonText={
        <FormattedMessage id="ModalExtended.cancel" defaultMessage="Cancel" />
      }
      {...props}
      open
      selectorPrimaryFocus={false}
      className={`modal-extended ${props.className || ""}`}
    />
  );
};

ModalExtended.propTypes = {};

ModalExtended.defaultProps = {
  wide: true,
};

export default ModalExtended;
