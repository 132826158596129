import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import {
  TextInput,
  Checkbox,
  Input,
  RadioButton,
  RadioButtonGroup,
} from "@wfp/ui";
import Select from "react-select";
import {
  getVendorCategories,
  getAllApprovedVendors,
} from "data-handler/ducks/vendors";
import { useSelector } from "react-redux";
import "./_cash-to-school.scss";

export function vendorOptions(list) {
  return list.map((e) => {
    return {
      value: e.id,
      label: e.name,
    };
  });
}
// filter by selected vendor category
function filterVendorListBySelectedCategory(id, list) {
  return list.filter((item) => id === item.vendor_category.id);
}
// filter by selected vendor category and vendor name
function filterVendorListBySelectedCategoryAndVendor(
  vendorId,
  vendorCategoryId,
  list
) {
  return list.filter(
    (item) =>
      vendorId === item.vendor_category.id && vendorCategoryId === item.id
  );
}

const VendorEdit = ({
  control,
  watch,
  errors,
  setValue,
  getValues,
  register,
  purchaseCurrentStoreData,
}) => {
  const intl = useIntl();

  const isInfoProvided =
    purchaseCurrentStoreData?.purchase_info ||
    purchaseCurrentStoreData?.vendor_name ||
    purchaseCurrentStoreData?.vendor;

  const [infoProvided, setInfoProvided] = useState(isInfoProvided);
  const [refreshKey, setRefreshKey] = useState(new Date().valueOf());

  const vendorList = useSelector(getAllApprovedVendors);
  const vendorCategories = useSelector(getVendorCategories);

  const selectedVendorCategory = watch(`vendor_category`);
  const selectedVendor = watch(`vendor`);

  const vendorNameOptions = vendorOptions(
    filterVendorListBySelectedCategory(
      selectedVendorCategory,
      vendorList.results
    )
  );
  const vendorCategoriesOptions = vendorOptions(
    Object.values(vendorCategories.results)
  );

  const ref_number = filterVendorListBySelectedCategoryAndVendor(
    selectedVendorCategory,
    selectedVendor,
    vendorList.results
  ).find((i) => i.ref_number);
  const ref_number_auto = ref_number?.ref_number;

  const selectedInfo = watch("purchase_info");
  const vendorNameCreated = watch("is_vendor_created");

  const vendorNameSelection = [
    {
      value: "create",
      label: (
        <FormattedMessage
          id="PurchaseEdit.createVendorLabel"
          defaultMessage="Create vendor"
        />
      ),
    },
    {
      value: "existing",
      label: (
        <FormattedMessage
          id="PurchaseEdit.useExistingVendorLabel"
          defaultMessage="Use existing vendor"
        />
      ),
    },
  ];

  const requiredLabelVendor = (
    <div
      className="wfp--form-required"
      styles={{ borderColor: " 2px solid #C5192D" }}
    >
      <FormattedMessage
        id="PurchaseEdit.vendorError"
        defaultMessage=" *Select vendor"
      />
    </div>
  );

  useEffect(() => {
    setRefreshKey(new Date().valueOf());
    setValue(`vendor`, null);
    vendorNameCreated
      ? setValue(`vendor`, null) && setValue(`ref_number`, "")
      : setValue(`vendor_name`, "") && setValue(`ref_number_auto`, "");
  }, [selectedVendorCategory, setValue, getValues, vendorNameCreated]);

  return (
    <div className="vendorContainer">
      <div className="container">
        <Controller
          render={({ onChange, value }) => {
            return (
              <div className="commodity-select__input">
                <Input
                  labelText={
                    <FormattedMessage
                      id="PurchaseEdit.vendorType"
                      defaultMessage="Vendor type"
                    />
                  }
                >
                  {() => (
                    <Select
                      options={vendorCategoriesOptions}
                      className="wfp--react-select-container"
                      classNamePrefix="wfp--react-select"
                      defaultValue={value}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      rules={{
                        required: intl.formatMessage({
                          id: "PurchaseEdit.vendorError",
                          defaultMessage: "*Select vendor",
                        }),
                      }}
                      invalid={errors[`vendor_category`]}
                      invalidText={errors[`vendor_category`]?.message}
                    />
                  )}
                </Input>{" "}
              </div>
            );
          }}
          name={`vendor_category`}
          placeholder="Select ..."
          control={control}
        />
      </div>{" "}
      {errors[`vendor_category`] && requiredLabelVendor}
      <div className="checkboxVendor">
        <label className="wfp--label">
          <FormattedMessage
            id="PurchaseEdit.vendorTypeInfo"
            defaultMessage="Provide further information (optional)"
          />{" "}
        </label>
        <Controller
          render={({ onChange }) => (
            <Checkbox
              className="checkbox"
              id="purchase_info"
              onChange={(e) => {
                onChange(e.target.checked);
                setInfoProvided(e.target.checked);
              }}
              value={selectedInfo}
              checked={selectedInfo}
              inputRef={register}
            />
          )}
          id="purchase_info"
          name={`purchase_info`}
          defaultValue={infoProvided}
          control={control}
          invalid={errors[`purchase_info`]}
          invalidText={errors[`purchase_info`]?.message}
        />
      </div>
      <div className={selectedInfo ? "container" : "disabled"}>
        <RadioButtonGroup
          labelText={intl.formatMessage({
            id: "PurchaseEdit.vendor_name",
            defaultMessage: "Create or use existing vendor?",
          })}
          name="vendorNameCreated"
        >
          {vendorNameSelection.map((vendorNameCreated) => (
            <RadioButton
              key={"is_vendor_created"}
              labelText={vendorNameCreated.label}
              id={`is_vendor_created-${vendorNameCreated.value}`}
              name="is_vendor_created"
              value={vendorNameCreated.value}
              onChange={() => setRefreshKey(new Date().valueOf())}
              ref={register({ required: selectedInfo ? true : false })}
              invalid={errors[`is_vendor_created`]}
              invalidText={errors[`is_vendor_created`]?.message}
            />
          ))}
        </RadioButtonGroup>
      </div>
      <div className="container">
        <div
          className={
            vendorNameCreated === "create" && selectedInfo
              ? "container"
              : "disabled"
          }
        >
          <Controller
            key={refreshKey}
            className="vendor"
            as={
              <TextInput
                labelText={
                  <FormattedMessage
                    id="PurchaseEdit.createVendorName"
                    defaultMessage="Create Vendor name"
                  />
                }
              ></TextInput>
            }
            name={`vendor_name`}
            placeholder=""
            control={control}
            rules={{
              required:
                vendorNameCreated === "create" && selectedInfo
                  ? "Select vendor name"
                  : false,
            }}
            invalid={errors[`vendor_name`]}
            invalidText={errors[`vendor_name`]?.message}
          />
        </div>
        <div
          className={
            vendorNameCreated === "existing" && selectedInfo
              ? "container"
              : "disabled"
          }
        >
          <Controller
            key={refreshKey}
            render={({ onChange, value }) => {
              return (
                <Input
                  labelText={
                    <FormattedMessage
                      id="PurchaseEdit.VendorNameCreated"
                      defaultMessage="Vendor name"
                    />
                  }
                >
                  {() => (
                    <Select
                      className="wfp--react-select-container"
                      options={vendorNameOptions}
                      control={control}
                      onChange={(e) => {
                        onChange(e.value);
                        setValue("vendor_name", "");
                      }}
                      rules={{
                        required:
                          vendorNameCreated === "existing" && selectedInfo
                            ? "Select vendor name"
                            : false,
                      }}
                      defaultValue={
                        vendorNameCreated === "existing" ? value : null
                      }
                    />
                  )}
                </Input>
              );
            }}
            name={`vendor`}
            invalid={errors[`vendor`]}
            invalidText={errors[`vendor`]?.message}
          />
        </div>
        <div
          className={
            selectedInfo && vendorNameCreated === "create"
              ? "container"
              : "disabled"
          }
        >
          <Controller
            as={
              <TextInput
                labelText={
                  <>
                    <FormattedMessage
                      id="PurchaseEdit.referenceNumber"
                      defaultMessage="Reference number (optional)"
                    />
                  </>
                }
              />
            }
            inputRef={register}
            name={`ref_number`}
            placeholder=""
            control={control}
          />
        </div>
        <div
          className={
            selectedInfo && vendorNameCreated === "existing"
              ? "container"
              : "disabled"
          }
        >
          <Controller
            key={refreshKey}
            render={({ onChange }) => {
              return (
                <Input
                  labelText={
                    <FormattedMessage
                      id="PurchaseEdit.referenceNumber"
                      defaultMessage="Reference number (optional)"
                    />
                  }
                >
                  {() => (
                    <TextInput
                      defaultValue={ref_number_auto}
                      value={ref_number_auto}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      name={
                        vendorNameCreated === "existing"
                          ? "ref_number_auto"
                          : "ref_number"
                      }
                      placeholder=""
                      control={control}
                      inputRef={register}
                      disabled={vendorNameCreated === "existing" ? true : false}
                    />
                  )}
                </Input>
              );
            }}
            name={`ref_number_auto`}
            placeholder=""
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

export default VendorEdit;
