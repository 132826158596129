import React from "react";

import { Pagination as WfpPagination } from "@wfp/ui";
import { FormattedMessage, useIntl } from "react-intl";

type PaginationProps = {
  pageSizes: number[];
  page?: number;
  pageSize?: number;
  totalItems?: number;
};

const Pagination: React.ElementType<PaginationProps> = (props) => {
  const intl = useIntl();
  return (
    <WfpPagination
      backwardText={intl.formatMessage({
        id: "pagination.backwardText",
        defaultMessage: "Backwards",
      })}
      forwardText={intl.formatMessage({
        id: "pagination.forwardText",
        defaultMessage: "Forwards",
      })}
      itemsPerPageText={intl.formatMessage({
        id: "pagination.itemPerPage",
        defaultMessage: "Items per page",
      })}
      pageNumberText={intl.formatMessage({
        id: "pagination.pageNumber",
        defaultMessage: "Page number",
      })}
      itemRangeText={(min: number, max: number, total: number) => (
        <FormattedMessage
          id="pagination.itemsNumber"
          defaultMessage="{min} to {max} of {total} items"
          values={{
            min,
            max,
            total,
          }}
        />
      )}
      pageRangeText={(current: number, total: number) => (
        <FormattedMessage
          id="pagination.pagesNumber"
          defaultMessage="{current} of {total} pages"
          values={{
            current,
            total,
          }}
        />
      )}
      {...props}
    />
  );
};

export default Pagination;
