import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { Blockquote, InlineLoading, TextArea } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";

import { getCurrentUser } from "data-handler/ducks/auth";

import reportsDuck from "data-handler/ducks/reports";
import useReportAction from "./useReportAction";

import { Controller, FormProvider } from "react-hook-form";
import { getCurrentSchoolYear } from "../../data-handler/ducks/stores";

const MODAL_LABEL = (
  <FormattedMessage id="ReportActionApprove.label" defaultMessage="Reporting" />
);
const MODAL_HEADING = (period) => (
  <FormattedMessage
    id="ReportActionApprove.heading"
    defaultMessage="Approve Report {period}"
    values={{ period }}
  />
);
const BUTTON_LABEL = (
  <FormattedMessage
    id="ReportActionApprove.buttonLabel"
    defaultMessage="Approve"
  />
);
const EXPLANATION_1 = (
  <FormattedMessage
    id="ReportActionApprove.explanation1"
    defaultMessage="After Approval, report data will be sent to all recipients. The report content cannot be changed after this point."
  />
);
const EXPLANATION_2 = (
  <FormattedMessage
    id="ReportActionApprove.explanation2"
    defaultMessage="By pressing Approve, you confirm that the report data is consistent and complete."
  />
);
const APPROVING_REPORT_AS = (
  <FormattedMessage
    id="ReportActionApprove.whichUser"
    defaultMessage="Approving report as"
  />
);
const COMMENTS_FIELD_LABEL = (
  <FormattedMessage
    id="ReportActionApprove.commentsLabel"
    defaultMessage="Comments"
  />
);
const REPORT_APPROVAL_WARNING = (
  <FormattedMessage
    id="ReportActionApprove.approvalWarningMessage"
    defaultMessage="Some of the previous months' reports are not approved, therefore recorded data may be inaccurate."
  />
);

const ReportModalApprove = ({ report }) => {
  const currentUser = useSelector(getCurrentUser);
  const reports = useSelector(reportsDuck.getList);
  const currentReportStartDate = `${report.year}/${report.month}/${report.start_day}`;
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  // Get all the previous unapproved reports and sort them from newest to oldest
  const previousUnapprovedReports = reports
    .filter((item) => {
      const previousDate = moment(
        `${item.year}/${item.month}/${item.start_day}`
      );
      return (
        previousDate.isBefore(currentReportStartDate) &&
        previousDate.isSameOrAfter(currentSchoolYear?.starts_on) &&
        item.state !== "approved"
      );
    })
    .sort((a, b) =>
      moment(`${b.year}/${b.month}/${b.start_day}`).isAfter(
        `${a.year}/${a.month}/${a.start_day}`
      )
        ? 1
        : -1
    );

  // do not allow the current report to be approved
  // if there are previous unapproved reports,
  const canApproveReport = previousUnapprovedReports.length > 0 ? false : true;

  const {
    actionFeedback,
    closeModal,
    performAction,
    isPerforming,
    period,
    form,
  } = useReportAction({
    report,
    action: "approve",
  });

  const previousUnapprovedReportsDates = previousUnapprovedReports.map(
    (item) => {
      const month = moment(
        `${item.year}/${item.month}/${item.start_day}`
      ).format("MMMM");
      return `${month} ${item.year}`;
    }
  );

  return (
    <ModalExtended
      onRequestSubmit={performAction}
      onRequestClose={closeModal}
      modalLabel={MODAL_LABEL}
      modalHeading={MODAL_HEADING(period)}
      primaryButtonText={isPerforming ? <InlineLoading /> : BUTTON_LABEL}
    >
      {!canApproveReport && (
        <Blockquote kind="warning" title={REPORT_APPROVAL_WARNING}>
          <p>
            <FormattedMessage
              id="ReportActionApprove.unaprovedMonths"
              defaultMessage="{dates}"
              values={{ dates: previousUnapprovedReportsDates.join(", ") }}
            />
          </p>
        </Blockquote>
      )}
      {actionFeedback}
      <Blockquote title={APPROVING_REPORT_AS}>
        {currentUser.other_names} {currentUser.last_name}
      </Blockquote>
      <div style={{ marginTop: 16 }}>{EXPLANATION_1}</div>
      <div style={{ marginTop: 16, marginBottom: 16 }}>{EXPLANATION_2}</div>
      <FormProvider {...form}>
        <form>
          <div className="wfp--form wfp--form-long">
            <Controller
              as={<TextArea labelText={COMMENTS_FIELD_LABEL} />}
              defaultValue=""
              name={`comments`}
            />
          </div>
        </form>
      </FormProvider>
    </ModalExtended>
  );
};

export default ReportModalApprove;
