import React from "react";

import "./_sub-title.scss";
import classnames from "classnames";

type SubtitleProps = {
  className?: string;
  additional?: React.ReactNode;
  small?: boolean;
};
const SubTitle: React.ComponentType<SubtitleProps> = ({
  className,
  children,
  additional,
  small,
}) => {
  const classes = classnames(
    "sub-title",
    {
      "sub-title--small": small,
    },
    className
  );
  return (
    <h3 className={classes}>
      {children && <span className="sub-title--children">{children}</span>}
      <span className="sub-title--additional">{additional}</span>
    </h3>
  );
};

SubTitle.propTypes = {};

export default SubTitle;
