import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";

import attendanceIcon from "images/Moving_van.svg";
import Empty from "components/Empty";
import { updateUser } from "data-handler/ducks/localAuth";
import { FAILURE } from "data-handler/ducks/auth";
import {
  getIsPrincipal,
  getIsStoreKeeper,
  getIsSchoolGroupAdmin,
  getIsTeacher,
} from "helpers/users";

function LoginRedirect() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const search = location.search;
  const params = new URLSearchParams(search);
  const code = params.get("code");
  const code_verifier = localStorage.getItem("code_verifier");

  useEffect(() => {
    // if it's a CIAM redirect, send a request to api endpoint to get token
    if (code && code_verifier) {
      localStorage.removeItem("code_verifier");
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_CIAM_API_URL}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          code: code,
          code_verifier: code_verifier,
        },
      })
        .then((response) => {
          params.delete("code");
          if (response.data && response.data.registration) {
            history.push({
              pathname: "/registration",
              state: { data: response.data },
            });
          } else {
            dispatch({ type: "LOGIN_CIAM", data: response.data });
            if (!response.data.user) {
              history.push({ pathname: "/userNotActive" });
            }
            const user = response.data.user;
            // TODO: better role naming and handling in backend implement role based access control
            const role = getIsSchoolGroupAdmin(user)
              ? "school_group_admin"
              : getIsPrincipal(user)
              ? "principal_at"
              : getIsTeacher(user)
              ? "teacher_at"
              : getIsStoreKeeper(user)
              ? "store_keeper_at"
              : undefined;

            // Handle local auth users if role is defined
            if (role) {
              dispatch(
                updateUser({
                  id: response.data.user.id,
                  values: {
                    ...response.data.user,
                    role: role,
                    isCiamUser: true,
                    token: "123",
                  }, //TODO: use something different as token
                })
              );
            }

            history.push({ pathname: "/" });
          }
        })
        .catch((error) => {
          console.log("error", error, error.response);
          if (error.response.status === 403) history.push({ pathname: "/" });
          dispatch({ type: FAILURE, error: error });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Empty
      title={
        <FormattedMessage
          id="Login.waitASecond"
          defaultMessage="Wait a second..."
        />
      }
      kind="large"
      icon={
        <img
          alt="attendance empty illustration a bag of rice"
          src={attendanceIcon}
          style={{
            marginLeft: "1%",
            marginRight: "3%",
            marginBottom: "1%",
          }}
        />
      }
    >
      <FormattedMessage
        id="Login.processingRequest"
        defaultMessage="We are processing your request."
      />
      <br />
      <Link to="/">
        <FormattedMessage id="Login.goBackToLogin" defaultMessage="Go back" />
      </Link>
    </Empty>
  );
}

export default LoginRedirect;
