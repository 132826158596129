import React from "react";
import { useIntl } from "react-intl";

import { Modal } from "@wfp/ui";

const SetSchoolStateModal = ({
  schoolStateOpen,
  submitNewSchoolState,
  setSchoolStateModalOpen,
  selectedState,
  reportSubmittedCheckSelectedSchools,
}) => {
  const intl = useIntl();
  const schoolNamesListString = reportSubmittedCheckSelectedSchools.reduce(
    (accumulator, item) =>
      item?.hasUnsubmittedReports
        ? accumulator + "   \u2022 " + item?.schoolName + "\n"
        : accumulator + "",
    "\n"
  );
  const label =
    selectedState === "active"
      ? !reportSubmittedCheckSelectedSchools.every(
          (element) => element === null
        )
        ? intl.formatMessage(
            {
              id: "schools.activateConfirmationUnsubmittedReports",
              defaultMessage:
                "The following schools still have unsubmitted reports:{schoolNames}Are you sure you want to deactivate them?",
            },
            {
              schoolNames: schoolNamesListString,
            }
          )
        : intl.formatMessage({
            id: "schools.activateConfirmation",
            defaultMessage:
              "Are you sure you want to deactivate these schools?",
          })
      : intl.formatMessage({
          id: "schools.deactivateConfirmation",
          defaultMessage: "Are you sure you want to activate these schools?",
        });
  return (
    <Modal
      open={schoolStateOpen}
      modalHeading={
        selectedState === "active"
          ? intl.formatMessage({
              id: "schools.dectivateSchoolState",
              defaultMessage: "Deactivate School",
            })
          : intl.formatMessage({
              id: "schools.activateSchoolState",
              defaultMessage: "Activate School",
            })
      }
      primaryButtonText={intl.formatMessage({
        id: "Report.actionSubmit",
        defaultMessage: "Submit",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      onRequestSubmit={submitNewSchoolState}
      onSecondarySubmit={() => setSchoolStateModalOpen(false)}
      onRequestClose={() => setSchoolStateModalOpen(false)}
      shouldSubmitOnEnter={true}
    >
      <p style={{ whiteSpace: "pre" }}>{label}</p>
    </Modal>
  );
};

export default SetSchoolStateModal;
