import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Controller, FormProvider } from "react-hook-form";

import { Blockquote, InlineLoading, TextArea } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";

import { getCurrentUser } from "data-handler/ducks/auth";

import useReportAction from "./useReportAction";

const MODAL_LABEL = (
  <FormattedMessage id="ReportActionReject.label" defaultMessage="Reporting" />
);
const MODAL_HEADING = (period) => (
  <FormattedMessage
    id="ReportActionReject.heading"
    defaultMessage="Reject Report {period}"
    values={{ period }}
  />
);
const BUTTON_LABEL = (
  <FormattedMessage
    id="ReportActionReject.buttonLabel"
    defaultMessage="Reject"
  />
);
const EXPLANATION_1 = (
  <FormattedMessage
    id="ReportActionReject.explanation1"
    defaultMessage="Rejecting this report requires the school principal to amend the report, correct the data and submit again."
  />
);
const EXPLANATION_2 = (
  <FormattedMessage
    id="ReportActionReject.explanation2"
    defaultMessage='To return the report for corrections by the school, press "Reject".'
  />
);
const REJECTING_REPORT_AS = (
  <FormattedMessage
    id="ReportActionReject.whichUser"
    defaultMessage="Rejecting report as"
  />
);

const COMMENTS_FIELD_LABEL = (
  <FormattedMessage
    id="ReportActionReject.commentsLabel"
    defaultMessage="Reason for rejecting is mandatory (max 500 characters) "
  />
);

const ERROR_COMMENTS_FIELD_MANDATORY = (
  <FormattedMessage
    id="ReportActionReject.errorCommentsFieldIsMandatory"
    defaultMessage="Reason for rejecting is mandatory when rejecting"
  />
);

const ReportModalReject = ({ report }) => {
  const currentUser = useSelector(getCurrentUser);
  const maxCharacters = 500;
  const rejectActionFormResolver = (values) => {
    const errors = {};
    if (!values.comments) {
      errors["comments"] = { message: ERROR_COMMENTS_FIELD_MANDATORY };
    }
    return {
      values,
      errors,
    };
  };

  const {
    actionFeedback,
    closeModal,
    performAction,
    isPerforming,
    period,
    form,
    value,
  } = useReportAction({
    report,
    action: "reject",
    useFormArguments: {
      context: {},
      defaultValues: {},
      resolver: rejectActionFormResolver,
    },
  });

  return (
    <ModalExtended
      onRequestSubmit={performAction}
      onRequestClose={closeModal}
      modalLabel={MODAL_LABEL}
      modalHeading={MODAL_HEADING(period)}
      primaryButtonText={isPerforming ? <InlineLoading /> : BUTTON_LABEL}
    >
      {actionFeedback}
      <Blockquote title={REJECTING_REPORT_AS}>
        {currentUser.other_names} {currentUser.last_name}
      </Blockquote>
      <div style={{ marginTop: 16 }}>{EXPLANATION_1}</div>
      <div style={{ marginTop: 16, marginBottom: 16 }}>{EXPLANATION_2}</div>
      <FormProvider {...form}>
        <form>
          <div className="wfp--form wfp--form-long">
            <Controller
              as={
                <TextArea
                  maxLength={maxCharacters}
                  labelText={COMMENTS_FIELD_LABEL}
                  invalid={form.errors?.comments}
                  invalidText={form.errors?.comments?.message}
                  value={value}
                />
              }
              defaultValue=""
              name={`comments`}
            />
          </div>
        </form>
      </FormProvider>
    </ModalExtended>
  );
};

export default ReportModalReject;
