import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import moment from "moment";

import { Blockquote } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";
import SignatureInput from "components/SignatureInput";

import { getCurrentUser } from "data-handler/ducks/auth";
import { addReportSignature } from "data-handler/ducks/reportSignatures";
import useReportAction from "components/Report/useReportAction";

const MODAL_LABEL = (
  <FormattedMessage id="ReportActionSign.label" defaultMessage="Reporting" />
);
const MODAL_HEADING = (period) => (
  <FormattedMessage
    id="ReportActionSign.heading"
    defaultMessage="Sign Report {period}"
    values={{ period }}
  />
);
const BUTTON_LABEL = (
  <FormattedMessage id="ReportActionSign.buttonLabel" defaultMessage="Sign" />
);
const EXPLANATION_1 = (
  <FormattedMessage
    id="ReportActionSign.explanation1"
    defaultMessage="The report is closed to modifications, you may now verify its accuracy and sign it."
  />
);
const EXPLANATION_2 = (
  <FormattedMessage
    id="ReportActionSign.explanation2"
    defaultMessage="If some report data is incorrect, ask the principal to reopen the report, so that you may provide corrections."
  />
);
const SIGNING_REPORT_AS = (
  <FormattedMessage
    id="ReportActionSign.whichUser"
    defaultMessage="Signing report as"
  />
);

const SIGNATURE_FIELD_LABEL = (
  <FormattedMessage
    id="ReportActionSign.signatureLabel"
    defaultMessage="Signature"
  />
);

const ReportModalSign = ({ report }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  // useReportAction is not fully used here, as we don't need to perform BE actions
  const { closeModal, form, period } = useReportAction({
    report,
    action: "sign",
  });

  const submit = () => {
    const { signature } = form.getValues();
    if (!signature) {
      toast.error(
        <FormattedMessage
          id="ReportModalSign.failure"
          defaultMessage="You need to provide a signature"
        />
      );
    } else {
      const userName = currentUser.last_name + " " + currentUser.other_names;
      dispatch(
        addReportSignature({
          userName: userName,
          userId: currentUser.id.toString(),
          userRole: currentUser.user_role || currentUser.role,
          datetime: moment().toISOString(),
          reportYear: report.year,
          reportMonth: report.month,
          reportStartDay: report.start_day,
          reportEndDay: report.end_day,
          signature,
        })
      );
      toast.success(
        <FormattedMessage
          id="ReportModalSign.success"
          defaultMessage="Your signature has been recorded"
        />
      );
      closeModal();
    }
  };

  return (
    <ModalExtended
      onRequestSubmit={submit}
      onRequestClose={closeModal}
      modalLabel={MODAL_LABEL}
      modalHeading={MODAL_HEADING(period)}
      primaryButtonText={BUTTON_LABEL}
    >
      <Blockquote title={SIGNING_REPORT_AS}>
        {currentUser.other_names} {currentUser.last_name}
      </Blockquote>
      <div style={{ marginTop: 16 }}>{EXPLANATION_1}</div>
      <div style={{ marginTop: 16, marginBottom: 16 }}>{EXPLANATION_2}</div>
      <FormProvider {...form}>
        <form>
          <div className="wfp--form wfp--form-long">
            <Controller
              as={<SignatureInput labelText={SIGNATURE_FIELD_LABEL} />}
              name={`signature`}
            />
          </div>
        </form>
      </FormProvider>
    </ModalExtended>
  );
};

export default ReportModalSign;
