import React, { useRef, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";

import Select from "react-select";
import { Modal, colors } from "@wfp/ui";

import {
  requestLocations,
  getAllDistributionReportsNonPaginated,
  getPartnerships,
  getLocations,
  requestDistributionReportsNonPaginated,
} from "data-handler/ducks/distributionReports";
import { selectContainerStyles } from "SCConstants";

import "./_content.scss";

const SetDistributionReportModal = ({
  distributionReportOpen,
  submitNewDistributionReportProfile,
  setDistributionReportOpen,
}) => {
  const dispatch = useDispatch();
  const submitBtnRef = useRef();

  const methods = useForm();
  const { control, handleSubmit, errors, setValue, watch } = methods;

  const allDistributionReportsNonPaginated = useSelector(
    getAllDistributionReportsNonPaginated
  );
  const partnerships = useSelector(getPartnerships);
  const locations = useSelector(getLocations);
  const intl = useIntl();

  const selectedPartnership = watch("partnership");
  const selectedLocation = watch("location");
  const selectedDistributionReport = watch("distribution_report");

  useEffect(() => {
    if (selectedPartnership) {
      dispatch(requestLocations(selectedPartnership.value));
    }
    setValue("location", null);
    setValue("distribution_report", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartnership]);

  useEffect(() => {
    if (selectedLocation) {
      dispatch(requestDistributionReportsNonPaginated(selectedLocation.value));
    }
    setValue("distribution_report", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const selectFields = [
    {
      id: "partnership",
      name: "partnership",
      options: partnerships?.map((partnership) => ({
        label: partnership.code,
        value: partnership.id,
      })),
      disabled: false,
      defaultValue: null,
      labelText: (
        <FormattedMessage
          id="countryAdmin.partnership"
          defaultMessage="Partnership"
        />
      ),
      errors: errors.partnership,
      required: true,
    },
    {
      id: "location",
      name: "location",
      options: locations?.map((location) => ({
        label: `${location.admin_breakdown_name} ${location.settlement_english_name} ${location.point_of_interest_english_name}`,
        value: location.id,
      })),
      disabled: !selectedPartnership,
      defaultValue: null,
      labelText: (
        <FormattedMessage
          id="countryAdmin.location"
          defaultMessage="Location"
        />
      ),
      errors: errors.location,
      required: true,
    },
    {
      id: "distribution_report",
      name: "distribution_report",
      options: allDistributionReportsNonPaginated?.map(
        (distributionReport) => ({
          label: distributionReport.country.name,
          value: distributionReport.id,
        })
      ),
      disabled: !selectedLocation,
      defaultValue: null,
      labelText: (
        <FormattedMessage
          id="countryAdmin.distributionReport"
          defaultMessage="Distribution report"
        />
      ),
      errors: errors.distribution_report,
      required: true,
    },
  ];

  const onSubmit = () => {
    submitNewDistributionReportProfile(selectedDistributionReport.value);
    setValue("partnership", null);
    setValue("location", null);
    setValue("distribution_report", null);
    setDistributionReportOpen(false);
  };

  const standardStyleController = (disabled) => {
    return {
      control: (styles) => ({
        ...styles,
        border: "1px solid rgba(140, 164, 181, 1)",
        opacity: disabled ? "0.5" : "",
      }),
    };
  };

  return (
    <Modal
      open={distributionReportOpen}
      modalHeading={intl.formatMessage({
        id: "schools.selectDistributionReport",
        defaultMessage: "Select distribution report",
      })}
      primaryButtonText={intl.formatMessage({
        id: "Report.actionSubmit",
        defaultMessage: "Submit",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      onRequestSubmit={() => {
        submitBtnRef.current.click();
      }}
      onSecondarySubmit={() => {
        setValue("partnership", null);
        setValue("location", null);
        setValue("distribution_report", null);
        setDistributionReportOpen(false);
      }}
      onRequestClose={() => {
        setValue("partnership", null);
        setValue("location", null);
        setValue("distribution_report", null);
        setDistributionReportOpen(false);
      }}
      shouldSubmitOnEnter={true}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {selectFields.map((item) => (
            <div key={item.id} className="select-row">
              <label
                className="wfp--label"
                style={{
                  opacity: item.disabled ? "0.5" : "",
                }}
              >
                {item.labelText}
              </label>
              <Controller
                render={(props) => (
                  <Select
                    styles={
                      item.errors
                        ? selectContainerStyles.invalid
                        : standardStyleController(item.disabled)
                    }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={item.options}
                    defaultValue={item.defaultValue}
                    isMulti={false}
                    isDisabled={item.disabled}
                    value={props.value}
                    onChange={(value) => {
                      props.onChange(value);
                    }}
                  />
                )}
                id={item.id}
                name={item.name}
                defaultValue={item.defaultValue}
                rules={{ required: item.required }}
                control={control}
              />
            </div>
          ))}

          <div
            className="select-row"
            style={{ color: colors["support-01"].hex }}
          >
            {
              // Only display if there are errors.
              // Unable to use useForm message within "errors" variable as this does not support our translation method
              Object.keys(errors).length === 0 ? (
                ""
              ) : (
                <FormattedMessage
                  id="countryAdmin.schoolProfileErrors"
                  defaultMessage="Please enter a valid value within the highlighted fields"
                />
              )
            }
          </div>
          {/* Fake Button to submit form data 
              Check onConfirmationModalSubmit
              That function controls this button.                  
            */}
          <button type="submit" className="hidden-btn" ref={submitBtnRef} />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SetDistributionReportModal;
