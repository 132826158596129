import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { studentKinds, allLevels } from "SCConstants";

import TableExtended from "components/TableExtended";
import { getEnrolmentAggregateOnDate } from "helpers/enrolment";
import { getLevelAgeMappings } from "data-handler/ducks/ageGroups";

/**
 * Table displaying current enrolment across all student levels.
 *
 * Crashes if rendered without a current, ongoing school year.
 */
const EnrolmentTable = () => {
  const { rows, totals_rows } = useSelector(
    getEnrolmentAggregateOnDate(moment())
  );

  const totals = totals_rows.find((row) => row.name === "total");
  const levelMapping = useSelector(getLevelAgeMappings);
  return (
    <TableExtended>
      <thead>
        <tr>
          <th>{/* first square is empty */}</th>
          {studentKinds.map((studentKind) => (
            <th key={studentKind.value}>{studentKind.labelTrans}</th>
          ))}
          <th>
            <FormattedMessage
              id="EnrolmentTable.kindTotal"
              defaultMessage="total"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          const customName = levelMapping.find(
            (item) => item.level === row.level
          )?.custom_name;
          const defaultName = allLevels.find((item) => item.value === row.level)
            ?.label;
          const level =
            customName && customName !== "" ? customName : defaultName;
          return (
            <tr key={row.level}>
              <td>{level}</td>
              {studentKinds.map((studentKind) => (
                <td key={studentKind.value}>{row[studentKind.value]}</td>
              ))}
              <td>{row.total}</td>
            </tr>
          );
        })}
        {/* Totals */}
        <tr style={{ fontWeight: 600 }}>
          <td>
            <FormattedMessage
              id="EnrolmentTable.studentLevels"
              defaultMessage="All levels"
            />
          </td>
          {studentKinds.map((studentKind) => (
            <td key={studentKind.value}>{totals[studentKind.value]}</td>
          ))}
          <td>{totals.total}</td>
        </tr>
      </tbody>
    </TableExtended>
  );
};

export default EnrolmentTable;
