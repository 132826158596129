import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  NavLink,
} from "react-router-dom";
import { FormattedMessage } from "react-intl";

import {
  SecondaryNavigation,
  SecondaryNavigationTitle,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbHome,
  Tabs,
  Tab,
} from "@wfp/ui";
import AllSchools from "./AllSchools";
import NewSchools from "./NewSchools";
import SchoolsImports from "./SchoolsImports";

const CountryAdminSchoolsTable = () => {
  const history = useHistory();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      label: (
        <FormattedMessage
          id="CountryAdmin.AllSchools"
          defaultMessage="All Schools"
        />
      ),
      href: `/countryAdmin/schools/allSchools`,
    },
    {
      label: (
        <FormattedMessage
          id="CountryAdmin.NewSchools"
          defaultMessage="New Schools"
        />
      ),
      href: `/countryAdmin/schools/newSchools`,
    },
    {
      label: (
        <FormattedMessage
          id="CountryAdmin.schoolsImports"
          defaultMessage="Schools Imports"
        />
      ),
      href: `/countryAdmin/schools/schoolsImports`,
    },
  ];

  const setInitTabAndRedirect = () => {
    const index = tabs.findIndex((tab) => tab.href === location.pathname);
    if (index < 0) {
      setTabIndex(0);
      history.replace(tabs[0].href);
    } else {
      setTabIndex(index);
    }
  };
  useEffect(setInitTabAndRedirect, [tabs, location.pathname, history]);

  const loadTab = (index) => {
    const tab = tabs[index];
    history.push(tab ? tab.href : `countryAdmin/schools/`);
    setTabIndex(tab ? index : 0);
  };

  const tabsKey = tabs.reduce((acc, cur) => acc + cur.label, "");

  const schoolsLabel = (
    <FormattedMessage id="schools.title" defaultMessage="Schools" />
  );

  return (
    <div className="schools-table">
      <SecondaryNavigation pageWidth="lg">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/countryAdmin/">
              <BreadcrumbHome />
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink to={location.pathname}>{schoolsLabel}</NavLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <SecondaryNavigationTitle>{schoolsLabel}</SecondaryNavigationTitle>
        <Tabs key={tabsKey} onSelectionChange={loadTab} selected={tabIndex}>
          {tabs.map((tab) => (
            <Tab key={tab.href} label={tab.label} />
          ))}
        </Tabs>
      </SecondaryNavigation>
      <Switch>
        <Route
          path={`/countryAdmin/schools/allSchools`}
          component={AllSchools}
        />
        <Route
          path={`/countryAdmin/schools/newSchools`}
          component={NewSchools}
        />
        <Route
          path={`/countryAdmin/schools/schoolsImports`}
          component={SchoolsImports}
        />
      </Switch>
    </div>
  );
};

export default CountryAdminSchoolsTable;
