import { faArrowAltFromLeft, faSchool } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@wfp/ui";
import Empty from "components/Empty";
import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

export default () => (
  <Empty
    icon={<FontAwesomeIcon icon={faSchool} />}
    title="School has no profile"
    kind="large"
    button={
      <NavLink exact to={`/logoutNoSchoolProfile/`}>
        <Button
          small
          icon={<FontAwesomeIcon icon={faArrowAltFromLeft} />}
          style={{ marginBottom: "0.75rem" }}
        >
          <FormattedMessage
            id="UserNavigation.logoutButtonLabel"
            defaultMessage="Logout"
          />
        </Button>
      </NavLink>
    }
  >
    <FormattedMessage
      id="SchoolHasNoProfile.explanation"
      defaultMessage="Your school is not related to a school profile. Please contact the administrator."
    />
  </Empty>
);
