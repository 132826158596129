import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import * as Sentry from "@sentry/react";

import rootReducer from "data-handler/rootReducer";
import rootSaga from "data-handler/rootSaga";
import axiosInterceptors from "data-handler/axiosInterceptors";
import { googleAnalytics } from "data-handler/reactGAMiddlewares";
import customMiddleware from "./customMiddleware";

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // No options, but in the future we may want
  // - actionTransformer
  // - stateTransformer
  // to hide sensitive data.
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const storeEntry = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(googleAnalytics),
    applyMiddleware(customMiddleware),
    sentryReduxEnhancer
  )
);

axiosInterceptors.setupInterceptors(storeEntry);

sagaMiddleware.run(rootSaga);

export const store = storeEntry;
export const persistor = persistStore(storeEntry);
