import React from "react";
import "./_empty.scss";
import classNames from "classnames";

type EmptyProps = {
  title: React.ReactNode;
  icon: React.ReactNode;
  className?: string;
  kind?: "large" | "section";
  button?: React.ReactNode;
};
/**
 * Generic container widget
 *
 * `children` will be rendered inside a <p>.
 */
const Empty: React.ComponentType<EmptyProps> = ({
  button,
  className,
  children,
  icon,
  kind = undefined,
  title = null,
}) => {
  const emptyClasses = classNames(
    {
      empty: true,
      "empty--large": kind === "large",
      "empty--section": kind === "section",
    },
    className
  );

  return (
    <div className={emptyClasses}>
      <div className="empty__icon">{icon}</div>
      <div className="empty__text">
        {title && <h2>{title}</h2>}
        <p>{children}</p>
      </div>
      <div className="empty__button">{button}</div>
    </div>
  );
};

export default Empty;
