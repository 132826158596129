export function getCreateAdminFields(createdAdmins, countryAdminLevels) {
  // Sort in descending order
  const sortedCreatedAdmins = createdAdmins.sort((a, b) =>
    a.level < b.level ? 1 : a.level > b.level ? -1 : 0
  );
  let adminsObject = {};
  // Populate the new object with all the "child" fields
  for (
    let adminItem = 0;
    adminItem < sortedCreatedAdmins.length - 1;
    adminItem++
  ) {
    const countryAdminLevelId = countryAdminLevels.find(
      (item) => item.level === sortedCreatedAdmins[adminItem].level
    )?.id;
    adminsObject["child"] = {
      adm_level: countryAdminLevelId,
      name: sortedCreatedAdmins[adminItem].name,
      ...adminsObject,
    };
  }
  // Add the data for the first created admin level
  const countryAdminLevelId = countryAdminLevels.find(
    (item) =>
      item.level === sortedCreatedAdmins[sortedCreatedAdmins.length - 1].level
  )?.id;
  // Get the parent id of the last item
  const parentId = sortedCreatedAdmins[sortedCreatedAdmins.length - 1]?.parent;
  adminsObject = {
    parent: parentId,
    adm_level: countryAdminLevelId,
    name: sortedCreatedAdmins[sortedCreatedAdmins.length - 1].name,
    ...adminsObject,
  };
  return adminsObject;
}

export function getAdminLevelFields(data, adminFields) {
  return Object.keys(data)
    .filter((key) => key.startsWith("adm_"))
    .map((item) => {
      const parentIndex = adminFields.indexOf(item) - 1;
      const parent = adminFields[parentIndex];
      return {
        id: data[item].value,
        parent:
          parseInt(item.split("_")[1]) === 1
            ? null
            : data["adm_" + parseInt(parent.split("_")[1])]?.value,
        level: parseInt(item.split("_")[1]),
        name: data[item].label,
        isNew: data[item].__isNew__,
      };
    });
}
