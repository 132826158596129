import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { Modal, Button } from "@wfp/ui";

import moment from "moment";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Calendar, DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Select from "react-select";

import { getCurrentUser } from "data-handler/ducks/auth";
import { getIsInternal } from "helpers/users";

import { selectContainerStyles } from "SCConstants";

import styles from "./styles.module.scss";
import "./_content.scss";
import "react-multi-date-picker/styles/layouts/prime.css";
import {
  getRawDataExtractionLinks,
  requestLinks,
} from "data-handler/ducks/links";
import { getLanguage } from "data-handler/ducks/language";

const generateAllDates = () => {
  const months = [];
  const dateEnd = moment();
  const dateStart = moment().subtract({ years: 3 });

  while (dateEnd.diff(dateStart, "months") >= 0) {
    // To display all dates in the "Date Panel" they need to be of DateObject Type.
    const date = new DateObject();
    date.set({
      month: Number(dateStart.format("MM")),
      year: Number(dateStart.format("YYYY")),
    });
    months.push(date);
    dateStart.add(1, "month");
  }
  return months;
};

type RawDataExtractModalProps = {
  state: boolean;
  setState: (state: boolean) => void;
};

const RawDataExtractModal: React.ElementType<RawDataExtractModalProps> = ({
  state,
  setState,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const submitBtnRef = useRef();
  const methods = useForm();
  const currentUser = useSelector(getCurrentUser);
  const language = useSelector(getLanguage);
  const [dateValue, setDateValue] = useState([] as DateObject[]);
  const { control, handleSubmit, errors, register, setValue } = methods;
  const rawDataExtractionLinks: any = useSelector(getRawDataExtractionLinks);
  const isInternal = getIsInternal(currentUser?.email);

  useEffect(() => {
    dispatch(
      requestLinks({ type: "raw_data_extraction", internal: isInternal })
    );
  }, [dispatch, isInternal]);

  const dateErrorMessage = (
    <label className="wfp--form-required" htmlFor="months">
      <FormattedMessage
        id="rawDataExtraction.errorMonthLabel"
        defaultMessage="* Please select at least 1 month"
      />
    </label>
  );

  const dateTitle = (
    <label className="wfp--label" htmlFor="months">
      <FormattedMessage
        id="rawDataExtraction.selectMonthsLabel"
        defaultMessage="Months"
      />
    </label>
  );

  const dateHelperText = (
    <label className="wfp--form__helper-text" htmlFor="months">
      <FormattedMessage
        id="rawDataExtraction.selectMonthsHelperLabel"
        defaultMessage="Select all months for which you are downloading"
      />
    </label>
  );

  const dataTypeErrorMessage = (
    <label className="wfp--form-required" htmlFor="dataType">
      <FormattedMessage
        id="rawDataExtraction.errorDataTypeLabel"
        defaultMessage="* Please select at least 1 category"
      />
    </label>
  );

  const dataTypeTitle = (
    <label className="wfp--label" htmlFor="raw data select">
      <FormattedMessage
        id="rawDataExtraction.selectDataTypeLabel"
        defaultMessage="Data Categories"
      />
    </label>
  );

  const allLabel = (
    <FormattedMessage
      id="Common.all"
      defaultMessage="All months from today to {date}"
      values={{ date: moment().subtract({ years: 3 }).format("MM/YYYY") }}
    />
  );

  const dataTypeHelperText = (
    <label className="wfp--form__helper-text" htmlFor="months">
      <FormattedMessage
        id="rawDataExtraction.selectDataTypeHelperLabel"
        defaultMessage="Select all categories for which you are downloading"
      />
    </label>
  );

  const DatePanelHeaderLabel = (
    <FormattedMessage
      id="rawDataExtraction.datePanelHeaderLabel"
      defaultMessage="Selected Months"
    />
  );

  const rawDataOptions = rawDataExtractionLinks.map((item: any) => ({
    label: item[`name_${language}`],
    value: item,
  }));

  const onSubmit = (values: any) => {
    values.type.forEach(({ value }: any) => {
      let params = undefined;
      let date = undefined;
      if (values.date === "all") {
        const dateList = generateAllDates();
        date = dateList
          .map((item) => `${item.month.number}-${item.year}`)
          .join(",");
      } else {
        // Remove 0 from months such as 01, 02, 03 etc.
        const dates = values.date.split(",") || [];
        let formattedDates: string[] = [];
        dates.forEach((item: any) => {
          if (item[0] === "0") {
            formattedDates.push(item.substring(1));
          } else {
            formattedDates.push(item);
          }
        });
        date = formattedDates.join(",");
      }
      params = value.usesParameters ? "?MonthYear=" + date : "";
      const url = value.url + params;
      /**
       * Tableau requires login therefore unable to do a seamless download
       * When Tableau implements passing of user infomation we need to open links in a new tab.
       */
      window.open(url);
    });
    setState(false);
  };

  return (
    <Modal
      open={state}
      modalHeading={intl.formatMessage({
        id: "rawDataExtraction.title",
        defaultMessage: "Raw Data Extraction",
      })}
      primaryButtonText={intl.formatMessage({
        id: "rawDataExtraction.download",
        defaultMessage: "Download",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      onRequestClose={() => setState(false)}
      onRequestSubmit={() => (submitBtnRef.current as any).click()}
      selectorPrimaryFocus={true}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="wfp--form wfp--form-long"
        >
          <div className={styles.column}>
            <div>
              <div>
                {dateTitle}
                {dateHelperText}
              </div>
              <Controller
                control={control}
                name="date"
                render={({ onChange, name }: any) => {
                  return (
                    <Calendar
                      value={dateValue}
                      className={
                        errors.hasOwnProperty("date")
                          ? "rmdp-prime rmdp-prime-error"
                          : "rmdp-prime"
                      }
                      multiple
                      onlyMonthPicker
                      onChange={(array: any) => {
                        onChange(array.join(",").replaceAll("/", "-"));
                      }}
                      /*
                      // @ts-ignore */
                      name={name}
                      shadow={false}
                      styles={{ width: "auto" }}
                      datePanel={{ right: true }}
                      plugins={[
                        <DatePanel
                          sort="date"
                          /*
                          // @ts-ignore */
                          header={DatePanelHeaderLabel}
                        />,
                      ]}
                    >
                      <Button
                        onClick={() => {
                          setValue("date", "all");
                          setDateValue(generateAllDates());
                        }}
                      >
                        {allLabel}
                      </Button>
                    </Calendar>
                  );
                }}
                rules={{ required: true }}
              />
              <div>{errors.hasOwnProperty("date") && dateErrorMessage}</div>
            </div>
            <div>
              <div>
                {dataTypeTitle}
                {dataTypeHelperText}
              </div>
              <Controller
                control={control}
                name="type"
                render={({ onChange, value }) => (
                  <Select
                    styles={
                      errors?.hasOwnProperty("type")
                        ? selectContainerStyles.invalid
                        : selectContainerStyles.valid
                    }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={rawDataOptions}
                    onChange={onChange}
                    value={value}
                    defaultValue
                    isMulti
                  />
                )}
                options={rawDataOptions}
                rules={{ required: true }}
                inputRef={register}
              />
              <div>{errors.hasOwnProperty("type") && dataTypeErrorMessage}</div>
            </div>
          </div>
          <div className={styles.hidden}>
            <button type="submit" ref={submitBtnRef as any} />
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default RawDataExtractModal;
