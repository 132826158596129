const ADD = "schoolconnect/reportSignatures/ADD";
const REMOVE = "schoolconnect/reportSignatures/REMOVE";

export default (state = [], action) => {
  switch (action.type) {
    case ADD:
      return [...state, action.signature];

    case REMOVE:
      const {
        userId,
        reportYear,
        reportMonth,
        reportStartDay,
        reportEndDay,
      } = action.filters;

      // Keep the non-matching signatures
      return state.filter(
        (signature) =>
          !(
            (userId === null || userId.toString() === signature.userId) &&
            (reportYear === null || reportYear === signature.reportYear) &&
            (reportMonth === null || reportMonth === signature.reportMonth) &&
            (reportStartDay === null ||
              reportStartDay === signature.reportStartDay) &&
            (reportEndDay === null || reportEndDay === signature.reportEndDay)
          )
      );

    default:
      return state;
  }
};

export const addReportSignature = ({
  datetime,
  reportMonth,
  reportYear,
  reportStartDay,
  reportEndDay,
  signature,
  userId,
  userName,
  userRole,
}) => ({
  type: ADD,
  signature: {
    datetime,
    reportMonth,
    reportYear,
    reportStartDay,
    reportEndDay,
    signature,
    userId,
    userName,
    userRole,
  },
});

/**
 * Drops signatures which match the given parameters (drops all if no parameters)
 */
export const removeReportSignatures = ({
  userId = null,
  reportYear = null,
  reportMonth = null,
  reportStartDay = null,
  reportEndDay = null,
}) => ({
  type: REMOVE,
  filters: { userId, reportYear, reportMonth, reportStartDay, reportEndDay },
});

export const getAllReportSignatures = (state) => state.reportSignatures;

/**
 * Returns a selector which returns an array of signatures matching the given params
 */
export const getFilteredReportSignatures = ({
  reportYear = null,
  reportMonth = null,
  reportStartDay = null,
  reportEndDay = null,
  userId = null,
  userRole = null,
}) => (state) =>
  state.reportSignatures.filter(
    (signature) =>
      (userId === null || userId.toString() === signature.userId) &&
      (userRole === null || userRole === signature.userRole) &&
      (reportYear === null || reportYear === signature.reportYear) &&
      (reportMonth === null || reportMonth === signature.reportMonth) &&
      (reportStartDay === null ||
        reportStartDay === signature.reportStartDay) &&
      (reportEndDay === null || reportEndDay === signature.reportEndDay)
  );
