import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Blockquote, InlineLoading, TextArea } from "@wfp/ui";

import ModalExtended from "components/ModalExtended";

import { getCurrentUser } from "data-handler/ducks/auth";
import reportsDuck from "data-handler/ducks/reports";
import {
  getAllStores,
  getDeliveryAttendancesByDateRange,
  getSchoolYearByDate,
  getUnsyncedStores,
} from "data-handler/ducks/stores";
import { getWeekDay } from "helpers/dates";

import useReportAction from "./useReportAction";

import { Controller, FormProvider } from "react-hook-form";

import { getReportableMonthsBySyncables } from "./index";

const MODAL_LABEL = (
  <FormattedMessage id="ReportActionClose.label" defaultMessage="Reporting" />
);
const MODAL_HEADING = (period) => (
  <FormattedMessage
    id="ReportActionClose.heading"
    defaultMessage="Close Report {period}"
    values={{ period }}
  />
);
const BUTTON_LABEL = (
  <FormattedMessage
    id="ReportActionClose.buttonLabel"
    defaultMessage="Close Report"
  />
);
const EXPLANATION_1 = (
  <FormattedMessage
    id="ReportActionClose.explanation1"
    defaultMessage="Before providing signatures, you must first close the report, to prevent further edits."
  />
);
const EXPLANATION_2 = (
  <FormattedMessage
    id="ReportActionClose.explanation2"
    defaultMessage="Please ensure you have checked all report data before closing."
  />
);
const CLOSING_REPORT_AS = (
  <FormattedMessage
    id="ReportActionClose.whichUser"
    defaultMessage="Closing report as"
  />
);
const COMMENTS_FIELD_LABEL = (
  <FormattedMessage
    id="ReportActionClose.commentsLabel"
    defaultMessage="Comments (optional)"
  />
);
const REPORT_CANNOT_BE_CLOSED = (
  <FormattedMessage
    id="ReportActionClose.reportCannotBeClosed"
    defaultMessage="Report cannot be closed"
  />
);

export const getSchoolDays = (date0, date1, schoolWeekdays) => {
  let schoolDays = [];
  let occurred_on = moment(date0);
  while (occurred_on.isSameOrBefore(date1)) {
    if (schoolWeekdays[getWeekDay(occurred_on)]) {
      schoolDays.push(occurred_on.format("YYYY-MM-DD"));
    }
    occurred_on = occurred_on.add(1, "day");
  }
  return schoolDays;
};

const previousReportNotSubmitted = (
  allStores,
  yearsMonths,
  allReports,
  currentReport,
  currentSchoolYear
) => {
  // Get all the reports from the current school year and sort them from newest to oldest
  // Also filter reports to only include reports with syncables (some in backend but not in stores)
  const currentSchoolYearReports = allReports
    .filter((item) => {
      return (
        item.school_year === currentSchoolYear.object_id &&
        yearsMonths.some((e) => e.year === item.year && e.month === item.month)
      );
    })
    .sort((a, b) =>
      moment(`${b.year}/${b.month}/${b.start_day}`).isAfter(
        `${a.year}/${a.month}/${a.start_day}`
      )
        ? 1
        : -1
    );
  // Fetch the previous month report from current report by index
  const currentReportIndex = currentSchoolYearReports.findIndex((object) => {
    return (
      object.month === currentReport.month && object.year === currentReport.year
    );
  });
  const previousMonth = currentSchoolYearReports[currentReportIndex + 1];
  // Perform check if the previous month report is approved/submitted
  // also check if it's the last report in school year (no previous month)
  const previousReportSubmitRequired =
    previousMonth !== undefined &&
    !["approved", "submitted"].includes(previousMonth?.state);
  // return the report requiring submit start data for validation values
  const reportRequiringSubmitStartDate = moment(
    `${previousMonth?.year}/${previousMonth?.month}/${previousMonth?.start_day}`
  );
  return {
    previousReportSubmitRequired,
    reportRequiringSubmitStartDate,
  };
};

// Check if the report can be closed by verifying the data for the defined school days
const checkIfReportCanBeClosed = (schoolDays, attendances) => {
  let attendanceDaysToBeAdded = [];
  let consumptionDaysToBeAdded = [];
  let contradictingDataDays = [];

  // Go through all the days in the school days
  // and check if it has valid data
  schoolDays.forEach((day) => {
    const attendanceStoreObject = attendances.find(
      (item) => item.occurred_on === day
    );
    if (!attendanceStoreObject) {
      // If there is no attendance and no consumption,
      // add the day in the attendance and consumption flag
      attendanceDaysToBeAdded.push(day);
      consumptionDaysToBeAdded.push(day);
    } else {
      if (
        attendanceStoreObject.levels?.length > 0 &&
        !attendanceStoreObject.consumption
      ) {
        // If there is attendance but no consumption,
        // add the day in the consumption flag
        consumptionDaysToBeAdded.push(day);
      } else if (
        !attendanceStoreObject.levels?.length > 0 &&
        attendanceStoreObject.consumption &&
        (attendanceStoreObject.consumption.no_meal_reasons?.length < 0 ||
          (!attendanceStoreObject?.consumption?.no_meal_reasons?.includes(1) &&
            !attendanceStoreObject?.consumption?.no_meal_reasons?.includes(5) &&
            !attendanceStoreObject?.consumption?.no_meal_reasons?.includes(7)))
      ) {
        // If there is no attendance but there is consumption with
        // 0 no-meal-reasons or with no-meal-reasons, except pedagogical
        // day-off,holiday or no class today, add the day in the attendance flag
        attendanceDaysToBeAdded.push(day);
      } else if (
        attendanceStoreObject.levels?.length > 0 &&
        attendanceStoreObject.consumption &&
        (attendanceStoreObject?.consumption?.no_meal_reasons?.includes(1) ||
          attendanceStoreObject?.consumption?.no_meal_reasons?.includes(5) ||
          attendanceStoreObject?.consumption?.no_meal_reasons?.includes(7))
      ) {
        // If there is attendance but there is consumption with pedagogical
        // day-off or holiday reason, add the day in the contradicting data flag
        contradictingDataDays.push(day);
      }
    }
  });
  return {
    attendanceDaysToBeAdded,
    consumptionDaysToBeAdded,
    contradictingDataDays,
  };
};

const ReportModalClose = ({ report }) => {
  const currentUser = useSelector(getCurrentUser);
  const unsyncedStores = useSelector(getUnsyncedStores);
  const {
    actionFeedback,
    closeModal,
    performAction,
    isPerforming,
    period,
    form,
  } = useReportAction({
    report,
    action: "close",
  });
  const reports = useSelector(reportsDuck.getList);
  const reportStartDate = moment(
    `${report.year}/${report.month}/${report.start_day}`
  );
  const reportEndDate = moment(
    `${report.year}/${report.month}/${report.end_day}`
  );

  // Get the associated school year
  const schoolYear = useSelector(getSchoolYearByDate(reportStartDate));

  // Get syncable report years and months
  const allStores = useSelector(getAllStores);
  const yearsMonths = useSelector((state) =>
    getReportableMonthsBySyncables(allStores, state)
  );

  // do not allow the current report to be closed
  // if the previous month report is unapproved/unsubmitted,
  const {
    previousReportSubmitRequired,
    reportRequiringSubmitStartDate,
  } = previousReportNotSubmitted(
    allStores,
    yearsMonths,
    reports,
    report,
    schoolYear
  );

  // Get all the attendances store objects for the report's month
  const attendances = useSelector(
    getDeliveryAttendancesByDateRange(reportStartDate, reportEndDate)
  );

  // Get the school days for the report's month
  const schoolDays = getSchoolDays(
    reportStartDate.format("YYYY-MM-DD"),
    reportEndDate.format("YYYY-MM-DD"),
    schoolYear.weekdays
  );

  const {
    attendanceDaysToBeAdded,
    consumptionDaysToBeAdded,
    contradictingDataDays,
  } = checkIfReportCanBeClosed(schoolDays, attendances);

  const canCloseReport =
    attendanceDaysToBeAdded.length === 0 &&
    consumptionDaysToBeAdded.length === 0 &&
    contradictingDataDays.length === 0 &&
    !previousReportSubmitRequired;

  const getValuesForFormattedMessage = (data) => ({
    days: data.map((item) => moment(item).date()).join(", "),
    month: reportStartDate.format("MMMM"),
  });

  return (
    <ModalExtended
      onRequestSubmit={performAction}
      onRequestClose={closeModal}
      modalLabel={MODAL_LABEL}
      modalHeading={MODAL_HEADING(period)}
      primaryButtonText={isPerforming ? <InlineLoading /> : BUTTON_LABEL}
      primaryButtonDisabled={!canCloseReport}
    >
      {canCloseReport ? (
        <div>
          {actionFeedback}
          <Blockquote title={CLOSING_REPORT_AS}>
            {currentUser.other_names} {currentUser.last_name}
          </Blockquote>
          <div style={{ marginTop: 16 }}>{EXPLANATION_1}</div>
          <div style={{ marginTop: 16, marginBottom: 16 }}>{EXPLANATION_2}</div>
          <FormProvider {...form}>
            <form>
              <div className="wfp--form wfp--form-long">
                <Controller
                  as={<TextArea labelText={COMMENTS_FIELD_LABEL} />}
                  defaultValue=""
                  name={`comments`}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      ) : (
        <div>
          <Blockquote kind="error" title={REPORT_CANNOT_BE_CLOSED}>
            {attendanceDaysToBeAdded.length > 0 && (
              <p>
                <FormattedMessage
                  id="ReportActionClose.addAttendanceData"
                  defaultMessage="Please add attendance data on {days} {month}"
                  values={getValuesForFormattedMessage(attendanceDaysToBeAdded)}
                />
              </p>
            )}
            {consumptionDaysToBeAdded.length > 0 && (
              <p>
                <FormattedMessage
                  id="ReportActionClose.addConsumptionData"
                  defaultMessage="Please add meal data on {days} {month}"
                  values={getValuesForFormattedMessage(
                    consumptionDaysToBeAdded
                  )}
                />
              </p>
            )}
            {contradictingDataDays.length > 0 && (
              <p>
                <FormattedMessage
                  id="ReportActionClose.contradictingDataDays"
                  defaultMessage="Please correct contradicting data on {days} {month}, with holiday/pedagogical day-off, but entered attendance"
                  values={getValuesForFormattedMessage(contradictingDataDays)}
                />
              </p>
            )}
            {previousReportSubmitRequired && (
              <p>
                <FormattedMessage
                  id="ReportActionClose.previousReportSubmitRequired"
                  defaultMessage="Please submit the previous months report for {month} {year}"
                  values={{
                    month: reportRequiringSubmitStartDate.format("MMMM"),
                    year: reportRequiringSubmitStartDate.format("YYYY"),
                  }}
                />
              </p>
            )}
            {unsyncedStores.length > 0 && (
              <p>
                <FormattedMessage
                  id="ReportActionClose.previousSyncUnsynced"
                  defaultMessage="Please synchronise all data before closing the report"
                />
              </p>
            )}
          </Blockquote>
        </div>
      )}
    </ModalExtended>
  );
};

export default ReportModalClose;
