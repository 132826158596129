// TODO this file seems to be the wrong place for this code.
// if there is a better place move this file.
import { deliveryCategory, mixedCategory } from "SCConstants";

const allEqual = <T extends unknown>(a: T[]): boolean =>
  a.every((e) => a[0] === e);

export function getCategory(
  getValues: () => { commodities: { category: string }[] }
) {
  const categoryPerCommodity = getValues()["commodities"]?.map(
    (commodity: { category: string }) => commodity.category
  );

  var consumptionCategory;
  if (!categoryPerCommodity || categoryPerCommodity?.length === 0) {
    consumptionCategory = deliveryCategory;
  } else if (allEqual(categoryPerCommodity)) {
    consumptionCategory = categoryPerCommodity[0];
  } else {
    consumptionCategory = mixedCategory;
  }

  return consumptionCategory;
}
