import React from "react";
import ModalExtended from "../ModalExtended";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getOnlineRequiredError,
  unsetOnlineRequiredError,
} from "../../data-handler/ducks/auth";
import { useHistory } from "react-router";

function OnlineRequiredModal() {
  const onlineRequiredError = useSelector(getOnlineRequiredError);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const closeWindow = () => {
    dispatch(unsetOnlineRequiredError());
    history.push(`${history.location.pathname}?nav=true`);
  };

  const requireOnlineLogin = () => {
    history.push("/corporateLogin");
  };
  return (
    <ModalExtended
      open={onlineRequiredError}
      onRequestSubmit={requireOnlineLogin}
      onSecondarySubmit={closeWindow}
      onRequestClose={closeWindow}
      modalLabel={intl.formatMessage({
        id: "RequireOnlineLogin.label",
        defaultMessage: "Online login",
      })}
      modalHeading={intl.formatMessage({
        id: "RequireOnlineLogin.heading",
        defaultMessage: "Log in as an online user",
      })}
      wide={false}
      primaryButtonText={intl.formatMessage({
        id: "RequireOnlineLogin.primaryButton",
        defaultMessage: "Login",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "RequireOnlineLogin.cancel",
        defaultMessage: "Continue offline",
      })}
    >
      <FormattedMessage
        id="RequireOnlineLogin.mainMessage"
        defaultMessage="The action you were trying to perform requires you to log in as an online user. You can log in as on online user (if you have an online account) or continue in offline mode."
      />
    </ModalExtended>
  );
}

export default OnlineRequiredModal;
