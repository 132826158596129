import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getCurrentSchool } from "data-handler/ducks/schools";
import { getStoreByClientId } from "data-handler/ducks/stores";

import AttendanceItem from "./AttendanceItem";
import DeliveryItem from "./DeliveryItem";
import EnrolmentItem from "./EnrolmentItem";
import IncidentItem from "./IncidentItem";
import YearItem from "./YearItem";
import PurchaseItem from "components/ItemSwitcher/PurchaseItem";
import OfflineUserItem from "components/ItemSwitcher/OfflineUserItem";

const MODEL_TO_ITEM_MAP = {
  attendance: AttendanceItem,
  delivery: DeliveryItem,
  incident: IncidentItem,
  takehomeration: DeliveryItem,
  purchasedetail: PurchaseItem,
  enrolment: EnrolmentItem,
  year: YearItem,
  offlineuser: OfflineUserItem,
};

const ItemSwitcher = ({ store, ...extraProps }) => {
  const params = useParams();
  const school = useSelector(getCurrentSchool);
  const currentStoreData = useSelector(getStoreByClientId(params.item));

  const AppropriateItemSwitcher = MODEL_TO_ITEM_MAP[store.model];
  return (
    <AppropriateItemSwitcher
      store={store}
      schoolId={school && school.id}
      currentStoreData={currentStoreData}
      {...extraProps}
    />
  );
};

export default ItemSwitcher;
